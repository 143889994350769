:root {
  --score-selected: rgb(201, 233, 255);
  --score-selected-complete: rgb(0, 88, 146);
  --score-selected-c: rgb(66, 66, 66);
  --score-progress: rgb(189, 255, 205);
  --score-def: rgb(14, 214, 30);
  --score-past: rgb(255, 197, 197);
  --score-nr: rgb(208, 208, 208);
  --score-complete: rgb(233, 247, 252);
  --score-scores: rgb(24, 206, 39);
  --score-rt: rgb(219, 251, 219);
  --score-type: rgb(219, 219, 219);
  --score-winner: rgb(0, 141, 7);
  --match-winner: rgb(0, 0, 0);
  --match-team: rgb(73, 73, 73);
  --score-blue-back: rgb(56, 117, 209);
  --score-blue: rgb(69, 142, 252);
  --score-red: #db2828;
  --score-green: #21ba45;
  --score-grey: #767676;
  --score-dark-grey: #707070;
  --score-black: #1b1c1d;
  --section-grid-header: rgb(54, 68, 90);
  --score-divider: rgb(223, 223, 223);
  --upset: rgb(248, 255, 153);
  --match-loss: #db2828;
  --match-win: #21ba45;
  --header-light: #f0f0f0;
  --date-wrapper: rgb(215, 215, 215);
  --bracket-wrapper: rgb(96, 96, 96);
  --match-same: rgb(66, 66, 66);
  --match-bye: rgb(0, 52, 71);
  --br-8: 8px;
  --playoff-bg: rgb(16, 12, 37);
}

.p-bracket.roundOf16 {
  display: grid;
  grid-template-columns: repeat(2, 50%) !important;
  grid-template-rows: repeat(8, 1fr) !important;
  grid-template-areas:
    "p-r16-1"
    "p-r16-2"
    "p-r16-3"
    "p-r16-4"
    "p-r16-5"
    "p-r16-6"
    "p-r16-7"
    "p-r16-8"
  ;
}

.p-bracket.quarterFinals {
  display: grid;
  grid-template-columns: repeat(4, 50%) !important;
  grid-template-rows: repeat(4, 1fr) !important;
  grid-template-areas:
    "p-qf-1"
    "p-qf-2"
    "p-qf-3"
    "p-qf-4"
  ;
}

.p-bracket.semiFinals {
  display: grid;
  grid-template-columns: repeat(4, 50%) !important;
  grid-template-rows: repeat(2, 1fr) !important;
  grid-template-areas:
    "p-sf-1"
    "p-sf-2"
  ;
}

.p-bracket>div {
  padding: .25em;
}

.p-r16-1 {
  grid-area: p-r16-1;
}

.p-r16-1>.match-grid,
.p-r16-3>.match-grid,
.p-r16-6>.match-grid,
.p-r16-8>.match-grid,
.p-qf-1>.match-grid,
.p-qf-4>.match-grid {
  border: 1px solid var(--bracket-wrapper);
  border-bottom: 1px solid var(--off-blackish-bc);
  ;
  border-radius: 4px 4px 0 0;
}

.p-r16-2>.match-grid,
.p-r16-4>.match-grid,
.p-r16-5>.match-grid,
.p-r16-7>.match-grid,
.p-qf-2>.match-grid,
.p-qf-3>.match-grid {
  border: 1px solid var(--bracket-wrapper);
  border-top: 1px solid var(--off-blackish-bc);
  border-radius: 0 0 4px 4px;
}


.p-r16-2 {
  grid-area: p-r16-2;
}

.p-r16-3 {
  grid-area: p-r16-3;
}

.p-r16-4 {
  grid-area: p-r16-4;
}

.p-r16-5 {
  grid-area: p-r16-5;
}

.p-r16-6 {
  grid-area: p-r16-6;
}

.p-r16-7 {
  grid-area: p-r16-7;
}

.p-r16-8 {
  grid-area: p-r16-8;
}

.p-qf-1 {
  grid-area: p-qf-1;
}

.p-qf-2 {
  grid-area: p-qf-2;
}

.p-qf-3 {
  grid-area: p-qf-3;
}

.p-qf-4 {
  grid-area: p-qf-4;
}

.p-sf-1 {
  grid-area: p-sf-1;
}

.p-sf-2 {
  grid-area: p-sf-2;
}

.matches-filter {
  display: grid;
  height: 100% !important;
}

.matches-filter>.content {
  height: 100%;
  overflow-y: auto;
}

.sports-container {
  padding: .15em;
}

.list-create-teams.list>.item>.content>.description {
  font-size: .8rem !important;
}

.sports-active>.item {
  font-weight: bold !important;
}

.mi-sports>.item {
  display: grid !important;
  grid-template-columns: 5fr 1fr !important;
  grid-template-rows: 1fr 1fr !important;
  grid-template-areas:
    "mi-sports-name mi-sports-active"
    "mi-sports-season mi-sports-season";
}

.mi-sports-name {
  grid-area: mi-sports-name;
  font-weight: bold;
}

.mi-sports-active {
  grid-area: mi-sports-active;
  text-align: right;
}

.mi-sports-season {
  grid-area: mi-sports-season;
}


.match-scoring-container {
  height: 100%;
}

.match-scoring-container>.segment {
  padding: .5em;
}

.match-scoring-container>.segment>.grid {
  margin: 0;
}

.match-scoring-container>.segment>.grid>.row:first-child {
  font-weight: bold;
}

.athletes-container {
  height: 100%;
  padding: 0 !important;
  background-color: rgb(200, 200, 200);
}

.athletes-container {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.athletes-container>.athlete {
  display: grid !important;
  grid-template-columns: 1fr 6fr 1fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  grid-template-areas:
    "athlete-icon athlete-details athlete-number";
}

.athletes-container.golfers {
  padding: 0 !important;
  display: grid !important;
  grid-template-rows: auto 1fr;
  height: 100%;
  background-color: var(--sem-black) !important;
}

.athletes-container.golfers>div:nth-child(2) {
  height: 100%;
  overflow-y: auto;
}

.athletes-container>.athlete.golfers {
  grid-template-columns: 1fr 1fr 6fr 1fr !important;
  grid-template-areas:
    "athlete-index athlete-icon athlete-details athlete-number";
}

.athletes-container>.athlete-team-golfers>.athlete-team {
  display: grid !important;
  grid-template-columns: 3fr 2fr 3fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  font-size: .85rem;
  font-weight: bold;
  grid-template-areas:
    "athlete-details-1 athlete-number-1 athlete-number-2 athlete-details-2";
}

.athlete-details-2 {
  text-align: right;
}

.athlete-number-1,
.athlete-number-2,
.athlete-index {
  text-align: center;
}

.athlete-icon {
  grid-area: athlete-icon;
}

.athlete-icon {
  grid-area: athlete-icon;
  text-align: center;
}

.athlete-details {
  grid-area: athlete-details;
  padding: 0 .5em;
  opacity: .8;
}

.athlete-number {
  grid-area: athlete-number;
  text-align: center;
}

.athlete-number>.label {
  min-width: 30px;
}

.athlete-number-1 {
  border-right: 1px solid rgb(216, 216, 216);
}

.athlete-number-2 {
  border-left: 1px solid rgb(216, 216, 216);
}

.athletes-container>.athlete .athlete-name {
  font-weight: bold !important;
  opacity: 1 !important
}

.athlete-icon>i.icon {
  margin: 0 !important
}

.athlete-team-select {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  text-align: center;
}

.athlete-team-select .label {
  width: 99% !important;
  padding: 1em .1em;
  background-color: rgb(173, 173, 173);
}

.athlete-team-header {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  text-align: center;
  padding: .5em;
  font-weight: bold;
}

.athlete-team-header-select {
  display: grid !important;
  grid-template-rows: 1fr 1fr !important;
  text-align: center;
  padding: .5em;
  font-weight: bold;
}

.athlete-team-header-select>div {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
}

.player-split {
  display: grid !important;
  grid-template-rows: 1fr 1fr !important;
  gap: 1em;
}

.drop-list>.athletes-container>.athlete {
  margin-bottom: .5em !important;
  background-color: white !important;
}

.ddp>.athletes-container {
  margin: 0 !important;
}

.ddp>.athletes-container>.athlete {
  margin: 0 !important;
  padding: .25em !important;
  background-color: rgb(228, 228, 228) !important;
}

.portal>div {
  width: 100%;
  /* padding: 1em !important; */
}

.grid-row {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

.grid-row>div>div {
  padding: 5em !important;
  align-items: center;
}

.match-scoring-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.team-history .winner-history,
.team-history .runnerUp-history,
.team-history .no-history {
  text-align: center;
  font-size: .8rem;
  padding: .5em;
}

.team-history .no-history {
  text-align: left;
}

.team-history .winner-history>div:nth-child(2),
.team-history .runnerUp-history>div:nth-child(2) {
  display: flex;
  gap: .5em 1em;
  flex-wrap: wrap;
}

.team-history .winner-history>div:nth-child(2),
.team-history .runnerUp-history>div:nth-child(2) {
  font-size: .8rem;
  text-align: left;
}

.team-history .winner-history span,
.team-history .runnerUp-history span {
  color: grey;
  margin-left: .25em
}

.score-type {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(3, 1fr);
}

.score-type>div.selected {
  padding: .5em;
  background-color: var(--score-selected);
  color: var(--score-selected-c);
}

.sets {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(5, 1fr);
  ;
}

/* AIzaSyDayDpX0nEt_xJ38e83UDRlq2rV_iW_dSg */

/* AIzaSyDqAm-McPCZRjYeKhcTOmtXrKvEFh4JvIE */
/* AIzaSyDqAm-McPCZRjYeKhcTOmtXrKvEFh4JvIE */
/* AIzaSyD9kyNb74Q9g6dlZQlvDnjG8epC54cbLUU */
/* AIzaSyD9kyNb74Q9g6dlZQlvDnjG8epC54cbLUU */
/* AIzaSyD9kyNb74Q9g6dlZQlvDnjG8epC54cbLUU */

/* GSAPIKEY */
/* AIzaSyDayDpX0nEt_xJ38e83UDRlq2rV_iW_dSg */

/* AIzaSyBAnPqX2PmWY_cxjm48cUyyYDBEMGmxvPQ */

.sets>div {
  padding: .25em;
  border: 1px solid rgb(214, 214, 214);
  margin: .1em;
  border-radius: var(--br-8);
}

.sets>div.selected {
  background-color: var(--score-selected);
  color: var(--score-selected-c);
  font-weight: bold;
}

.sidebar .match-set-scoring {
  background-color: rgb(38, 37, 44) !important;
}

.match-set-scoring {
  padding: 1em .5em;
  z-index: 9999 !important;
}

.match-set-scoring>.header,
.match-set-scoring>.content {
  padding: .25em
}

.match-score-table {
  grid-area: match-score-table;
  grid-template-rows: repeat(2, 1fr);
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  padding: .5em !important;
  gap: 4px;
  background-color: black !important;
  color: white;
}

.schedule-match .match-score-table {
  border: 3px solid var(--off-blackish-bc)
}

.match-score-table>div:not(:first-child) {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: 7fr repeat(6, 1fr);
  gap: 1px;
}

.match-score-table>div>div {
  padding: .15em
}

.match-score-table>div:not(:first-child)>div:first-child {
  text-align: left;
}

.scores {
  display: grid;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  align-items: center;
  text-align: center;
  grid-template-rows: repeat(1, 1fr);
  ;
  grid-template-columns: repeat(2, 1fr);
}

.scores.single {
  grid-template-columns: repeat(1, 1fr);
}

.scores-set {
  text-align: center;
  padding: 0 0 .5em 0;
  font-weight: bold;
  border-bottom: 1px solid gray;
}

.scores-teams {
  display: grid;
  width: 100%;
  text-align: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

.scores-teams>div {
  /* display: grid;
  width: 100%;  */
  padding: .5em;
  /* grid-template-rows: auto 1fr; */
}

.scores .scoring-team {
  display: grid;
  width: 100%;
  gap: 1px;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: 1fr;   */
  overflow-y: auto;
  height: 100%;
  padding: .25em;
}

.scores .sets-team {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(4, 1fr);
}

.scores .sets-team>div {
  padding: .5em;
  font-weight: bold;
}

.scores .scoring-team>div {
  padding: .25em;
  background-color: rgb(233, 233, 233);
  border: 1px solid lightblue;
  border-radius: 4px;
}

.scores .scoring-team.ns>div {
  padding: .25em;
  background-color: rgb(233, 233, 233);
  border-width: 0 !important;
  border-radius: 4px;
}

.scores .sets-team>div.selected,
.scores .scoring-team>div.selected {
  background-color: var(--score-selected);
  color: var(--score-selected-c)
}

.scoring-team>div.def {
  border-color: var(--score-def);
  font-weight: bold;
}

.scores-team,
.match-score-table>div:not(:first-child)>div:first-child {
  font-weight: bold;
}

.match-score-table .winner {
  background-color: var(--score-winner);
  font-weight: bold;
}

.scoring-header>div.selected {
  background-color: var(--score-selected);
  color: var(--score-selected-c);
}

.scoring-header>div.complete {
  background-color: var(--score-selected-complete);
  color: white;
}

.scoring-row-1>div:not(:first-child),
.scoring-row-2>div:not(:first-child),
.scoring-header>div:not(:first-child) {
  border-radius: var(--br-8);
}

.match-details {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: repeat(3, 1fr);
  background: #fff;
  width: 100%;
  align-items: center;
  margin: 0 0 .25em 0px;
  padding: .25em;
  grid-template-areas:
    "md-away md-toggle md-home"
    "md-date md-date md-date"
    "md-time md-time md-time";
}

/* schedule-match */
.schedule-match {
  display: grid;
  background: #fff;
  width: 100%;
  padding: .25em;
  gap: .5em;
}

.schedule-match.full {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  grid-template-areas:
    "match-date match-time"
    "match-sl match-sl"
    "match-away match-home";
}

.schedule-match.section {
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
  grid-template-areas:
    "match-away match-display match-home";
}

.schedule-match.blocked.section {
  grid-template-columns: 2fr 2fr 1fr;
  grid-template-areas:
    "match-away match-away-score match-score-status";
}

.schedule-match:not(.rt).future {
  grid-template-columns: 4fr 2fr 4fr !important;
}

.schedule-match.playoff,
.schedule-match.playoff.bye {
  border-bottom: 1px solid var(--off-blackish) !important;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    ". . match-sl"
    "match-away match-display match-home"
    "match-trophy . match-location";
}

.schedule-match.playoff.rt {
  grid-template-columns: 3fr 3fr;
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    ".  match-sl"
    "match-away match-home"
    "match-trophy match-location"
    "match-display match-display";
}

.schedule-match.pool {
  padding: .75em;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  border-bottom: 1px solid var(--score-divider);
  grid-template-areas:
    "pool-header"
    "pool-match";
}

.match-playoff {
  display: grid;
  grid-area: match-playoff;
  grid-template-columns: 2fr 1fr 2fr;
  text-align: center;
  font-size: .8rem;
  padding-top: .25em;
  grid-template-areas:
    "match-round match-display match-location";
}

.schedule-match.playoff.rt .match-playoff {
  grid-template-columns: 33% 33% 33%;
}

.matches-latest>.vert-container>.content {
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
}

.matches-latest-team>.vert-container>.content {
  display: grid;
  grid-template-rows: repeat(5, 1fr) !important;
}

.matches-latest-team:nth-child(2) {
  border-left: 1px solid var(--off-blackish-bc) !important;
}

.schedule-match.teams.lmo {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  padding: 0 .75em;
  align-items: center;
  grid-template-areas:
    "match-display"
    "match-last-date"
    "match-opponent" !important;
}

.matches-latest-team .schedule-match.lmo {
  grid-template-columns: 1fr 2fr 2fr 1fr !important;
  grid-template-rows: repeat(1, 1fr);
  padding: .25em;
  grid-template-areas:
    ". match-display match-opponent ." !important;
}

.match-last-date {
  grid-area: match-last-date;
  font-size: .8rem;
  text-align: center;
}

.team-form,
.team-overview {
  margin: .25em;
}

.match-players {
  text-align: center;
  font-size: .8rem;
}

.match-players>div,
.match-form>div,
.team-overview>div {
  margin-bottom: .5em;
  border: solid 1px var(--off-black);
  border-radius: var(--br-8) !important;
  padding: .25em;
  background-color: var(--off-black);
}

.match-players>div:first-child,
.match-form>div:first-child,
.team-overview>div>div:first-child {
  padding: .75em 1em;
}

.team-overview .schedule-match {
  border: 0 !important;
}

.app-dark-mode .team-overview>.segments>.segment:first-child {
  color: white !important;
}

.school-info>div {
  display: grid;
  grid-template-columns: 1fr 3fr !important;
}

.team-overview {
  padding: .25em;
}

.team-overview>.segments>.segment:last-child {
  padding: .75em;
}

.team-overview>.segments>.segment:first-child {
  font-weight: bold;
}

/* .team-overview .label {
  margin: 0 .5em !important;
} */

.app-dark-mode .schedule-match {
  background-color: var(--sem-black) !important;
  border-bottom-color: var(--sem-black-b) !important;
}

.app-dark-mode .pool-match>.match-home,
.app-dark-mode .pool-match>.match-away,
.app-dark-mode .schedule-match>.match-home,
.app-dark-mode .schedule-match>.match-away {
  color: white;
}

.app-dark-mode .standings-selected {
  background-color: var(--sem-black-b) !important;
  color: rgb(242, 242, 242);
}

.app-dark-mode .athletes-container>.athlete {
  background-color: var(--sem-black) !important;
  border-bottom-color: var(--sem-black-b) !important;
}

.app-dark-mode .ms-summary>div {
  background-color: var(--sem-black) !important;
  /* border: 1px solid var(--sem-black-b) !important; */
}

.app-dark-mode .ms-summary {
  background-color: black;
}

.app-dark-mode .table-rankings {
  border-radius: var(--br);
  border: 1px solid var(--off-blackish-bc)
}

.app-dark-mode .table-rankings,
.app-dark-mode .table-rankings th {
  background-color: black !important;
  background: black !important;
  color: white !important;
}

.pool-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em 0;
  grid-template-areas:
    "match-court match-work";
}

.pool-match {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
  width: 100%;
  font-size: .85rem;
  padding: .5em 0;
  align-items: center;
  grid-template-areas:
    "match-away match-home";
}

.match-grid {
  padding: 0 .5em;
  border: 1px solid var(--off-blackish-bc);
}

.match-grid>.pool-match {
  display: grid;
  grid-template-columns: 85% 15%;
  grid-template-rows: repeat(3, 1fr);
  background: #fff;
  width: 100%;
  font-size: .85rem;
  align-items: center;
  grid-template-areas:
    "match-away result-away"
    "match-home result-home"
    "match-dates match-dates";
}

.match-grid>.pool-match.rt {
  grid-template-columns: 85% 15%;
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    "match-away result-away"
    "score-away score-away"
    "match-home result-home"
    "score-home score-home"
    "match-dates match-dates";
}

.match-dates {
  display: grid;
  color: var(--off-whiteish);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: .5em;
  grid-area: match-dates;
  grid-template-areas:
    "match-date match-time"
    "match-location match-location";
}

.schedule-match.teams {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  grid-template-rows: 1fr 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .75em;
  border-bottom: 1px solid rgb(192, 192, 192);
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display match-home";
}

.schedule-match.teams.tia.oo:not(.playoff) {
  grid-template-areas:
    "match-date . match-sl "
    "match-home match-display .";
}

.schedule-match.teams.tih.oo:not(.playoff) {
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display .";
}

.schedule-match.teams.playoff {
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display match-home"
    "match-trophy . match-location";
}

.schedule-match.teams.playoff.rt {
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "match-date . match-sl "
    "match-away . match-home"
    "match-trophy . match-location"
    "match-display match-display match-display ";
}

.schedule-match.teams.tih:not(.oo) .match-away {
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.schedule-match.teams.tia:not(.oo) .match-home {
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.schedule-match.teams.tia.oo:not(.playoff) .match-away {
  display: none;
}

.schedule-match.teams.tih.oo:not(.playoff) .match-home {
  display: none;
}

.schedule-match.teams.rt:not(.lmo) {
  grid-template-columns: 3fr 2fr 3fr;
}

.team-overview .schedule-match.teams.rt:not(.lmo) {
  grid-template-columns: 4fr 3fr 4fr;
}

.schedule-match.teams.blocked {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date match-sl match-score-status"
    "match-away match-away-score match-away-score"
    "match-home match-home-score match-home-score";
}

.match-result {
  grid-area: match-result;
}

.schedule-match.team {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 2fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .5em;
  /* justify-items: center; */
  grid-template-areas:
    "match-away match-display match-home"
    "match-ls match-ls match-ls";
}

.schedule-match.teams.scores {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .5em;
  /* justify-items: center; */
  grid-template-areas:
    "score-away match-away match-display match-home score-home";
}

.push-header.score-types {
  padding: .5em 2em
}

.push-header.score-types .menu-slider {
  background-color: var(--score-type);
}

.score-away,
.result-away {
  grid-area: result-away;
  font-size: 1rem;
  text-align: center;
  color: var(--off-whiteish);
}

.score-home,
.result-home {
  grid-area: result-home;
  font-size: 1rem;
  text-align: center;
  color: var(--off-whiteish);
}

.score-away.w,
.score-home.w .result-away.w,
.result-home.w {
  color: white;
  font-weight: bold;
}

.match-date {
  grid-area: match-date;
  font-size: .8rem;
}

.match-time {
  grid-area: match-time;
  font-size: .8rem;
  justify-self: right;
}

.match-display {
  grid-area: match-display;
  text-align: center;
  font-size: .9rem;
  font-weight: bold;
  border-radius: 4px;
  padding: .15em;
  color: white;
}

.schedule-match>div {
  align-self: center !important;
}

.schedule-match.lmo.teams>.match-display {
  padding: .1em;
}

.schedule-match.ae .match-display {
  border: 1px solid rgb(223, 223, 223);
}

.schedule-match.ame:not(.complete):not(.rt) .match-display {
  border: 1px solid var(--sem-green) !important;
}

.schedule-match.ame .match-display {
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.ame .match-display {
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.ase .match-display {
  border-bottom: 1px solid var(--sem-blue) !important;
}

.match-display>div {
  text-align: center;
}

.match-display.paste>.label {
  background-color: var(--score-past);
  color: black !important;
}

.team-overview .match-display.complete,
.schedule-match.complete .match-display>.label {
  background-color: var(--score-complete);
  font-weight: bold;
  text-align: center;
}

.schedule-match.complete .match-display {
  font-size: 1rem;
}

.team-overview .schedule-match {
  padding: .25em
}

.match-display>.label {
  width: 100%;
}

.schedule-match.rt .match-display>.label {
  padding: .5em .5em;
  color: white;
  font-size: 1rem;
}

.schedule-match.rt.ame .match-display>.label:nth-child(2) {
  border: 1px solid gray !important;
}

.pool-match.rt .up,
.schedule-match.rt .match-display>.label.up {
  border: 1px solid var(--sem-green);
}

.schedule-match.rt .match-display .button.up {
  border-top: 1px solid var(--sem-green) !important;
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.rt .match-display .button.down {
  border-top: 1px solid var(--sem-red) !important;
  border-bottom: 1px solid var(--sem-red) !important;
}

.schedule-match.rt .match-display .button.tie {
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}


.pool-match.rt .down,
.schedule-match.rt .match-display>.label.down {
  border: 1px solid var(--sem-red);
}

.pool-match.rt .tie,
.schedule-match.rt .match-display>.label.tie {
  border: 1px solid grey;
}


.team-overview .match-display.win,
.match-display.win>.label {
  border-color: var(--match-win);
  color: white;
}

.team-overview .match-display.loss,
.match-display.loss>.label {
  background-color: var(--match-loss);
  color: white;
}

.match-display.score-available {
  border: 1px solid var(--score-scores) !important;
  font-weight: bold;
  border-radius: 4px;
}

.ms-summary .match-display,
.schedule-match.rt:not(.lmo) .match-display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.matches-latest .schedule-match.rt:not(.lmo) .match-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "match-display-score match-display-score"
    "team-score-away team-score-home";
}

.summary-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.match-display-score {
  grid-area: match-display-score;
  text-align: center;
}

.team-score-away {
  grid-area: team-score-away;
}

.team-score-home {
  grid-area: team-score-home;
}

.match-display.nr {
  background-color: var(--score-nr);
}

.match-display.ae {
  border: 1px solid rgb(223, 223, 223);
}

.mr {
  grid-area: mr;
}

.match-sl {
  grid-area: match-sl;
  text-align: right;
}

.match-score {
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
}

.match-score.progress {
  background-color: var(--score-progress);
}

.match-playoff-header {
  text-align: center;
  grid-area: match-playoff-header;
  font-weight: bold;
}

.match-away {
  grid-area: match-away;
  color: var(--match-team);
  text-align: left;
}

.schedule-match.teams.tia.oo:not(.playoff) .match-home {
  text-align: left;
}

.match-home-score {
  grid-area: match-home-score;
  font-weight: bold !important;
}

.match-away-score {
  grid-area: match-away-score;
  font-weight: bold !important;
}

.match-away-score.win>.label,
.match-home-score.win>.label {
  background-color: var(--sem-green);
  color: white;
}

.match-away-score.loss>.label,
.match-home-score.loss>.label {
  background-color: var(--sem-red);
  color: white;
}

.match-score-status {
  grid-area: match-score-status;
  text-align: right;
}

.match-opponent-full {
  grid-area: match-opponent-full;
  font-weight: bold;
  text-align: left;
}

.match-opponent {
  grid-area: match-opponent;
  text-align: center;
  font-size: .8rem;
  font-weight: bold;
}

.match-full-score {
  grid-area: match-full-score;
  text-align: center;
  padding: .5em 0
}

.match-away>span,
.match-home>span {
  font-weight: bold !important;
  font-size: 1rem !important;
}

/* .match-away.upset>span,
.match-home.upset>span {
  background-color: var(--upset);
} */

.match-number {
  grid-area: match-number;
  font-weight: bold;
}

.match-home {
  grid-area: match-home;
  text-align: right;
  color: var(--match-team)
}

.match-grid .match-home {
  text-align: left;
}

.blocked .match-home {
  text-align: left;
}

.match-work {
  grid-area: match-work;
  text-align: right;
  font-size: .7rem;
}

.match-court {
  grid-area: match-court;
  font-size: .7rem;
}

.match-ls {
  grid-area: match-ls;
  text-align: right;
}

.score-away {
  grid-area: score-away;
}

.score-home {
  grid-area: score-home;
}

.match-round {
  grid-area: match-round;
  text-align: left;
  font-size: .8rem;
  color: var(--off-whiteish);
}

.match-location {
  grid-area: match-location;
  text-align: right;
  font-size: .8rem;
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.match-trophy {
  grid-area: match-trophy;
  text-align: left;
  font-size: .8rem;
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.schedule-match.playoff .match-location {
  text-align: right;
}

.schedule-match.playoff.teams .match-location {
  text-align: right;
}

.match-dates .match-location {
  text-align: center;
}

.schedule-match>.match-away .label,
.schedule-match>.match-home .label {
  width: 20px;
  text-align: center;
  padding: .5em !important;
}

.schedule-match .match-away .label {
  margin-right: .5em;
}

.schedule-match .match-home .label {
  margin-left: .5em;
}

.standings-table .label {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.standings-table td>.label:nth-child(2) {
  float: right;
}

.match-away.winner,
.match-home.winner {
  font-weight: bold;
  font-size: 1.1rem !important;
  color: var(--match-winner);
}

.match-away.winner,
.match-home.winner {
  font-weight: bold;
  font-size: 1.1rem !important;
  color: var(--match-winner);
}


.schedule-matchDate {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-matchDate-header"
    "schedule-matchDateTeams";
}

.schedule-matchDate-header {
  grid-area: schedule-matchDate-header;
  padding: .25em;
  font-weight: bold;
  font-size: .8rem;
  text-align: center;
  border-top: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
}

.schedule-matchDateTeams {
  grid-area: schedule-matchDateTeams;
}

.schedule-dates {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  max-width: 600px;
  margin: auto;
  align-items: center;
  grid-template-areas:
    "schedule-dates-header"
    "schedule-levels";
}

.schedule-dates.nr {
  opacity: .2;
}

.match-grids {
  max-width: 600px;
  margin: auto;
}

.schedule-dates-header>.icon {
  float: right;
}

.schedule-dates-ls {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-dates-header schedule-dates-header-ls"
    "schedule-levels schedule-levels";
}

.schedule-dates-header {
  grid-area: schedule-dates-header;
  padding: .5em;
  font-weight: bold;
}

.schedule-dates-header-ls {
  grid-area: schedule-dates-header-ls;
  padding: .5em;
  font-weight: bold;
}

.schedule-levels {
  grid-area: schedule-levels;
  border-bottom: 1px solid rgb(207, 207, 207);
}

.sdw {
  background-color: var(--date-wrapper) !important;
  border: 1px solid var(--date-wrapper);
  border-radius: var(--br);
  padding: 0em;
  margin: .5em .25em;
  text-align: left;
}

.sdw>div:first-child {
  display: flex;
  justify-content: space-between;
  padding: .5em 1em;
  font-size: .8rem;
  font-weight: bold;
  background-color: var(--date-wrapper);
  border-radius: var(--br-8) var(--br-8) 0 0;
}

.sdw>div:first-child>.icon {
  text-align: right;
}

.schedule-level {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-level-header"
    "schedule-level-section";
}

.schedule-level-header {
  grid-area: schedule-level-header;
  padding: .5em;
  font-weight: bold;
  background-color: rgb(214, 213, 213)
}

.schedule-level-section {
  grid-area: schedule-level-section;
}

.schedule-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  grid-template-areas:
    "schedule-section-header"
    "schedule-section-teams";
}

.schedule-section>.divider {
  margin-top: .25em;
  margin-bottom: 0;
  font-size: .8rem;
  color: rgb(170, 170, 170) !important;
}

.schedule-section>.divider>.header {
  color: grey;
  font-size: .8rem;
  font-weight: 600 !important;
  color: rgb(170, 170, 170) !important;
}

.schedule-section .divider .label {
  font-size: .2rem;
}

.schedule-date-header {
  border-top: 3px solid rgb(185, 185, 185);
  padding: .5em;
  font-weight: bold;
  background-color: rgb(64, 64, 64);
  color: white;
}

.schedule-section.fav {
  margin-bottom: .5em;
  border-bottom: 1px solid rgb(199, 199, 199);
  padding: .25em;
}

.schedule-section.fav>.schedule-section-header {
  background-color: rgb(214, 255, 214);
  font-weight: bold;
}

.schedule-section.fav>.schedule-section-header>i.icon {
  margin-right: .5em;
}

.app-dark-mode .schedule-section.fav {
  border-bottom: 1px solid var(--off-blackish-bc)
}

.schedule-section-header {
  grid-area: schedule-section-header;
  padding: .5em;
  background-color: rgb(221, 221, 221);
  border: 1px solid rgb(185, 185, 185);
  font-weight: bold;
  border-radius: 4px;
}

.schedule-section-teams {
  grid-area: schedule-section-teams;
  padding: .5em 0
}

.schedule-levels>div,
.schedule-levels>div>div {
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
  max-height: none !important;
}

.league-standings {
  padding: .5em .5em;
  width: 100%;
  height: 100%;
}

.standings-container {
  padding: .25em !;
  width: 100%;
  border-radius: 8px !important;
  box-shadow: none !important;
  margin-bottom: .5em;
}

.standings-container.team {
  padding-top: .25em;
  width: 100%;
  margin-bottom: 0 !important;
}

.standings-header {
  padding: .75em !important;
  font-weight: bold;
  text-align: center !important;
  width: 100%;
  border-radius: var(--br-8) var(--br-8) 0 0;
}

.standings-header>.icon.star {
  margin-right: 1em;
}

.standings-table {
  padding: 0 0 0 .2em !important;
  font-size: 1rem !important;
}

.standings-table>.table {
  border: 0;
  border-radius: 0 !important;
}

.standings-table>.table>thead>tr>th,
.standings-table>.table>tbody>tr>td {
  text-align: center;
}

.history-container>.grid>.row>.column.six,
.table-rankings td:nth-child(3),
.standings-table>.table>tbody>tr>td:first-child {
  font-weight: bold !important;
  font-size: 1rem;
}

.table-rankings td:nth-child(2) .label {
  min-width: 36px;
}

.standings-table>.table>tbody>tr.fav>td>.icon {
  margin-left: 1em;
}

.standings-table>.table>tbody>tr.fav>td>.icon {
  margin-left: 1em;
}

.standings-selected {
  background-color: var(--score-selected);
  color: var(--score-selected-c)
}

.standings-headerr>tr>th {
  padding: .25em !important;
  text-align: center;
}

.ms-summary-full {
  height: 100% !important;
  overflow-y: auto;
  /* padding-bottom: 5em; */
}

.ms-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, auto) 1fr;
  background: #fff;
  background-color: white;
  padding: .25em;
  width: 100%;
  font-weight: bold;
  grid-template-areas:
    "ms-away ms-home"
    "ms-result ms-result"
    "ms-date ms-time"
    "ms-level ms-section"
    "ms-location ms-location"
    "ms-score ms-score"
  ;
}

.ms-summary>div {
  padding: .5em;
  text-align: center;
  border-radius: var(--br);
}

.ms-summary>div:nth-child(-n+2) {
  padding: .75em;
}

.ms-summary .icon {
  margin: 0 1em;
}

.ms-date {
  grid-area: ms-date;
}

.ms-time {
  grid-area: ms-time;
}

.ms-result {
  grid-area: ms-result;
}

.ms-level {
  grid-area: ms-level;
}

.ms-section {
  grid-area: ms-section;
}

.ms-location {
  grid-area: ms-location;
}

.ms-away {
  grid-area: ms-away;
  font-size: 1.2rem;
  text-align: center;
}

.ms-home {
  grid-area: ms-home;
  font-size: 1.2rem;
  text-align: center;
}

.ms-scores {
  grid-area: ms-scores;
  background-color: lightblue;
}

.ms-score {
  grid-area: ms-score;
  height: 100% !important;
}

.match-details>div {
  padding: .75em;
  text-align: center;
  margin: 1px;
  border-radius: 2px;
  border: 1px solid rgb(216, 216, 216);
}

.md-away {
  grid-area: md-away;
}

.md-toggle {
  grid-area: md-toggle
}

.md-home {
  grid-area: md-home;
  text-align: right;
}

.md-date {
  grid-area: md-date;
  text-align: center;
}

.md-time {
  grid-area: md-time;
  text-align: center;
}

.md-toggle>i.icon {
  font-size: 1em !important;
}

.level-grid {
  display: grid;
  background: #fff;
  width: 100%;
  gap: 1px;
  font-weight: bold;
}

.level-grid>div {
  border-radius: 2px;
  border: 1px solid rgb(216, 216, 216);
}

.section-div {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr;
  padding: .5em;
  border-radius: var(--br);
  margin: .5em;
  background-color: grey;
}

.section-div>div:first-child {
  padding: .5em 1em;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: var(--section-grid-header);
}

.section-grid {
  display: grid;
  width: 100%;
  font-weight: bold;
  padding: .25em;
  gap: .25em;
}

.section-grid.tc-5 {
  grid-template-columns: repeat(6, 1fr);
}

.section-grid.tc-6 {
  grid-template-columns: repeat(7, 1fr);
}

.section-grid.tc-7 {
  grid-template-columns: 2fr repeat(7, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.section-grid.tc-8 {
  grid-template-columns: 2fr repeat(8, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.section-grid.tc-9 {
  grid-template-columns: 2fr repeat(9, 1fr);
  grid-template-rows: repeat(19, 1fr);
}

.section-grid>div {
  text-align: center;
  border: 1px solid rgb(226, 226, 226);
  font-size: .8rem;
  padding: .5em .2em;
  background-color: var(--sem-blue);
  color: white;
  border-radius: var(--br);
}

.section-grid>div.home {
  background-color: white;
  color: black;
}

.section-grid>div.home {
  background-color: white;
  color: black;
}

.section-grid>div.away {
  background-color: rgb(199, 199, 199);
  color: black;
}

.section-grid>div.section-date-header,
.section-grid>div.section-date {
  background-color: black;
  color: white;
}

.section-grid>div.open {
  background-color: rgb(205, 212, 0);
  color: black;
  border-radius: var(--br);
}

.match-teams {
  display: grid;
  width: 100%;
  height: 100%;
  padding: .25em !important;
  /* border-top: 1px solid rgb(141, 141, 141);
  border-bottom: 1px solid rgb(141, 141, 141); */
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;
}

.match-teams.fh {
  height: 100%;
}

.match-teams-teams {
  display: grid;
  width: 100%;
  padding: 0;
  grid-template-columns: 1fr 1fr;
}

.match-teams>div {
  margin-bottom: .5em;
  width: 100%;
}

.match-teams-teams>div,
.match-teams-update {
  text-align: right;
}

.match-teams-teams .dropdown,
.match-teams-teams .dropdown .item>span {
  font-size: .8rem;
}

.match-header {
  display: grid;
  background-color: whitesmoke;
  width: 100%;
  padding: 1em;
  border-bottom: 1px solid rgb(141, 141, 141);
  grid-template-rows: auto auto;
}

.match-header>.row {
  padding: .5em 0 !important;
  font-weight: bold;
}

.scoring-update {
  display: grid;
  grid-template-rows: repeat(auto 1fr);
  grid-template-columns: repeat(1, 1fr);
  background-color: whitesmoke;
  width: 100%;
  padding: 1em .25em;
  border-top: 1px solid rgb(141, 141, 141);
  border-bottom: 1px solid rgb(141, 141, 141);
  grid-template-areas:
    "match-score-table"
    "team-scoring";
}

.match-team-names {
  text-align: center !important;
  display: grid;
  padding-bottom: 1em;
  grid-template-columns: 1fr 1fr;
  font-weight: bold;
}

.match-away-home {
  padding: 0 0 .5em 0;
  text-align: center;
  font-weight: bold;
}

.match-update-date {
  padding: 0 0 .5em 0;
  text-align: center;
}

.team-scores {
  text-align: center !important;
  display: grid;
  padding: 0 .25em;
  grid-template-columns: 1fr 1fr;
  width: 100% !important;
  gap: .5em;
}

.team-scoring {
  text-align: center !important;
  display: grid;
  padding: 0 .25em;
  grid-template-columns: 3fr 1fr 3fr;
  width: 100% !important;
  gap: .5em;
}

.team-scores .label {
  padding: 1em
}

.team-score {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  font-weight: bold;
  gap: .5em;
  align-items: center;
}

.match-score-header {
  background-color: red;
}

.match-updates {
  border-top: 1px solid grey;
  text-align: center;
  margin-top: 1em;
  padding-top: 1em;
}

.match-calendar {
  display: grid;
  grid-template-columns: 4fr 1fr;
  padding: .5em 0;
}

.match-calendar>div:nth-child(2) {
  text-align: right;
}

.team-section {
  display: flex;
  justify-content: space-around;
}

.ss-pt,
.ss-elim {
  padding-left: .25em !important;
  padding-right: .25em !important;
}

.ss-pt td:nth-child(1) {
  border-left: 2px solid var(--sem-green-light) !important;
  margin-right: 1em !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}


.ss-elim td:nth-child(1) {
  border-left: 2px solid var(--sem-red-light) !important;
  margin-right: 1em !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/* .ss-elim .label:nth-child(1) {
  border: 1px solid var(--sem-red-light) !important;
  margin-right: 1em !important;
} */

.ss-pt .label:nth-child(2),
.ss-elim .label:nth-child(2) {
  margin-left: 1em !important;
}

.legend,
.legend-schedule>div {
  margin: .5em .25em !important;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 0 !important;
}

.legend-schedule>div {
  flex-direction: row;
}

.legend-schedule .label {
  font-size: .2rem !important;
}

.legend>div {
  padding: 0 .5em;
  font-size: .8rem;
}

.legend>div:nth-child(1) {
  border-left: 2px solid var(--sem-green-light) !important;
}

.legend>div:nth-child(2) {
  border-left: 2px solid var(--sem-red-light) !important;
}

.legend>div:nth-child(3) {
  border-left: 2px solid grey !important;
}

.match-rosters>.header {
  text-align: center;
  padding: 1em !important;
}

.card-profile.athlete .header {
  display: grid !important;
  grid-template-columns: 1fr 4fr 2fr 2fr;
  width: 100%;
}

.card-profile.athlete .header>div.label {
  padding: .5em;
  text-align: center !important;
}


.card-profile.athlete .description {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.card-profile.athlete .description>div {
  text-align: center;
}

.set-buttons {
  display: grid;
  grid-template-columns: 1fr;
  padding: .5em 0;
  text-align: center;
}

.match-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1em 0;
  text-align: center;
}

.scorepad-container {
  display: grid;
  width: 100%;
  text-align: center;
  gap: 6px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background-color: var(--score-blue-back);
  padding: .5em;
}

.scorepad-container.done {
  grid-template-rows: repeat(5, 1fr);

}

.scorepad-header {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  text-align: center;
  padding: .5em;
}

.scorepad-header>div {
  line-height: 36px !important;
  text-align: center;
  font-size: 1.1rem;
}

.scorepad-header .label.selected {
  border: 1px solid black !important
}

/* .scorepad-container.kp{
  grid-template-rows: repeat(6, 1fr);
} */

.scorepad-container>div {
  display: block;
  border-radius: var(--br);
  background-color: var(--score-grey);
}

.scorepad-container>div[color='blue'] {
  background-color: var(--score-blue);
}

.scorepad-container>div[color='grey'] {
  background-color: var(--score-grey);
}

.scorepad-container>div[color='dark-grey'] {
  background-color: var(--score-dark-grey);
}

.scorepad-container>div[color='green'] {
  background-color: var(--score-green);
}

.scorepad-container>div[color='red'] {
  background-color: var(--score-red);
}

.scorepad-container>div[color='black'] {
  background-color: var(--sem-black) !important;
}

.scorepad-container>div {
  line-height: 60px !important;
  text-align: center;
  color: white !important;
  max-height: 60px;
}

.scorepad-container .keypad-score {
  font-size: 2rem;
}

.scorepad-container>div>i.icon {
  font-size: 2rem;
}

.grid-overflow,
.playoff-grid {
  width: auto !important;
  margin: auto;
  padding: 1.25em
}

.grid-overflow-c {
  height: 100%;
  overflow-y: auto;
}

/* .grid-overflow {
  height: 100%;
  overflow-y: auto;
} */

.playoff-grid .row>.column {
  min-width: 180px;
  padding: 0 !important
}

.playoff-grid .row>.column>div {
  padding: .25em !important
}

.playoff-grid .segment {
  display: flex;
  align-items: center;
  padding: 0 .25em;
  margin: .25em 0;
}

.playoff-grid .match-grid {
  width: 100%;
  min-width: 140px
}

.playoff-grid .match-grid .pool-match>div {
  width: 100%;
  padding: .25em 0
}

.playoff-grid .match-grid .pool-match>div>.label {
  margin-right: 1em;
  width: 24px;
  text-align: center;
}

.playoff-grid-header {
  margin-bottom: -2.5em !important
}

.playoff-grid-header>.column {
  padding: .25 0 !important;
  text-align: center;
  justify-items: center;
}

.playoff-grid-header>.column>.segment {
  padding: .25em;
  font-weight: bold;
}

.match-menu-header {
  text-align: center;
}

.match-menu-header>div:nth-child(1) {
  font-size: 1.1rem;
}

.match-menu-header>div:nth-child(n+2) {
  font-weight: normal;
  font-size: 0.8rem;
  margin: .5em;
  color: rgb(195, 195, 195);
}

.match-menu-header>.rf {
  font-size: 1.1rem !important;
  background-color: blue;
  padding: .25em;
}

.split-header {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 1fr 5fr;
}

.split-header>div:nth-child(1) {
  text-align: left;
}

.split-header>div:nth-child(2) {
  text-align: center;
}

.split-header>div:nth-child(3) {
  text-align: right;
}

.allStar-container>.segment:nth-child(odd) {
  font-weight: bold;
  background-color: var(--header-light);
}

.pools-container {
  height: 100%;
  display: grid !important;
  padding: .5em .5em !important;
  grid-template-rows: 1fr 4fr;
}

.pools-container>div:nth-child(2) {
  height: 100%;
  overflow-y: scroll;
}

.sections-container {
  padding: .25em
}

.section-container {
  margin: .25em
}

.section-container>.segment {
  padding: .5em
}

.section-container>.segment:first-child {
  background-color: rgb(226, 226, 226);
  display: flex;
  justify-content: space-between;
}

.cal-status {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.alignment-container {
  display: grid !important;
  height: 100%;
  grid-template-columns: 1fr 1fr !important;
}

.alignment-container.existing {
  display: grid !important;
  height: 100%;
  grid-template-columns: 1fr 1fr !important;
}

.alignment-container>div {
  height: 100%;
  overflow-y: auto;
  padding: .5em
}

.alignment-container>div:nth-child(2)>div {
  height: 100%;
  overflow-y: auto;
}

.match-score-rt {
  grid-area: match-score-rt;
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
  grid-template-rows: 1fr 1fr !important;
  grid-template-areas:
    "rt-h-s-c rt-h-ss-c rt-sn-c rt-a-ss-c rt-a-s-c"
    "rt-h-s rt-h-ss rt-sn rt-a-ss rt-a-s";
  gap: .2em;
  width: 100%;
  padding: .5em;
  font-size: .9rem;
}

.match-score-rt>div {
  border: 1px solid grey;
  padding: .25em 1em;
  background-color: var(--off-blackish-bc);
  border-radius: 4px;
}

.match-score-rt>div.winning {
  background-color: var(--sem-green);
  font-weight: bold;
}

.match-score-rt>div.losing {
  background-color: var(--sem-red);
  font-weight: bold;
}

.lbl-rnk {
  width: auto !important;
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.app-dark-mode .standings-container,
.app-dark-mode .standings-table,
.app-dark-mode .schedule-dates {
  background-color: var(--me-background-bgc-dm) !important;
  color: var(--off-white-text) !important;
}

.app-dark-mode .schedule-dates,
.app-dark-mode .schedule-levels {
  color: inherit !important;
  border-color: var(--off-blackish-bc) !important;
}


.app-dark-mode .schedule-level {
  background: var(--off-black) !important;
}

.app-dark-mode .schedule-section-teams,
.app-dark-mode .schedule-match,
.app-dark-mode .standings-header,
.app-dark-mode .standings-table,
.app-dark-mode .sdw,
.app-dark-mode .schedule-section,
.app-dark-mode .standings-container,
.app-dark-mode .sports-active,
.app-dark-mode .schedule-match .app-dark-mode .team-overview div {
  background-color: var(--off-black) !important;
  background: var(--off-black) !important;
  color: white !important;
}

.app-dark-mode .sports-active {
  border-radius: var(--br-8) !important;
}

.app-dark-mode .sports-active .item {
  color: white !important;
}

.app-dark-mode .sports-active .item:not(:last-child) {
  border-bottom: 1px solid var(--off-blackish-bc) !important;
}

.app-dark-mode .segments,
.app-dark-mode .segment {
  background-color: black !important;
  background: black !important;
  color: white;
}

.app-dark-mode>.content,
.app-dark-mode .match-grid,
.app-dark-mode .pool-match,
.app-dark-mode .pool-match .app-dark-mode .schedule-level,
.app-dark-mode .ui.table thead tr>th,
.app-dark-mode .table-rankings,
.app-dark-mode .history-container,
.app-dark-mode .standings-table,
.app-dark-mode .standings-table div,
.app-dark-mode .standings-table .table,
.app-dark-mode .standings-table .table thead,
.app-dark-mode .standings-table .table thead tr,
.app-dark-mode .standings-table .standings-headerr,
.app-dark-mode .standings-table .standings-headerr .table thead tr,
.app-dark-mode .standings-table .standings-headerr .table thead th,
.app-dark-mode .standings-table .table {
  background-color: var(--off-black) !important;
  background: var(--off-black) !important;
  color: white;
}


.app-dark-mode .sdw {
  background-color: var(--off-black) !important;
  border: 1px solid var(--off-black) !important;
}

.schedule-match.playoff,
.schedule-match.playoff.bye {
  border: 1px solid var(--off-blackish) !important;
}

.match-grid.bye,
.schedule-match.playoff.bye {
  background-color: black !important;
  opacity: .7 !important;
}

.schedule-match.rt {
  border: 1px solid green !important;
  padding: .5em;
  border-radius: 8px;
}

.app-dark-mode .standings-header {
  background-color: var(--off-blackish) !important;
}

.app-dark-mode .sdw>div:nth-child(1) {
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .sdw>div:nth-child(1) {
  border: 1px solid var(--off-black) !important;
}


.app-dark-mode .sdw>div {
  background-color: var(--off-black) !important;
}

/* .app-dark-mode .divider {
  background-color: var(--off-black) !important;
} */

.app-dark-mode .schedule-match.teams.ae .match-display,
.app-dark-mode .schedule-match.section.ae .match-display,
.app-dark-mode .schedule-match.playoff.ae .match-display {
  border-color: var(--off-blackish-bc);
}

.app-dark-mode .ms-result.ae {
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .match-buttons .button:not(.btn-sel),
.app-dark-mode .ms-button-group .button:not(.btn-sel) {
  background-color: var(--off-blackish-bc) !important;
  color: white !important;
}

.app-dark-mode .history-container .grid .label {
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .history-container .grid .row {
  border-bottom: 1px solid var(--off-blackish-bc) !important;
}

.app-dark-mode .team-overview .label {
  color: var(--off-white-text) !important;
}

.schedule-match.wa:not(.rt):not(.teams) .match-playoff>.match-display,
.schedule-match.wa:not(.rt):not(.teams)>.match-display {
  border-left: 2px solid var(--sem-green);
}

.schedule-match.wh:not(.rt):not(.teams) .match-playoff>.match-display,
.schedule-match.wh:not(.rt):not(.teams)>.match-display {
  border-right: 2px solid var(--sem-green);
}

.matches-latest-team .schedule-match.win.lmo>.match-display,
.schedule-match.win.teams>.match-display {
  background-color: var(--sem-green) !important;
  border: 0 !important;
}

.matches-latest-team .schedule-match.loss.lmo>.match-display,
.schedule-match.loss.teams>.match-display {
  background-color: var(--sem-red) !important;
  border: 0 !important;
}

.app-dark-mode .standings-container {
  border: 1px solid var(--off-blackish-bc) !important;
}

.app-dark-mode .upcoming-matches {
  background-color: var(--off-blackish) !important;
  padding: .5em;
  text-align: center;
}

.app-dark-mode .schedule-section .divider .header {
  color: var(--off-white-text) !important
}

.app-dark-mode .schedule-match.future .match-display {
  color: var(--off-whiteish) !important;
}

.app-dark-mode .schedule-match.future.pending .match-away {
  font-size: .8rem;
}

.app-dark-mode .match-grid.sm,
.app-dark-mode .match-grid.sm>.pool-match {
  background-color: var(--match-same) !important;
}

.app-dark-mode .match-away.upset>span,
.app-dark-mode .match-home.upset>span {
  background-color: var(--off-blackish) !important;
  color: var(--upset);
  /* border-color: red !important; */
  /* text-decoration: underline; */
}

.matches-latest-team .match-display {
  border: 0 !important;
}

.chg {
  background-color: pink;
}