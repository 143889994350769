:root {
  --main-bgc: #0081c6;
  --main-c: white;

  --secondary-bgc: #193e6b;
  --secondary-c: white;

  --me-background-bgc: #dbd9d9ca;
  --me-background-bgc-sp: #fcfcfcca;
  --background-c: black;

  --alternative-bgc: #2db099;
  --alternative-c: black;

  --active-bgc: #2db099;
  --active-c: black;

  --settings-bgc: #004062;
  --settings-c: white;

  --settings-seg-bgc: #006aa3;
  --settings-seg-c: white;

  --settings-dead-bgc: #c0cbd1;
  --settings-dead-c: black;

}

.bc-main {
  background-color: var(--main-bgc) !important;
  color: var(--main-c) !important;
}

.bc-sec {
  background-color: var(--secondary-bgc) !important;
  color: var(--secondary-c) !important;
}

.bc-bk {
  background-color: var(--me-background-bgc);
}

.app-container .bc-bk {
  background-color: var(--me-background-bgc);
}

.bc-alt {
  background-color: var(--alternative-bgc) !important;
  color: var(--alternative-c) !important;
}

.bc-act {
  background-color: var(--active-bgc) !important;
  color: var(--active-c) !important;
}

.bc-settings {
  background-color: var(--settings-bgc) !important;
  color: var(--settings-c) !important;
}

.bc-settings.dead {
  background-color: var(--settings-dead-bgc) !important;
  color: var(--settings-dead-c) !important;
}

.menu-signin {
  padding: 1.5em !important;
  font-weight: bold !important;
  border-bottom: 1px solid lightgray;
}

.menu-signin-confirm {
  border-radius: 8px;
  margin: 8px 4px;
  text-align: center;
}

.menu-signin-confirm.cancel {
  border: 2px solid white !important;
  background-color: red !important;
}

.menu-signin-confirm.confirm {
  border: 2px solid white !important;
  background-color: green !important;
}

.hlgt,
.hlgt>div {
  -webkit-animation-name: flash-animation;
  -webkit-animation-duration: 0.3s;
  animation-name: flash-animation;
  animation-duration: 0.3s;
}

@-webkit-keyframes flash-animation {
  from {
    background: hotpink;
  }

  to {
    background: default;
  }
}

@keyframes flash-animation {
  from {
    background: hotpink;
  }

  to {
    background: default;
  }
}