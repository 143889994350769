/* VIEW SETTINGS */

:root {
  --dark-black-bc: black;
  --dark-dark-border: #6e6e6e;
  --dark-drop-bc: #363636;
  --dark-light-c: rgb(204, 204, 204);
  --light-bgc: #f0f0f0;
  --live-global-bgc: rgb(0, 119, 255);
  --live-view-bgc: rgb(20, 216, 20);
  --google-sheets-bc: #e8ffe7;
  --google-data-bc: rgb(226, 239, 255);
  --google-preview-bc: #f8e3ff;
  --google-sheets: #38c833;
  --google-data: rgb(0, 119, 255);
  --google-preview: #a333c8;
}

/* app_settings-container */
.app-settings-container {
  height: 100vh !important;
  width: 100vw !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: white;
}

.dt .app-settings-container .app-content {
  width: 100%;
  margin: 0 auto;
}

.app-settings-container>.grid>.column:nth-child(2) {
  padding: 0 !important
}

/* app_settings_menu */
.app-settings-menu {
  position: fixed !important;
  z-index: 9999;
  text-align: center;
  width: 100%;
  top: .3em;
  height: 0;
}

.app-settings-menu.cap {
  top: auto;
  bottom: 2em !important;
}

.app-settings-menu-google {
  position: relative !important;
  top: 1em;
  height: 0;
  width: 40vw !important;
  margin: 0 auto !important;
  text-align: center !important;
}

.app-settings-menu>.buttons {
  border: 2px solid grey;
  border-radius: 6px;
  background-color: gray;
}

.app-settings-menu-bottom {
  position: fixed !important;
  z-index: 9999;
  text-align: center;
  width: 100%;
  bottom: 5em;
  height: 0;
}

.app-settings-menu-bottom>.buttons {
  border: 2px solid grey;
  border-radius: 6px;
}

.desktop-settings-container {
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 4fr;
}

.top-settings-caption {
  display: inline;
  font-size: .7rem;
  /* margin-top: 1em !important; */
  pointer-events: none;
  display: flex;
  column-gap: .5em;
  justify-content: center;
}

.top-settings-caption {
  margin-bottom: 0em !important;
}

/* app_settings_grid */
.app-settings-grid {
  height: 100vh;
  margin: 0 !important;
}

.app-settings-grid>.column {
  padding: 0 !important
}

.app-settings-grid>.column.app {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* settings-app-container */
.settings-app-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "settings-app-content";
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
}

.pusher>.settings-app-container {
  position: absolute;
}

.settings-app-content {
  grid-area: settings-app-content;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-app-content.mob {
  margin: auto 0;
}

.settings-app-content.dt-mode {
  width: 100%;
  height: 100%;
  padding: 0;
}

.settings-app-content.ft:not(.dt-mode)>div>.settings-app-mobile-wrap,
.settings-app-content:not(.dt-mode)>.settings-app-mobile-wrap {
  border: 10px solid rgb(150, 150, 150);
  margin: 0px auto;
  border-radius: 8px;
}

/* .settings-menu-container.segment{padding: 0 !important; margin: 0 }   */

.settings-menu-header {
  padding: 0;
  border-bottom: var(--thin-border-settings);
}

.settings-menu-header .menu>.item:first-child {
  margin-left: 1em !important
}

.settings-menu-footer {
  padding: 0;
  border-top: var(--thin-border-settings)
}

.settings-menu-header .menu>.buttons>.button {
  border-radius: 0;
}

.react-swipeable-view-container {
  height: 100%;
}

.swiper-simple {
  padding: 0.5em .75em;
}

.swiper-simple>div>div {
  padding: 0 .5em !important;
}

.swiper-simple>.react-swipeable-view-container {
  height: auto;
}


/* STYLE MODIFIERS */
/* navigation  */
/* grid-icons-content */
/* menu-filter  */
/* sec */
/* settings-menu-container */
/* top-menu  */

/* BOX SHADOW */
.nbs {
  box-shadow: none !important;
}

.nbs>.header {
  border: none !important;
  border-radius: none !important;
}

/* .prop-mode .prc,
.prop-mode .card .meta > div
{padding: .5em; border: 1px dotted lightblue;} */
.prop-picker {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: .5em;
  /* border: 1px dotted lightblue */
}

/* CASE */
.upperCase.header,
.upperCase.meta,
.upperCase>.button,
.upperCase.menu-filter .filter-dd,
.upperCase.menu-filter .filter-dd .item span,
.upperCase.sub-menu,
.upperCase.swipe-tab .item,
.upperCase.top-menu>.item,
.upperCase.cards-container,
.upperCase .grid-text,
.upperCase.sec-container>div,
.upperCase.sec-header>div,
.upperCase.sec-content>div,
.upperCase .sec-content,
.upperCase .sec-content,
.prc>.upperCase {
  text-transform: uppercase !important;
}

.lowerCase.header,
.lowerCase.meta,
.lowerCase>.button,
.lowerCase.menu-filter .filter-dd,
.lowerCase.menu-filter .filter-dd .item span,
.lowerCase.sub-menu,
.lowerCase.swipe-tab .item,
.lowerCase.top-menu>.item,
.lowerCase.cards-container,
.lowerCase .grid-text,
.lowerCase.sec-header>div,
.lowerCase.sec-content>div,
.lowerCase .sec-content,
.prc>.lowerCase {
  text-transform: lowercase !important;
}

/* DIVIDER SIZE - ds */
.ds-tiny.divider,
.ds-mini.divider>.divider {
  font-size: .6rem !important
}

.ds-tiny.divider,
.ds-tiny.divider>.divider {
  font-size: .8rem !important
}

.ds-small.divider,
.ds-small.divider>.divider {
  font-size: .9rem !important
}

.ds-normal.divider,
.ds-normal.divider>.divider {
  font-size: 1rem !important
}

.ds-large.divider,
.ds-large.divider>.divider {
  font-size: 1.2rem !important
}

.ds-big.divider,
.ds-big.divider>.divider {
  font-size: 1.4rem !important
}

.ds-huge.divider,
.ds-huge.divider>.divider {
  font-size: 1.6rem !important
}

.ds-massive.divider,
.ds-massive.divider>.divider {
  font-size: 1.8rem !important
}

/* FONT SIZE - fs */
/* defaults */
.grid-text,
.cards-container,
.top-menu>.item.menu-title,
.navigation>.item,
.sub-menu,
.sec-header>div,
.sec-content>div,
.sec-content,
.prc {
  font-size: 1rem
}

.fs-tiny.header,
.fs-tiny.meta,
.fs-tiny>.button,
.fs-tiny.header,
.fs-tiny:not(.icon) .grid-text,
.fs-tiny:not(.icon).cards-container,
.fs-tiny.top-menu>.item.menu-title,
.fs-tiny.navigation>.item,
.fs-tiny.sub-menu,
.fs-tiny.swipe-tab>.item,
.fs-tiny.sec-header>div,
.fs-tiny.sec-header>div,
.fs-tiny.sec-content>div,
.fs-tiny .sec-content,
.fs-tiny .sec-content>div,
.prc>.fs-tiny {
  font-size: .6rem !important
}

.fs-small.header,
.fs-small.meta,
.fs-small>.button,
.fs-small:not(.icon) .grid-text,
.fs-small:not(.icon).cards-container,
.fs-small.top-menu>.item.menu-title,
.fs-small.navigation>.item,
.fs-small.sub-menu,
.fs-small.swipe-tab>.item,
.fs-small.sec-header>div,
.fs-small.sec-content>div,
.fs-small .sec-content,
.fs-small .sec-content>div,
.prc>.fs-small {
  font-size: .8rem !important
}

.grid-text.eight {
  font-size: .8rem !important
}

.fs-normal.header,
.fs-normal.meta,
.fs-normal>.button,
.fs-normal:not(.icon) .grid-text,
.fs-normal:not(.icon).cards-container,
.fs-normal.top-menu>.item.menu-title,
.fs-normal.navigation>.item,
.fs-normal.sub-menu,
.fs-normal.swipe-tab>.item,
.fs-normal.sec-header>div,
.fs-normal.sec-content>div,
.fs-normal .sec-content,
.fs-normal .sec-content>div,
.prc>.fs-normal {
  font-size: 1rem !important
}

.fs-large.header,
.fs-large.meta,
.fs-large>.button,
.fs-large:not(.icon) .grid-text,
.fs-large:not(.icon).cards-container,
.fs-large.top-menu>.item.menu-title,
.fs-large.navigation>.item,
.fs-large.sub-menu,
.fs-large.swipe-tab>.item,
.fs-large.sec-header>div,
.fs-large.sec-content>div,
.fs-large .sec-content,
.fs-large .sec-content>div,
.prc>.fs-large {
  font-size: 1.1rem !important
}

.fs-big.header,
.fs-big.meta,
.fs-big>.button,
.fs-big:not(.icon) .grid-text,
.fs-big:not(.icon).cards-container,
.fs-big.top-menu>.item.menu-title,
.fs-big.navigation>.item,
.fs-big.sub-menu,
.fs-big.swipe-tab>.item,
.fs-big.sec-content>div,
.fs-big .sec-content,
.fs-big .sec-content>div,
.prc>.fs-big {
  font-size: 1.2rem !important
}

.fs-huge.header,
.fs-huge.meta,
.fs-huge>.button,
.fs-huge:not(.icon) .grid-text,
.fs-huge:not(.icon).cards-container,
.fs-huge.top-menu>.item.menu-title,
.fs-huge.navigation>.item,
.fs-huge.sub-menu,
.fs-huge.swipe-tab>.item,
.fs-huge.sec-header>div,
.fs-huge.sec-content>div,
.fs-huge .sec-content,
.fs-huge .sec-content>div,
.prc>.fs-huge {
  font-size: 1.4rem !important
}

.fs-massive.header,
.fs-massive.meta,
.fs-massive>.button,
.fs-massive:not(.icon) .grid-text,
.fs-massive:not(.icon).cards-container,
.fs-massive.top-menu>.item.menu-title,
.fs-massive.navigation>.item,
.fs-massive.sub-menu,
.fs-massive.swipe-tab>.item,
.fs-massive.sec-header>div,
.fs-massive.sec-content>div,
.fs-massive .sec-content,
.fs-massive .sec-content>div,
.prc>.fs-massive {
  font-size: 1.5rem !important
}

/* END FONT SIZE */

/* FONT WEIGHT - fw */
/* .fw-normal.settings-menu-container > .inh .item,  */
.fw-normal.header,
.fw-normal.meta,
.fw-normal .grid-text,
.fw-normal.top-menu .item.menu-title,
.fw-normal .sec-content,
.fw-normal.sec-header,
.fw-normal.sub-menu,
.fw-normal.swipe-tab>.item,
.prc>.fw-normal {
  font-weight: normal !important
}

/* .fw-bold.settings-menu-container > .inh .item,  */
.fw-bold.header,
.fw-bold.meta,
.fw-bold .grid-text,
.fw-bold.top-menu .item.menu-title,
.fw-bold .sec-content,
.fw-bold.sec-header,
.fw-bold.sub-menu,
.fw-bold.swipe-tab>.item,
.prc>.fw-bold {
  font-weight: bold !important
}

/* .fw-bolder.settings-menu-container > .inh .item,  */
.fw-bolder.header,
.fw-bolder.meta,
.fw-bolder .grid-text,
.fw-bolder.top-menu .item.menu-title,
.fw-bolder .sec-content,
.fw-bolder.sec-header,
.fw-bolder.sub-menu,
.fw-bolder.swipe-tab>.item,
.prc>.fw-bolder {
  font-weight: bolder !important
}

/* .fw-lighter.settings-menu-container > .inh .item,  */
.fw-lighter.header,
.fw-lighter.meta,
.fw-lighter .grid-text,
.fw-lighter.top-menu .item.menu-title,
.fw-lighter .sec-content,
.fw-lighter.sec-header,
.fw-lighter.sub-menu,
.fw-lighter.swipe-tab>.item,
.prc>.fw-lighter {
  font-weight: lighter !important
}

/* END FONT WEIGHT */

/* HEADER ALIGNEMNT - ha */
.ha-right,
.ha-right .header {
  text-align: right;
}

.ha-center,
.ha-center .header {
  text-align: center;
}

/* END HEADER ALIGNEMNT */

/* ICON SIZE - i */
/* defaults */
.grid-icons-content .icon {
  font-size: 2rem !important
}

.grid-icons-content img {
  max-width: 40px !important
}

.i-tiny.grid-icons-content .icon {
  font-size: 1rem !important
}

.i-small.grid-icons-content .icon {
  font-size: 1.5rem !important
}

.i-normal.grid-icons-content .icon {
  font-size: 2rem !important
}

.i-large.grid-icons-content .icon {
  font-size: 2.5rem !important
}

.i-big.grid-icons-content .icon {
  font-size: 3rem !important
}

.i-huge.grid-icons-content .icon {
  font-size: 3.5rem !important
}

.i-massive.grid-icons-content .icon {
  font-size: 4rem !important
}

/* END ICON SIZE */

/* OPACITY - op */
.op-lightest.cards-container,
.op-lightest .grid-text,
.op-lightest.grid-icons-content .icon,
.op-lightest.top-menu .item.menu-title,
.op-lightest.sub-menu,
.op-lightest.swipe-tab>.item,
.op-lightest.sec-content>div,
.op-lightest .sec-content,
.prc>.op-lightest {
  opacity: .4;
}

.op-light.cards-container,
.op-light .grid-text,
.op-light.grid-icons-content .icon,
.op-light.top-menu .item.menu-title,
.op-light.sub-menu,
.op-light.swipe-tab>.item,
.op-light.sec-content>div,
.op-light .sec-content,
.prc>.light {
  opacity: .6;
}

.op-medium.cards-container,
.op-medium .grid-text,
.op-medium.grid-icons-content .icon,
.op-medium.top-menu .item.menu-title,
.op-medium.sub-menu,
.op-medium.swipe-tab>.item,
.op-medium.sec-content>div,
.op-medium .sec-content,
.prc>.op-medium {
  opacity: .8;
}

.op-dark.cards-container,
.op-dark .grid-text,
.op-dark.grid-icons-content .icon,
.op-dark.top-menu .item.menu-title,
.op-dark.sub-menu,
.op-dark.swipe-tab>.item,
.op-dark.sec-content>div,
.op-dark .sec-content,
.prc>.op-dark {
  opacity: .9 !important;
}

.op-darkest.cards-container,
.op-darkest .grid-text,
.op-darkest.grid-icons-content .icon,
.op-darkest.top-menu .item.menu-title,
.op-darkest.sub-menu,
.op-darkest.swipe-tab>.item,
.op-darkest.sec-content>div,
.op-darkest .sec-content,
.prc>.op-darkest {
  opacity: 1;
}

/* ONLY FOR GRID ICONS */

/* BORDER RADIUS */
.br-none.grid-icons-content .segment {
  border-radius: 0 !important
}

.br-tiny.grid-icons-content .segment {
  border-radius: 4px !important
}

.br-small.grid-icons-content .segment {
  border-radius: 6px !important
}

.br-normal.grid-icons-content .segment {
  border-radius: 8px !important
}

.br-large.grid-icons-content .segment {
  border-radius: 10px !important
}

.br-big.grid-icons-content .segment {
  border-radius: 12px !important
}

.br-huge.grid-icons-content .segment {
  border-radius: 14px !important
}

.br-massive.grid-icons-content .segment {
  border-radius: 16px !important
}

/* BORDER WIDTH */
.bw-none.grid-icons-content .segment {
  border-width: 0 !important
}

.bw-thin.grid-icons-content .segment {
  border-width: 1px !important
}

.bw-normal.grid-icons-content .segment {
  border-width: 2px !important
}

.bw-thick.grid-icons-content .segment {
  border-width: 4px !important
}

/* PADDING */
.g-none.grid-icons-content .column {
  padding: 0 !important
}

.g-thinnest.grid-icons-content .column {
  padding: .1em !important
}

.g-thin.grid-icons-content .column {
  padding: .15em !important
}

.g-normal.grid-icons-content .column {
  padding: .2em !important
}

.g-wide.grid-icons-content .column {
  padding: .3em !important
}

.g-veryWide.grid-icons-content .column {
  padding: .6em !important
}

/* background content padding */
.g-none.app-content .content-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.g-thinnest.app-content .content-container {
  padding-top: .1em !important;
  padding-bottom: .1em !important
}

.g-thin.app-content .content-container {
  padding-top: .15em !important;
  padding-bottom: .15em !important
}

.g-normal.app-content .content-container {
  padding-top: .2em !important;
  padding-bottom: .2em !important
}

.g-wide.app-content .content-container {
  padding-top: .3em !important;
  padding-bottom: .3em !important
}

.g-veryWide.app-content .content-container {
  padding-top: .4em !important;
  padding-bottom: .4em !important
}

.p-none.sem-banner {
  padding: 0 !important;
}

.p-thinnest.sem-banner {
  padding: .2em !important;
}

.p-thin.sem-banner {
  padding: .4em !important;
}

.p-normal.sem-banner {
  padding: .6em !important;
}

.p-wide.sem-banner {
  padding: .8em !important;
}

.p-veryWide.sem-banner {
  padding: 1em !important;
}

.gp-none.cards-container .card {
  margin: 0 0 !important
}

.gp-thinnest.cards-container .card {
  margin: .2em 0 !important
}

.gp-thin.cards-container .card {
  margin: .4em 0 !important
}

.gp-normal.cards-container .card {
  margin: .6em 0 !important
}

.gp-wide.cards-container .card {
  margin: .8em 0 !important
}

.gp-veryWide.cards-container .card {
  margin: 1em 0 !important
}

/* ITEM/SECTION HEADER */

/* make this inline */
.sec-container.header .sec-content {
  display: flex;
  padding: .75em .25em .75em .5em;
}

.sec-container.header.qrc .sec-content {
  display: flex;
  padding: .75em .25em .75em .5em;
  justify-content: space-between;
}


/* .sec-container.header .sec-content{ */
/* justify-content: space-between; */
/* } */

/* header drop down */
.sec-container.header .sec-content>div+div {
  margin-left: 6px
}

.sec-container.header .sec-content.lot div:last-of-type {
  margin-left: auto;
}

/* NON SECTION */
/* pad the non header sections */
.sec-container:not(.header) {
  margin: .5em;
}

/* bottom border for attached section headers */
.sec-container.blockAttached:not(.separate) {
  border: var(--thin-border);
}

.sec-container.table:not(.blockAttached)>.sec-content {
  border: var(--thin-border);
  padding: .25em
}

/* this is a non main header section header  */
.sec-header {
  margin: 0;
  padding: .55em;
}

.sec-container.block>.sec-header {
  margin-bottom: .75em;
}

.sec-container.blockAttached>.sec-header {
  margin-bottom: 0em;
}

.sec-container.bio>.segment,
.sec-container.description>.segment {
  padding: 0 .5em !important
}

.blockAttached {
  border-radius: 6px 6px 0 0 !important;
}

/* images */
.card-image.left {
  margin: auto 0 !important
}

.card-image.right {
  margin: auto 0 !important
}

.card-image>.image {
  margin: auto !important
}

.card-image.bottom,
.card-image.top {
  margin: 0 auto;
  padding: .5em;
  text-align: center;
}

.card-image.bottomLeft>.image,
.card-image.topLeft>.image {
  float: left;
  padding: .5em
}

.card-image.bottomRight>.image,
.card-image.topRight>.image {
  float: right;
  padding: .5em
}

.card-img-container-left,
.card-img-container-right {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-areas:
    "card-image-left"
    "cc-right";
}

.crd-icon {
  text-align: center;
}

.card-img-container-left {
  grid-template-columns: 25% 75%;
}

.card-img-container-right {
  grid-template-columns: 75% 25%;
}

.sec-header>.divider {
  margin: .5rem 0 !important;
}

.sec-header .icon.secret {
  float: right;
}

.t-thin.item-active {
  border-bottom-width: 1px !important;
}

.t-normal.item-active {
  border-bottom-width: 2px !important;
}

.t-thick.item-active {
  border-bottom-width: 4px !important;
}

.t-none.grid-icons-content .segment {
  border-width: 0 !important;
}

.t-thin.grid-icons-content .segment {
  border-width: 1px !important;
}

.t-normal.grid-icons-content .segment {
  border-width: 2px !important;
}

.t-thick.grid-icons-content .segment {
  border-width: 4px !important;
}

/* .hlgt, .hlgt > div{ 
  border: 1px solid hotpink
} */

.theme-on .app-banner,
.theme-on .app-menu,
.theme-on .app-navigation,
.theme-on .grid-icons-container,
.theme-on .app-swipe-menu,
.theme-on .app-swipe-content {
  opacity: .1;
}

.theme-on-appBanner .app-banner,
.theme-on-topMenu .app-menu,
.theme-on-navigation .app-navigation,
.theme-on-gridIcons .grid-icons-container,
.theme-on-tabs .app-swipe-menu,
.theme-on-dddd .app-swipe-content {
  opacity: 1 !important;
}

/* .theme-on-item{ opacity: 1 !important} */

.uploader-path>div,
.message>.content>p,
.is-html>p>a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.wbbw {
  word-break: break-word;
  font-size: .8rem;
  font-weight: normal !important;
}

.sec-content.none {
  padding: .5em
}

.pretty-json-container .variable-row svg {
  font-size: 1.5em !important;
  margin-left: 1em;
}

.pretty-json-container .variable-value svg {
  font-size: 2em !important;
  margin: .25em
}

.pretty-json-container .object-key-val {
  font-size: .9rem;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.blink_me_1 {
  animation: blinker 1s linear infinite !important;
}

.blink_me_2 {
  animation: blinker 2s linear infinite !important;
}

.blink_me_p {
  animation: blinker 1.5s linear infinite !important;
  background-color: rgb(146, 202, 255) !important;
}


@keyframes blinker {
  50% {
    opacity: .5;
  }
}

.view-settings-container .column>.segment {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  font-size: 1.1rem !important
}

/* alignment */
.sec-content.right .prc>div:nth-child(odd) {
  text-align: right;
}

.sec-content.center .prc>div:nth-child(odd) {
  text-align: center;
}

/* cell stuff */
.cards-container .table.sec-content .prc>div:nth-child(even) {
  text-align: left;
}

.cards-container .labelCaptions.sec-content .prc>div:nth-child(even) {
  text-align: left;
}

.cards-container .labels.sec-content .prc>div:nth-child(even) {
  text-align: left;
}

/* table */
/* .sec-content .prc{font-size: .8rem;} */

.sec-content.table>.prc:not(.nc),
.sec-content.captions>.prc:not(.nc),
.sec-content.labelCaptions>.prc:not(.nc) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.sec-content.captions,
.sec-content.labelCaptions {
  padding: .5em
}

.sec-content.table>.prc>div {
  padding: .5em;
}

.sec-content.table.divided>.prc:not(:first-child) {
  border-top: 1px solid rgba(34, 36, 38, .15);
}

.sec-content.table>.prc>div:nth-child(odd) {
  font-weight: 700;
}

.sec-content.table.celled>.prc>div:nth-child(odd) {
  border-right: 1px solid rgba(34, 36, 38, .15);
}

.sec-content.captions>.prc>div {
  padding: .25em
}

.sec-content.labelCaptions>.prc>div {
  padding: .25em
}

.sec-content.w-thin .prc:not(.nc),
.sec-content.w-thin.table .prc:not(.nc) {
  grid-template-columns: 2fr 8fr;
}

.sec-content.w-normal .prc:not(.nc),
.sec-content.w-normal.table .prc:not(.nc) {
  grid-template-columns: 3fr 7fr;
}

.sec-content.w-wide .prc:not(.nc),
.sec-content.w-wide.table .prc:not(.nc) {
  grid-template-columns: 5fr 5fr;
}

.sec-content.w-veryWide .prc:not(.nc),
.sec-content.w-veryWide.table .prc:not(.nc) {
  grid-template-columns: 6fr 4fr;
}

.prc.edt {
  grid-template-columns: 9fr 1fr !important;
}

.sec-content.header>i.icon {
  margin-right: .5em !important;
}

.menu-title>div,
.header,
.menu,
.card,
.prc {
  font-family: inherit !important;
}

.vertical .prc {
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto 1fr !important;
}

.vertical .prc div:nth-child(2) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-left: .5em;
  margin-bottom: .5em;
}

.card-image>.image>img.mini {
  min-height: 30px;
}

.card-image>.image>img.tiny {
  min-height: 40px;
}

.card-image>.image>img.small {
  min-height: 60px;
}

.card-image>.image>img.op-medium {
  min-height: 80px;
}

.card-image>.image>img.large {
  min-height: 90px;
}

.card-image>.image>img.big {
  min-height: 120px;
}

.card-image>.image>img.huge {
  min-height: 160px;
}

.card-image>.image>img.massive {
  min-height: 180px;
}

.sec-content-gen.venue {
  padding: .5em;
  width: 100%;
  display: grid !important;
  gap: .5em;
  align-content: start;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "pi-name pi-name pi-name"
    "pi-address pi-phone pi-website";
}

.sec-content-gen.venue .label {
  border: 1px solid grey;
  border-radius: 4px;
  text-align: center;
  padding: .5em;
}

.pi-name {
  grid-area: pi-name;
  padding: .5em;
  font-weight: bold;
}

.pi-address {
  grid-area: pi-address;
}

.pi-phone {
  grid-area: pi-phone;
}

.pi-phone>a {
  color: white !important;
  opacity: 1 !important;
}

.pi-website {
  grid-area: pi-website;
}