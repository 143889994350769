/* VIEW SETTINGS */

:root {
  --dark-black-bc: black;
  --dark-dark-border: #6e6e6e;
  --dark-drop-bc: #363636;
  --dark-light-c: rgb(204, 204, 204);
  --light-bgc: #f0f0f0;
  --live-global-bgc: rgb(0, 119, 255);
  --live-view-bgc: rgb(20, 216, 20);
  --google-sheets-bc: #e8ffe7;
  --google-data-bc: rgb(226, 239, 255);
  --google-preview-bc: #f8e3ff;
  --google-sheets: #38c833;
  --google-data: rgb(0, 119, 255);
  --google-preview: #a333c8;
}

/* app_settings-container */
.app-settings-container {
  height: 100vh !important;
  width: 100vw !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: white;
}

.dt .app-settings-container .app-content {
  width: 100%;
  margin: 0 auto;
}

.app-settings-container>.grid>.column:nth-child(2) {
  padding: 0 !important
}

/* app_settings_menu */
.app-settings-menu {
  position: fixed !important;
  z-index: 9999;
  text-align: center;
  width: 100%;
  top: .3em;
  height: 0;
}

.app-settings-menu.cap {
  top: auto;
  bottom: 2em !important;
}

.app-settings-menu-google {
  position: relative !important;
  top: 1em;
  height: 0;
  width: 40vw !important;
  margin: 0 auto !important;
  text-align: center !important;
}

.app-settings-menu>.buttons {
  border: 2px solid grey;
  border-radius: 6px;
  background-color: gray;
}

.app-settings-menu-bottom {
  position: fixed !important;
  z-index: 9999;
  text-align: center;
  width: 100%;
  bottom: 5em;
  height: 0;
}

.app-settings-menu-bottom>.buttons {
  border: 2px solid grey;
  border-radius: 6px;
}

.desktop-settings-container {
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 4fr;
}

.top-settings-caption {
  display: inline;
  font-size: .7rem;
  /* margin-top: 1em !important; */
  pointer-events: none;
  display: flex;
  grid-column-gap: .5em;
  -webkit-column-gap: .5em;
          column-gap: .5em;
  justify-content: center;
}

.top-settings-caption {
  margin-bottom: 0em !important;
}

/* app_settings_grid */
.app-settings-grid {
  height: 100vh;
  margin: 0 !important;
}

.app-settings-grid>.column {
  padding: 0 !important
}

.app-settings-grid>.column.app {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* settings-app-container */
.settings-app-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "settings-app-content";
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
}

.pusher>.settings-app-container {
  position: absolute;
}

.settings-app-content {
  grid-area: settings-app-content;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-app-content.mob {
  margin: auto 0;
}

.settings-app-content.dt-mode {
  width: 100%;
  height: 100%;
  padding: 0;
}

.settings-app-content.ft:not(.dt-mode)>div>.settings-app-mobile-wrap,
.settings-app-content:not(.dt-mode)>.settings-app-mobile-wrap {
  border: 10px solid rgb(150, 150, 150);
  margin: 0px auto;
  border-radius: 8px;
}

/* .settings-menu-container.segment{padding: 0 !important; margin: 0 }   */

.settings-menu-header {
  padding: 0;
  border-bottom: var(--thin-border-settings);
}

.settings-menu-header .menu>.item:first-child {
  margin-left: 1em !important
}

.settings-menu-footer {
  padding: 0;
  border-top: var(--thin-border-settings)
}

.settings-menu-header .menu>.buttons>.button {
  border-radius: 0;
}

.react-swipeable-view-container {
  height: 100%;
}

.swiper-simple {
  padding: 0.5em .75em;
}

.swiper-simple>div>div {
  padding: 0 .5em !important;
}

.swiper-simple>.react-swipeable-view-container {
  height: auto;
}


/* STYLE MODIFIERS */
/* navigation  */
/* grid-icons-content */
/* menu-filter  */
/* sec */
/* settings-menu-container */
/* top-menu  */

/* BOX SHADOW */
.nbs {
  box-shadow: none !important;
}

.nbs>.header {
  border: none !important;
  border-radius: none !important;
}

/* .prop-mode .prc,
.prop-mode .card .meta > div
{padding: .5em; border: 1px dotted lightblue;} */
.prop-picker {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: .5em;
  /* border: 1px dotted lightblue */
}

/* CASE */
.upperCase.header,
.upperCase.meta,
.upperCase>.button,
.upperCase.menu-filter .filter-dd,
.upperCase.menu-filter .filter-dd .item span,
.upperCase.sub-menu,
.upperCase.swipe-tab .item,
.upperCase.top-menu>.item,
.upperCase.cards-container,
.upperCase .grid-text,
.upperCase.sec-container>div,
.upperCase.sec-header>div,
.upperCase.sec-content>div,
.upperCase .sec-content,
.upperCase .sec-content,
.prc>.upperCase {
  text-transform: uppercase !important;
}

.lowerCase.header,
.lowerCase.meta,
.lowerCase>.button,
.lowerCase.menu-filter .filter-dd,
.lowerCase.menu-filter .filter-dd .item span,
.lowerCase.sub-menu,
.lowerCase.swipe-tab .item,
.lowerCase.top-menu>.item,
.lowerCase.cards-container,
.lowerCase .grid-text,
.lowerCase.sec-header>div,
.lowerCase.sec-content>div,
.lowerCase .sec-content,
.prc>.lowerCase {
  text-transform: lowercase !important;
}

/* DIVIDER SIZE - ds */
.ds-tiny.divider,
.ds-mini.divider>.divider {
  font-size: .6rem !important
}

.ds-tiny.divider,
.ds-tiny.divider>.divider {
  font-size: .8rem !important
}

.ds-small.divider,
.ds-small.divider>.divider {
  font-size: .9rem !important
}

.ds-normal.divider,
.ds-normal.divider>.divider {
  font-size: 1rem !important
}

.ds-large.divider,
.ds-large.divider>.divider {
  font-size: 1.2rem !important
}

.ds-big.divider,
.ds-big.divider>.divider {
  font-size: 1.4rem !important
}

.ds-huge.divider,
.ds-huge.divider>.divider {
  font-size: 1.6rem !important
}

.ds-massive.divider,
.ds-massive.divider>.divider {
  font-size: 1.8rem !important
}

/* FONT SIZE - fs */
/* defaults */
.grid-text,
.cards-container,
.top-menu>.item.menu-title,
.navigation>.item,
.sub-menu,
.sec-header>div,
.sec-content>div,
.sec-content,
.prc {
  font-size: 1rem
}

.fs-tiny.header,
.fs-tiny.meta,
.fs-tiny>.button,
.fs-tiny.header,
.fs-tiny:not(.icon) .grid-text,
.fs-tiny:not(.icon).cards-container,
.fs-tiny.top-menu>.item.menu-title,
.fs-tiny.navigation>.item,
.fs-tiny.sub-menu,
.fs-tiny.swipe-tab>.item,
.fs-tiny.sec-header>div,
.fs-tiny.sec-header>div,
.fs-tiny.sec-content>div,
.fs-tiny .sec-content,
.fs-tiny .sec-content>div,
.prc>.fs-tiny {
  font-size: .6rem !important
}

.fs-small.header,
.fs-small.meta,
.fs-small>.button,
.fs-small:not(.icon) .grid-text,
.fs-small:not(.icon).cards-container,
.fs-small.top-menu>.item.menu-title,
.fs-small.navigation>.item,
.fs-small.sub-menu,
.fs-small.swipe-tab>.item,
.fs-small.sec-header>div,
.fs-small.sec-content>div,
.fs-small .sec-content,
.fs-small .sec-content>div,
.prc>.fs-small {
  font-size: .8rem !important
}

.grid-text.eight {
  font-size: .8rem !important
}

.fs-normal.header,
.fs-normal.meta,
.fs-normal>.button,
.fs-normal:not(.icon) .grid-text,
.fs-normal:not(.icon).cards-container,
.fs-normal.top-menu>.item.menu-title,
.fs-normal.navigation>.item,
.fs-normal.sub-menu,
.fs-normal.swipe-tab>.item,
.fs-normal.sec-header>div,
.fs-normal.sec-content>div,
.fs-normal .sec-content,
.fs-normal .sec-content>div,
.prc>.fs-normal {
  font-size: 1rem !important
}

.fs-large.header,
.fs-large.meta,
.fs-large>.button,
.fs-large:not(.icon) .grid-text,
.fs-large:not(.icon).cards-container,
.fs-large.top-menu>.item.menu-title,
.fs-large.navigation>.item,
.fs-large.sub-menu,
.fs-large.swipe-tab>.item,
.fs-large.sec-header>div,
.fs-large.sec-content>div,
.fs-large .sec-content,
.fs-large .sec-content>div,
.prc>.fs-large {
  font-size: 1.1rem !important
}

.fs-big.header,
.fs-big.meta,
.fs-big>.button,
.fs-big:not(.icon) .grid-text,
.fs-big:not(.icon).cards-container,
.fs-big.top-menu>.item.menu-title,
.fs-big.navigation>.item,
.fs-big.sub-menu,
.fs-big.swipe-tab>.item,
.fs-big.sec-content>div,
.fs-big .sec-content,
.fs-big .sec-content>div,
.prc>.fs-big {
  font-size: 1.2rem !important
}

.fs-huge.header,
.fs-huge.meta,
.fs-huge>.button,
.fs-huge:not(.icon) .grid-text,
.fs-huge:not(.icon).cards-container,
.fs-huge.top-menu>.item.menu-title,
.fs-huge.navigation>.item,
.fs-huge.sub-menu,
.fs-huge.swipe-tab>.item,
.fs-huge.sec-header>div,
.fs-huge.sec-content>div,
.fs-huge .sec-content,
.fs-huge .sec-content>div,
.prc>.fs-huge {
  font-size: 1.4rem !important
}

.fs-massive.header,
.fs-massive.meta,
.fs-massive>.button,
.fs-massive:not(.icon) .grid-text,
.fs-massive:not(.icon).cards-container,
.fs-massive.top-menu>.item.menu-title,
.fs-massive.navigation>.item,
.fs-massive.sub-menu,
.fs-massive.swipe-tab>.item,
.fs-massive.sec-header>div,
.fs-massive.sec-content>div,
.fs-massive .sec-content,
.fs-massive .sec-content>div,
.prc>.fs-massive {
  font-size: 1.5rem !important
}

/* END FONT SIZE */

/* FONT WEIGHT - fw */
/* .fw-normal.settings-menu-container > .inh .item,  */
.fw-normal.header,
.fw-normal.meta,
.fw-normal .grid-text,
.fw-normal.top-menu .item.menu-title,
.fw-normal .sec-content,
.fw-normal.sec-header,
.fw-normal.sub-menu,
.fw-normal.swipe-tab>.item,
.prc>.fw-normal {
  font-weight: normal !important
}

/* .fw-bold.settings-menu-container > .inh .item,  */
.fw-bold.header,
.fw-bold.meta,
.fw-bold .grid-text,
.fw-bold.top-menu .item.menu-title,
.fw-bold .sec-content,
.fw-bold.sec-header,
.fw-bold.sub-menu,
.fw-bold.swipe-tab>.item,
.prc>.fw-bold {
  font-weight: bold !important
}

/* .fw-bolder.settings-menu-container > .inh .item,  */
.fw-bolder.header,
.fw-bolder.meta,
.fw-bolder .grid-text,
.fw-bolder.top-menu .item.menu-title,
.fw-bolder .sec-content,
.fw-bolder.sec-header,
.fw-bolder.sub-menu,
.fw-bolder.swipe-tab>.item,
.prc>.fw-bolder {
  font-weight: bolder !important
}

/* .fw-lighter.settings-menu-container > .inh .item,  */
.fw-lighter.header,
.fw-lighter.meta,
.fw-lighter .grid-text,
.fw-lighter.top-menu .item.menu-title,
.fw-lighter .sec-content,
.fw-lighter.sec-header,
.fw-lighter.sub-menu,
.fw-lighter.swipe-tab>.item,
.prc>.fw-lighter {
  font-weight: lighter !important
}

/* END FONT WEIGHT */

/* HEADER ALIGNEMNT - ha */
.ha-right,
.ha-right .header {
  text-align: right;
}

.ha-center,
.ha-center .header {
  text-align: center;
}

/* END HEADER ALIGNEMNT */

/* ICON SIZE - i */
/* defaults */
.grid-icons-content .icon {
  font-size: 2rem !important
}

.grid-icons-content img {
  max-width: 40px !important
}

.i-tiny.grid-icons-content .icon {
  font-size: 1rem !important
}

.i-small.grid-icons-content .icon {
  font-size: 1.5rem !important
}

.i-normal.grid-icons-content .icon {
  font-size: 2rem !important
}

.i-large.grid-icons-content .icon {
  font-size: 2.5rem !important
}

.i-big.grid-icons-content .icon {
  font-size: 3rem !important
}

.i-huge.grid-icons-content .icon {
  font-size: 3.5rem !important
}

.i-massive.grid-icons-content .icon {
  font-size: 4rem !important
}

/* END ICON SIZE */

/* OPACITY - op */
.op-lightest.cards-container,
.op-lightest .grid-text,
.op-lightest.grid-icons-content .icon,
.op-lightest.top-menu .item.menu-title,
.op-lightest.sub-menu,
.op-lightest.swipe-tab>.item,
.op-lightest.sec-content>div,
.op-lightest .sec-content,
.prc>.op-lightest {
  opacity: .4;
}

.op-light.cards-container,
.op-light .grid-text,
.op-light.grid-icons-content .icon,
.op-light.top-menu .item.menu-title,
.op-light.sub-menu,
.op-light.swipe-tab>.item,
.op-light.sec-content>div,
.op-light .sec-content,
.prc>.light {
  opacity: .6;
}

.op-medium.cards-container,
.op-medium .grid-text,
.op-medium.grid-icons-content .icon,
.op-medium.top-menu .item.menu-title,
.op-medium.sub-menu,
.op-medium.swipe-tab>.item,
.op-medium.sec-content>div,
.op-medium .sec-content,
.prc>.op-medium {
  opacity: .8;
}

.op-dark.cards-container,
.op-dark .grid-text,
.op-dark.grid-icons-content .icon,
.op-dark.top-menu .item.menu-title,
.op-dark.sub-menu,
.op-dark.swipe-tab>.item,
.op-dark.sec-content>div,
.op-dark .sec-content,
.prc>.op-dark {
  opacity: .9 !important;
}

.op-darkest.cards-container,
.op-darkest .grid-text,
.op-darkest.grid-icons-content .icon,
.op-darkest.top-menu .item.menu-title,
.op-darkest.sub-menu,
.op-darkest.swipe-tab>.item,
.op-darkest.sec-content>div,
.op-darkest .sec-content,
.prc>.op-darkest {
  opacity: 1;
}

/* ONLY FOR GRID ICONS */

/* BORDER RADIUS */
.br-none.grid-icons-content .segment {
  border-radius: 0 !important
}

.br-tiny.grid-icons-content .segment {
  border-radius: 4px !important
}

.br-small.grid-icons-content .segment {
  border-radius: 6px !important
}

.br-normal.grid-icons-content .segment {
  border-radius: 8px !important
}

.br-large.grid-icons-content .segment {
  border-radius: 10px !important
}

.br-big.grid-icons-content .segment {
  border-radius: 12px !important
}

.br-huge.grid-icons-content .segment {
  border-radius: 14px !important
}

.br-massive.grid-icons-content .segment {
  border-radius: 16px !important
}

/* BORDER WIDTH */
.bw-none.grid-icons-content .segment {
  border-width: 0 !important
}

.bw-thin.grid-icons-content .segment {
  border-width: 1px !important
}

.bw-normal.grid-icons-content .segment {
  border-width: 2px !important
}

.bw-thick.grid-icons-content .segment {
  border-width: 4px !important
}

/* PADDING */
.g-none.grid-icons-content .column {
  padding: 0 !important
}

.g-thinnest.grid-icons-content .column {
  padding: .1em !important
}

.g-thin.grid-icons-content .column {
  padding: .15em !important
}

.g-normal.grid-icons-content .column {
  padding: .2em !important
}

.g-wide.grid-icons-content .column {
  padding: .3em !important
}

.g-veryWide.grid-icons-content .column {
  padding: .6em !important
}

/* background content padding */
.g-none.app-content .content-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.g-thinnest.app-content .content-container {
  padding-top: .1em !important;
  padding-bottom: .1em !important
}

.g-thin.app-content .content-container {
  padding-top: .15em !important;
  padding-bottom: .15em !important
}

.g-normal.app-content .content-container {
  padding-top: .2em !important;
  padding-bottom: .2em !important
}

.g-wide.app-content .content-container {
  padding-top: .3em !important;
  padding-bottom: .3em !important
}

.g-veryWide.app-content .content-container {
  padding-top: .4em !important;
  padding-bottom: .4em !important
}

.p-none.sem-banner {
  padding: 0 !important;
}

.p-thinnest.sem-banner {
  padding: .2em !important;
}

.p-thin.sem-banner {
  padding: .4em !important;
}

.p-normal.sem-banner {
  padding: .6em !important;
}

.p-wide.sem-banner {
  padding: .8em !important;
}

.p-veryWide.sem-banner {
  padding: 1em !important;
}

.gp-none.cards-container .card {
  margin: 0 0 !important
}

.gp-thinnest.cards-container .card {
  margin: .2em 0 !important
}

.gp-thin.cards-container .card {
  margin: .4em 0 !important
}

.gp-normal.cards-container .card {
  margin: .6em 0 !important
}

.gp-wide.cards-container .card {
  margin: .8em 0 !important
}

.gp-veryWide.cards-container .card {
  margin: 1em 0 !important
}

/* ITEM/SECTION HEADER */

/* make this inline */
.sec-container.header .sec-content {
  display: flex;
  padding: .75em .25em .75em .5em;
}

.sec-container.header.qrc .sec-content {
  display: flex;
  padding: .75em .25em .75em .5em;
  justify-content: space-between;
}


/* .sec-container.header .sec-content{ */
/* justify-content: space-between; */
/* } */

/* header drop down */
.sec-container.header .sec-content>div+div {
  margin-left: 6px
}

.sec-container.header .sec-content.lot div:last-of-type {
  margin-left: auto;
}

/* NON SECTION */
/* pad the non header sections */
.sec-container:not(.header) {
  margin: .5em;
}

/* bottom border for attached section headers */
.sec-container.blockAttached:not(.separate) {
  border: var(--thin-border);
}

.sec-container.table:not(.blockAttached)>.sec-content {
  border: var(--thin-border);
  padding: .25em
}

/* this is a non main header section header  */
.sec-header {
  margin: 0;
  padding: .55em;
}

.sec-container.block>.sec-header {
  margin-bottom: .75em;
}

.sec-container.blockAttached>.sec-header {
  margin-bottom: 0em;
}

.sec-container.bio>.segment,
.sec-container.description>.segment {
  padding: 0 .5em !important
}

.blockAttached {
  border-radius: 6px 6px 0 0 !important;
}

/* images */
.card-image.left {
  margin: auto 0 !important
}

.card-image.right {
  margin: auto 0 !important
}

.card-image>.image {
  margin: auto !important
}

.card-image.bottom,
.card-image.top {
  margin: 0 auto;
  padding: .5em;
  text-align: center;
}

.card-image.bottomLeft>.image,
.card-image.topLeft>.image {
  float: left;
  padding: .5em
}

.card-image.bottomRight>.image,
.card-image.topRight>.image {
  float: right;
  padding: .5em
}

.card-img-container-left,
.card-img-container-right {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-areas:
    "card-image-left"
    "cc-right";
}

.crd-icon {
  text-align: center;
}

.card-img-container-left {
  grid-template-columns: 25% 75%;
}

.card-img-container-right {
  grid-template-columns: 75% 25%;
}

.sec-header>.divider {
  margin: .5rem 0 !important;
}

.sec-header .icon.secret {
  float: right;
}

.t-thin.item-active {
  border-bottom-width: 1px !important;
}

.t-normal.item-active {
  border-bottom-width: 2px !important;
}

.t-thick.item-active {
  border-bottom-width: 4px !important;
}

.t-none.grid-icons-content .segment {
  border-width: 0 !important;
}

.t-thin.grid-icons-content .segment {
  border-width: 1px !important;
}

.t-normal.grid-icons-content .segment {
  border-width: 2px !important;
}

.t-thick.grid-icons-content .segment {
  border-width: 4px !important;
}

/* .hlgt, .hlgt > div{ 
  border: 1px solid hotpink
} */

.theme-on .app-banner,
.theme-on .app-menu,
.theme-on .app-navigation,
.theme-on .grid-icons-container,
.theme-on .app-swipe-menu,
.theme-on .app-swipe-content {
  opacity: .1;
}

.theme-on-appBanner .app-banner,
.theme-on-topMenu .app-menu,
.theme-on-navigation .app-navigation,
.theme-on-gridIcons .grid-icons-container,
.theme-on-tabs .app-swipe-menu,
.theme-on-dddd .app-swipe-content {
  opacity: 1 !important;
}

/* .theme-on-item{ opacity: 1 !important} */

.uploader-path>div,
.message>.content>p,
.is-html>p>a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.wbbw {
  word-break: break-word;
  font-size: .8rem;
  font-weight: normal !important;
}

.sec-content.none {
  padding: .5em
}

.pretty-json-container .variable-row svg {
  font-size: 1.5em !important;
  margin-left: 1em;
}

.pretty-json-container .variable-value svg {
  font-size: 2em !important;
  margin: .25em
}

.pretty-json-container .object-key-val {
  font-size: .9rem;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.blink_me_1 {
  -webkit-animation: blinker 1s linear infinite !important;
          animation: blinker 1s linear infinite !important;
}

.blink_me_2 {
  -webkit-animation: blinker 2s linear infinite !important;
          animation: blinker 2s linear infinite !important;
}

.blink_me_p {
  -webkit-animation: blinker 1.5s linear infinite !important;
          animation: blinker 1.5s linear infinite !important;
  background-color: rgb(146, 202, 255) !important;
}


@-webkit-keyframes blinker {
  50% {
    opacity: .5;
  }
}


@keyframes blinker {
  50% {
    opacity: .5;
  }
}

.view-settings-container .column>.segment {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  font-size: 1.1rem !important
}

/* alignment */
.sec-content.right .prc>div:nth-child(odd) {
  text-align: right;
}

.sec-content.center .prc>div:nth-child(odd) {
  text-align: center;
}

/* cell stuff */
.cards-container .table.sec-content .prc>div:nth-child(even) {
  text-align: left;
}

.cards-container .labelCaptions.sec-content .prc>div:nth-child(even) {
  text-align: left;
}

.cards-container .labels.sec-content .prc>div:nth-child(even) {
  text-align: left;
}

/* table */
/* .sec-content .prc{font-size: .8rem;} */

.sec-content.table>.prc:not(.nc),
.sec-content.captions>.prc:not(.nc),
.sec-content.labelCaptions>.prc:not(.nc) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.sec-content.captions,
.sec-content.labelCaptions {
  padding: .5em
}

.sec-content.table>.prc>div {
  padding: .5em;
}

.sec-content.table.divided>.prc:not(:first-child) {
  border-top: 1px solid rgba(34, 36, 38, .15);
}

.sec-content.table>.prc>div:nth-child(odd) {
  font-weight: 700;
}

.sec-content.table.celled>.prc>div:nth-child(odd) {
  border-right: 1px solid rgba(34, 36, 38, .15);
}

.sec-content.captions>.prc>div {
  padding: .25em
}

.sec-content.labelCaptions>.prc>div {
  padding: .25em
}

.sec-content.w-thin .prc:not(.nc),
.sec-content.w-thin.table .prc:not(.nc) {
  grid-template-columns: 2fr 8fr;
}

.sec-content.w-normal .prc:not(.nc),
.sec-content.w-normal.table .prc:not(.nc) {
  grid-template-columns: 3fr 7fr;
}

.sec-content.w-wide .prc:not(.nc),
.sec-content.w-wide.table .prc:not(.nc) {
  grid-template-columns: 5fr 5fr;
}

.sec-content.w-veryWide .prc:not(.nc),
.sec-content.w-veryWide.table .prc:not(.nc) {
  grid-template-columns: 6fr 4fr;
}

.prc.edt {
  grid-template-columns: 9fr 1fr !important;
}

.sec-content.header>i.icon {
  margin-right: .5em !important;
}

.menu-title>div,
.header,
.menu,
.card,
.prc {
  font-family: inherit !important;
}

.vertical .prc {
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto 1fr !important;
}

.vertical .prc div:nth-child(2) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-left: .5em;
  margin-bottom: .5em;
}

.card-image>.image>img.mini {
  min-height: 30px;
}

.card-image>.image>img.tiny {
  min-height: 40px;
}

.card-image>.image>img.small {
  min-height: 60px;
}

.card-image>.image>img.op-medium {
  min-height: 80px;
}

.card-image>.image>img.large {
  min-height: 90px;
}

.card-image>.image>img.big {
  min-height: 120px;
}

.card-image>.image>img.huge {
  min-height: 160px;
}

.card-image>.image>img.massive {
  min-height: 180px;
}

.sec-content-gen.venue {
  padding: .5em;
  width: 100%;
  display: grid !important;
  grid-gap: .5em;
  gap: .5em;
  align-content: start;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "pi-name pi-name pi-name"
    "pi-address pi-phone pi-website";
}

.sec-content-gen.venue .label {
  border: 1px solid grey;
  border-radius: 4px;
  text-align: center;
  padding: .5em;
}

.pi-name {
  grid-area: pi-name;
  padding: .5em;
  font-weight: bold;
}

.pi-address {
  grid-area: pi-address;
}

.pi-phone {
  grid-area: pi-phone;
}

.pi-phone>a {
  color: white !important;
  opacity: 1 !important;
}

.pi-website {
  grid-area: pi-website;
}
:root {
  --score-selected: rgb(201, 233, 255);
  --score-selected-complete: rgb(0, 88, 146);
  --score-selected-c: rgb(66, 66, 66);
  --score-progress: rgb(189, 255, 205);
  --score-def: rgb(14, 214, 30);
  --score-past: rgb(255, 197, 197);
  --score-nr: rgb(208, 208, 208);
  --score-complete: rgb(233, 247, 252);
  --score-scores: rgb(24, 206, 39);
  --score-rt: rgb(219, 251, 219);
  --score-type: rgb(219, 219, 219);
  --score-winner: rgb(0, 141, 7);
  --match-winner: rgb(0, 0, 0);
  --match-team: rgb(73, 73, 73);
  --score-blue-back: rgb(56, 117, 209);
  --score-blue: rgb(69, 142, 252);
  --score-red: #db2828;
  --score-green: #21ba45;
  --score-grey: #767676;
  --score-dark-grey: #707070;
  --score-black: #1b1c1d;
  --section-grid-header: rgb(54, 68, 90);
  --score-divider: rgb(223, 223, 223);
  --upset: rgb(248, 255, 153);
  --match-loss: #db2828;
  --match-win: #21ba45;
  --header-light: #f0f0f0;
  --date-wrapper: rgb(215, 215, 215);
  --bracket-wrapper: rgb(96, 96, 96);
  --match-same: rgb(66, 66, 66);
  --match-bye: rgb(0, 52, 71);
  --br-8: 8px;
  --playoff-bg: rgb(16, 12, 37);
}

.p-bracket.roundOf16 {
  display: grid;
  grid-template-columns: repeat(2, 50%) !important;
  grid-template-rows: repeat(8, 1fr) !important;
  grid-template-areas:
    "p-r16-1"
    "p-r16-2"
    "p-r16-3"
    "p-r16-4"
    "p-r16-5"
    "p-r16-6"
    "p-r16-7"
    "p-r16-8"
  ;
}

.p-bracket.quarterFinals {
  display: grid;
  grid-template-columns: repeat(4, 50%) !important;
  grid-template-rows: repeat(4, 1fr) !important;
  grid-template-areas:
    "p-qf-1"
    "p-qf-2"
    "p-qf-3"
    "p-qf-4"
  ;
}

.p-bracket.semiFinals {
  display: grid;
  grid-template-columns: repeat(4, 50%) !important;
  grid-template-rows: repeat(2, 1fr) !important;
  grid-template-areas:
    "p-sf-1"
    "p-sf-2"
  ;
}

.p-bracket>div {
  padding: .25em;
}

.p-r16-1 {
  grid-area: p-r16-1;
}

.p-r16-1>.match-grid,
.p-r16-3>.match-grid,
.p-r16-6>.match-grid,
.p-r16-8>.match-grid,
.p-qf-1>.match-grid,
.p-qf-4>.match-grid {
  border: 1px solid rgb(96, 96, 96);
  border: 1px solid var(--bracket-wrapper);
  border-bottom: 1px solid var(--off-blackish-bc);
  ;
  border-radius: 4px 4px 0 0;
}

.p-r16-2>.match-grid,
.p-r16-4>.match-grid,
.p-r16-5>.match-grid,
.p-r16-7>.match-grid,
.p-qf-2>.match-grid,
.p-qf-3>.match-grid {
  border: 1px solid rgb(96, 96, 96);
  border: 1px solid var(--bracket-wrapper);
  border-top: 1px solid var(--off-blackish-bc);
  border-radius: 0 0 4px 4px;
}


.p-r16-2 {
  grid-area: p-r16-2;
}

.p-r16-3 {
  grid-area: p-r16-3;
}

.p-r16-4 {
  grid-area: p-r16-4;
}

.p-r16-5 {
  grid-area: p-r16-5;
}

.p-r16-6 {
  grid-area: p-r16-6;
}

.p-r16-7 {
  grid-area: p-r16-7;
}

.p-r16-8 {
  grid-area: p-r16-8;
}

.p-qf-1 {
  grid-area: p-qf-1;
}

.p-qf-2 {
  grid-area: p-qf-2;
}

.p-qf-3 {
  grid-area: p-qf-3;
}

.p-qf-4 {
  grid-area: p-qf-4;
}

.p-sf-1 {
  grid-area: p-sf-1;
}

.p-sf-2 {
  grid-area: p-sf-2;
}

.matches-filter {
  display: grid;
  height: 100% !important;
}

.matches-filter>.content {
  height: 100%;
  overflow-y: auto;
}

.sports-container {
  padding: .15em;
}

.list-create-teams.list>.item>.content>.description {
  font-size: .8rem !important;
}

.sports-active>.item {
  font-weight: bold !important;
}

.mi-sports>.item {
  display: grid !important;
  grid-template-columns: 5fr 1fr !important;
  grid-template-rows: 1fr 1fr !important;
  grid-template-areas:
    "mi-sports-name mi-sports-active"
    "mi-sports-season mi-sports-season";
}

.mi-sports-name {
  grid-area: mi-sports-name;
  font-weight: bold;
}

.mi-sports-active {
  grid-area: mi-sports-active;
  text-align: right;
}

.mi-sports-season {
  grid-area: mi-sports-season;
}


.match-scoring-container {
  height: 100%;
}

.match-scoring-container>.segment {
  padding: .5em;
}

.match-scoring-container>.segment>.grid {
  margin: 0;
}

.match-scoring-container>.segment>.grid>.row:first-child {
  font-weight: bold;
}

.athletes-container {
  height: 100%;
  padding: 0 !important;
  background-color: rgb(200, 200, 200);
}

.athletes-container {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.athletes-container>.athlete {
  display: grid !important;
  grid-template-columns: 1fr 6fr 1fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  grid-template-areas:
    "athlete-icon athlete-details athlete-number";
}

.athletes-container.golfers {
  padding: 0 !important;
  display: grid !important;
  grid-template-rows: auto 1fr;
  height: 100%;
  background-color: var(--sem-black) !important;
}

.athletes-container.golfers>div:nth-child(2) {
  height: 100%;
  overflow-y: auto;
}

.athletes-container>.athlete.golfers {
  grid-template-columns: 1fr 1fr 6fr 1fr !important;
  grid-template-areas:
    "athlete-index athlete-icon athlete-details athlete-number";
}

.athletes-container>.athlete-team-golfers>.athlete-team {
  display: grid !important;
  grid-template-columns: 3fr 2fr 3fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  font-size: .85rem;
  font-weight: bold;
  grid-template-areas:
    "athlete-details-1 athlete-number-1 athlete-number-2 athlete-details-2";
}

.athlete-details-2 {
  text-align: right;
}

.athlete-number-1,
.athlete-number-2,
.athlete-index {
  text-align: center;
}

.athlete-icon {
  grid-area: athlete-icon;
}

.athlete-icon {
  grid-area: athlete-icon;
  text-align: center;
}

.athlete-details {
  grid-area: athlete-details;
  padding: 0 .5em;
  opacity: .8;
}

.athlete-number {
  grid-area: athlete-number;
  text-align: center;
}

.athlete-number>.label {
  min-width: 30px;
}

.athlete-number-1 {
  border-right: 1px solid rgb(216, 216, 216);
}

.athlete-number-2 {
  border-left: 1px solid rgb(216, 216, 216);
}

.athletes-container>.athlete .athlete-name {
  font-weight: bold !important;
  opacity: 1 !important
}

.athlete-icon>i.icon {
  margin: 0 !important
}

.athlete-team-select {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  text-align: center;
}

.athlete-team-select .label {
  width: 99% !important;
  padding: 1em .1em;
  background-color: rgb(173, 173, 173);
}

.athlete-team-header {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  text-align: center;
  padding: .5em;
  font-weight: bold;
}

.athlete-team-header-select {
  display: grid !important;
  grid-template-rows: 1fr 1fr !important;
  text-align: center;
  padding: .5em;
  font-weight: bold;
}

.athlete-team-header-select>div {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
}

.player-split {
  display: grid !important;
  grid-template-rows: 1fr 1fr !important;
  grid-gap: 1em;
  gap: 1em;
}

.drop-list>.athletes-container>.athlete {
  margin-bottom: .5em !important;
  background-color: white !important;
}

.ddp>.athletes-container {
  margin: 0 !important;
}

.ddp>.athletes-container>.athlete {
  margin: 0 !important;
  padding: .25em !important;
  background-color: rgb(228, 228, 228) !important;
}

.portal>div {
  width: 100%;
  /* padding: 1em !important; */
}

.grid-row {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

.grid-row>div>div {
  padding: 5em !important;
  align-items: center;
}

.match-scoring-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.team-history .winner-history,
.team-history .runnerUp-history,
.team-history .no-history {
  text-align: center;
  font-size: .8rem;
  padding: .5em;
}

.team-history .no-history {
  text-align: left;
}

.team-history .winner-history>div:nth-child(2),
.team-history .runnerUp-history>div:nth-child(2) {
  display: flex;
  grid-gap: .5em 1em;
  gap: .5em 1em;
  flex-wrap: wrap;
}

.team-history .winner-history>div:nth-child(2),
.team-history .runnerUp-history>div:nth-child(2) {
  font-size: .8rem;
  text-align: left;
}

.team-history .winner-history span,
.team-history .runnerUp-history span {
  color: grey;
  margin-left: .25em
}

.score-type {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(3, 1fr);
}

.score-type>div.selected {
  padding: .5em;
  background-color: rgb(201, 233, 255);
  background-color: var(--score-selected);
  color: rgb(66, 66, 66);
  color: var(--score-selected-c);
}

.sets {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(5, 1fr);
  ;
}

/* AIzaSyDayDpX0nEt_xJ38e83UDRlq2rV_iW_dSg */

/* AIzaSyDqAm-McPCZRjYeKhcTOmtXrKvEFh4JvIE */
/* AIzaSyDqAm-McPCZRjYeKhcTOmtXrKvEFh4JvIE */
/* AIzaSyD9kyNb74Q9g6dlZQlvDnjG8epC54cbLUU */
/* AIzaSyD9kyNb74Q9g6dlZQlvDnjG8epC54cbLUU */
/* AIzaSyD9kyNb74Q9g6dlZQlvDnjG8epC54cbLUU */

/* GSAPIKEY */
/* AIzaSyDayDpX0nEt_xJ38e83UDRlq2rV_iW_dSg */

/* AIzaSyBAnPqX2PmWY_cxjm48cUyyYDBEMGmxvPQ */

.sets>div {
  padding: .25em;
  border: 1px solid rgb(214, 214, 214);
  margin: .1em;
  border-radius: 8px;
  border-radius: var(--br-8);
}

.sets>div.selected {
  background-color: rgb(201, 233, 255);
  background-color: var(--score-selected);
  color: rgb(66, 66, 66);
  color: var(--score-selected-c);
  font-weight: bold;
}

.sidebar .match-set-scoring {
  background-color: rgb(38, 37, 44) !important;
}

.match-set-scoring {
  padding: 1em .5em;
  z-index: 9999 !important;
}

.match-set-scoring>.header,
.match-set-scoring>.content {
  padding: .25em
}

.match-score-table {
  grid-area: match-score-table;
  grid-template-rows: repeat(2, 1fr);
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  padding: .5em !important;
  grid-gap: 4px;
  gap: 4px;
  background-color: black !important;
  color: white;
}

.schedule-match .match-score-table {
  border: 3px solid var(--off-blackish-bc)
}

.match-score-table>div:not(:first-child) {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: 7fr repeat(6, 1fr);
  grid-gap: 1px;
  gap: 1px;
}

.match-score-table>div>div {
  padding: .15em
}

.match-score-table>div:not(:first-child)>div:first-child {
  text-align: left;
}

.scores {
  display: grid;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  align-items: center;
  text-align: center;
  grid-template-rows: repeat(1, 1fr);
  ;
  grid-template-columns: repeat(2, 1fr);
}

.scores.single {
  grid-template-columns: repeat(1, 1fr);
}

.scores-set {
  text-align: center;
  padding: 0 0 .5em 0;
  font-weight: bold;
  border-bottom: 1px solid gray;
}

.scores-teams {
  display: grid;
  width: 100%;
  text-align: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

.scores-teams>div {
  /* display: grid;
  width: 100%;  */
  padding: .5em;
  /* grid-template-rows: auto 1fr; */
}

.scores .scoring-team {
  display: grid;
  width: 100%;
  grid-gap: 1px;
  gap: 1px;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: 1fr;   */
  overflow-y: auto;
  height: 100%;
  padding: .25em;
}

.scores .sets-team {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(4, 1fr);
}

.scores .sets-team>div {
  padding: .5em;
  font-weight: bold;
}

.scores .scoring-team>div {
  padding: .25em;
  background-color: rgb(233, 233, 233);
  border: 1px solid lightblue;
  border-radius: 4px;
}

.scores .scoring-team.ns>div {
  padding: .25em;
  background-color: rgb(233, 233, 233);
  border-width: 0 !important;
  border-radius: 4px;
}

.scores .sets-team>div.selected,
.scores .scoring-team>div.selected {
  background-color: rgb(201, 233, 255);
  background-color: var(--score-selected);
  color: rgb(66, 66, 66);
  color: var(--score-selected-c)
}

.scoring-team>div.def {
  border-color: rgb(14, 214, 30);
  border-color: var(--score-def);
  font-weight: bold;
}

.scores-team,
.match-score-table>div:not(:first-child)>div:first-child {
  font-weight: bold;
}

.match-score-table .winner {
  background-color: rgb(0, 141, 7);
  background-color: var(--score-winner);
  font-weight: bold;
}

.scoring-header>div.selected {
  background-color: rgb(201, 233, 255);
  background-color: var(--score-selected);
  color: rgb(66, 66, 66);
  color: var(--score-selected-c);
}

.scoring-header>div.complete {
  background-color: rgb(0, 88, 146);
  background-color: var(--score-selected-complete);
  color: white;
}

.scoring-row-1>div:not(:first-child),
.scoring-row-2>div:not(:first-child),
.scoring-header>div:not(:first-child) {
  border-radius: 8px;
  border-radius: var(--br-8);
}

.match-details {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: repeat(3, 1fr);
  background: #fff;
  width: 100%;
  align-items: center;
  margin: 0 0 .25em 0px;
  padding: .25em;
  grid-template-areas:
    "md-away md-toggle md-home"
    "md-date md-date md-date"
    "md-time md-time md-time";
}

/* schedule-match */
.schedule-match {
  display: grid;
  background: #fff;
  width: 100%;
  padding: .25em;
  grid-gap: .5em;
  gap: .5em;
}

.schedule-match.full {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  grid-template-areas:
    "match-date match-time"
    "match-sl match-sl"
    "match-away match-home";
}

.schedule-match.section {
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
  grid-template-areas:
    "match-away match-display match-home";
}

.schedule-match.blocked.section {
  grid-template-columns: 2fr 2fr 1fr;
  grid-template-areas:
    "match-away match-away-score match-score-status";
}

.schedule-match:not(.rt).future {
  grid-template-columns: 4fr 2fr 4fr !important;
}

.schedule-match.playoff,
.schedule-match.playoff.bye {
  border-bottom: 1px solid var(--off-blackish) !important;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    ". . match-sl"
    "match-away match-display match-home"
    "match-trophy . match-location";
}

.schedule-match.playoff.rt {
  grid-template-columns: 3fr 3fr;
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    ".  match-sl"
    "match-away match-home"
    "match-trophy match-location"
    "match-display match-display";
}

.schedule-match.pool {
  padding: .75em;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  border-bottom: 1px solid rgb(223, 223, 223);
  border-bottom: 1px solid var(--score-divider);
  grid-template-areas:
    "pool-header"
    "pool-match";
}

.match-playoff {
  display: grid;
  grid-area: match-playoff;
  grid-template-columns: 2fr 1fr 2fr;
  text-align: center;
  font-size: .8rem;
  padding-top: .25em;
  grid-template-areas:
    "match-round match-display match-location";
}

.schedule-match.playoff.rt .match-playoff {
  grid-template-columns: 33% 33% 33%;
}

.matches-latest>.vert-container>.content {
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
}

.matches-latest-team>.vert-container>.content {
  display: grid;
  grid-template-rows: repeat(5, 1fr) !important;
}

.matches-latest-team:nth-child(2) {
  border-left: 1px solid var(--off-blackish-bc) !important;
}

.schedule-match.teams.lmo {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  padding: 0 .75em;
  align-items: center;
  grid-template-areas:
    "match-display"
    "match-last-date"
    "match-opponent" !important;
}

.matches-latest-team .schedule-match.lmo {
  grid-template-columns: 1fr 2fr 2fr 1fr !important;
  grid-template-rows: repeat(1, 1fr);
  padding: .25em;
  grid-template-areas:
    ". match-display match-opponent ." !important;
}

.match-last-date {
  grid-area: match-last-date;
  font-size: .8rem;
  text-align: center;
}

.team-form,
.team-overview {
  margin: .25em;
}

.match-players {
  text-align: center;
  font-size: .8rem;
}

.match-players>div,
.match-form>div,
.team-overview>div {
  margin-bottom: .5em;
  border: solid 1px var(--off-black);
  border-radius: 8px !important;
  border-radius: var(--br-8) !important;
  padding: .25em;
  background-color: var(--off-black);
}

.match-players>div:first-child,
.match-form>div:first-child,
.team-overview>div>div:first-child {
  padding: .75em 1em;
}

.team-overview .schedule-match {
  border: 0 !important;
}

.app-dark-mode .team-overview>.segments>.segment:first-child {
  color: white !important;
}

.school-info>div {
  display: grid;
  grid-template-columns: 1fr 3fr !important;
}

.team-overview {
  padding: .25em;
}

.team-overview>.segments>.segment:last-child {
  padding: .75em;
}

.team-overview>.segments>.segment:first-child {
  font-weight: bold;
}

/* .team-overview .label {
  margin: 0 .5em !important;
} */

.app-dark-mode .schedule-match {
  background-color: var(--sem-black) !important;
  border-bottom-color: var(--sem-black-b) !important;
}

.app-dark-mode .pool-match>.match-home,
.app-dark-mode .pool-match>.match-away,
.app-dark-mode .schedule-match>.match-home,
.app-dark-mode .schedule-match>.match-away {
  color: white;
}

.app-dark-mode .standings-selected {
  background-color: var(--sem-black-b) !important;
  color: rgb(242, 242, 242);
}

.app-dark-mode .athletes-container>.athlete {
  background-color: var(--sem-black) !important;
  border-bottom-color: var(--sem-black-b) !important;
}

.app-dark-mode .ms-summary>div {
  background-color: var(--sem-black) !important;
  /* border: 1px solid var(--sem-black-b) !important; */
}

.app-dark-mode .ms-summary {
  background-color: black;
}

.app-dark-mode .table-rankings {
  border-radius: var(--br);
  border: 1px solid var(--off-blackish-bc)
}

.app-dark-mode .table-rankings,
.app-dark-mode .table-rankings th {
  background-color: black !important;
  background: black !important;
  color: white !important;
}

.pool-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em 0;
  grid-template-areas:
    "match-court match-work";
}

.pool-match {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
  width: 100%;
  font-size: .85rem;
  padding: .5em 0;
  align-items: center;
  grid-template-areas:
    "match-away match-home";
}

.match-grid {
  padding: 0 .5em;
  border: 1px solid var(--off-blackish-bc);
}

.match-grid>.pool-match {
  display: grid;
  grid-template-columns: 85% 15%;
  grid-template-rows: repeat(3, 1fr);
  background: #fff;
  width: 100%;
  font-size: .85rem;
  align-items: center;
  grid-template-areas:
    "match-away result-away"
    "match-home result-home"
    "match-dates match-dates";
}

.match-grid>.pool-match.rt {
  grid-template-columns: 85% 15%;
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    "match-away result-away"
    "score-away score-away"
    "match-home result-home"
    "score-home score-home"
    "match-dates match-dates";
}

.match-dates {
  display: grid;
  color: var(--off-whiteish);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: .5em;
  gap: .5em;
  grid-area: match-dates;
  grid-template-areas:
    "match-date match-time"
    "match-location match-location";
}

.schedule-match.teams {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  grid-template-rows: 1fr 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .75em;
  border-bottom: 1px solid rgb(192, 192, 192);
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display match-home";
}

.schedule-match.teams.tia.oo:not(.playoff) {
  grid-template-areas:
    "match-date . match-sl "
    "match-home match-display .";
}

.schedule-match.teams.tih.oo:not(.playoff) {
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display .";
}

.schedule-match.teams.playoff {
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display match-home"
    "match-trophy . match-location";
}

.schedule-match.teams.playoff.rt {
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "match-date . match-sl "
    "match-away . match-home"
    "match-trophy . match-location"
    "match-display match-display match-display ";
}

.schedule-match.teams.tih:not(.oo) .match-away {
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.schedule-match.teams.tia:not(.oo) .match-home {
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.schedule-match.teams.tia.oo:not(.playoff) .match-away {
  display: none;
}

.schedule-match.teams.tih.oo:not(.playoff) .match-home {
  display: none;
}

.schedule-match.teams.rt:not(.lmo) {
  grid-template-columns: 3fr 2fr 3fr;
}

.team-overview .schedule-match.teams.rt:not(.lmo) {
  grid-template-columns: 4fr 3fr 4fr;
}

.schedule-match.teams.blocked {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date match-sl match-score-status"
    "match-away match-away-score match-away-score"
    "match-home match-home-score match-home-score";
}

.match-result {
  grid-area: match-result;
}

.schedule-match.team {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 2fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .5em;
  /* justify-items: center; */
  grid-template-areas:
    "match-away match-display match-home"
    "match-ls match-ls match-ls";
}

.schedule-match.teams.scores {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .5em;
  /* justify-items: center; */
  grid-template-areas:
    "score-away match-away match-display match-home score-home";
}

.push-header.score-types {
  padding: .5em 2em
}

.push-header.score-types .menu-slider {
  background-color: rgb(219, 219, 219);
  background-color: var(--score-type);
}

.score-away,
.result-away {
  grid-area: result-away;
  font-size: 1rem;
  text-align: center;
  color: var(--off-whiteish);
}

.score-home,
.result-home {
  grid-area: result-home;
  font-size: 1rem;
  text-align: center;
  color: var(--off-whiteish);
}

.score-away.w,
.score-home.w .result-away.w,
.result-home.w {
  color: white;
  font-weight: bold;
}

.match-date {
  grid-area: match-date;
  font-size: .8rem;
}

.match-time {
  grid-area: match-time;
  font-size: .8rem;
  justify-self: right;
}

.match-display {
  grid-area: match-display;
  text-align: center;
  font-size: .9rem;
  font-weight: bold;
  border-radius: 4px;
  padding: .15em;
  color: white;
}

.schedule-match>div {
  align-self: center !important;
}

.schedule-match.lmo.teams>.match-display {
  padding: .1em;
}

.schedule-match.ae .match-display {
  border: 1px solid rgb(223, 223, 223);
}

.schedule-match.ame:not(.complete):not(.rt) .match-display {
  border: 1px solid var(--sem-green) !important;
}

.schedule-match.ame .match-display {
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.ame .match-display {
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.ase .match-display {
  border-bottom: 1px solid var(--sem-blue) !important;
}

.match-display>div {
  text-align: center;
}

.match-display.paste>.label {
  background-color: rgb(255, 197, 197);
  background-color: var(--score-past);
  color: black !important;
}

.team-overview .match-display.complete,
.schedule-match.complete .match-display>.label {
  background-color: rgb(233, 247, 252);
  background-color: var(--score-complete);
  font-weight: bold;
  text-align: center;
}

.schedule-match.complete .match-display {
  font-size: 1rem;
}

.team-overview .schedule-match {
  padding: .25em
}

.match-display>.label {
  width: 100%;
}

.schedule-match.rt .match-display>.label {
  padding: .5em .5em;
  color: white;
  font-size: 1rem;
}

.schedule-match.rt.ame .match-display>.label:nth-child(2) {
  border: 1px solid gray !important;
}

.pool-match.rt .up,
.schedule-match.rt .match-display>.label.up {
  border: 1px solid var(--sem-green);
}

.schedule-match.rt .match-display .button.up {
  border-top: 1px solid var(--sem-green) !important;
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.rt .match-display .button.down {
  border-top: 1px solid var(--sem-red) !important;
  border-bottom: 1px solid var(--sem-red) !important;
}

.schedule-match.rt .match-display .button.tie {
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}


.pool-match.rt .down,
.schedule-match.rt .match-display>.label.down {
  border: 1px solid var(--sem-red);
}

.pool-match.rt .tie,
.schedule-match.rt .match-display>.label.tie {
  border: 1px solid grey;
}


.team-overview .match-display.win,
.match-display.win>.label {
  border-color: #21ba45;
  border-color: var(--match-win);
  color: white;
}

.team-overview .match-display.loss,
.match-display.loss>.label {
  background-color: #db2828;
  background-color: var(--match-loss);
  color: white;
}

.match-display.score-available {
  border: 1px solid rgb(24, 206, 39) !important;
  border: 1px solid var(--score-scores) !important;
  font-weight: bold;
  border-radius: 4px;
}

.ms-summary .match-display,
.schedule-match.rt:not(.lmo) .match-display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.matches-latest .schedule-match.rt:not(.lmo) .match-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "match-display-score match-display-score"
    "team-score-away team-score-home";
}

.summary-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.match-display-score {
  grid-area: match-display-score;
  text-align: center;
}

.team-score-away {
  grid-area: team-score-away;
}

.team-score-home {
  grid-area: team-score-home;
}

.match-display.nr {
  background-color: rgb(208, 208, 208);
  background-color: var(--score-nr);
}

.match-display.ae {
  border: 1px solid rgb(223, 223, 223);
}

.mr {
  grid-area: mr;
}

.match-sl {
  grid-area: match-sl;
  text-align: right;
}

.match-score {
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
}

.match-score.progress {
  background-color: rgb(189, 255, 205);
  background-color: var(--score-progress);
}

.match-playoff-header {
  text-align: center;
  grid-area: match-playoff-header;
  font-weight: bold;
}

.match-away {
  grid-area: match-away;
  color: rgb(73, 73, 73);
  color: var(--match-team);
  text-align: left;
}

.schedule-match.teams.tia.oo:not(.playoff) .match-home {
  text-align: left;
}

.match-home-score {
  grid-area: match-home-score;
  font-weight: bold !important;
}

.match-away-score {
  grid-area: match-away-score;
  font-weight: bold !important;
}

.match-away-score.win>.label,
.match-home-score.win>.label {
  background-color: var(--sem-green);
  color: white;
}

.match-away-score.loss>.label,
.match-home-score.loss>.label {
  background-color: var(--sem-red);
  color: white;
}

.match-score-status {
  grid-area: match-score-status;
  text-align: right;
}

.match-opponent-full {
  grid-area: match-opponent-full;
  font-weight: bold;
  text-align: left;
}

.match-opponent {
  grid-area: match-opponent;
  text-align: center;
  font-size: .8rem;
  font-weight: bold;
}

.match-full-score {
  grid-area: match-full-score;
  text-align: center;
  padding: .5em 0
}

.match-away>span,
.match-home>span {
  font-weight: bold !important;
  font-size: 1rem !important;
}

/* .match-away.upset>span,
.match-home.upset>span {
  background-color: var(--upset);
} */

.match-number {
  grid-area: match-number;
  font-weight: bold;
}

.match-home {
  grid-area: match-home;
  text-align: right;
  color: rgb(73, 73, 73);
  color: var(--match-team)
}

.match-grid .match-home {
  text-align: left;
}

.blocked .match-home {
  text-align: left;
}

.match-work {
  grid-area: match-work;
  text-align: right;
  font-size: .7rem;
}

.match-court {
  grid-area: match-court;
  font-size: .7rem;
}

.match-ls {
  grid-area: match-ls;
  text-align: right;
}

.score-away {
  grid-area: score-away;
}

.score-home {
  grid-area: score-home;
}

.match-round {
  grid-area: match-round;
  text-align: left;
  font-size: .8rem;
  color: var(--off-whiteish);
}

.match-location {
  grid-area: match-location;
  text-align: right;
  font-size: .8rem;
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.match-trophy {
  grid-area: match-trophy;
  text-align: left;
  font-size: .8rem;
  font-weight: normal !important;
  color: var(--off-whiteish);
}

.schedule-match.playoff .match-location {
  text-align: right;
}

.schedule-match.playoff.teams .match-location {
  text-align: right;
}

.match-dates .match-location {
  text-align: center;
}

.schedule-match>.match-away .label,
.schedule-match>.match-home .label {
  width: 20px;
  text-align: center;
  padding: .5em !important;
}

.schedule-match .match-away .label {
  margin-right: .5em;
}

.schedule-match .match-home .label {
  margin-left: .5em;
}

.standings-table .label {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.standings-table td>.label:nth-child(2) {
  float: right;
}

.match-away.winner,
.match-home.winner {
  font-weight: bold;
  font-size: 1.1rem !important;
  color: rgb(0, 0, 0);
  color: var(--match-winner);
}

.match-away.winner,
.match-home.winner {
  font-weight: bold;
  font-size: 1.1rem !important;
  color: rgb(0, 0, 0);
  color: var(--match-winner);
}


.schedule-matchDate {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-matchDate-header"
    "schedule-matchDateTeams";
}

.schedule-matchDate-header {
  grid-area: schedule-matchDate-header;
  padding: .25em;
  font-weight: bold;
  font-size: .8rem;
  text-align: center;
  border-top: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
}

.schedule-matchDateTeams {
  grid-area: schedule-matchDateTeams;
}

.schedule-dates {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  max-width: 600px;
  margin: auto;
  align-items: center;
  grid-template-areas:
    "schedule-dates-header"
    "schedule-levels";
}

.schedule-dates.nr {
  opacity: .2;
}

.match-grids {
  max-width: 600px;
  margin: auto;
}

.schedule-dates-header>.icon {
  float: right;
}

.schedule-dates-ls {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-dates-header schedule-dates-header-ls"
    "schedule-levels schedule-levels";
}

.schedule-dates-header {
  grid-area: schedule-dates-header;
  padding: .5em;
  font-weight: bold;
}

.schedule-dates-header-ls {
  grid-area: schedule-dates-header-ls;
  padding: .5em;
  font-weight: bold;
}

.schedule-levels {
  grid-area: schedule-levels;
  border-bottom: 1px solid rgb(207, 207, 207);
}

.sdw {
  background-color: rgb(215, 215, 215) !important;
  background-color: var(--date-wrapper) !important;
  border: 1px solid rgb(215, 215, 215);
  border: 1px solid var(--date-wrapper);
  border-radius: var(--br);
  padding: 0em;
  margin: .5em .25em;
  text-align: left;
}

.sdw>div:first-child {
  display: flex;
  justify-content: space-between;
  padding: .5em 1em;
  font-size: .8rem;
  font-weight: bold;
  background-color: rgb(215, 215, 215);
  background-color: var(--date-wrapper);
  border-radius: 8px 8px 0 0;
  border-radius: var(--br-8) var(--br-8) 0 0;
}

.sdw>div:first-child>.icon {
  text-align: right;
}

.schedule-level {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-level-header"
    "schedule-level-section";
}

.schedule-level-header {
  grid-area: schedule-level-header;
  padding: .5em;
  font-weight: bold;
  background-color: rgb(214, 213, 213)
}

.schedule-level-section {
  grid-area: schedule-level-section;
}

.schedule-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  grid-template-areas:
    "schedule-section-header"
    "schedule-section-teams";
}

.schedule-section>.divider {
  margin-top: .25em;
  margin-bottom: 0;
  font-size: .8rem;
  color: rgb(170, 170, 170) !important;
}

.schedule-section>.divider>.header {
  color: grey;
  font-size: .8rem;
  font-weight: 600 !important;
  color: rgb(170, 170, 170) !important;
}

.schedule-section .divider .label {
  font-size: .2rem;
}

.schedule-date-header {
  border-top: 3px solid rgb(185, 185, 185);
  padding: .5em;
  font-weight: bold;
  background-color: rgb(64, 64, 64);
  color: white;
}

.schedule-section.fav {
  margin-bottom: .5em;
  border-bottom: 1px solid rgb(199, 199, 199);
  padding: .25em;
}

.schedule-section.fav>.schedule-section-header {
  background-color: rgb(214, 255, 214);
  font-weight: bold;
}

.schedule-section.fav>.schedule-section-header>i.icon {
  margin-right: .5em;
}

.app-dark-mode .schedule-section.fav {
  border-bottom: 1px solid var(--off-blackish-bc)
}

.schedule-section-header {
  grid-area: schedule-section-header;
  padding: .5em;
  background-color: rgb(221, 221, 221);
  border: 1px solid rgb(185, 185, 185);
  font-weight: bold;
  border-radius: 4px;
}

.schedule-section-teams {
  grid-area: schedule-section-teams;
  padding: .5em 0
}

.schedule-levels>div,
.schedule-levels>div>div {
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
  max-height: none !important;
}

.league-standings {
  padding: .5em .5em;
  width: 100%;
  height: 100%;
}

.standings-container {
  padding: .25em !;
  width: 100%;
  border-radius: 8px !important;
  box-shadow: none !important;
  margin-bottom: .5em;
}

.standings-container.team {
  padding-top: .25em;
  width: 100%;
  margin-bottom: 0 !important;
}

.standings-header {
  padding: .75em !important;
  font-weight: bold;
  text-align: center !important;
  width: 100%;
  border-radius: 8px 8px 0 0;
  border-radius: var(--br-8) var(--br-8) 0 0;
}

.standings-header>.icon.star {
  margin-right: 1em;
}

.standings-table {
  padding: 0 0 0 .2em !important;
  font-size: 1rem !important;
}

.standings-table>.table {
  border: 0;
  border-radius: 0 !important;
}

.standings-table>.table>thead>tr>th,
.standings-table>.table>tbody>tr>td {
  text-align: center;
}

.history-container>.grid>.row>.column.six,
.table-rankings td:nth-child(3),
.standings-table>.table>tbody>tr>td:first-child {
  font-weight: bold !important;
  font-size: 1rem;
}

.table-rankings td:nth-child(2) .label {
  min-width: 36px;
}

.standings-table>.table>tbody>tr.fav>td>.icon {
  margin-left: 1em;
}

.standings-table>.table>tbody>tr.fav>td>.icon {
  margin-left: 1em;
}

.standings-selected {
  background-color: rgb(201, 233, 255);
  background-color: var(--score-selected);
  color: rgb(66, 66, 66);
  color: var(--score-selected-c)
}

.standings-headerr>tr>th {
  padding: .25em !important;
  text-align: center;
}

.ms-summary-full {
  height: 100% !important;
  overflow-y: auto;
  /* padding-bottom: 5em; */
}

.ms-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, auto) 1fr;
  background: #fff;
  background-color: white;
  padding: .25em;
  width: 100%;
  font-weight: bold;
  grid-template-areas:
    "ms-away ms-home"
    "ms-result ms-result"
    "ms-date ms-time"
    "ms-level ms-section"
    "ms-location ms-location"
    "ms-score ms-score"
  ;
}

.ms-summary>div {
  padding: .5em;
  text-align: center;
  border-radius: var(--br);
}

.ms-summary>div:nth-child(-n+2) {
  padding: .75em;
}

.ms-summary .icon {
  margin: 0 1em;
}

.ms-date {
  grid-area: ms-date;
}

.ms-time {
  grid-area: ms-time;
}

.ms-result {
  grid-area: ms-result;
}

.ms-level {
  grid-area: ms-level;
}

.ms-section {
  grid-area: ms-section;
}

.ms-location {
  grid-area: ms-location;
}

.ms-away {
  grid-area: ms-away;
  font-size: 1.2rem;
  text-align: center;
}

.ms-home {
  grid-area: ms-home;
  font-size: 1.2rem;
  text-align: center;
}

.ms-scores {
  grid-area: ms-scores;
  background-color: lightblue;
}

.ms-score {
  grid-area: ms-score;
  height: 100% !important;
}

.match-details>div {
  padding: .75em;
  text-align: center;
  margin: 1px;
  border-radius: 2px;
  border: 1px solid rgb(216, 216, 216);
}

.md-away {
  grid-area: md-away;
}

.md-toggle {
  grid-area: md-toggle
}

.md-home {
  grid-area: md-home;
  text-align: right;
}

.md-date {
  grid-area: md-date;
  text-align: center;
}

.md-time {
  grid-area: md-time;
  text-align: center;
}

.md-toggle>i.icon {
  font-size: 1em !important;
}

.level-grid {
  display: grid;
  background: #fff;
  width: 100%;
  grid-gap: 1px;
  gap: 1px;
  font-weight: bold;
}

.level-grid>div {
  border-radius: 2px;
  border: 1px solid rgb(216, 216, 216);
}

.section-div {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr;
  padding: .5em;
  border-radius: var(--br);
  margin: .5em;
  background-color: grey;
}

.section-div>div:first-child {
  padding: .5em 1em;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: rgb(54, 68, 90);
  background-color: var(--section-grid-header);
}

.section-grid {
  display: grid;
  width: 100%;
  font-weight: bold;
  padding: .25em;
  grid-gap: .25em;
  gap: .25em;
}

.section-grid.tc-5 {
  grid-template-columns: repeat(6, 1fr);
}

.section-grid.tc-6 {
  grid-template-columns: repeat(7, 1fr);
}

.section-grid.tc-7 {
  grid-template-columns: 2fr repeat(7, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.section-grid.tc-8 {
  grid-template-columns: 2fr repeat(8, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.section-grid.tc-9 {
  grid-template-columns: 2fr repeat(9, 1fr);
  grid-template-rows: repeat(19, 1fr);
}

.section-grid>div {
  text-align: center;
  border: 1px solid rgb(226, 226, 226);
  font-size: .8rem;
  padding: .5em .2em;
  background-color: var(--sem-blue);
  color: white;
  border-radius: var(--br);
}

.section-grid>div.home {
  background-color: white;
  color: black;
}

.section-grid>div.home {
  background-color: white;
  color: black;
}

.section-grid>div.away {
  background-color: rgb(199, 199, 199);
  color: black;
}

.section-grid>div.section-date-header,
.section-grid>div.section-date {
  background-color: black;
  color: white;
}

.section-grid>div.open {
  background-color: rgb(205, 212, 0);
  color: black;
  border-radius: var(--br);
}

.match-teams {
  display: grid;
  width: 100%;
  height: 100%;
  padding: .25em !important;
  /* border-top: 1px solid rgb(141, 141, 141);
  border-bottom: 1px solid rgb(141, 141, 141); */
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;
}

.match-teams.fh {
  height: 100%;
}

.match-teams-teams {
  display: grid;
  width: 100%;
  padding: 0;
  grid-template-columns: 1fr 1fr;
}

.match-teams>div {
  margin-bottom: .5em;
  width: 100%;
}

.match-teams-teams>div,
.match-teams-update {
  text-align: right;
}

.match-teams-teams .dropdown,
.match-teams-teams .dropdown .item>span {
  font-size: .8rem;
}

.match-header {
  display: grid;
  background-color: whitesmoke;
  width: 100%;
  padding: 1em;
  border-bottom: 1px solid rgb(141, 141, 141);
  grid-template-rows: auto auto;
}

.match-header>.row {
  padding: .5em 0 !important;
  font-weight: bold;
}

.scoring-update {
  display: grid;
  grid-template-rows: repeat(auto 1fr);
  grid-template-columns: repeat(1, 1fr);
  background-color: whitesmoke;
  width: 100%;
  padding: 1em .25em;
  border-top: 1px solid rgb(141, 141, 141);
  border-bottom: 1px solid rgb(141, 141, 141);
  grid-template-areas:
    "match-score-table"
    "team-scoring";
}

.match-team-names {
  text-align: center !important;
  display: grid;
  padding-bottom: 1em;
  grid-template-columns: 1fr 1fr;
  font-weight: bold;
}

.match-away-home {
  padding: 0 0 .5em 0;
  text-align: center;
  font-weight: bold;
}

.match-update-date {
  padding: 0 0 .5em 0;
  text-align: center;
}

.team-scores {
  text-align: center !important;
  display: grid;
  padding: 0 .25em;
  grid-template-columns: 1fr 1fr;
  width: 100% !important;
  grid-gap: .5em;
  gap: .5em;
}

.team-scoring {
  text-align: center !important;
  display: grid;
  padding: 0 .25em;
  grid-template-columns: 3fr 1fr 3fr;
  width: 100% !important;
  grid-gap: .5em;
  gap: .5em;
}

.team-scores .label {
  padding: 1em
}

.team-score {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  font-weight: bold;
  grid-gap: .5em;
  gap: .5em;
  align-items: center;
}

.match-score-header {
  background-color: red;
}

.match-updates {
  border-top: 1px solid grey;
  text-align: center;
  margin-top: 1em;
  padding-top: 1em;
}

.match-calendar {
  display: grid;
  grid-template-columns: 4fr 1fr;
  padding: .5em 0;
}

.match-calendar>div:nth-child(2) {
  text-align: right;
}

.team-section {
  display: flex;
  justify-content: space-around;
}

.ss-pt,
.ss-elim {
  padding-left: .25em !important;
  padding-right: .25em !important;
}

.ss-pt td:nth-child(1) {
  border-left: 2px solid var(--sem-green-light) !important;
  margin-right: 1em !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}


.ss-elim td:nth-child(1) {
  border-left: 2px solid var(--sem-red-light) !important;
  margin-right: 1em !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/* .ss-elim .label:nth-child(1) {
  border: 1px solid var(--sem-red-light) !important;
  margin-right: 1em !important;
} */

.ss-pt .label:nth-child(2),
.ss-elim .label:nth-child(2) {
  margin-left: 1em !important;
}

.legend,
.legend-schedule>div {
  margin: .5em .25em !important;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 0 !important;
}

.legend-schedule>div {
  flex-direction: row;
}

.legend-schedule .label {
  font-size: .2rem !important;
}

.legend>div {
  padding: 0 .5em;
  font-size: .8rem;
}

.legend>div:nth-child(1) {
  border-left: 2px solid var(--sem-green-light) !important;
}

.legend>div:nth-child(2) {
  border-left: 2px solid var(--sem-red-light) !important;
}

.legend>div:nth-child(3) {
  border-left: 2px solid grey !important;
}

.match-rosters>.header {
  text-align: center;
  padding: 1em !important;
}

.card-profile.athlete .header {
  display: grid !important;
  grid-template-columns: 1fr 4fr 2fr 2fr;
  width: 100%;
}

.card-profile.athlete .header>div.label {
  padding: .5em;
  text-align: center !important;
}


.card-profile.athlete .description {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.card-profile.athlete .description>div {
  text-align: center;
}

.set-buttons {
  display: grid;
  grid-template-columns: 1fr;
  padding: .5em 0;
  text-align: center;
}

.match-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1em 0;
  text-align: center;
}

.scorepad-container {
  display: grid;
  width: 100%;
  text-align: center;
  grid-gap: 6px;
  gap: 6px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background-color: rgb(56, 117, 209);
  background-color: var(--score-blue-back);
  padding: .5em;
}

.scorepad-container.done {
  grid-template-rows: repeat(5, 1fr);

}

.scorepad-header {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  text-align: center;
  padding: .5em;
}

.scorepad-header>div {
  line-height: 36px !important;
  text-align: center;
  font-size: 1.1rem;
}

.scorepad-header .label.selected {
  border: 1px solid black !important
}

/* .scorepad-container.kp{
  grid-template-rows: repeat(6, 1fr);
} */

.scorepad-container>div {
  display: block;
  border-radius: var(--br);
  background-color: #767676;
  background-color: var(--score-grey);
}

.scorepad-container>div[color='blue'] {
  background-color: rgb(69, 142, 252);
  background-color: var(--score-blue);
}

.scorepad-container>div[color='grey'] {
  background-color: #767676;
  background-color: var(--score-grey);
}

.scorepad-container>div[color='dark-grey'] {
  background-color: #707070;
  background-color: var(--score-dark-grey);
}

.scorepad-container>div[color='green'] {
  background-color: #21ba45;
  background-color: var(--score-green);
}

.scorepad-container>div[color='red'] {
  background-color: #db2828;
  background-color: var(--score-red);
}

.scorepad-container>div[color='black'] {
  background-color: var(--sem-black) !important;
}

.scorepad-container>div {
  line-height: 60px !important;
  text-align: center;
  color: white !important;
  max-height: 60px;
}

.scorepad-container .keypad-score {
  font-size: 2rem;
}

.scorepad-container>div>i.icon {
  font-size: 2rem;
}

.grid-overflow,
.playoff-grid {
  width: auto !important;
  margin: auto;
  padding: 1.25em
}

.grid-overflow-c {
  height: 100%;
  overflow-y: auto;
}

/* .grid-overflow {
  height: 100%;
  overflow-y: auto;
} */

.playoff-grid .row>.column {
  min-width: 180px;
  padding: 0 !important
}

.playoff-grid .row>.column>div {
  padding: .25em !important
}

.playoff-grid .segment {
  display: flex;
  align-items: center;
  padding: 0 .25em;
  margin: .25em 0;
}

.playoff-grid .match-grid {
  width: 100%;
  min-width: 140px
}

.playoff-grid .match-grid .pool-match>div {
  width: 100%;
  padding: .25em 0
}

.playoff-grid .match-grid .pool-match>div>.label {
  margin-right: 1em;
  width: 24px;
  text-align: center;
}

.playoff-grid-header {
  margin-bottom: -2.5em !important
}

.playoff-grid-header>.column {
  padding: .25 0 !important;
  text-align: center;
  justify-items: center;
}

.playoff-grid-header>.column>.segment {
  padding: .25em;
  font-weight: bold;
}

.match-menu-header {
  text-align: center;
}

.match-menu-header>div:nth-child(1) {
  font-size: 1.1rem;
}

.match-menu-header>div:nth-child(n+2) {
  font-weight: normal;
  font-size: 0.8rem;
  margin: .5em;
  color: rgb(195, 195, 195);
}

.match-menu-header>.rf {
  font-size: 1.1rem !important;
  background-color: blue;
  padding: .25em;
}

.split-header {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 1fr 5fr;
}

.split-header>div:nth-child(1) {
  text-align: left;
}

.split-header>div:nth-child(2) {
  text-align: center;
}

.split-header>div:nth-child(3) {
  text-align: right;
}

.allStar-container>.segment:nth-child(odd) {
  font-weight: bold;
  background-color: #f0f0f0;
  background-color: var(--header-light);
}

.pools-container {
  height: 100%;
  display: grid !important;
  padding: .5em .5em !important;
  grid-template-rows: 1fr 4fr;
}

.pools-container>div:nth-child(2) {
  height: 100%;
  overflow-y: scroll;
}

.sections-container {
  padding: .25em
}

.section-container {
  margin: .25em
}

.section-container>.segment {
  padding: .5em
}

.section-container>.segment:first-child {
  background-color: rgb(226, 226, 226);
  display: flex;
  justify-content: space-between;
}

.cal-status {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.alignment-container {
  display: grid !important;
  height: 100%;
  grid-template-columns: 1fr 1fr !important;
}

.alignment-container.existing {
  display: grid !important;
  height: 100%;
  grid-template-columns: 1fr 1fr !important;
}

.alignment-container>div {
  height: 100%;
  overflow-y: auto;
  padding: .5em
}

.alignment-container>div:nth-child(2)>div {
  height: 100%;
  overflow-y: auto;
}

.match-score-rt {
  grid-area: match-score-rt;
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
  grid-template-rows: 1fr 1fr !important;
  grid-template-areas:
    "rt-h-s-c rt-h-ss-c rt-sn-c rt-a-ss-c rt-a-s-c"
    "rt-h-s rt-h-ss rt-sn rt-a-ss rt-a-s";
  grid-gap: .2em;
  gap: .2em;
  width: 100%;
  padding: .5em;
  font-size: .9rem;
}

.match-score-rt>div {
  border: 1px solid grey;
  padding: .25em 1em;
  background-color: var(--off-blackish-bc);
  border-radius: 4px;
}

.match-score-rt>div.winning {
  background-color: var(--sem-green);
  font-weight: bold;
}

.match-score-rt>div.losing {
  background-color: var(--sem-red);
  font-weight: bold;
}

.lbl-rnk {
  width: auto !important;
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.app-dark-mode .standings-container,
.app-dark-mode .standings-table,
.app-dark-mode .schedule-dates {
  background-color: var(--me-background-bgc-dm) !important;
  color: var(--off-white-text) !important;
}

.app-dark-mode .schedule-dates,
.app-dark-mode .schedule-levels {
  color: inherit !important;
  border-color: var(--off-blackish-bc) !important;
}


.app-dark-mode .schedule-level {
  background: var(--off-black) !important;
}

.app-dark-mode .schedule-section-teams,
.app-dark-mode .schedule-match,
.app-dark-mode .standings-header,
.app-dark-mode .standings-table,
.app-dark-mode .sdw,
.app-dark-mode .schedule-section,
.app-dark-mode .standings-container,
.app-dark-mode .sports-active,
.app-dark-mode .schedule-match .app-dark-mode .team-overview div {
  background-color: var(--off-black) !important;
  background: var(--off-black) !important;
  color: white !important;
}

.app-dark-mode .sports-active {
  border-radius: 8px !important;
  border-radius: var(--br-8) !important;
}

.app-dark-mode .sports-active .item {
  color: white !important;
}

.app-dark-mode .sports-active .item:not(:last-child) {
  border-bottom: 1px solid var(--off-blackish-bc) !important;
}

.app-dark-mode .segments,
.app-dark-mode .segment {
  background-color: black !important;
  background: black !important;
  color: white;
}

.app-dark-mode>.content,
.app-dark-mode .match-grid,
.app-dark-mode .pool-match,
.app-dark-mode .pool-match .app-dark-mode .schedule-level,
.app-dark-mode .ui.table thead tr>th,
.app-dark-mode .table-rankings,
.app-dark-mode .history-container,
.app-dark-mode .standings-table,
.app-dark-mode .standings-table div,
.app-dark-mode .standings-table .table,
.app-dark-mode .standings-table .table thead,
.app-dark-mode .standings-table .table thead tr,
.app-dark-mode .standings-table .standings-headerr,
.app-dark-mode .standings-table .standings-headerr .table thead tr,
.app-dark-mode .standings-table .standings-headerr .table thead th,
.app-dark-mode .standings-table .table {
  background-color: var(--off-black) !important;
  background: var(--off-black) !important;
  color: white;
}


.app-dark-mode .sdw {
  background-color: var(--off-black) !important;
  border: 1px solid var(--off-black) !important;
}

.schedule-match.playoff,
.schedule-match.playoff.bye {
  border: 1px solid var(--off-blackish) !important;
}

.match-grid.bye,
.schedule-match.playoff.bye {
  background-color: black !important;
  opacity: .7 !important;
}

.schedule-match.rt {
  border: 1px solid green !important;
  padding: .5em;
  border-radius: 8px;
}

.app-dark-mode .standings-header {
  background-color: var(--off-blackish) !important;
}

.app-dark-mode .sdw>div:nth-child(1) {
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .sdw>div:nth-child(1) {
  border: 1px solid var(--off-black) !important;
}


.app-dark-mode .sdw>div {
  background-color: var(--off-black) !important;
}

/* .app-dark-mode .divider {
  background-color: var(--off-black) !important;
} */

.app-dark-mode .schedule-match.teams.ae .match-display,
.app-dark-mode .schedule-match.section.ae .match-display,
.app-dark-mode .schedule-match.playoff.ae .match-display {
  border-color: var(--off-blackish-bc);
}

.app-dark-mode .ms-result.ae {
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .match-buttons .button:not(.btn-sel),
.app-dark-mode .ms-button-group .button:not(.btn-sel) {
  background-color: var(--off-blackish-bc) !important;
  color: white !important;
}

.app-dark-mode .history-container .grid .label {
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .history-container .grid .row {
  border-bottom: 1px solid var(--off-blackish-bc) !important;
}

.app-dark-mode .team-overview .label {
  color: var(--off-white-text) !important;
}

.schedule-match.wa:not(.rt):not(.teams) .match-playoff>.match-display,
.schedule-match.wa:not(.rt):not(.teams)>.match-display {
  border-left: 2px solid var(--sem-green);
}

.schedule-match.wh:not(.rt):not(.teams) .match-playoff>.match-display,
.schedule-match.wh:not(.rt):not(.teams)>.match-display {
  border-right: 2px solid var(--sem-green);
}

.matches-latest-team .schedule-match.win.lmo>.match-display,
.schedule-match.win.teams>.match-display {
  background-color: var(--sem-green) !important;
  border: 0 !important;
}

.matches-latest-team .schedule-match.loss.lmo>.match-display,
.schedule-match.loss.teams>.match-display {
  background-color: var(--sem-red) !important;
  border: 0 !important;
}

.app-dark-mode .standings-container {
  border: 1px solid var(--off-blackish-bc) !important;
}

.app-dark-mode .upcoming-matches {
  background-color: var(--off-blackish) !important;
  padding: .5em;
  text-align: center;
}

.app-dark-mode .schedule-section .divider .header {
  color: var(--off-white-text) !important
}

.app-dark-mode .schedule-match.future .match-display {
  color: var(--off-whiteish) !important;
}

.app-dark-mode .schedule-match.future.pending .match-away {
  font-size: .8rem;
}

.app-dark-mode .match-grid.sm,
.app-dark-mode .match-grid.sm>.pool-match {
  background-color: rgb(66, 66, 66) !important;
  background-color: var(--match-same) !important;
}

.app-dark-mode .match-away.upset>span,
.app-dark-mode .match-home.upset>span {
  background-color: var(--off-blackish) !important;
  color: rgb(248, 255, 153);
  color: var(--upset);
  /* border-color: red !important; */
  /* text-decoration: underline; */
}

.matches-latest-team .match-display {
  border: 0 !important;
}

.chg {
  background-color: pink;
}
:root {
  --ticket-bg-color: rgb(233, 205, 44);
  --ticket-color: black;
  --ticket-border: solid 2px #fbbd08;
  --ticket-shadow: rgb(82, 82, 82);
  --ticket-shadow-2: rgb(48, 48, 48);
  --sub-ticket-border: solid 3px rgb(187, 187, 187);
}

/* #ccab66; */
/* box-shadow: -2px -2px 2px 0px var( --ticket-shadow), 2px 2px 2px 0px var( --ticket-shadow-2) !important;   */
.card-ticket {
  background-color: rgb(233, 205, 44);
  background-color: var(--ticket-bg-color);
  color: black;
  color: var(--ticket-color);
  display: grid;
  /* margin-bottom: 1em !important; */
  position: relative;
}

.card-ticket.single {
  border-radius: .5em;
  border: solid 2px #fbbd08;
  border: var(--ticket-border);
  border-width: 6px 12px;
  margin-bottom: .5em;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "ticket-info"
}

.card-ticket.full {
  border: solid 2px #fbbd08;
  border: var(--ticket-border);
  border-width: 6px;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "ticket-info"
    "ticket-subs"
    "ticket-send"
}

.card-ticket.willCall>.ticket-info,
.card-ticket.full>.ticket-info,
.card-ticket.single>.ticket-info {
  display: grid;
  grid-template-areas:
    "ticket-logo"
    "ticket-name"
    "ticket-location"
    "ticket-date-container"
    "ticket-status"
    "ticket-click"
}

.card-ticket.willCall {
  border-radius: 1em;
  margin: .5em 0 .5em 0;
  border: solid 3px rgb(187, 187, 187) !important;
  border: var(--sub-ticket-border) !important;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "ticket-info"
    "ticket-subs"
}

.card-ticket.display {
  height: 100%;
  padding: 1em;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "ticket-name"
    "ticket-status"
    "ticket-subs"
}

.ticket-info {
  grid-area: ticket-info;
}

.ticket-name {
  grid-area: ticket-name;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}

.ticket-location {
  grid-area: ticket-location;
  text-align: center;
  font-size: .7rem;
  padding: 0 !important;
  color: white
}

.ticket-logo {
  grid-area: ticket-logo;
}

.ticket-click {
  grid-area: ticket-click;
  text-align: center;
  font-size: .9rem;
  text-transform: uppercase;
  font-weight: bold;
}

.ticket-logo {
  padding: .5em !important
}

.ticket-logo>.image {
  height: 40px !important;
  margin: auto;
  text-align: center;
}

.ticket-date {
  grid-area: ticket-date;
  text-align: center;
}

.ticket-date-2 {
  grid-area: ticket-date-2;
  text-align: center;
}

.ticket-start-time {
  grid-area: ticket-start-time;
}

.ticket-end-time {
  grid-area: ticket-end-time;
}

.ticket-time-to {
  grid-area: ticket-time-to;
  margin: auto
}

.ticket-start-time-2 {
  grid-area: ticket-start-time-2;
}

.ticket-end-time-2 {
  grid-area: ticket-end-time-2;
}

.ticket-time-to-2 {
  grid-area: ticket-time-to-2;
  margin: auto
}

.ticket-subs {
  grid-area: ticket-subs;
  height: 100% !important;
  overflow-y: auto;
  padding: .5em .5em;
  background-color: rgb(53, 53, 53);
  /* border-radius: 0 0 .75em .75em; */
}

.ticket-send {
  grid-area: ticket-send;
}

.ticket-send {
  grid-area: ticket-given;
}

.page-wrapper .ticket-subs {
  padding: 0 !important
}

.ticket-sms {
  /* display: grid; */
  /* grid-template-rows: auto 1fr;  */
  height: 100%;
  padding: .5em;
}


.ticket-scanner {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
}

.ticket-scanner>div:nth-child(2) {
  margin: auto
}

.ticket-warning {
  padding: .5em
}

.cred-scanner {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
}

.cred-scanner .segment {
  height: 100%;
  overflow-y: auto;
}

.scan-split {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
}

.scan-split .qr-reader>div {
  padding-top: 50% !important;
  height: 50% !important;
  background-color: black;
}

section.test {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.scan-split>section>section {
  padding-top: 0 !important;
}

/* .scanned-status{height: 100%; margin: auto;} */

.will-call-container {
  height: 100%;
}

.will-call-container>div {
  height: 100%;
}

/* .will-call{
  padding: .5em;
  display: grid;
  gap: 1em;
  text-align: center;
  grid-template-columns: 1fr;  
  grid-template-rows: 1fr 2fr;
  grid-template-areas: 
  "will-call-info"  
  "will-call-qr"
} */

.will-call-info {
  grid-area: will-call-info;
  display: grid;
  grid-template-rows: repeat(4 1fr);
}

.will-call-info>div {
  font-size: 1.1rem;
}

.will-call-qr {
  grid-area: will-call-qr;
  margin: auto;
}

/* .will-call.exchange{
  height: 100%;
} */
.will-call.print {
  height: auto;
}

.will-call-header {
  padding: .5em;
  background-color: var(--topper) !important;
  color: white;
  text-align: center;
}


.card-ticket-sub {
  display: grid;
  padding: 0em !important;
  border-width: 3px !important;
  height: 100%;
  padding: 0em !important;
  border: 3px solid gray !important;
  margin: .1em !important;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sub-ticket-header"
    "sub-ticket-qr";
  ;
}

.card-ticket-sub.will-call {
  margin: .5em .1em !important;
  /* page-break-after: always; */
  page-break-inside: avoid;
}

.card-ticket-sub.will-call.exchange {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "sub-ticket-header"
    "sub-ticket-qr"
    "sub-ticket-forward"
  ;
}

.sub-ticket-forwarded {
  color: black
}

.card-ticket-sub.entered {
  border-color: var(--sem-red);
  background-color: var(--sem-light-red);
}

.card-ticket-sub>div {
  text-align: center;
}

.card-ticket-sub>.label {
  margin: 0;
  border-radius: 0;
  padding: 1em 0;
  text-align: center;
}

.sub-ticket-header {
  width: 100%;
  display: flex;
  justify-items: self-end;
}

.sub-ticket-header.fwd>.label {
  background-color: grey !important
}

.sub-ticket-header>.label {
  width: 100%;
  border-radius: 0;
}

.sub-ticket-header>.label>.label {
  margin-right: 2em;
}

.ticket-name>div:nth-child(2) {
  font-weight: bold;
  font-size: 1.2rem;
}

.ticket-name>div:nth-child(2),
.sub-ticket-name>div:first-child {
  padding: .5em 0;
}

.sub-ticket-number {
  grid-area: sub-ticket-number;
}

.sub-ticket-label {
  grid-area: sub-ticket-label;
}

.sub-ticket-label>.label {
  width: 90%;
  height: 100% !important;
  padding: 1em;
}

.sub-ticket-name>.sub-ticket-user {
  width: 100%;
  text-align: center;
}

.sub-ticket-name>.sub-ticket-user>.icon {
  width: 100%;
  text-align: center;
}

*/ .sub-ticket-name>div:nth-child(3) {
  font-size: .7rem;
}

.sub-ticket-name-name {
  grid-area: sub-ticket-name-name;
}

.sub-ticket-date {
  grid-area: sub-ticket-date;
}

.sub-ticket-subs {
  grid-area: sub-ticket-subs;
  overflow-y: auto;
  padding: em .5em !important;
  background-color: black;
}

.sub-ticket-send {
  grid-area: sub-ticket-send;
  padding: .5em !important;
  margin-top: .5em !important;
  text-align: center;
}

.sub-ticket-dt {
  grid-area: sub-ticket-dt;
  padding: .5em !important;
  margin-top: .5em !important;
  text-align: center;
}

.sub-ticket-exchange {
  grid-area: sub-ticket-exchange;
  padding: 0 !important;
  margin-top: .5em !important;
  text-align: center;
}

.sub-ticket-user {
  grid-area: sub-ticket-user;
}

.list.relaxed.ordered .icon {
  padding-left: .5em !important;
}

.ticket-status-grid {
  font-size: .8rem;
}

.ticket-status-grid>.row {
  padding: .75rem 0 !important;
}

.ticket-status-grid>.row:nth-child(1) {
  font-weight: bold;
  font-size: 1rem;
}

.ticket-status {
  grid-area: ticket-status;
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  /* border-bottom: 2px solid rgb(204, 204, 204); */
  padding: .5em;
}

/* .ticket-description{grid-area: ticket-description;} */
.ticket-qr {
  grid-area: ticket-qr;
  text-align: center;
  margin: auto
}

.card-ticket.full>.ticket-qr,
.card-ticket.display>.ticket-qr {
  margin: auto
}

.ticket-info>div {
  padding: .5em
}

.sub-ticket-qr {
  display: flex;
  align-items: center;
  grid-area: sub-ticket-qr;
  height: 100%;
  text-align: center;
  margin: auto !important;
}

.sub-ticket-qr .icon {
  margin: 0 !important;
  text-align: right;
}

.ticketing-status {
  display: flex;
  flex-direction: column;
  font-size: .8rem;
  font-weight: normal;
  width: 40%;
  justify-items: space-between !important;
}

/* .ticket-date-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  width: 100%;
  grid-area: ticket-date-container;
} */

.ticket-date-container {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 3fr 2fr 2fr;
  grid-gap: .5em;
  gap: .5em;
  justify-content: center;
  width: 100%;
  grid-area: ticket-date-container;
  grid-template-areas:
    "ticket-date ticket-start-time ticket-end-time";
  /* grid-template-areas:  
  "ticket-date ticket-date ticket-date"
  "ticket-start-time ticket-time-to ticket-end-time"; */
}

.ticket-date-container.multiple {
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "ticket-date ticket-start-time ticket-end-time"
    "ticket-date-2 ticket-start-time-2 ticket-end-time-2"
    /* grid-template-areas:  
  "ticket-date ticket-date ticket-date"
  "ticket-start-time ticket-time-to ticket-end-time"
  "ticket-date-2 ticket-date-2 ticket-date-2"
  "ticket-start-time-2 ticket-time-to-2 ticket-end-time-2"; */
}

.ticket-date-container>div {
  text-align: center;
}

.ticket-date-container>.label {
  margin: 0;
  text-align: center;
  padding: .5em .1em
}

.ticket-status>.label {
  display: flex;
  justify-content: space-between !important;
  margin: 0 0 0 0 !important;
  width: auto;
  padding: .75em;
  font-size: .7rem;
  font-weight: normal;
}

.ticketing-header {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(5, 1fr);
  font-size: .8rem;
  font-weight: normal;
  text-align: center;
}

.ticketing-header.solo {
  grid-template-columns: repeat(4, 1fr);
}

.card-ticket .card-canvas.horz {
  float: right;
  padding: 0em;
}

/* box-shadow: -2px -2px 2px 0px var( --ticket-shadow), 2px 2px 2px 0px var( --ticket-shadow-2) !important;   */
.sub-ticket-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 4fr 1fr;
  grid-gap: 1em;
  gap: 1em;
  align-items: center;
  grid-template-areas:
    "sub-ticket-number sub-ticket-key sub-ticket-icon"
    "sub-ticket-number sub-ticket-assigned sub-ticket-icon";
  background-color: white;
  color: black;
  border: solid 3px rgb(187, 187, 187) !important;
  border: var(--sub-ticket-border) !important;
  border-radius: 1em;
  margin: 1em 0;
  padding: 1em;
}

.sub-ticket-container.assigned {
  border-color: var(--sem-blue);
  background-color: var(--sem-light-blue);
}

.sub-ticket-container.entered {
  border-color: var(--sem-red);
  background-color: var(--sem-light-red);
}

.sub-ticket-container.selectedd {
  background-color: rgb(59, 59, 59);
  color: white;
  border-color: black
}

.sub-ticket-assigned {
  grid-area: sub-ticket-assigned;
  font-size: .8rem;
  padding: 0;
}

.will-call>.sub-ticket-assigned {
  font-size: 1rem;
}

.will-call .sub-ticket-assigned {
  justify-content: space-evenly;
}

.sub-ticket-icon {
  grid-area: sub-ticket-icon;
  text-align: center;
}

.sub-ticket-key {
  grid-area: sub-ticket-key;
  font-size: .8rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.sub-ticket-number {
  grid-area: sub-ticket-number;
  text-align: center;
  font-size: 1.2rem;
  position: relative;
  font-weight: bold;
}

.sub-ticket-number>.label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.sub-tickets-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: .5em;
  gap: .5em;
  font-size: .7rem;
}

.sub-tickets-container:nth-child(1)>div {
  text-align: center;
  padding: .5em 0 1em 0;
  font-weight: bold;
  font-size: 1rem;
}

.sub-tickets-container>.list {
  margin: 0
}

.sub-tickets-container>.list:nth-child(1)>.item>.content>.header {
  color: blue
}

.sub-tickets-container>.list:nth-child(2)>.item>.content>.header {
  color: green !important
}

.sub-ticket-container.selectedd .sub-ticket-number>.label {
  background-color: var(--sem-green) !important;
}

.print .menu-header-topper-simple,
.print .menu-header-topper,
.print .wizard-wrapper .ticket-subs,
.print .wizard-wrapper .card-ticket,
.print .wizard-wrapper .footer {
  background-color: white !important;
  color: black;
  border-color: white !important;
}


.tickets-container {
  padding: .5em !important;
  height: 100%;
}

.page-wrapper .tickets-container {
  background-color: black;
}

.icon-phone {
  margin-bottom: .25em;
}

.qr-code {
  background-color: white;
  height: 100%;
}

.qr-code>div:nth-child(1)>.label {
  padding: 1em;
  width: 100%;
  text-align: center;
  border-radius: 0;
}

.sub-ticket-forward {
  grid-area: sub-ticket-forward;
  padding: 1em !important;
  color: black
}

.print .vert-container,
.print .content,
.print .card,
.print .ticket-info,
.print .card-ticket,
.print .card-ticket-sub {
  height: auto !important;
}

.print .sub-ticket-qr {
  padding: 2em !important
}

.ticks .card-ticket-sub {
  padding: .5em
}

.ticket-subs>div>div {
  width: 96% !important;
  margin: auto;
}

.page-wrapper.print .ticket-subs>div>div {
  width: 100% !important;
}

.page-wrapper.print .sub-ticket-qr {
  display: inline;
  margin: auto !important;
  width: 100%;
}

/* @media print {
  .card-ticket-sub {page-break-after: always;}
} */


.ticket-sms>.buttons {
  margin-bottom: 2em;
}
/* This css sets all the colors */

:root {
  --card-shadow: rgb(228, 228, 228);
  --card-shadow-fav: rgb(175, 185, 255);
  --ddp: rgb(70, 70, 70);
  --drag-number-b: rgb(149, 182, 157);
  --drag-number-d: rgb(149, 182, 157);
  --drag-number-dd: rgb(101, 126, 107);
  --drag-number: rgb(186, 226, 196);
  --drag-number: rgb(224, 224, 224);
  --drop-content: lightgray;
  --help-header: rgb(235, 235, 235);
  --json-viewer-light: rgb(241, 241, 241);
  --json-viewer: rgb(19, 19, 19);
  --light-bgc: #f0f0f0;
  --marked-items: rgb(210, 252, 255);
  --mid-bgc: #adadad;
  --mod-h-bgc: rgb(245, 240, 240);
  --modify-footer: rgb(233, 233, 233);
  --off-black: rgb(24, 24, 24);
  --off-blackish: rgb(36, 36, 36);
  --off-blackish-bc: rgb(48, 48, 48);
  --off-blackish-bcp: rgb(72, 72, 72);
  --off-blackish-sel: rgb(64, 64, 64);
  --off-white-text: rgb(236, 236, 236);
  --off-whiteish: rgb(183, 183, 183);
  --off-white: rgb(196, 196, 196);
  --page-bgc-mob: rgb(218, 218, 218);
  --page-bgc: rgb(31, 31, 31);
  --selected-borders: solid 2px lightgrey;
  --sem-blue: #2185d0;
  --sem-light-green: #bbffcb;
  --sem-green: #21ba45;
  --sem-greenish: #dfffe6;
  --sem-green-light: #21ba45;
  --sem-light-blue: #d2ebff;
  --sem-light-green: #b6f1c4;
  --sem-grey: rgb(116, 116, 116);
  --sem-light-red: #ebc3c3;
  --sem-purple: #a333c8;
  --sem-red: #db2828;
  --sem-redish: #ffe3e3;
  --sem-red-light: rgb(255, 118, 118);
  --sem-orange: #f2711c;
  --sem-black: #1b1c1d;
  --sem-black-b: rgb(55, 56, 59);
  --sem-yellow: #fbbd08;
  --settings-menu-item-dd: rgb(77, 33, 33);
  --side-menu-bgc: rgba(0, 0, 0);
  --side-menu-dmo-bgc: rgb(44, 40, 40);
  --sort-borders: solid 1px rgb(153, 163, 177);
  --splash-container: rgb(14, 14, 14);
  --test: rgb(40, 75, 172);
  --theme-c: #131313;
  --thin-border-dm: solid 1px rgb(42, 42, 42);
  --thin-border-settings: solid 1px rgb(73, 73, 73);
  --thin-border-thin: solid 1px rgb(255, 255, 255);
  --thin-border: solid 1px lightgrey;
  --thin-borders-sort: dotted 1px rgb(75, 75, 75);
  --thin-borders: solid 1px lightgrey;
  --topper-sub-2: rgb(123, 123, 123);
  --topper-sub: rgb(93, 93, 93);
  --topper: rgb(63, 63, 63);
  --url-color: #4183c4;
  --me-background-bgc-dm: black;
  --me-background-bgc-dmoff: rgb(43, 43, 43);
  --me-border-bgc-dm: rgb(66, 66, 66);
  --me-card-bgc-dm: rgb(36, 36, 36);
  --me-card-c-dm: rgb(230, 230, 230);
  --me-card-c-dm-2: rgb(177, 177, 177);
  --app-google: rgb(241, 254, 231);
  --app-google-temp: rgb(231, 245, 254);
  --app-google-wrap: rgb(185, 207, 170);
  --app-google-wrap-temp: rgb(180, 220, 245);
  --non-sel: rgb(184, 184, 184);
  --horz-height: 36px;
}

.App,
.start-container,
.splash-container {
  background-color: rgb(14, 14, 14) !important;
  background-color: var(--splash-container) !important;
}

.card-indics {
  border-color: rgb(228, 228, 228) !important;
  border-color: var(--card-shadow) !important;
}

.app-homepage {
  background-color: rgb(61, 61, 61);
  color: white;
}

.app-container {
  background-color: var(--me-background-bgc) !important;
}

.all-headers-menu .menu>.divider {
  background-color: black;
}

.all-headers-menu .input.icon>.icon {
  color: white !important
}

/* THIS iS THE SORT DIV */
.ddp>div,
.ddp>div>div {
  background-color: rgb(64, 64, 64) !important;
  background-color: var(--off-blackish-sel) !important;
  color: white !important;
  padding: .25em !important;
}

.drop-group-header {
  background-color: #575757;
  color: white;
}

.drop-group-content {
  background-color: lightgray;
  background-color: var(--drop-content);
}

.drag-number>.label {
  background-color: rgb(224, 224, 224);
  background-color: var(--drag-number);
  border: 1px solid rgb(149, 182, 157);
  border: 1px solid var(--drag-number-b);
}

.drag-number.issue:not(.ls)>.label {
  background-color: rgb(255, 95, 95) !important;
}

.dd-menu-menu>.item,
.dd-menu>.item {
  background-color: rgb(170, 170, 170) !important;
}

.ddp .label {
  color: white;
}

.ddp,
.ddp.simple>div,
.ddp.viewSettingsMenu>div {
  background-color: rgb(70, 70, 70);
  background-color: var(--ddp);
}

.drop-header {
  background-color: #adadad;
  background-color: var(--mid-bgc);
}

.drop-content {
  background-color: lightgray;
  background-color: var(--drop-content);
}

.filter-selected {
  padding: .5em;
  border-bottom: solid 1px lightgrey;
  border-bottom: var(--thin-border);
  background-color: lightgrey;
}

.form-container:not(.sf) .divider:not(.text) {
  background-color: rgb(232, 232, 232);
}



.grid-check-container {
  background-color: var(--settings-bgc);
}

.grid-check-container.adding .segment {
  background-color: var(--settings-bgc);
  color: var(--settings-c);
}

.grid-icons-content .segment.ao {
  border-color: gray;
  background-color: black !important;
  color: rgb(175, 175, 175) !important
}

.grid-icons-content .segment.hid {
  background-color: rgb(88, 88, 88) !important;
  color: white !important;
}

.hcf-footer.modify {
  background-color: rgb(233, 233, 233);
  background-color: var(--modify-footer);
}

.hcf-container.vid>.hcf-header,
.hcf-container.vid>.hcf-footer {
  background-color: white;
}

.hcs-container {
  background-color: black;
}

.hcaf-header {
  border-bottom: solid 1px lightgrey;
  border-bottom: var(--thin-border);
  background-color: rgb(245, 240, 240);
  background-color: var(--mod-h-bgc);
}

.help-container {
  background-color: rgb(65, 65, 65) !important;
}

.help-container .list>.item .header {
  background-color: inherit;
}

.help-header.group {
  background-color: rgb(153, 153, 255);
}

.help-seg:not(.doc) .help-group-item-header {
  background-color: rgb(68, 68, 68) !important;
}

.help-seg:not(.doc) .help-group-item-description {
  color: white !important;
}

.dark-mode .help-edit-edit {
  background-color: rgb(29, 29, 29);
}

.help-seg:not(.doc),
.help-seg:not(.doc) .header,
.help-seg:not(.doc) .message,
.help-seg:not(.doc) .accordion,
.help-seg:not(.doc) .accordion .content {
  background-color: black !important;
  color: white !important;
}

.help-seg:not .header {
  background-color: rgb(41, 41, 41) !important;
}

.images-content {
  border: 1px solid lightgrey;
  background-color: rgb(220, 242, 248);
}

.images-content>.images-picker .item>.content>.description {
  color: var(--dark-adjust-text) !important;
}

.inline-container:not(.drk) .content:nth-child(1) {
  background-color: #f0f0f0 !important;
  background-color: var(--light-bgc) !important;
  border-color: rgb(24, 24, 24) !important;
  border-color: var(--off-black) !important;
}

.single-page-container>.item-container {
  border: 1px solid grey;
  background-color: var(--dtd-bgc);
}

.label-container>.ui.label.selected {
  background-color: lightblue;
}

.map-list-container {
  background-color: white;
}

.hcaf-container {
  background-color: white;
}

.map-list-list .map-item-selected {
  background-color: whitesmoke;
}

.message-line>.message-message {
  background-color: lightgray;
}

.message-line.au>.message-message {
  background-color: lightblue;
}

.menu-row-container {
  background-color: inherit !important;
}

.menu-select-items .item {
  background-color: whitesmoke !important;
}

.modify-container {
  background-color: white;
}

.modify-header {
  border-bottom: solid 1px lightgrey;
  border-bottom: var(--thin-border);
  background-color: rgb(245, 240, 240);
  background-color: var(--mod-h-bgc);
}

.page-wrapper-container {
  background-color: grey;
}

.page-wrapper {
  background-color: black;
}

.page-area-container {
  background-color: rgb(31, 31, 31);
  background-color: var(--page-bgc);
}

.push-container>.push-header>.push-caption {
  color: white;
}

.start-content {
  color: white !important;
}

.tab-menu>.item.active {
  border-color: red !important;
}

.update-container {
  background-color: white;
  border-color: rgba(34, 36, 38, .15);
}

.uploader-content>.bulk-container {
  color: black
}

.start-dimmer {
  background-color: rgb(14, 14, 14);
  background-color: var(--splash-container);
}

.update-wrapper {
  background-color: rgb(0, 0, 0, 0.2);
}

.num-pad {
  background-color: rgb(42, 42, 42);
}

.num-pad-current {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr !important;
}

.num-pad-value {
  background-color: white;
}

.num-pad-pad>div {
  background-color: rgb(61, 61, 61);
  color: white;
}

.num-pad-pad>div:nth-child(10),
.num-pad-pad>div:nth-child(12) {
  background-color: rgb(170, 170, 170);
  color: black
}

.pending-wait {
  background-color: black;
}

.scroll-active {
  color: red !important;
}

.mob .page-area-container {
  background-color: rgb(218, 218, 218);
  background-color: var(--page-bgc-mob)
}

.ns-header {
  background-color: rgb(88, 88, 88);
  color: white;
}

.app-notification-container.active {
  background-color: rgb(236, 236, 236)
}

.app-notification-container.past {
  background-color: rgb(239, 255, 237)
}

.app-notification-container.past-ns {
  background-color: rgb(255, 229, 224)
}

.app-notification-container.past {
  border: 1px solid rgb(182, 250, 172) !important;
}

.app-notification-container.past-ns {
  border: 2px solid rgb(255, 197, 197) !important;
}

.app-notification-container .note-description,
.app-notification-container .note-note {
  background-color: rgb(255, 255, 255)
}

.top-menu>.item {
  color: inherit !important
}

.top-menu .icon {
  color: inherit !important
}

.top-menu>.menu-title {
  color: inherit !important
}

.sem-banner {
  background-color: black;
}

.sem-carosel {
  background-color: black;
}

.img-ts-col.approved {
  border: solid 2px lightgrey;
  border: var(--selected-borders);
  border-color: rgb(214, 255, 214);
}

.img-ts-container {
  background-color: white !important;
}

.search-page {
  background-color: white;
}

.item-mask {
  background-color: inherit !important;
}

.item-filter .menu-filter.tags .label.active {
  background-color: var(--live-view-bgc)
}

.item-filter .menu-filter.menu .menu,
.item-filter .menu-filter.menu .dropdown {
  color: inherit !important;
}

/* ui tiny inverted vertical ui overlay bottom thin visible sidebar action-sidebar menu */
/* ui tiny inverted vertical ui overlay bottom thin visible sidebar action-sidebar menu" */

.action-sidebar {
  width: 100%;
  /* height: 0; */
}

.action-sidebar:not(.visable) {
  width: 100%;
  /* display: none; */
  /* height: 0; */
}

.action-sidebar>.item {
  width: 100% !important;
}

.action-sidebar>.item {
  padding: 1.5em !important;
  font-weight: bold !important;
  font-size: .9rem;
  color: inherit !important;
  background-color: inherit !important;
}

.box {
  background-color: rgb(221, 221, 221);
}

.box.mapp {
  background-color: rgb(241, 241, 241);
}

.cal-close>div {
  border: 1px solid grey;
  background-color: rgb(230, 230, 230);
}

.cal-month {
  background-color: rgb(185, 185, 185);
  border: 1px solid grey;
}

.calendar-container {
  background-color: rgb(230, 230, 230);
}

.calendar-container>div {
  border: 1px solid lightblue;
}

.calendar-container>div.dow {
  background-color: rgb(135, 206, 250);
}

.calendar-container>div.nd {
  background-color: rgb(211, 211, 211);
}

.calendar-container>div.sel {
  background-color: rgb(135, 250, 154);
}

.calendar-container>div.norm {
  border-color: rgb(135, 250, 154);
}

.calendar-container>div.norm.dk {
  background-color: rgb(135, 250, 154);
}

.non-sel {
  background-color: rgb(184, 184, 184);
  background-color: var(--non-sel);
}


.norm-count>span>div {
  background-color: red !important;
}

.month-select-container>div {
  background-color: rgb(187, 187, 216);
  border: 1px solid grey;
}

.current-date {
  background-color: lightblue !important;
}

.card-meta {
  color: rgba(0, 0, 0, 0.6) !important
}

.wizard-wrapper.full {
  background-color: whitesmoke;
}

.news-container {
  color: white;
}

.ps-pager .item.active {
  background-color: lightgreen !important;
}

.card-img.card-profile>.content.extra {
  background-color: whitesmoke;
}

.app-sidebar-left-wrapper {
  background-color: black;
  color: white;
}

.item-popup.single>.icon {
  background-color: green !important;
}

.item-popup.single>.icon {
  background-color: green !important;
}

.item-popup>.item-popup-content {
  background-color: #eeeeee !important;
  border: 1px solid #bababc;
  box-shadow: 1px 1px 0 0 #bababc;
}

.item-settings>.item-settings-content {
  background-color: #eeeeee !important;
  border: 1px solid #bababc;
  box-shadow: 1px 1px 0 0 #bababc;
}

.aul-events .label {
  background-color: rgb(202, 202, 202) !important;
}

.ms-key {
  color: grey
}

.hdd.tv.nf {
  color: red !important
}

.prp-description {
  color: black
}

.sidebar-fdv {
  background-color: black !important;
}

.sidebar-fdv-settings {
  background-color: black !important;
}

.sidebar-fdv-settings.normal {
  background-color: white !important;
}

.query-line div {
  color: greenyellow;
}

.query-line div.isa {
  color: rgb(250, 149, 149);
}

.query-line>div:nth-child(2) {
  color: rgb(189, 189, 189)
}

.list-button,
.list-button>.text,
.list-button .header,
.list-button>.menu,
.list-button>.menu>.item {
  background-color: #2185d0 !important;
  background-color: var(--sem-blue) !important;
  color: white !important;
  border-color: grey !important;
}

.vert-container.sdc {
  background-color: rgb(224, 249, 255);
}

.abc.so {
  background-color: inherit !important;
}

.action-sidebar>.item.mi-norm {
  color: white !important;
}

.action-sidebar>.item.mi-data {
  color: inherit !important;
}

.field.hlpw label {
  color: green !important
}

.hgs {
  background-color: #db2828;
}

.hodd {
  background-color: inherit !important;
}

.ip-url {
  color: #4183c4;
  color: var(--url-color);
}

.json-container {
  background-color: #1b1c1d !important;
  background-color: var(--sem-black) !important;
  color: whitesmoke !important;
}

.json-container.in-app {
  background-color: rgb(241, 241, 241) !important;
  background-color: var(--json-viewer-light) !important;
}

/* .react-json-view {
  background-color: inherit !important;
} */

.create-event-header {
  background-color: rgb(216, 216, 216);
}

.menu-slider .icon.caret {
  color: rgb(199, 199, 199) !important
}

.menu-header-topper-simple,
.menu-header-topper {
  background-color: rgb(63, 63, 63) !important;
  background-color: var(--topper) !important;
  color: white;
}

.menu-header-alt {
  background-color: #2185d0;
  background-color: var(--sem-blue);
  color: white;
}

.vert-container.apv>.header {
  background-color: rgb(93, 93, 93) !important;
  background-color: var(--topper-sub) !important;
}

.vert-container.full-data-viewer>.header {
  background-color: rgb(123, 123, 123) !important;
  background-color: var(--topper-sub-2) !important;
  /* background-color: rgb(14, 47, 109) !important; */
}

.dark-mode .vert-container.app .item {
  color: rgb(255, 255, 255, .7) !important
}

.dark-mode .vert-container .item.sel {
  color: rgb(255, 255, 255) !important
}

.profile-select.access {
  color: black !important;
}

.profile-select.access .item {
  color: black !important;
}

.help-seg:not(.doc) mark,
.help-container mark {
  background-color: inherit;
  color: yellow;
}

.help-seg.doc mark {
  background-color: inherit;
  color: black;
}

.help-group-item-description,
.help-seg .description {
  border: 1px solid rgb(228, 228, 228) !important;
  margin-bottom: .5em;
}

.inh,
.inh>.item {
  background: 0 0 !important;
  background-color: inherit !important;
  color: inherit;
}

.vert-container.paddedFooter>.footer.sbf {
  background-color: rgb(71, 71, 71);
}

.vert-container.splitVert>.header {
  background-color: rgb(203, 203, 203) !important;
  color: black !important;
}

.vert-container.splitVert>.header>.item {
  color: black !important;
}

.wizard-wrapper .vert-container>.header:not(.imgs),
.dark-mode .modal .vert-container>.header:not(.imgs),
.wizard-wrapper .vert-container>.footer {
  background-color: rgb(36, 36, 36);
  color: white;
}

.wizard-wrapper .vert-container>.header.imgs {
  background-color: #f0f0f0;
  background-color: var(--light-bgc);
}

.dark-mode .modal .vert-container>.footer {
  background-color: rgb(36, 36, 36);
  color: white
}

.map-address-address {
  background-color: white;
}

.map-address-name {
  background-color: white;
}

.vert-container.pending {
  background-color: var(--main-bgc);
  color: white;
}

.form.simput input {
  border-bottom: 1px solid red !important;
  background-color: black !important;
}

/* .tester {
  background-color: purple !important;
} */

.top-settings-caption {
  color: grey;
}

.app-settings-grid>.column {
  background-color: rgba(0, 0, 0);
  background-color: var(--side-menu-bgc);
}

.app-settings-grid>.column.app {
  background-color: white;
}

.settings-app-container {
  background-color: rgb(215, 255, 196);
}

.settings-app-container.gds {
  background-color: rgb(241, 254, 231) !important;
  background-color: var(--app-google) !important;
}

.settings-app-container.gds.temp {
  background-color: rgb(231, 245, 254) !important;
  background-color: var(--app-google-temp) !important;
}

.settings-app-mobile-wrap {
  border-color: rgb(182, 182, 182) !important;
}

.gds .settings-app-mobile-wrap {
  border-color: rgb(185, 207, 170) !important;
  border-color: var(--app-google-wrap) !important;
}

.gds.temp .settings-app-mobile-wrap {
  border-color: rgb(180, 220, 245) !important;
  border-color: var(--app-google-wrap-temp) !important;
}


.live.view.settings-app-mobile-wrap {
  border-color: var(--live-view-bgc) !important;
}

.live.global.settings-app-mobile-wrap {
  border-color: var(--live-global-bgc) !important;
}

.settings-app-container.google.sheets {
  background-color: var(--google-sheets-bc) !important;
}

.settings-app-container.google.previewing {
  background-color: var(--google-preview-bc) !important;
}

.settings-app-container.google.data {
  background-color: var(--google-data-bc) !important;
}

.settings-app-container.google.sheets .settings-app-mobile-wrap {
  border-color: var(--google-sheets) !important;
}

.settings-app-container.google.previewing .settings-app-mobile-wrap {
  border-color: var(--google-preview) !important;
}

.settings-app-container.google.data .settings-app-mobile-wrap {
  border-color: var(--google-data) !important;
}

.sec-container.block>.sec-header {
  border: solid 1px lightgrey;
  border: var(--thin-border);
  background-color: #f0f0f0;
  background-color: var(--light-bgc);
}

.sec-container.blockAttached>.sec-header {
  border-bottom: solid 1px lightgrey;
  border-bottom: var(--thin-border);
  background-color: #f0f0f0;
  background-color: var(--light-bgc);
}

.sec-header>.divider {
  color: inherit !important;
  background-color: inherit !important;
}

.noContent .header {
  background-color: inherit !important;
  padding: 0 !important;
}

.cards-container>.card,
.card-container,
.card-content {
  border-radius: 0 !important;
}

/* inverted */
.form-container:not(.inverted) .divider:not(.text) {
  color: rgb(57, 43, 122) !important;
}

.form-container:not(.inverted) .divider.emph:not(.text) {
  background-color: rgb(199, 199, 199);
  color: rgb(57, 43, 122) !important;
}

.inverted .app-notification-container.active {
  background-color: rgb(68, 68, 68);
}

.inverted .app-notification-group>div:first-child,
.inverted .app-notification-date-group>div:first-child,
.inverted .note,
.inverted .note>.note-note,
.inverted .note>.note-description {
  border-color: rgb(75, 75, 75) !important;
}

.inverted .note>.note-description,
.inverted .note>.note-note {
  background-color: rgb(90, 90, 90) !important;
}

.app-light-mode .push-pushable.inverted .menu-slider>.item,
.app-light-mode .push-pushable.inverted .menu-slider-sidebar>.item,
.app-light-mode .segment.inverted .card:not(.preview),
.app-light-mode .segment.inverted .card:not(.preview)>.content>.header,
.app-light-mode .segment.inverted .card:not(.preview)>.content>.description,
.app-light-mode .segment.inverted .card:not(.preview)>.content>.meta {
  background-color: #1b1c1d;
  background-color: var(--sem-black);
  color: rgba(255, 255, 255, .9);
}

.app-light-mode .push-pushable.inverted .menu-slider-sidebar,
.app-light-mode .push-pushable.inverted .menu-slider {
  border-color: #1b1c1d !important;
  border-color: var(--sem-black) !important;
}

.app-container.inverted .sec-container.blockAttached>.sec-header {
  background-color: inherit !important;
}

.app-container.inverted .grid-icons-container .divider,
.app-container.inverted .form>.divider {
  color: rgb(153, 153, 153) !important
}

.app-container.inverted .vert-container .header {
  color: white
}

.ui.card,
.ui.cards>.card {
  box-shadow: 0 1px 3px 0 rgb(228, 228, 228), 0 0 0 1px rgb(228, 228, 228) !important;
  box-shadow: 0 1px 3px 0 var(--card-shadow), 0 0 0 1px var(--card-shadow) !important;
}

.card-group-favs .ui.card,
.card-group-favs .ui.cards>.card {
  box-shadow: 0 1px 3px 0 rgb(175, 185, 255), 0 0 0 1px rgb(175, 185, 255) !important;
  box-shadow: 0 1px 3px 0 var(--card-shadow-fav), 0 0 0 1px var(--card-shadow-fav) !important;
}

.mi-fav>.icon.star {
  float: left !important;
  margin-left: 0 !important;
  margin-right: .5em !important;
}

.app-container.inverted .ui.card,
.app-container.inverted .ui.cards>.card,
.app-container.inverted .sec-container.blockAttached>.sec-header {
  box-shadow: 0 1px 3px 0 rgb(82, 82, 82), 0 0 0 1px rgb(82, 82, 82);
}

.app-container.inverted .sec-container.blockAttached:not(.separate) {
  border: 1px solid rgb(82, 82, 82);
  box-shadow: 0 1px 3px 0 rgb(82, 82, 82), 0 0 0 1px rgb(82, 82, 82);
}

.app-container .sec-container.separate .segment {
  margin: 0;
  padding: 0 .5em;
}

.full-data-viewer .menu-alphabet>.item.active {
  background-color: #2185d0 !important;
  background-color: var(--sem-blue) !important;
}

/* Dark Mode */
.app-container.app-dark-mode {
  background-color: black !important;
  background-color: var(--me-background-bgc-dm) !important;
}

.app-dark-mode .dimmer {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
  border-color: rgb(36, 36, 36) !important;
  border-color: var(--off-blackish) !important;
}

.app-dark-mode .calendar-container>div.dow {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.nd {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.norm {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.norm.dk {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.non-sel {
  background-color: rgb(48, 48, 48) !important;
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .calendar-container>div.sel {
  background-color: rgb(64, 64, 64) !important;
  background-color: var(--off-blackish-sel) !important;
}

.app-dark-mode .cal-month {
  background-color: rgb(36, 36, 36) !important;
  background-color: var(--off-blackish) !important;
  border-color: rgb(36, 36, 36) !important;
  border-color: var(--off-blackish) !important;
}

.app-dark-mode .cal-month-cb,
.app-dark-mode .cal-month-ic1,
.app-dark-mode .cal-month-ic2,
.app-dark-mode .cal-month-icw {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
  border-color: rgb(48, 48, 48) !important;
  border-color: var(--off-blackish-bc) !important;
}


.app-dark-mode .vert-container:not(.noContent) .content {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
  border-color: rgb(24, 24, 24) !important;
  border-color: var(--off-black) !important;
}

.app-dark-mode .cal-months-container {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
  border: 1px solid grey;
}

.app-dark-mode .item-content {
  background-color: black;
}

.app-dark-mode .card {
  background-color: inherit;
  box-shadow: 0 1px 3px 0 black, 0 0 0 1px black !important;
}

.app-dark-mode .meta {
  color: rgb(177, 177, 177) !important;
  color: var(--me-card-c-dm-2) !important;
}

.app-dark-mode .item-container .card .sec-header {
  background-color: rgb(36, 36, 36);
  background-color: var(--me-card-bgc-dm);
  border: 0 !important;
  box-shadow: 0 1px 3px 0 black, 0 0 0 1px black !important;
  color: rgb(230, 230, 230);
  color: var(--me-card-c-dm);
}

.app-dark-mode .item-container .card .sec-container {
  background-color: rgb(36, 36, 36);
  background-color: var(--me-card-bgc-dm);
  border: 0 !important;
  box-shadow: 0 1px 3px 0 rgb(39, 39, 39), 0 0 0 1px rgb(39, 39, 39) !important;
  color: rgb(230, 230, 230);
  color: var(--me-card-c-dm);
}

.app-dark-mode .sec-container,
.app-dark-mode .item-container .cards-container>.card,
.app-dark-mode .item-container .card-container,
.app-dark-mode .item-container .card.card-schedule,
.app-dark-mode .item-container .card.card-profile {
  background-color: rgb(36, 36, 36) !important;
  background-color: var(--me-card-bgc-dm) !important;
  box-shadow: 0 1px 3px 0 rgb(39, 39, 39), 0 0 0 1px rgb(39, 39, 39) !important;
  color: rgb(230, 230, 230);
  color: var(--me-card-c-dm);
}

.card.card-schedule.completed {
  background-color: rgb(255, 237, 237) !important;
}

.app-dark-mode .label {
  background-color: rgb(36, 36, 36);
  background-color: var(--me-card-bgc-dm);
  /* color: var(--off-white) !important; */
}

.app-dark-mode .divider,
.app-dark-mode .prp-description,
.app-dark-mode .segment {
  color: rgb(196, 196, 196) !important;
  color: var(--off-white) !important;
}

.app-dark-mode .blockAttached {
  border: none !important;
  box-shadow: none !important;
}

.app-dark-mode .ms-button-group {
  background-color: black !important;
}

.app-dark-mode .sec-container.blockAttached>.sec-header {
  border-bottom: solid 1px rgb(42, 42, 42) !important;
  border-bottom: var(--thin-border-dm) !important;
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .prc {
  color: rgb(230, 230, 230);
  color: var(--me-card-c-dm);
}

.app-dark-mode .header:not(.nob) {
  /* background-color: var(--me-card-bgc-dm) !important; */
  background-color: inherit !important;
  color: rgb(230, 230, 230) !important;
  color: var(--me-card-c-dm) !important;
}

.app-dark-mode .push-container {
  background-color: black !important;
  background-color: var(--me-background-bgc-dm) !important;
  color: rgb(236, 236, 236) !important;
  color: var(--off-white-text) !important;
}


.app-dark-mode .schedule-dates {
  background-color: black !important;
  color: inherit !important;
}

.app-dark-mode .placeholder.segment {
  background-color: black !important;
  background-color: var(--me-background-bgc-dm) !important;
}

.app-dark-mode .ct-no-data {
  background-color: black !important;
  background-color: var(--me-background-bgc-dm) !important;
}

.app-dark-mode .ct-no-data .icon {
  color: #2185d0 !important;
  color: var(--sem-blue) !important;
}

.app-dark-mode .menu-slider {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
  color: white;
}

.app-dark-mode .menu-slider>.item,
.app-dark-mode .menu-slider>.item:hover {
  color: white !important;
}

.app-dark-mode .pending-container,
.app-dark-mode .pending-container .dimmer,
.app-dark-mode .pending-container .loader {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
  color: white !important;
}

.app-dark-mode [data-swipeable] {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .menu-slider-sidebar {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .menu-slider-sidebar>.item {
  color: white !important;
}

.app-dark-mode .dropdown,
.app-dark-mode .dropdown .menu {
  background-color: rgb(24, 24, 24) !important;
  background-color: var(--off-black) !important;
  color: white !important;
}

.app-dark-mode .dropdown>.label,
.app-dark-mode .dropdown .menu .item {
  color: white !important;
  border-color: rgb(48, 48, 48) !important;
  border-color: var(--off-blackish-bc) !important;
}

.setz .header {
  color: white;
}
.App {
  width: 100%;
  height: 100%;
  position: absolute;
}

#parent {
  width: 100%;
  height: 200px;
  /* Set the desired height for the parent div */
  overflow: auto;
}

.menu {
  white-space: nowrap;
}

.menu-item {
  display: inline-block;
  padding: 10px;
  border: 1px solid black;
}

.example-container div {
  background: white;
  border-radius: 10px;
  /* width: 150px;
  height: 150px; */
}

.app-wrapper-container {
  width: 100%;
  height: 100%;
}

.motion-container.pageItem,
.motion-container.splash {
  width: 100%;
  height: 100%;
}

/* .motion-container.pageItem>div  {
  width: 100%;
  height: 100%;
} */

.motion-container.element {
  width: 100%;
}

.stack-error {
  font-size: .8rem;
  height: 100%;
  overflow-y: auto;
  padding: .5em;
}

.hint {
  padding-top: .5em;
  padding-left: 1em;
  font-size: .8rem;
  color: grey;
}

.app-homepage {
  position: absolute;
  bottom: 0;
  display: grid;
  width: 100%;
  grid-template-rows: auto auto 1fr;
  padding: 1em;
}

.app-homepage>.buttons {
  display: grid;
  grid-template-columns: 10% 80% 10%;
}

.app-homepage.nh>.buttons {
  grid-template-columns: auto 1fr;
}

.app-homepage.nh>.buttons>.button {
  padding: 1em 1.5em !important;
}

.app-homepage>div {
  padding: .75em;
}

.app-homepage>div:nth-child(1)>.image {
  margin: auto;
  /* color: #d1d1ff;
  background-color: #ffadad; */
}

.dd-container {
  display: grid;
  grid-template-columns: auto 1fr;
}

/* .app-homepage:not(.zz)>div:nth-child(3) {
  text-align: right;
} */

.app-homepage.fp {
  position: relative;
}

.app-homepage div>p {
  line-height: 1 !important;
}

.app-full {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
}

/* CONTAINERS */

.buttons-container {
  text-align: center;
  padding: .5em;
}

.icn-grp,
.icn-ngrp {
  display: grid;
  grid-template-columns: 5% 95%;
  grid-gap: .5em;
  gap: .5em;
}

/* ************************************************ */

/* APP CONTAINER */
.app-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto auto auto;
  grid-template-areas:
    "app-menu"
    "app-banner"
    "app-content"
    "app-banners"
    "app-navigation"
    "app-homepage"
}

.app-container.segment {
  padding: 0;
}

.app-container.blt {
  grid-template-areas:
    "app-menu"
    "app-banner"
    "app-banners"
    "app-content"
    "app-navigation"
    "app-homepage"
}

.dt-mode .app-container {
  scrollbar-width: none;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr 8fr 1fr;
  grid-template-areas:
    "app-sticky app-sticky app-sticky"
    ". app-content ."
    "app-footer app-footer app-footer"
}

.dt-mode .app-container::-webkit-scrollbar {
  display: none;
}

.dt-mode .app-container {
  height: auto;
  overflow-y: auto;
  scrollbar-width: none;
  min-height: 100vh !important;
}

.dt-mode .app-container .app-header {
  width: 100%;
  z-index: 100;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "app-menu"
    "app-navigation"
}

.dt-mode .app-container>.app-header>.app-menu {
  width: 100% !important;
}

.dt-mode .app-container>.app-header>.app-navigation {
  width: 100% !important;
}

/* ************************************************ */

/* APP CONTENT */
.app-content {
  grid-area: app-content;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: none;
}

.app-content.ui-item {
  overflow-x: hidden;
}

.app-content.padd {
  padding: 8px;
  padding-top: 16px;
}

.app-content.padd .big-icon {
  text-align: center;
}

.app-content .image {
  margin: 0px 8px 8px 0
}

.app-content .normal .image {
  margin: 0
}

.app-content .header {
  vertical-align: top !important;
}

.app-content .group-swiper,
.app-content .group-swiper>div {
  overflow-y: hidden !important;
}

.app-content.cbn {
  overflow-y: auto;
}

.app-content>.list {
  margin: 1em !important
}

.app-banner {
  grid-area: app-banner;
}

.app-banners {
  grid-area: app-banners;
}

.app-header {
  grid-area: app-header;
}

.app-menu {
  grid-area: app-menu;
}

.app-navigation {
  grid-area: app-navigation;
}

.app-footer {
  grid-area: app-footer;
}

.app-sticky {
  grid-area: app-sticky;
}

.app-homepage {
  grid-area: app-homepage;
  /* display: none; */
}

.app-homepage.fp {
  display: block;
  height: 100%;
}

/* APP SWIPE CONTAINER */
.app-swipe-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "app-swipe-menu"
    "app-swipe-content"
}

.app-swipe-menu {
  grid-area: app-swipe-menu;
}

.app-swipe-content {
  grid-area: app-swipe-content;
}

.app-swipe-content {
  overflow-y: auto;
}

.ms-button-group {
  width: 100% !important;
  overflow-x: auto;
}

.app-swipe-menu>.menu {
  padding: 0 !important;
  overflow-x: auto;
  scrollbar-width: none;
  border-bottom: 0px !important;
  padding-bottom: 2px !important;
  width: 100% !important;
}

.menu-alphabet:not(.vertical) {
  width: 100%;
  overflow-y: auto;
}

.app-swipe-menu>.menu>.item {
  margin-bottom: 2px !important
}

.app-swipe-menu>.menu::-webkit-scrollbar {
  display: none;
}

/* .app-swipe-menu > .menu{scrollbar-width: thin} */
.dt .app-swipe-menu>.menu {
  scrollbar-width: thin
}

.mob .app-swipe-menu>.menu {
  scrollbar-width: none;
}

.settings-app-container .app-swipe-menu>.menu::-webkit-scrollbar {
  display: inline;
}

.swipe-tab {
  font-size: .9rem;
}

.swipe-tab.item,
.swipe-tab.item.active {
  padding: 1em 1.25em .75em 1.25em !important
}

/* END - APP SWIPE CONTAINER */

/* ************************************************ */

/* ALL HEADERS */
.all-headers-menu {
  display: grid !important;
  grid-template-columns: 1fr 10fr 1fr !important;
}

.all-headers-menu>.item:first-child,
.all-headers-menu>.item:last-child {
  padding-right: 0 !important;
}

.all-headers-menu {
  border-radius: 0 !important;
}

.all-headers-menu>.item>.search {
  padding: .5em !important;
}

.all-headers-menu>.item,
.all-headers-menu>.menu>.item {
  padding-top: .5em !important;
  padding-bottom: .75em !important;
  border-radius: 0;
  border: 0 !important
}

.all-headers-menu {
  margin: 0 !important;
  padding: .25em 0;
  border-bottom: var(--thin-border)
}


/* ************************************************ */

/* COLORS CONTAINER */
.colorz-container {
  width: 100%;
  display: grid;
  margin: 0 0 1em 0px;
  border-radius: 4px;
  grid-template-columns: 20% 40% 40%;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    "colorz-apply"
    "colorz-bc"
    "colorz-c";
}

.colorz-container.colorSelectSingle {
  grid-template-columns: 20% 80%;
}

.colorz-container div {
  margin: 0 .1em;
  justify-self: stretch
}

.colorz-container .colorz-apply:not(.applied) {
  opacity: .3;
}

.msmi>.item>.header>.icon.clr:not(.applied) {
  opacity: .3;
}

/* END - COLORS CONTAINER */

/* ************************************************ */

/* CONTENT CONTAINER */
.content-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "content-header"
    "content-content";
}

.content-container {
  height: 100%;
  overflow-y: auto !important;
}

.content-content {
  grid-area: content-content;
  scrollbar-width: none;
  height: 100%;
}

.content-content>.cards.solo {
  margin: 0 !important;
}

.content-content>.seg-item-list>.item {
  padding: .5em 0em !important
}

.content-content.cardNameSectionOnly,
.content-content.cardTaggedOnly {
  padding: .35em
}

.content-content.static .card {
  border-left: black solid 1px !important;
}

.content-content.weather {
  margin: 0 !important;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
}

.dt-off .content-content .card {
  margin-bottom: .5em !important;
}

.menu>.content-container>.content-content {
  height: auto !important;
  overflow-y: hidden !important;
  padding: .5em;
}

.content-content.cardNameSectionOnly .cards-container>.motion-container.element>.card:not(:static),
.content-content.cardNameSectionOnly .cards-container>.card:not(:static) {
  box-shadow: none !important;
  margin-bottom: .25em !important;
  border-radius: 0 !important;
  border: none !important;
}

/* ************************************************ */

/* CONVERSATIONS */

.conversation-line {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas:
    "conversation-icon conversation-from conversation-date"
    "conversation-icon conversation-message conversation-date";
  border-bottom: var(--thin-border);
  padding: .5em 0;
}

.conversation-icon {
  grid-area: conversation-icon;
}

.conversation-from {
  grid-area: conversation-from;
  font-weight: bold;
}

.conversation-message {
  grid-area: conversation-message;
  padding: .5em .5em
}

.conversation-date {
  grid-area: conversation-date;
  font-weight: lighter;
  font-size: 9pt;
  text-align: right;
}

.conversation-from>.label {
  margin-bottom: .25em;
}

/* ************************************************ */


/* DELETE CONTAINER */
.delete-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "delete-button"
    "delete-header"
    "delete-content";
}

.delete-button {
  grid-area: delete-button;
  border-bottom: var(--thin-border);
}

.delete-header {
  grid-area: delete-header;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.delete-content {
  grid-area: delete-content;
  overflow-y: auto;
  scrollbar-width: none;
}

.delete-header>.label {
  margin-top: .5em !important
}

/* END - DELETE CONTAINER */

/* ************************************************ */

/* DELETE SUB CONTAINER */
.delete-sub-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "delete-sub-header"
    "delete-sub-content";
}

.delete-sub-header {
  grid-area: delete-sub-header;
}

.delete-sub-content {
  grid-area: delete-sub-content;
  padding: 1em;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.delete-sub-header>.label {
  margin-top: .5em !important
}

.delete-sub-content {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.delete-sub-content>.checkbox {
  padding-bottom: .5em;
}

/* END DELETE SUB CONTAINER */

/* ************************************************ */

/* DROP GROUP CONTAINER */
.drop-group-container {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: .25em;
  /* padding: .1em; */
  /* border: var(--sort-borders);   */
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "drop-group-header"
    "drop-group-content";
}

.drop-group-container.settingsGroups .item>.header>div {
  display: flex;
  flex-direction: column;
}

.drop-group-container.settingsGroups .item>.header>div>div:nth-child(1) {
  font-size: .7rem;
  -webkit-columns: blue !important;
          columns: blue !important;
}

.drop-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1.5em;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
}

.drop-group-content {
  min-height: 46px;
  margin: 0;
  display: block;
}

.drop-group-content.nd {
  border: none;
}

.drop-group-header>.dropdown {
  float: right;
}

.drop-group-header>.dropdown,
.drop-group-header>.dropdown>.menu>.item {
  font-size: .8rem !important;
}

.drop-group-container.fixed {
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
}

.drop-list .label {
  width: 100%;
  margin-bottom: .5em;
  padding: .75em;
}

.drop-list,
.drop-list.active {
  padding: 0;
  height: auto;
  min-height: 46px;
}

.ddp .label,
.drop-list .label {
  width: 100% !important;
  padding: 1em !important;
  margin-left: 0 !important;
  /* background-color: blue !important; */
}

.drop-list>div>label {
  display: flex;
}

.ddp.label>div>div {
  padding: .2em !important;
}

.lbl-additional {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.drag-number {
  margin-bottom: .25em !important;
}

.drag-number>.label {
  margin: 0 .25em 0 0 !important;
}

.drag-number>.label {
  width: 100% !important;
  padding: .5em !important;
}

.drag-number>.label>.label {
  width: 24px !important;
  padding: .5em !important;
  margin: 0 1em 0 0 !important;
  text-align: center;
}

.drag-number>.label>.label:nth-child(2) {
  float: right;
}

.drag-number>.label>.label:nth-child(3) {
  float: right;
  width: auto !important;
}

/* dragDropper */
.dd-menu {
  margin: .5em !important;
}

.dd-menu-menu>.item,
.dd-menu>.item {
  display: grid !important;
  padding: .5em !important;
  align-items: center !important;
  grid-template-columns: auto 1fr;
  grid-gap: .5em;
  gap: .5em;
  border-radius: 4px;
}

.dd-menu>.item.ls {
  grid-template-columns: auto 1fr auto !important;
}

.dd-menu>.item>.label {
  padding: .5em !important;
}

.dd-menu-menu>.item {
  margin: .5em !important
}

.ddp .label {
  width: 100% !important;
  border-radius: 4px !important;
  padding: .5em !important;
}

.ddp.label>div,
.ddp.simple>div,
.ddp.viewSettingsMenu>div {
  border-radius: 4px;
  font-weight: bold;
  margin: 0 !important;
  padding: 0 !important;
}

/* THIS IS THE div that is dragged */
.ddp {
  font-weight: bold;
}

.drop-header {
  padding: .5em;
  margin-bottom: 0em;
  font-weight: bold;
}

/* DROP CONTENT */
.drop-content {
  min-height: 46px;
  padding: 0 .25em;
  padding: .25em
}

.drop-content .label {
  width: 100% !important;
  margin-bottom: .5em !important;
  padding: .5em !important
}

.drpi {
  border-bottom: 3px solid red;
}

/* END - DROP GROUP CONTAINER */

/* FILTER */
.filter-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "filter-header"
    "filter-selected"
    "filter-content"
}

.menu-filter .dropdown>.menu {
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-selected {
  padding: .5em;
  border-bottom: var(--thin-border);
}

.filter-content {
  grid-area: filter-content;
  padding: .5em;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-selected {
  grid-area: filter-selected;
  padding: .5em;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-header {
  padding: .5em;
  border-top: var(--thin-border);
  display: flex;
  flex-direction: column;
  grid-gap: .25em;
  gap: .25em;
}

/* ************************************************ */

/* FILTER ITEMS */
.filter-group-container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "filter-group-header"
    "filter-group-footer"
}

.filter-group-header {
  padding: .5em;
  font-weight: bold;
  border-bottom: var(--thin-border)
}

.filter-group-content {
  padding: .5em
}

.filter-group-content>.label {
  margin: .2em !important
}

/* ************************************************ */

/* FORM CONTAINER */
.form-container {
  /* display: grid; */
  height: 100%;
  grid-template-rows: 1fr;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 1em;
}

.form-container.simp input {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid lightgray !important;
}

.form-container.simp .input,
.form-container.simp .dropdown {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.form-container.simp .dropdown {
  border-bottom: 1px solid lightgray !important;
}

.form-container.simp .dropdown.icon {
  border: none !important;
}

[formkey='dataSource'] {
  grid-template-rows: 5fr 1fr !important;
}

.form-buttons {
  padding: 1em 0 !important
}

.form-container.dm .form:not(.mlc) {
  padding-bottom: 4em !important;
}

.form-container.dm>.form {
  margin: .5em;
  padding-bottom: 4em !important;
}

.form-container.dm {
  padding: 0;
}

.form-container.segment {
  padding: 0 !important;
}

.form-container.sf .form {
  padding-bottom: 4em !important;
}

.form-container.sf>.form {
  margin: .5em;
  padding-bottom: 4em !important;
}

.form-container.sf {
  padding: .5em !important;
}

.form-container:not(.inverted) .divider:not(.text) {
  margin: 0 0 1em 0 !important;
  padding: .5em;
}

.form-container .divider>.icon {
  margin-right: .5em !important;
}

.form-container:not(.inverted) .divider.emph:not(.text) {
  margin: 0 0 1em 0 !important;
  padding: .5em;
}

.form-container .divider.alt {
  text-transform: lowercase;
}

/* END - FORM CONTAINER */

/* ************************************************ */

/* GOOGLE DATA CONTAINER */
.google-data-container {
  display: grid;
  width: 100%;
  height: 100%;
  /* grid-template-rows: auto 1fr;  */
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.google-data-container>.gst {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.google-data-container>.segment,
.google-data-container>.gst,
.google-data-container>.gst>.menu,
.google-data-container>.gst>.tab,
.google-data-container>.gst>.tab>.table-wrapper,
.google-data-container>.gst>.tab>.table-wrapper>.table {
  width: 100% !important;
}

.gst>.segment.tab {
  display: contents !important;
}

.google-data-container>.gst>.tab>.table-wrapper {
  height: 100%;
}

.google-data-container>.gst>.tab>.table-wrapper>.table {
  position: absolute;
}

.google-data-container>.gst>.menu {
  overflow-y: auto;
  background-color: black;
}

.google-data-container>.gst>.menu>.item {
  color: rgb(179, 179, 179) !important;
}

.google-data-container>.gst>.menu>.item.active {
  background-color: var(--sem-blue);
  color: white !important;
}

/* dddd */
.gst {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.gst.bot {
  grid-template-rows: 1fr auto;
}

.gst,
.gst>.grid,
.gst>.grid>.column,
.gst>.menu,
.gst>.tab,
.gst>.tab>.table-wrapper,
.gst>.tab>.table-wrapper>.table {
  width: 100% !important;
}

.gst>.tab,
.gst>.grid,
.gst>.grid>.column,
.gst>.tab .pushable,
.gst>.tab .pusher,
.gst>.tab>.table-wrapper {
  height: 100%;
}

.gst>.tab>.table-wrapper>.table {
  position: absolute;
}

.gst>.menu {
  overflow-y: auto;
  border-radius: 0;
}

.gst.vit>.menu {
  margin-bottom: 0 !important;
  padding: .25em;
}

.gst.vit>.menu>.item.active {
  background-color: var(--sem-blue) !important;
}

.gst.vit>.tab {
  padding: 0;
  margin-top: 0 !important;
  border: 0;
}

.gst>.menu>.item,
.gst>.menu>.item.active {
  font-weight: bold;
}

.google-data-container .table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
}

.table-wrapper>.table th:first-child,
.table-wrapper>.table td:first-child {
  width: 50px !important
}


/* ************************************************ */

/* GRID CHECK CONTAINER */

.grid-check-container {
  padding: 0 !important
}

.grid-check-container .label:nth-child(2) {
  border-left-width: 8px !important;
  border-right-width: 8px !important;
  font-weight: 700;
}

.grid-check-container .grid-check-header {
  font-weight: 700;
}

.grid-check-container .label:not(.applied) {
  opacity: .5;
}

.grid-check-container.adding .segment {
  margin-top: .5em !important;
  margin-bottom: .5em !important;
}

/* END - GRID CHECK CONTAINER */

/* ************************************************ */

/* GRID ICONS */
.grid-icons-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-x: hidden;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  grid-template-areas:
    "grid-icons-content"
}

.grid-icons-container {
  scrollbar-width: none;
  padding: .5em;
}

.grid-icons-content .column {
  align-self: stretch;
  padding: .5em !important;
}

.grid-icons-content .row {
  padding: 0 !important;
}

.grid-icons-content .segment .icon {
  text-align: center;
  margin-top: 10px
}

.grid-icons-content .segment div {
  text-align: center;
  font-size: 1rem;
  margin-top: 10px
}

.grid-icons-content {
  padding: .5em;
}

.grid-icons-content {
  padding: .5em;
  align-items: flex-start !important
}

.grid-icons-content .segment,
.grid-settings-icon {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 8px !important;
  border-style: solid !important;
}

.grid-icons-content>.divider.horizontal {
  padding: .5em
}

/* END - GRID ICONS */

/* ************************************************ */

/* GRID LIST CONTAINER */

.grid-list-container {
  height: 100%;
}

.grid-list-container>.list {
  padding: .25em .5em !important;
}

.grid-list-container>.list>.item {
  padding: .75em 0 !important;
}

.map-list-list>.menu>.item,
.grid-list-container>.menu>.item {
  padding: 1em .5em !important;
}

.grid-list-container>.menu>.item>.image:first-child {
  margin-right: .5em !important;
}

.grid-list-container>.menu>.item>.icon:first-child {
  float: left !important;
  margin-right: .5em !important;
}

.grid-list-container>.menu>.item>.icon,
.grid-list-container>.menu>.item>.image {
  display: inline-block;
}

.url-list-container {
  height: 100%;
}

.url-list-container>.list>.item {
  padding: .75em 1em !important;
}

/* ************************************************ */

/* HCF */
.hcf-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "hcf-header"
    "hcf-content"
    "hcf-footer";
}

.hcf-header {
  grid-area: hcf-header;
  border-bottom: var(--thin-border);
  font-weight: bold;
}

.hcf-content {
  grid-area: hcf-content;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
}

.hcf-header.padd,
.hcf-content.padd {
  padding: .5em;
}

.hcf-content.vpl div {
  display: contents;
}

.hcf-content.ctr>.segment {
  height: 100%;
}

.hcf-footer {
  grid-area: hcf-footer;
  border-top: var(--thin-border);
}

.hcf-footer:not(.settings-menu-footer) {
  padding: .5em;
}

.hcf-footer.modify>.button:nth-child(1) {
  float: left !important;
}

.hcf-footer.modify>.button:nth-child(2) {
  float: right !important;
}

.hcf-content form,
.hcf-content.cfm {
  margin: 1em;
  padding-bottom: 1em;
}

.hcf-container.vid>.hcf-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hcf-container.vid>.hcf-header,
.hcf-container.vid>.hcf-footer {
  z-index: 1000;
}

.hcf-header.mod {
  padding: 1em
}

.hcf-header.mod>.ui.dropdown {
  float: right;
  width: 80px;
  min-width: 5em;
  font-size: .8rem;
}

.hcf-footer.mod {
  padding: 1em
}

/* ************************************************ */

/* HCF */
.hcs-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "hcs-top"
    "hcs-bottom";
}

.hcs-top,
.hcs-bottom {
  padding: .5em !important
}

/* ************************************************ */

/* HCF */
.hcaf-container {
  height: 100%;
  width: 100%;
  display: grid;
  border-radius: 10px;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "hcaf-header"
    "hcaf-alert"
    "hcaf-content"
    "hcaf-footer";
}

.hcaf-header {
  padding: 1em 1em;
  border-radius: 10px;
}

.hcaf-header .icon:nth-child(1) {
  margin-right: 10px !important;
}

.hcaf-content {
  grid-area: hcaf-content;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
}

.hcaf-alert {
  grid-area: hcaf-alert;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.hcaf-footer {
  grid-area: hcaf-footer;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.hcaf-content form,
.hcaf-content.cc {
  margin: 1em;
  padding-bottom: 1em;
}

.hcaf-content.padd {
  padding: .5em;
}

/* ************************************************ */

/* HELP CONTAINER*/
.help-container {
  height: auto !important;
  padding: 0 !important;
  margin-top: .5em;
}

.help-container>.message {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.help-container .label.attached {
  margin: 0 !important;
  padding: .75em !important
}

.help-container .label.attached .icon {
  margin: 0 !important;
  padding: 0 !important
}

.help-container .list>.item .header {
  padding: .5em;
}

.help-container .list>.item .description {
  padding: 1em
}

.help-header {
  padding: .5em;
  font-weight: bold;
}

.help-content {
  padding: 1em;
}

.help-group-header {
  font-weight: bold;
  font-size: 1.1rem;
}

.help-seg .help-group-item-header {
  font-weight: bold
}

.help-seg:not(.doc) .help-group-item-description {
  font-weight: bold;
}

/* extra */
.help-seg {
  height: 100%;
  padding: .5em !important;
  border: 1px solid rgb(68, 68, 68) !important
}

.help-seg .header {
  margin-bottom: .25em !important;
}

.help-seg>.message {
  margin-bottom: 2em !important;
  padding: .75em !important;
  border-color: grey !important;
  box-shadow: none !important;
  ;
}

.help-seg>.message>.content {
  padding: .75em !important
}

.help-edit-seg {
  margin-top: .5em !important;
}

.help-accordion>.title {
  padding: .5em !important
}

.help-accordion>.title>.icon {
  float: right !important;
}

.help-edit textarea {
  width: 100%;
  padding: 1em
}

.help-list .label {
  margin: .5em !important
}

.help-edit label {
  padding-bottom: 1em !important
}

.help-list {
  padding: .5em
}

.help-edit-edit>div:first-child {
  margin-bottom: 1em;
}

F .help-edit-edit {
  padding: 1em !important;
  margin-top: .5em
}

.help-seg>.help-accordion>.content.active>.message {
  padding: 1em !important
}

/* END - HELP CONTAINER*/

/* ************************************************ */

/* .swipeable-group-container {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0 0 1em 0;
} */

.swipeable-group-container>div:nth-child(1) {
  padding: .5em;
  background-color: rgb(60, 60, 60);
  color: white
}

.swipeable-group-container .card {
  margin-top: .2em !important;
  margin-bottom: .5em !important;
}

/* HORIZONTAL */
.horizontal-container {
  overflow-y: auto;
  height: 100%;
  scrollbar-width: thin;
}

.app-horz-item {
  padding: .5em .2em;
}

.app-horz-item:not(:last-child) {
  border-bottom: solid 4px darkgray;
}

.item-horz {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  overflow-x: auto;
  scrollbar-width: thin;
  overflow-x: auto !important;
}

.item-horz>.card {
  min-width: 280px;
}


.horizontal-container .content-content {
  overflow-x: hidden;
}

.item-horz {
  margin: .2em;
  scrollbar-width: thin !important;
}

.horizontal-container .content-content .item-horz::-webkit-scrollbar {
  width: 1px !important;
}

.sb-content::-webkit-scrollbar,
.no-sb::-webkit-scrollbar {
  display: none;
}

/* ************************************************ */

/* IMAGES CONTAINER */
.images-container {
  /* height: 100%;  */
  width: 100%;
  display: grid;
  grid-template-areas:
    "images-header"
    "images-content";
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  margin-bottom: 1em;
}

.images-header {
  grid-area: images-header;
  padding: 1em;
  border-bottom: var(--thin-border);
  font-weight: bold;
}

.images-content {
  grid-area: images-content;
  padding: 10px;
  min-height: 80px;
}

.images-content>.sw {
  padding: 18px;
}

.img-80 {
  max-width: 80px !important
}

.img-100 {
  max-width: 100px !important
}

.img-140 {
  max-width: 140px !important
}

.img-200 {
  max-width: 200px !important
}

.images-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.images-content:not(.viewManifest)>.images-picker {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.images-content:not(.viewManifest)>.images-picker .span-image {
  max-width: 100px;
  margin: .25em
}

.images-content.viewManifest>.images-picker .span-image {
  margin: .25em
}

.images-content>.images-picker .image-manifest>.image {
  max-width: none
}

.file-selected>img,
.file-unselected>img {
  margin: 0 auto !important;
}

.file-selected {
  border: 2px solid rgba(53, 241, 7, 0.924);
}

.file-unselected {
  border: 2px solid #cfcfcf;
}

.images-manifest .span-image {
  max-width: 100px;
  margin: .25em
}

.images-manifest .image-manifest>.image {
  max-width: none
}

/* END - IMAGES CONTAINER */

/* ************************************************ */

/* INLINE CONTAINER */
.inline-container {
  width: 100%;
}

/* flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
 }
 .inline-container > .content > .checker,
 .inline-container > .content > .icon {
  width: 40%;  
} */

.inline-container .content>.checkbox>input {
  height: 1rem !important;
}

.inline-container .content>.checkbox {
  float: right;
  font-size: small;
  margin-left: 1em
}

.inline-container .content>.checker {
  float: right;
  font-size: small;
  margin-left: 1em;
  margin-left: 2em;
}

.inline-container .content:nth-child(1) {
  padding: 1em !important
}

.inline-container .content.extra {
  padding-right: 0 !important;
}

.inline-container .content.extra {
  padding: 1em !important
}

.inline-container .content.extra.checkers {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.inline-container .header {
  margin-bottom: .5em;
  font-size: small;
}

.inline-container {
  margin-bottom: .5em;
  font-size: small;
}

.header.in-line>div>div:first-child {
  float: left;
}

.header.in-line>div>div:last-child {
  float: right;
}

.inline-item-container {
  width: 100%;
  font-size: .9rem;
}

/* END - INLINE CONTAINER */

/* ************************************************ */

/* ITEM UI CONTAINER */
.item-container {
  height: 100%;
  width: 100%;
  /* overflow-x: hidden;  */
  scrollbar-width: none;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "item-header"
    "item-content"
}

.item-container.ac {
  grid-template-rows: auto 1fr;
}

.item-container .group-swiper>div {
  overflow-y: hidden !important;
}

.item-container .group-swiper,
.item-container .header {
  vertical-align: top !important;
}

.item-container .image {
  margin: 0px 8px 8px 0
}

.item-container.cbn {
  height: auto !important;
  overflow-y: auto;
}

.item-container.fh {
  height: 100%;
}

.item-container.fic .content-content .card {
  height: 100% !important;
}

.item-container.padd {
  padding: 8px;
  padding-top: 16px
}

.item-container.fic .content-content .card {
  height: 100% !important;
}

.item-content .gml-container,
.item-content .gml-container>div {
  height: 100% !important;
}

.item-container.solo>.item-content {
  display: grid;
  grid-template-rows: auto 1fr;
}

.item-container.solo>.item-content>.solo {
  height: 100%;
  overflow-y: auto;
  padding: 0 !important;
}

.item-container.giftCards>.item-content {
  padding: .25em
}

.single-page-container>.item-container {
  border-radius: 3px;
  padding: .5em;
  margin-bottom: 1em;
}


/* LABEL CONTAINER */
.label-container,
.label-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}

.landing-container.srt {
  grid-template-areas:
    "label-header"
    "label-save"
    "label-content";
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
}

.label-container>.ui.label {
  padding: .5em .5em !important;
}

.label-container>.label-header {
  padding: 1em !important;
  border-bottom: var(--thin-borders);
  font-size: .7em;
}

.label-container>.label-save {
  padding: .5em !important;
  border-bottom: var(--thin-borders);
}

.label-container>.label-header>.check-toggle {
  float: right;
  margin-right: 1em;
}

.hcf-header>.check-toggle,
.hc-header>.check-toggle {
  margin-bottom: 1em !important;
}

.label-content {
  padding: .5em;
  overflow-y: auto;
  max-height: 300px;
  scrollbar-width: thin;
}

/* ************************************************ */

/* MAP */

.map-container {
  height: 100%;
  width: 100% !important;
  display: grid;
  padding: 0 !important;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "map-map"
}

.map-map {
  grid-area: map-map;
  position: relative;
  height: 30vh;
  width: 100%;
}

.map-container.split {
  grid-template-rows: 6fr 3fr 1fr;
  grid-template-areas:
    "map-map"
    "map-list-list"
}

/* MAP-CONTAINER */
.map-list-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  grid-template-columns: 100%;
  grid-template-rows: 2fr 2fr;
  grid-template-areas:
    "map-list-map"
    "map-list-list";
}

.map-list-container.gm {
  position: absolute;
}

.map-list-map {
  grid-area: map-list-map;
  position: relative;
  display: block;
}

.map-list-map .gm-style {
  font-size: 8pt !important
}

.map-list-list {
  grid-area: map-list-list;
  scrollbar-width: thin;
  overflow-y: auto !important;
  padding: 0;
}

.map-list-list .item>.icon {
  width: 2em !important;
  display: inline-block;
  position: relative;
}

/* ************************************************ */

/* MAP LIST  CONTAINER */
.content-content.mapList {
  padding: 0;
  margin: 0 !important;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
}

.content-content.mapList>div {
  height: 100%;
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas:
    "map-list-container-a"
}

.map-list-container-a {
  grid-area: map-list-container-a;
}

/* ************************************************ */

/* MESSAGES */

.messages-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "messages-content"
    "messages-footer";
}

.messages-content {
  grid-area: messages-content;
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: 3em !important;
}

.message-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
  padding: 1em .5em;
}

.message-line {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas: "message-icon message-message message-date";
  margin-top: 1em;
}

.message-line.au {
  grid-template-areas: "message-date message-message message-icon";
}

.message-icon {
  grid-area: message-icon;
}

.message-from {
  grid-area: message-from;
  font-weight: bold;
}

.message-message {
  grid-area: message-message;
  border: var(--thin-border);
  padding: 1em .5em;
  border-radius: 8px;
}

.message-date {
  grid-area: message-date;
  font-weight: lighter;
  font-size: 9pt;
}

.message-from>.label {
  margin-bottom: .25em;
}

.message-line.au>.message-date,
.message-line>.message-message,
.message-line>.message-icon {
  text-align: left;
}

.message-line>.message-date,
.message-line.au>.message-message,
.message-line.au>.message-icon {
  text-align: right;
}

.message-line.au>.message-message {
  margin-left: 2em;
  margin-right: 0
}

.message-line>.message-message {
  margin-left: 0;
  margin-right: 2em
}

/* ************************************************ */


/* MENU ROW CONTAINER */
.menu-row-container {
  display: flex;
  grid-gap: 1em !important;
  gap: 1em !important;
  margin: 1em 0;
  padding-right: 0 !important;
  width: 100%;
  align-items: center;
}

.menu-row-container .menu-row-container.button {
  max-width: 300px !important;
}

.menu-row-container .button {
  margin-top: 2em;
}

/* END - MENU ROW CONTAINER */

/* ************************************************ */

.menu-select>.item {
  padding: .25em !important;
}

.menu-select .segment {
  padding: .25em
}

.menu-select.quantity>.item {
  padding: .5em !important;
}

/* MENU SELECT CONTAINER */
.menu-select-container {
  width: 100%;
  display: grid;
  padding-top: .5em;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "menu-select-header"
    "menu-select-toggles"
    "menu-select-items"
  ;
}

.menu-select-header {
  padding: 0 0 .5em;
  font-weight: 700;
}

.menu-select-header>.label {
  margin-left: 1em !important;
}

.menu-select-header>.checker {
  float: right;
}

.menu-select-toggles {
  padding: .5em;
  margin-bottom: .5em;
  border-bottom: var(--thin-border)
}

.menu-select-toggles .checkbox:nth-child(2) {
  float: right;
  margin-right: 10px
}

.menu-select-toggles .checkbox>label {
  font-size: small !important;
}

.menu-select-items {
  max-height: 300px;
  overflow-y: auto !important;
  scrollbar-width: thin;
}

.menu-select-items .item {
  font-size: .7rem;
  padding: .75em 1em !important
}

.menu-select-container.adding {
  margin-bottom: 1em;
}

/* END - MENU SELECT CONTAINER */

/* ************************************************ */

/* MODIFY CONTAINER */
.modify-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "modify-header"
    "modify-sub-header"
    "modify-content"
    "modify-footer";
}

.modify-container.sw {
  grid-template-rows: 1fr;
  grid-template-areas:
    "modify-content"
}

.modify-content {
  grid-area: modify-content;
  overflow-y: auto;
  overflow-x: hidden;
  padding: .5em 1em;
  scrollbar-width: thin;
}

.modify-content>.form {
  margin-bottom: 1em;
}

.modify-content.dataTable {
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  padding: .5em .5em;
}

.modify-content.direct {
  padding: .25em;
}

.modify-footer {
  grid-area: modify-footer;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.modify-header .checkbox {
  margin-left: 1em;
}

.modify-header .icon:nth-child(1) {
  margin-right: 1em;
}

.modify-header>.grid>.column:nth-child(1) {
  font-weight: bold;
  display: inline-flex !important;
}

.modify-header {
  grid-area: modify-header;
  padding: 1em
}

.modify-sub-header {
  grid-area: modify-sub-header;
  border-bottom: var(--thin-border);
  padding: 1em 1em;
  text-align: right;
}

/* END - MODIFY CONTAINER */

/* ************************************************ */

/* PAGE WRAPPER */
.page-wrapper-container {
  height: 100vh;
  width: 100%;
}

.page-wrapper {
  height: 100vh;
  width: 40%;
  margin: auto;
}

.page-wrapper.mobile {
  width: 100%;
}

.page-wrapper>div:nth-child(1),
.page-wrapper>div:nth-child(3) {
  display: none;
}

.page-wrapper>div {
  height: 100vh;
}

.page-wrapper.print {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin: 0 auto;
  width: 100%;
}

.page-wrapper.print>div:nth-child(2) {
  border: 1em solid rgb(240, 240, 240);
  border-radius: 10px;
  height: 100%;
}

.page-wrapper.print {
  display: contents
}

/* END PAGE WRAPPER */

/* ************************************************ */

/* page-area-container */
.page-area-container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* end page-area-container */

/* ************************************************ */

/* push-container */
.push-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: .05em .15em;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
}

.push-container>.push-header {
  padding: 0 0 .1em 0;
}

.push-container>.push-header>.push-caption {
  padding: .5em;
}

.push-container>.push-content {
  padding: .25em .15em 0 .15em;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.push-container>.push-content.np {
  padding: 0;
  overflow-y: auto;
  height: 100%;
}

/* end push-container */

/* ************************************************ */

/* SLIDER CONTAINER */
.slider-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 1em 0;
}

.slider-container>div:nth-child(1) {
  width: 25%;
  text-align: left;
}

.slider-container>div:nth-child(2) {
  width: 25%;
  text-align: center;
}

.slider-container>div:nth-child(3) {
  width: 50%;
  text-align: right;
  margin: .5em;
  margin-left: 1.5em;
}

.slider-container>div:nth-child(2)>.label {
  min-width: 40px;
}

/* END SLIDER CONTAINER */

/* ************************************************ */

/* SLIDING */
.sliding-container {
  margin: 0;
  padding: 1em 0
}

.sliding-container>.header {
  padding-left: 1em !important;
  padding-right: .5em !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
}

/* ************************************************ */

/* SPLASH CONTAINER */
.splash-container {
  height: 100%;
  width: 100%;
}

.splash-content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    "splash-text"
    "splash-logo"
    "splash-footer";
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat !important;
}

/* .splash-container.logo-only{
  grid-template-rows:  auto 1fr;   
  grid-template-areas:  
  "splash-logo" 
  "splash-footer"; 
} */

.splash-text {
  text-align: center;
  font-size: 1.5rem;
  margin: auto 0
}

.splash-content>.splash-logo {
  padding: 2em;
  max-width: 280px;
}

.splash-content>.splash-text {
  padding: 1em;
}

.splash-content.desktop {
  height: 100%;
  width: 100%;
}

.splash-content.fh {
  height: 100vh !important;
}

.splash-content.mobile {
  height: 100vh;
}

.splash-content.preview {
  height: 600px;
}

.splash-content {
  max-width: 100%;
}

.splash-text {
  grid-area: splash-text;
}

.splash-footer {
  grid-area: splash-footer;
  bottom: 0px;
}

.splash-logo {
  grid-area: splash-logo;
}

.splash-text>.image {
  max-width: 200px;
}

.splash-logo,
.splash-footer {
  bottom: 0px;
  position: relative;
  margin: auto auto
}

/* END - SPLASH CONTAINER */

/* ************************************************ */

/* START CONTAINER */
.start-container {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 4fr 1fr;
  grid-template-areas:
    "start-logo"
    "start-content";
  /* "start-footer";  */
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat !important;
}

.start-container {
  max-width: 100%;
}

.start-container.fh {
  height: 100vh !important;
}

.start-container.desktop {
  height: 100%;
  width: 100%;
}

.start-container.mobile {
  height: 100vh;
}

.start-logo {
  grid-area: start-logo;
}

.start-content {
  grid-area: start-content;
  display: block;
}

.start-footer {
  grid-area: start-footer;
  bottom: 0px;
}

.start-logo,
.start-footer {
  bottom: 0px;
  position: relative;
  margin: auto auto
}

.start-content {
  text-align: center;
  font-size: 1rem;
  margin: auto 0;
  padding: 2em;
}

.start-container>.start-logo {
  padding: 2em;
  max-width: 180px;
}

/* END - START CONTAINER */

/* ************************************************ */

/* SWIPE-CONTAINER */
.swipe-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    "filter"
    "tab"
    "alpha"
    "app-content-swiper"
}

.search:not(.ua-search):not(.icon) {
  width: 100%;
}

.ua-search {
  min-width: auto !important;
  font-size: .7rem !important;
}

.app-tab-menu-swiper {
  grid-area: app-tab-menu-swiper;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: none;
  overflow-x: auto;
}

.app-content-swiper {
  grid-area: app-content-swiper;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

/* ************************************************ */

/* TAB CONTAINER */
.tab-container {
  height: 100%;
  width: 100%;
  display: grid;
  /* grid-template-columns: 100%; */
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "tab-menu"
    "tab-content"
}

.tab-menu {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: thin;
  grid-area: tab-menu;
  margin-bottom: 0 !important;

}

.tab-content {
  grid-area: tab-content;
  width: 100%;
}

.tab-content.tbl {
  overflow: hidden;
}

.tab-content>.form,
.tab-content>.map-list-container {
  padding: .75em !important;
  max-width: 100%;
}

.tab-button {
  padding: .25em 0;
  display: flow-root;
  margin: 1em 0;
  text-align: right;
}

/* END - TAB CONTAINER */

/* ************************************************ */

/* TABLE CONTAINER */
.table-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-search"
    "table-content";
}

.table-container.normal {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-diff"
    "table-content"
}

.table-container.normal.count {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-count"
    "table-content"
}

.table-container.normal.search {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-search"
    "table-content"
}

.table-container.normal.search.props {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "table-search"
    "table-props"
    "table-content"
}

.table-container.normal.toggle.props {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "table-props"
    "table-diff"
    "table-content"
}

.table-container.normal.foot {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "table-diff"
    "table-content"
    "table-footer";
}

.table-container.normal.toggle {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-diff"
    "table-content"
}

.table-count {
  grid-area: table-count;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-search {
  grid-area: table-search;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-props {
  grid-area: table-props;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-diff {
  grid-area: table-diff;
  padding: 1em;
  border-bottom: var(--thin-border)
}

.table-footer {
  grid-area: table-footer;
  padding: .5em 0;
  text-align: center;
  border-top: var(--thin-border)
}

.table-content {
  grid-area: table-content;
  font-size: .8rem !important;
  padding: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}

.table-content.np {
  padding: 0;
}

.dbl-lbl>.label {
  margin-left: .5em !important
}


.table-count {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: .25em;
}

/* END - TABLE CONTAINER */

/* ************************************************ */

/* TAG FILTER */
.tag-filter-container {
  padding: .5em;
}

.tag-filter-content {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.tag-filter-content>.label {
  text-align: left;
}

/* ************************************************ */

/* update-container */
.update-container {
  border: 3px solid rgba(34, 36, 38, .15);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%)
}

.update-container>.header {
  text-align: center;
  font-weight: bold;
}

.update-container label,
.update-container .input,
.update-container .menu,
.update-container .menu>.item,
.update-container .menu>.item>span,
.update-container .dropdown {
  font-size: .8rem;
}

/* end update-container */


/* UPLOADER CONTAINER */
.uploader-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-x: hidden;
  border: 1px dashed lightgray !important;
  padding: .5em !important;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas:
    "uploader-header"
    "uploader-path"
    "uploader-progress"
    "uploader-file-name"
    "uploader-content"
    "uploader-manifest"
}

.uploader-container.url {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas:
    "uploader-header"
    "uploader-path"
    "uploader-url"
    "uploader-progress"
    "uploader-content"
}

.uploader-header {
  grid-area: uploader-header;
  padding: 0em;
  text-align: right;
}

.uploader-url {
  grid-area: uploader-url;
  padding: 8px;
}

.uploader-path {
  grid-area: uploader-path;
  padding: 1em;
  font-size: .7rem;
  text-align: center;
}

.uploader-progress {
  grid-area: uploader-progress;
  padding: 8px;
}

.uploader-file-name {
  grid-area: uploader-file-name;
  padding: 8px;
}

/* border-top: var(--thin-border); */
.uploader-content {
  grid-area: uploader-content;
  padding: 1em;
}

.uploader-manifest {
  grid-area: uploader-manifest;
}

.uploader-manifest>img {
  padding: .5em
}

.uploader-url>.input {
  width: 100%;
}

.dark-mode .uploader-header {
  border-bottom: var(--dark-thin-border-settings);
}

.dark-mode .uploader-path {
  border-bottom: var(--dark-thin-border-settings);
}

.dark-mode .uploader-progress {
  border-top: var(--dark-thin-border-settings);
}

.uploader-content .uploader-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  gap: 1em;
}

/* END - UPLOADER CONTAINER */

/* ************************************************ */

/* ************************************************ */

/* ************************************************ */

/* ************************************************ */

/* END CONTAINERS */

.ssc-normal {
  height: 100%;
}

.modal-wide {
  height: 84% !important;
  width: 98% !important;
}

.menu-slim:not(.chk)>.item {
  padding: .75em 1em !important;
  font-weight: bold !important;
}

.menu-slim {
  border: 0 !important;
  height: 100%;
}

.menu-slim .item.iconed .icon:first-child {
  float: left !important;
  padding: 0 !important;
  margin-right: 1em !important;
}

/* .modal-wide > .vert-container > .content{max-width: 1000px; margin: auto;} */

.trcl {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.trcl>.label {
  margin-left: 0 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.field.disabled {
  opacity: .9 !important;
}

.side-wrapper div:first-child {
  overflow-x: auto;
}

.display-editor {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.display-editor .editor {
  border: 1px solid red !important;
  margin-left: 1em;
  height: 100%;
  overflow-y: auto;
}

.ssc-normal,
.ssc-normal .segment,
.ssc-normal .menu,
.ssc-normal .grid,
.ssc-normal .label:not(.mini):not(.tiny):not(.small):not(.large),
.ssc-normal .table {
  font-size: .8rem !important;
}

.seg-nbm {
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  grid-template-rows: auto 1fr !important;
}

.ms-button-group {
  padding: .5em .5em !important;
  text-align: center;
  margin-bottom: .25em;
  background-color: white;
  border-radius: 4px;
  width: 100%;
  overflow-x: auto;
}

.ccs {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.start-dimmer {
  height: 100%;
  z-index: 9999;
}

.suspense-dimmer {
  background-color: red !important;
}

/* START ELEMENTS */
.start-dots-container {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-dot-container {
  width: 10rem;
  height: 5rem;
  display: flex;
  justify-content: space-around
}

.start-dot {
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: rgb(0, 54, 191);
  border-radius: 50%;
}

.send-buttons {
  display: flex;
  justify-content: space-between;
}

.start-circle-container {
  position: relative;
  width: 3rem;
  height: 3rem;
  box-sizing: border-box;
  margin: auto;
}

.start-circle {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.4rem solid darkgrey;
  border-top: 0.4rem solid blue;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.start-circle.suspense,
.start-circle.pageItem {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2rem solid white;
  border-top-color: black;
}

/* 
.start-circle.app {
  border-top-color: green;
}

.start-circle.authorizing {
  border-top-color: yellow;
}

.start-circle.event {
  border-top-color: blue;
} */

/* END START ELEMENTS */
.save-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1em 0 0 0;
}

.vid-container>div {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}

.vid-container iframe {
  margin: 0 !important;
}

.lazyload-wrapper {
  width: 100% !important;
}

.lazyload-wrapper>div {
  width: 100% !important;
}

.pending-container {
  height: 100%;
}

.update-wrapper {
  width: 100%;
  height: 100%;
  padding: .5em .25em;
  margin: 0;
}


.fcm-container>.item>.content {
  font-size: .7rem;
  word-wrap: break-word;
  word-break: break-all;
}

.num-pad {
  display: grid;
  grid-template-rows: 1fr 1fr 9fr;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
}

.num-pad-value {
  font-size: 2.5rem;
  padding: .25em;
  font-weight: bold;
}

.num-grid .num-pad-pad {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  font-size: 2rem;
  height: 100%;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  background-color: rgb(42, 42, 42);
  padding: .5em;
}

.num-grid .num-pad-pad>div {
  display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  justify-items: center;
}

.pending-wait {
  height: 100% !important;
  width: 100% !important;
  display: block
}

.push-pushable {
  height: 100%;
  width: 100%;
  /* padding: .5em 0 !important; */
}

.push-push {
  height: 100%;
}

.scroll-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}

.scroll-contents {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.scroll-element {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: scroll;
}

.scroll-element.no-padding {
  padding-top: 0;
}

.menu-wrapper {
  width: 100%;
  padding: .25em .5em .25em .2em;
  margin: 0 auto;
  ;
}

.phone-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 9fr 1fr;
  align-items: center;
  margin-bottom: 1em;
}

.phone-container.full {
  grid-template-columns: 1fr;
}

.phone-container .icon {
  height: 100%;
  width: 100%;
}

.page-area-content.dt.ima-d {
  height: 100vh;
}

.page-area-content.dt-off.mob.ima-m {
  height: 100vh;
}

.page-area-content.ns .app-container {
  margin: 0 auto !important;
}

.app-container.dtm {
  padding: 0 0em
}

.questions-container>.header>.ui>i.icon:nth-child(1),
.questions-container>.content>.ui>i.icon:nth-child(1) {
  margin-right: .75em;
  margin-left: 0;
  font-size: 1.5em !important;
}

.questions-container>.header>.ui>i.icon:nth-child(3),
.questions-container>.content>.ui>i.icon:nth-child(3) {
  margin: 0;
  font-size: 1.5em !important;
}

.questions-container>.list>.item>.content>.description {
  padding-top: .5em;
}

.app-notifications-container {
  height: 100%;
}

.app-notification-group div {
  font-size: .9rem;
}

.app-notification-title-container>div:nth-child(4) {
  font-size: .7rem !important;
  padding: .25em;
  color: rgb(148, 161, 255) !important;
}

/* .app-notification-group>div, */
.app-notification-date-group>div {
  padding: 1em .25em;
}

.app-notification-date-group>div:nth-child(1) {
  text-align: center;
  margin-top: 1em;
}

.app-notification-date-group>div:nth-child(2) {
  padding: 0;
}

.app-notification-group>div:first-child,
.app-notification-date-group>div:first-child {
  font-weight: bold;
  padding: .25em !important;
  /* border-bottom: 1px solid rgb(202, 202, 202); */
  margin-bottom: .5em;
  font-size: 1.1rem;
}

.note:not(.icon) {
  border: 1px solid rgb(212, 212, 212);
  padding: .5em;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.app-note-user {
  margin: .2em !important;
}

.app-note-title {
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.app-note-title>div:nth-child(2) {
  text-align: right;
}

.app-note-title>div>div:nth-child(1) {
  font-weight: bold;
}

.note>.note-time {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding: .75em 0;
  text-align: left;
  font-size: .9rem;
}

.note>.note-note {
  display: grid;
  grid-template-rows: auto 1fr;
  border: 1px solid rgb(212, 212, 212);
  padding: 1em;
}

.note>.note-note>div:nth-child(2) {
  padding: 1em;
}

.note>.note-description {
  border: 1px solid rgb(212, 212, 212);
  padding: 1em;
}

.btn-rows {
  padding: 0;
  display: grid;
  grid-gap: .25em;
  gap: .25em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
}

.btn-rows.one {
  grid-template-rows: repeat(1, 1fr);
}

.btn-rows.two {
  grid-template-rows: repeat(2, 1fr);
}

.modal-wide .btn-rows {
  display: flex;
  justify-content: flex-end;
}

.note>.note-buttons {
  padding: .75em 0;
  display: grid;
  grid-gap: .25em;
  gap: .25em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.buttons-two-row {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.notification-status .nsi-header {
  padding: .5em;
  text-align: center;
  background-color: rgb(202, 202, 202);
  font-weight: bold;
}

.notification-status .nsi.sl,
.notification-status .nsi.dl {
  display: flex;
  padding: .75em 0;
  /* border: 1px solid rgb(228, 228, 228); */
}

.notification-status .dl {
  flex-direction: column;
}

.notification-status .sl {
  flex-direction: row;
  justify-content: space-between;
}

.notification-status .dl>div:nth-child(1) {
  text-align: center;
  padding: 1em 0 0 0;
}

.notification-status .dl>div:nth-child(2) {
  padding: 1em;
}

.notification-status .sl {
  grid-template-columns: 3fr 2fr;
}

.notification-status .sl>div:nth-child(1),
.notification-status .dl>div:nth-child(1) {
  font-weight: bold;
}

.notification-status .sl>div:nth-child(2),
.notification-status .dl>div:nth-child(2) {
  font-weight: normal;
}

.notification-status .nsstp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.notification-status .nsstp>div {
  padding: .25em;
}

.app-notifications .title>div:last-child {
  text-align: right;
}

.app-notifications .checker {
  float: right;
  font-size: 1em !important;
}

.app-notifications .checker label {
  margin-right: .5em;
  font-size: .8em;
}

.app-notifications>.title>div:nth-child(3) {
  margin-left: .5em !important
}

.seg-cal-select>.checker {
  padding: .5em;
  text-align: right;
}

.app-notification-container {
  display: grid;
  grid-template-rows: auto auto auto auto;
  padding: 1em !important;
  height: 100%;
  border-radius: 8px;
  grid-gap: .5em;
  gap: .5em;
  margin-bottom: .5em;
  border: 1px solid rgb(229, 229, 229);
}

.app-notification-container>div:nth-child(4) {
  font-size: .7rem;
  color: rgb(161, 161, 161) !important;
}

.app-notification-content {
  display: none;
}

.app-notification-content.active {
  display: block;
}

.table-filter-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 2fr;
}

/* APP HEADER */
.top-menu {
  border-radius: 0;
}

.top-menu>.item:nth-child(1) {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.top-menu .right>.item {
  padding: 0 .35em !important;
}

.top-menu>.item:nth-child(1) {
  padding-left: .75em !important;
  padding-right: .75em !important
}

.top-menu>.menu-title {
  display: grid !important;
  grid-template-rows: auto auto auto;
  padding-left: .25em !important;
}

.top-menu>.menu-title>div:not(:first-child) {
  font-size: .8rem;
  padding-top: .5em;
  opacity: .7;
}

.top-menu>.menu-title {
  font-size: .8rem;
  /* padding-top: 0.25 !important;
  padding-bottom: 0 !important; */
}


.app-topMenu {
  grid-area: app-topMenu;
}

.dt-mode .app-content,
.dt-mode .item-content,
.dt-mode .app-swipe-content,
.dt-mode .react-swipeable-view-container>div,
.dt-mode .react-swipeable-view-container>div>div {
  height: auto !important;
  overflow-y: hidden !important;
}

/* .react-swipeable-view-container>div,
.react-swipeable-view-container>div>div {
  height: 100% !important;
} */

.single-page-container {
  height: 100%;
  overflow-y: auto;
}

.single-page-container .item-container {
  height: auto;
  border: 0 !important;
  margin-bottom: 0;
}

.single-page-container .item-header {
  font-weight: bold;
  padding: .5em;
}


.single-page-container .item-content {
  overflow-y: hidden !important;
}

.dt-mode .app-container.mcnf>.app-content {
  padding-top: 4em !important
}

.dt-mode .react-swipeable-view-container>div {
  height: auto !important;
}

.dt-mode .item-container>.item-content {
  height: auto !important;
  overflow-y: hidden !important;
}

.settings-app-content {
  margin: auto !important;
}

[propname=selectedOnly] [propname=selectAll],
[propname=selectAll]>label {
  font-size: .8rem !important;
}

/* DATA-LIST */
.data-list-container {
  padding: 1em;
  height: 100%;
  font-size: .8rem;
}

.data-list-list>.item {
  justify-content: center !important;
}

.list-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dm-mode .app-header img {
  max-width: 200px !important;
  max-height: 200px !important;
}

.icons-left>.item.sel {
  opacity: .6;
}

/* This will line up the the icons to the left (sideMenu/iconsLeft */
.icons-left .item {
  display: flex !important;
  padding: .75em !important
}

.icons-left>.item {
  align-self: center !important;
}

.icons-left>.item>img,
.icons-left>.item>.icon {
  float: left !important;
  margin-right: 1em !important;
  margin-left: 0 !important;
  align-self: center;
}

.icons-left>.item>img {
  vertical-align: middle !important;
  display: inline-block !important;
}

.vert-container>.content>.menu>.item {
  align-items: center !important;
}

.app-users-container {
  padding: .25em !important;
}

.app-users-container .header {
  font-size: 1rem !important;
  margin-bottom: .25em;
}

.app-ticketing-container {
  padding: .5em !important;
}

.app-ticketing-container>.list>.item {
  display: grid !important;
  grid-template-columns: 6fr 1fr 1fr 1fr 1fr !important;
  align-items: center;
}

.app-ticketing-container .dropdown,
.app-ticketing-container .dropdown>.menu>.item {
  font-size: .9em !important;
}

.app-ticketing-container>.list>.item>div:not(:first-child) {
  text-align: center;
}

.app-ticketing-container .icon {
  margin-left: .5em
}

.app-container .menu:not(.menu-slider):not(.sports-active) {
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0;
}

/* app-note */

/* SV DATA */
.sv-data {
  width: 100%;
  height: 100%;
}

.sv-data.nc {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
}

.header.vds {
  padding: 0 !important
}

.sv-data-footer {
  padding: .5em
}

.sem-carosel {
  padding: .25em;
}

.sem-carosel .banners-image>.image {
  height: 60px;
  width: auto;
}

/* IMAGE THUMBNAIL */
.img-ts-col {
  /* padding: .25em !important; */
  border: var(--thin-borders);
}

.img-ts-col.pdf {
  border: none
}

.img-ts-container {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.img-ts-image {
  position: absolute;
  left: 50%;
  top: 50%;
  /* max-width: unset !important; */
  text-align: center;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.img-ts-image.pending {
  opacity: .35
}

;

.img-ico-sel {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.img-ico-view {
  position: absolute;
  top: 5px;
  right: 2px;
  z-index: 1;
}

.grid .img-ts-container>.img-ts-image {
  width: 100% !important;
}

.img-profile {
  padding: 3em !important;
  border: var(--thin-borders);
}

.item-header {
  grid-area: item-header;
}

.item-header {
  /* display: grid; */
  width: 100%;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 100%;
}

.search-page {
  padding: .5em;
  border: 0
}

.search-page .menu-search,
.item-header .menu-search {
  width: 100% !important;
}

.search-page>.item.search,
.item-header .menu-search>.menu {
  width: 100% !important;
}

.search-page>.item.search>.input.labeled {
  width: 100% !important;
}

.search-page>.item.search>.input.labeled .icon,
.item-header .menu-search>.menu .label>.icon {
  margin: 0 !important
}

/* .item-header div div{width: 100% !important;} */

.item-caption {
  grid-area: item-caption;
}

.dt-mode .item-caption {
  grid-area: item-caption;
  padding: 1em;
  font-size: 1.2rem;
  font-weight: bold;
}

.item-content {
  grid-area: item-content;
}

.item-content {
  overflow-y: auto;
  -webkit-overflow-y: auto;
  -webkit-box-flex: 0;
}

/* NEW */
.item-content>.cards.solo {
  margin: 0 !important;
}

.item-mask {
  grid-area: item-mask;
}

*/
/* END - ITEM UI CONTAINER */

/* .item-filter{text-align: right; }  */
.item-filter .menu-filter.fullPage,
.item-filter .menu-filter.tags {
  display: flex;
  padding: .5em;
}

.item-filter .menu-filter.fullPage {
  justify-content: flex-end;
}

.item-filter .menu-filter.tags {
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: thin;
  flex: 1 1 auto;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
}

.item-filter .menu-filter.menu {
  padding: .5em;
  border: 0;
}

.menu-alphabet {
  scrollbar-width: thin;
}

/* pad the cardNameSectionOnly smaller */
.cardNameSectionOnly .sec-container.header .sec-content {
  padding: .75em 0em .75em .5em;
}

/* END - CONTENT CONTAINER */

/* FULL HEIGHT */
.app-container,
.app-content,
.app-swipe-container,
.item-container {
  height: 100%;
  overflow-y: hidden !important;
}

/* CARDS CONTAINER */
.cards-container {
  padding: 0;
  overflow-x: hidden;
  margin-top: 4px !important;
  width: 100%;
}

.cards-container.profilePlus,
.cards-container.schedulePlus {
  margin-top: 0px !important;
}

.cards-container.solo {
  height: 100%;
}

.cards-container.solo>.card {
  border-radius: 0 !important;
}

.item-vert:not(.solo) {
  padding: .2em !important
}

.dt-off .cards-container.item-vert>.motion-container.element>.card,
.dt-off .cards-container.item-vert>.card {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: .5em !important;
}

.profilePlus>.card,
.card-profile.plus>.profilePlus>.card {
  margin: 0 !important;
}

.cards-container.multi {
  justify-content: space-between;
  align-content: stretch;
}

.cards-container.multi>.card {
  margin: .25em !important;
  width: calc(33.33333333% - 1em) !important;
  font-size: .8rem !important;
}

.cardMini {
  padding: 8px !important
}

.cardMini .sec-container {
  border: none !important
}

.cardMini .cards-container {
  display: grid !important;
  flex-direction: column !important;
  grid-gap: 6px;
  justify-content: space-evenly;
}

.cardMini .ipr-2 {
  grid-template-columns: repeat(2, 50%);
  font-size: .9rem;
}

.cardMini .ipr-3 {
  grid-template-columns: repeat(3, 33%);
  font-size: .8rem;
}

.cardMini .ipr-4 {
  grid-template-columns: repeat(4, 25%);
  font-size: .7rem;
}

.card.card-profile.plus,
.cards-container.profilePlus .card {
  border-radius: 0 !important;
  box-shadow: none;
}

.scroll-container>.card {
  width: 100%;
}

.cardMini .card {
  overflow-y: auto;
  scrollbar-width: none;
  height: 100%;
}

.cardMini .card {
  overflow-x: hidden;
}

.cardMini .card {
  margin: .25em !important;
  width: auto !important;
}

.cardMini .card .header {
  margin: 0 auto !important;
}

.cardMini .card .prc {
  font-size: .8rem !important
}

.prc.edt {
  display: grid;
}


/* .prc{width: 100%;} */

/* .solo .card {height: fit-content;} */
/* END -SEG CARD GROUP */

/* CARD */
.card>.header>.header {
  border-radius: 0 !important;
}

.dt-off .card:not(.normal):not(.card-normal):not(.inline-container):not(.card-profile):not(.card-schedule):not(.card-access):not(.card-note):not(.card-ticket):not(.card-bus-schedule)>.content {
  padding: 0 !important;
}

.busSchedule .cards {
  padding: .5em !important;
}

.busSchedule .cards .label {
  width: 100%;
  text-align: center;
}

.busSchedule .cards .content.extra {
  display: flex;
  flex-direction: row;
}

.card-normal.gift-card {
  padding: .5em 0;
  border: solid 2px rgb(185, 185, 247);
  height: 120px;
}

.card-normal.gift-card .buttons>.button:first-child {
  background-color: rgb(255, 216, 216) !important;
}

.card-normal.gift-card .buttons>.button:last-child {
  background-color: rgb(212, 255, 207) !important;
}

.card-normal.gift-card {
  border: solid 2px rgb(221, 221, 221);
}

.card-normal.gift-card>.content>.meta>.label {
  margin-top: 1em;
}

.dt-off .card.full {
  border-radius: 0;
  height: 100%
}

/* .card-access > .content > .header{margin-bottom: 1em !important;} */
.card.card-schedule>.content,
.card.card-profile>.content {
  padding: .75em .25em .5em .25em !important;
}

.cards .card-schedule {
  align-self: baseline !important;
}

/* INDIVIDUAL CONTIAINERS */


.action-sidebar>.item {
  padding: 1.5em !important;
  font-weight: bold !important;
  font-size: .9rem;
}

/* END - ACTION CONAINTER */

/* CONFIRM BUTTONS */
.btn-confirm {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr;
  padding-bottom: .5em !important;
  margin-bottom: 8px !important
}

.btn-confirm.alt-ib {
  grid-template-columns: 1fr 1fr 8fr 1fr;
}

.btn-confirm.alt-ib.alt-cb {
  grid-template-columns: 1fr 1fr 1fr 6fr 1fr;
}

.btn-confirm.help {
  grid-template-columns: 1fr 8fr 1fr 1fr;
}

.btn-confirm.no-commit {
  grid-template-columns: 1fr 8fr;
}

.btn-confirm.no-commit.help {
  grid-template-columns: 1fr 8fr 1fr;
}

.btn-confirm.confirmed {
  grid-template-columns: 8fr 1fr;
}

.btn-confirm .button {
  padding: 1em !important
}

.btn-confirm .button:nth-child(2) {
  text-align: left;
}

.btn-confirm.pending,
.btn-confirm.upload {
  grid-template-columns: 8fr 1fr !important;
}

.btn-confirm.alt-ib .button:nth-child(1),
.btn-confirm.alt-cb .button:nth-child(2) {
  border-right: 2px solid white;
}

.btn-confirm>.button.btn-input {
  padding: 0 .25em !important
}

.btn-confirm>.button.btn-input>.input {
  width: 100%;
}

.btn-confirm .rc-input-number {
  width: 100% !important;
}

.btn-confirm .rc-input-number-handler-wrap {
  width: 100% !important;
}

.btn-confirm .rc-input-number-input-wrap {
  width: 100% !important;
}


/* .78571429em 1.5em .78571429em */

.upload-btn-wrapper {
  height: 34px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 30px !important;
  position: relative !important;
  left: 0 !important;
  /* top: -30px !important; */
  opacity: 0 !important;
  z-index: 1 !important;
}

.upload-btn-wrapper .btn,
.upload-btn-wrapper input[type=file] {
  width: 200px !important;
  height: 34px !important;
  position: absolute;
}

/* END - CONFIRM BUTTONS */

/* ************************* */

.address-container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 30% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row];
}

.box {
  border-radius: 5px;
  padding: .25em .5em
}

.address {
  grid-column: span 2;
  grid-row: row 1;
}

.city {
  grid-column: span 2;
  grid-row: row 2;
}

.state {
  grid-column: span 1;
  grid-row: row 3;
}

.zip {
  grid-column: span 1;
  grid-row: row 3;
}

.address-container.mapp {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 30% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row] auto [row];
}

.address-container.mapp.url {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 50% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row] auto [row] auto [row];
}

.box.mapp {
  border-radius: 5px;
  padding: .5em;
}

.map-view.mapp {
  grid-column: span 2;
  grid-row: row 1;
  width: 100% !important;
  height: 200px !important;
  position: relative;
}

.address.mapp {
  grid-column: span 2;
  grid-row: row 2;
}

.city.mapp {
  grid-column: span 2;
  grid-row: row 3;
}

.state.mapp {
  grid-column: span 1;
  grid-row: row 4;
}

.zip.mapp {
  grid-column: span 1;
  grid-row: row 4;
}

/* ************************* */


/* ************************* */

.cal-months-container {
  width: 100%;
  height: 100%;
  padding: .1em;
  display: grid;
  grid-template-rows: auto 1fr;
}

.cal-close {
  display: grid;
  grid-gap: .2em;
  gap: .2em;
  grid-template-columns: 1fr;
  margin-bottom: .15em;
}

.cal-close.today {
  grid-template-columns: 1fr 1fr;
}

.cal-close.missing.today {
  grid-template-columns: 2fr 5fr 2fr;
}

.cal-close>div {
  padding: .5em;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.cal-months {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cal-month {
  font-weight: bold;
  text-align: center;
  padding: .5em;
  border-radius: 4px;
}

/* CALENDAR CONTAINER */
.calendar-container {
  display: grid;
  padding: .25em;
  grid-gap: .2em;
  gap: .2em;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto repeat(5, 1fr);
  /* max-width: 600px; */
  margin: auto;
}

.calendar-container>div {
  border-radius: 6px;
  padding: .5em;
  text-align: center;
}


.norm-count>span>div {
  margin: 0;
}

/* END - CALENDAR CONTAINER */

/* CALENDAR */
.cal-select-container {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
}

.cal-select-container>div {
  padding: 1em;
  text-align: center;
  font-weight: bold;
}

.cal-select-container>div:nth-child(0) {
  border-top: 1px solid grey
}

.cal-select-container>div {
  border-right: 1px solid grey
}

.cal-select-container>div {
  border-bottom: 1px solid grey
}

.cal-select-container>div:nth-child(0) {
  border-left: 1px solid grey
}

/* CALENDAR - MONTH */
.month-select-container {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background: #fff;
}

.month-select-container>div {
  padding: 1.78em;
  /* border: 1px solid grey; */
  text-align: center;
  font-weight: bold;
}

/* ************************* */

.header-checker {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.header-checker>.checker {
  text-align: right;
}


.push-pushable.inverted .menu-slider-sidebar,
.push-pushable.inverted .menu-slider {
  border-radius: 8px;
}

.modal-wide .sw-wrapper.dm {
  background-color: black;
}

.modal-wide .sw-wrapper.dm>.content {
  width: 60%;
  margin: 0 auto;
  border: 1px solid var(--topper);
}

/* .sw-wrapper.dm>.content>.form {
  padding: .5em;
} */

.sem-menu {
  padding: 1em;
  height: 100%;
}

.sw-wrapper.dm .divider {
  color: rgb(209, 209, 209);
}

.sw-wrapper .push-pushable {
  padding: 0
}

.sw-wrapper .push-container {
  padding: 0
}

.sw-wrapper .push-content {
  padding: 0
}

.sw-wrapper.sub {
  padding: .25em
}

/* ************************* */

/* JITSI */

.jitsi-wrapper {
  height: 100%;
}

.jitsi-wrapper>div {
  height: 100% !important;
  width: 100% !important;
}

#jitsi-container {
  height: 100% !important;
}

.chrome-extension-banner {
  width: 200px !important;
}

.fresnel-container {
  padding: 0 !important;
  margin: 0 !important
}

.hdr-containerXX {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center
}

.hdr-container {
  display: grid;
  padding: .5em;
  justify-content: center;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.hdr-container>div:nth-child(1) {
  text-align: left;
}

.hdr-container>div:nth-child(2) {
  text-align: center;
  margin: auto;
  font-weight: bold;
}

.hdr-container>div:nth-child(3) {
  text-align: right;
}

.sw-wrapper.full {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sw-wrapper>.header {
  border-bottom: none !important;
}

.sw-wrapper>.header>.menu {
  padding: 0 !important;
}

.sw-wrapper>.header>.menu>.item:nth-child(2) {
  font-weight: bold;
}

.sw-wrapper.topper {
  grid-template-rows: auto auto 1fr !important;
  height: 100% !important;
  grid-template-areas:
    "topper"
    "header"
    "content" !important
}

.sw-wrapper.topper.hoc {
  grid-template-rows: auto auto auto 1fr !important;
  height: 100% !important;
  grid-template-areas:
    "topper"
    "header"
    "notification"
    "content" !important
}

.modal-full>.vert-container.fpw {
  border: 4px solid var(--topper) !important;
}

.fpw .footer:not(.footer-right),
.sw-wrapper.topper .footer {
  background-color: var(--topper) !important
}

.hdr-container div[width='10'] {
  width: 10% !important;
}

.hdr-container div[width='20'] {
  width: 20% !important;
}

.hdr-container div[width='30'] {
  width: 30% !important;
}

.hdr-container div[width='40'] {
  width: 40% !important;
}

.hdr-container div[width='50'] {
  width: 50% !important;
}

.hdr-container div[width='60'] {
  width: 60% !important;
}

.hdr-container div[width='70'] {
  width: 70% !important;
}

.hdr-container div[width='80'] {
  width: 80% !important;
}

.hdr-container div[width='90'] {
  width: 90% !important;
}

.grid.drop-list .column {
  padding: .2em !important;
}

/* 
.ui[class*="three column"].grid >.row > [data-rbd-draggable-context-id],
.ui[class*="three column"].grid > [data-rbd-draggable-context-id] {
  width: 33.33333333% !important;
  padding: 0 !important; 
  display: inline-block; 
} */

/* ************************* */


.list-with-header .content {
  padding: .25em 0 .5em 0 !important;
  font-weight: bold;
}

/* ************************* */

.menu-slider-sidebar>.item {
  display: flex !important;
  padding: 1em 1em !important;
  justify-content: space-between !important;
}

.menu-alphabet-slider {
  display: grid !important;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr) !important;
}

.menu-alphabet:not(.vertical) {
  width: 100%;
  overflow-y: auto;
}

.copy-container {
  display: grid;
  grid-template-rows: auto 1fr;
}

.copy-container>div {
  padding: 1em;
}

.npicker-container {
  display: flex;
  flex-direction: row;
}

.time-container {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr
}

.time-container label {
  font-weight: bold;
  font-size: .92857143em;
}

.time-container>.grid {
  width: 100%;
  margin: 0 !important;
}

.time-container>.grid>.column {
  padding: 0 .25em 0 0 !important;
}

.time-container>.grid .dropdown {
  width: 100%;
  min-width: 1em !important;
}

.time-container>.menu {
  width: 50px !important;
}

/* ACTION CONAINTER */
.news-container {
  width: 100%;
  padding-top: .2em !important;
  margin: 0 !important;
}

.news-container .card {
  padding: 0 !important;
  margin: .2em !important;
}

/* ************************* */

/* PS CONTAINER */
.ps-container {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "ps-header"
    "ps-pager"
}

.ps-header {
  grid-area: ps-header;
  padding: 0 0 .5em;
  font-weight: 700;
}

.ps-pager {
  grid-area: ps-pager;
  text-align: center;
  padding: .5em 0 1em 0;
  width: 100%;
  overflow-x: auto !important;
  scrollbar-width: none;
}

.pgl-contaner {
  display: flex;
}

/* END - PS CONTAINER */

.question-container>textarea {
  width: 100% !important;
  height: 100% !important;
  border: .1px solid lightgrey;
  outline: none;
  padding: .5em;
}

.questions-container .list>.item>.icon {
  float: right;
}

/* ************************* */
.qr-content {
  padding: 1em;
  text-align: center;
  margin: auto 0;
}

.em-content {
  padding: 1.2em;
  text-align: center;
  margin: auto 0;
  font-size: 1.2rem !important;
}

/* ************************* */
.card-profile.fnd {
  border: 2px solid blue !important;
}

.card-profile.rct {
  border: 2px solid green !important;
}

.card-schedule>.content,
.card-profile>.content {
  padding: 1em !important;
}

.card-profile>.content>.header {
  display: flex !important;
}

.card-profile>.content>.header>div:not(.label) {
  padding-right: .2em !important;
}

.card-profile>.content>.header>div.label {
  margin-right: 1em !important;
}

.card-profile .card-image>.image {
  padding: 0 .25em 0 0 !important;
}

.card-profile .header>.label {
  margin: 0 0 .5em 0 !important;
}

.card-schedule>.content>.meta .icon,
.card-profile>.content>.meta .icon {
  margin-right: .5em !important;
}

.card-profile.plus,
.card-schedule.plus {
  width: 100% !important;
  border-radius: 0 !important;
  padding: .25em !important;
  margin-bottom: 0 !important;
}

.card-profile>.content {
  width: 100% !important;
}

.card-img.card-profile>.content.extra {
  padding: .5em !important;
}

/* card image */
.card-img.normal .card,
.card-img.nameSectionOnly .card,
.card-img:not(.img-inline).card-profile {
  display: grid !important;
}

.card-img.nameSectionOnly .card-content .header,
.card-img.img-inline.card-profile .card-content .header {
  grid-template-columns: 1fr 4fr !important;
  grid-template-areas: "card-image card-content";
}

.card-img.nameSectionOnly .card-content .icon,
.card-img.img-inline .card-image .icon {
  font-size: 1.2em;
}

.card-img.img-left.normal .card,
.card-img.img-left.nameSectionOnly .card,
.card-img.img-left.card-profile {
  grid-template-columns: 1fr 3fr !important;
  grid-template-areas: "card-image card-content";
}

.card-img.img-left.normal .card.plus,
.card-img.img-left.nameSectionOnly .card.plus,
.card-img.img-left.card-profile.plus {
  grid-template-columns: 2fr 5fr !important;
  grid-template-areas: "card-image card-content";
}

.card-img.img-right.normal .card,
.card-img.img-right.nameSectionOnly .card,
.card-img.img-right.card-profile {
  grid-template-columns: 1fr 3fr !important;
  grid-template-areas: "card-content card-image";
}

.card-img.img-top.normal .card,
.card-img.img-top.nameSectionOnly .card,
.card-img.img-topLeft.nameSectionOnly .card,
.card-img.img-topRight.nameSectionOnly .card,
.card-img.img-top.card-profile,
.card-img.img-topLeft.card-profile,
.card-img.img-topRight.card-profile {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image"
    "card-content";
}

.card-img.img-top.normal .card-image {
  text-align: center;
}

.card-img.img-bottom.normal .card,
.card-img.img-bottom.nameSectionOnly .card,
.card-img.img-bottomLeft.nameSectionOnly .card,
.card-img.img-bottomRight.nameSectionOnly .card,
.card-img.img-bottom.card-profile,
.card-img.img-bottomLeft.card-profile,
.card-img.img-bottomRight.card-profile {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-image";
}

.card-img.nameSectionOnly .card-image {
  padding: .5em 0 .5em .5em
}

.card-content {
  grid-area: card-content;
}

.card-image {
  grid-area: card-image
}

.card-indics {
  grid-area: card-indics;
  padding: .5em;
  border-top: 1px solid white;
}

.card-indic {
  padding: .25em;
}

.card-indic .icon {
  margin-right: .5em;
}

.card-image.il-topLeft,
.card-image.il-bottomLeft,
.img-topLeft .card-image,
.img-bottomLeft .card-image {
  text-align: left;
}

.card-image.il-topRight,
.card-image.il-bottomRight,
.img-topRight .card-image,
.img-bottomRight .card-image {
  text-align: right;
}

.card-image.il-top,
.card-img.img-top .card-image {
  text-align: center;
}

.card-image.il-topRight img,
.card-image.il-bottomRight img,
.img-topRight .card-image img,
.img-bottomRight .card-image img,
.card-image.il-topLeft img,
.card-image.il-bottomLeft img,
.img-topLeft .card-image img,
.img-bottomLeft .card-image img {
  padding: .5em;
}

.card-image .ui.image {
  /* width: 100%; */
  margin: auto;
}

.giftCards .card-image,
.giftCard .card-image {
  margin-bottom: .5em;
}

/* card qr */
.card-qr:not(.img-inline).card-profile {
  display: grid !important;
}

.card-qr.qr-inline .card-profile .card-content .header {
  grid-template-columns: 1fr 4fr !important;
  grid-template-areas: "card-qr card-content";
}

.card-qr.qr-left .card-profile {
  grid-template-columns: 1fr 4fr !important;
  grid-template-areas: "card-qr card-content";
}

.card-qr.qr-right .card-profile {
  grid-template-columns: 4fr 1fr !important;
  grid-template-areas: "card-content card-qr";
}

.card-qr.qr-top .card-profile,
.card-qr.qr-topLeft .card-profile,
.card-qr.qr-topRight .card-profile {
  grid-template-rows: 1fr auto !important;
  grid-template-areas:
    "card-qr"
    "card-content";
}

.card-qr.qr-bottom .card-profile,
.card-qr.qr-bottomLeft .card-profile,
.card-qr.qr-bottomRight .card-profile {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-qr";
}

.card-profile.indic {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image card-content"
    "card-indics card-indics" !important;
}

.card-profile.indic.top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-indics card-indics"
    "card-image card-content" !important;
}

.card-profile.indic.card-img.img-top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image"
    "card-content"
    "card-indics" !important;
}

.card-profile.card-img.img-top>.card-image {
  margin: auto;
  padding: 1em !important;
}

.card-profile.card-img.img-top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-image "
    "card-indics" !important;
}

.card>.card-content>.card-icon {
  float: right !important;
  margin: .5em;
  /* padding: .75em; */
}

.card-content {
  grid-area: card-content;
}

.card-image {
  grid-area: card-image
}

.card-qr {
  grid-area: card-qr
}

.card-auth-wrapper {
  width: 100%;
}

.card-auth-wrapper>.card {
  margin-bottom: .25em;
}

.card-auth {
  padding: .25em 1em;
  font-size: .8rem;
  background-color: rgb(220, 220, 255);
  width: 100%;
  border: 1px solid grey;
  margin-bottom: .5em;
  border-radius: 8px;
}

.qr-topLeft .card-qr,
.qr-bottomLeft .card-qr {
  text-align: left;
}

.qr-topRight .card-qr,
.qr-bottomRight .card-qr {
  text-align: right;
}

.card-qr .card-profile {
  display: grid !important;
  grid-template-columns: 4fr 1fr !important;
}


.card-schedule .header {
  font-size: 1.1rem !important;
}

.sec-container:not(.header) {
  display: grid;
  overflow-x: hidden;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "sec-header"
    "sec-content"
}

.sec-header {
  grid-area: sec-header
}

.sec-content {
  grid-area: sec-content;
}

.sec-content.noCaptions {
  padding: .5em !important;
}

/* ************************* */

/* SIDEBAR */
.app-sidebar-left-wrapper {
  z-index: 10000 !important;
  width: 100%;
  overflow-x: hidden;
  max-width: 90%;
}

.apl-3 .app-sidebar-left-wrapper {
  max-width: 90% !important;
}

.app-sidebar-left-wrapper.full-width {
  max-width: 100%;
}

.dt-on .app-sidebar-left-wrapper {
  max-width: 30%;
}

.app-navigation .menu>.item>.image {
  display: inline-block;
  max-height: 30px !important;
  max-width: 30px !important;
}

.app-sidebar-left-wrapper .menu>.item>.image {
  display: inline-block;
  margin-right: 1em;
  max-height: 40px !important;
  max-width: 40px !important;
}

/* END - SIDEBAR */

/* INDIVIDUAL ITEMS */

/* CHECKER */
.table .checker {
  justify-content: center;
}

.standings-table .table .checker {
  justify-content: left;
}

.checker .label {
  border-radius: 4px;
  border: 1px solid #9dbbd1 !important;
  padding: .5em .25em;
  min-width: 30px;
  text-align: center;
  margin-right: .25em !important;
}

.checker .icon {
  border-radius: 4px;
  border: 1px solid #9dbbd1 !important;
}

.checker label {
  margin-left: 1em !important;
  align-self: center;
}

.form-container .checker,
.form>.checker,
.checker.inline {
  padding: 0 0 1.5em 0;
}

.checker {
  display: flex;
  justify-items: center;
  align-items: flex-end;
}

.checker-text {
  display: flex;
  flex-direction: column;
  justify-items: left;
}

.checker-text>label:nth-child(1) {
  font-weight: bold;
}

.checker-text>label {
  align-self: normal;
}

/* .checker.alt {
  display: grid;
  justify-items: start !important;
  align-items: start !important;
} */

.inline-container .content>.checker label {
  margin-left: .25em !important
}

.sw-wrapper .checker {
  margin-bottom: .25em;
}

.menu-select-items .checker.counter {
  display: grid;
  grid-template-columns: 1fr 8fr 2fr;
  text-align: left !important;
  justify-items: left !important;
  align-items: center;
}

.checker.counter .rc-input-number {
  margin: 0 !important;
}

.wfw .checker {
  padding: 0 0 1.5em 0;
}

/* ITEM ADMIN */
/* https://the-echoplex.net/flexyboxes/?fixed-height=on&legacy=on&display=flex&flex-direction=column&flex-wrap=nowrap&justify-content=flex-end&align-items=flex-end&align-content=flex-end&order%5B%5D=0&flex-grow%5B%5D=0&flex-shrink%5B%5D=1&flex-basis%5B%5D=200px&align-self%5B%5D=auto&order%5B%5D=0&flex-grow%5B%5D=0&flex-shrink%5B%5D=1&flex-basis%5B%5D=100%25&align-self%5B%5D=auto&order%5B%5D=0&flex-grow%5B%5D=0&flex-shrink%5B%5D=1&flex-basis%5B%5D=200px&align-self%5B%5D=auto */
.item-popup {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  position: fixed;
  right: 1.5em;
  z-index: 1000 !important;
}

.react-swipeable-view-container>div>.golf-popup {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  position: fixed;
  right: 1.5em;
  z-index: 1000 !important;
}

.time-keeper {
  text-align: center;
  padding: 2em
}

.pusher-container .item-popup {
  bottom: 2em;
}

.item-popup.single-left {
  left: 2em;
  align-items: flex-start;
}

.item-popup.single-right {
  right: 2em !important;
  align-items: flex-start;
}

.item-popup.solo-right {
  position: relative;
  right: 2em;
  bottom: 2em !important;
  height: 0;
}

.item-popup.solo-right.stag {
  position: fixed;
}

.notes .item-popup {
  bottom: 2em !important;
}

.item-popup.solo-left {
  position: relative;
  align-items: flex-start;
  left: 2em;
}

.item-popup.indy {
  position: relative;
  align-items: flex-start;
  left: 2em;
}

.item-popup>.item-popup-content {
  padding: .25em .5em;
  border-radius: 2px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.page-wrapper .item-popup {
  bottom: 2em
}

.item-settings {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.item-settings>.item-settings-content {
  padding: .25em .5em;
  border-radius: 2px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.item-settings>.item-settings-content>.label {
  margin: .25em 0 .25em 0 !important;
  min-width: 160px;
  padding: .75em
}

.item-settings>.icon {
  margin-top: .5em
}

.item-settings>.segment {
  position: absolute !important;
  right: 1.5em;
  width: 40%;
  bottom: 4.5em;
  display: flex;
}

.item-allow {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: absolute;
  bottom: 5em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.item-mapping {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.item-meeting {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.icon-glow:before {
  -webkit-animation: glow 1300ms infinite;
  animation: glow 1300ms infinite;
}

.item-meeting>.icon {
  -webkit-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}

/* https://bit.ly/3o7h9gj */

/* .twitter-container,
.twitter-container>div,
.twitter-container>div>.twitter-timeline,
.twitter-container>.twitterContainer,
.twitter-container>.twitterContainer .twitter-embed,
.twitter-container>.twitterContainer .twitter-embed>div,
.twitter-container>.twitterContainer .twitter-embed>div>.twitter-timeline {
  height: 100% !important;
}

.twitter-container {
  padding: .5em !important;
}

.twitter-embed {
  overflow-y: scroll !important;
} */

.modals.dimmer {
  padding: 0;
  justify-content: space-around !important;
}

.seg-sign-in {
  display: grid !important;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100%;
  width: 100%;
  padding: .5em;
  text-align: center;
}

.seg-sign-in {
  height: 100%;
}

.seg-sign-in .field {
  margin: 0 !important;
  max-width: none !important;
}

.seg-sign-in .message.icon>.content {
  margin-left: 1em
}

.seg-sign-in .reset-password {
  margin-top: 2em !important;
}

[propname='password'] {
  color: 'red' !important;
  background-color: #1d83cd;
}

/* OTHER */

.warning.message {
  word-wrap: break-word;
}

.dd-linking {
  font-size: .8rem;
  width: 200px !important;
  background: var(--sem-grey) !important;
  color: white !important;
  font-weight: bold;
}

.footer-right {
  padding: 1em !important;
  background-color: black !important;
}

.grid-storage-images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  padding: .5em;
}

.grid-storage-images.pageDirect,
.grid-storage-images.profileGallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
}

.file-management .grid-storage-images {
  grid-template-columns: repeat(10, 1fr) !important;
}

.file-management .image {
  max-width: 120px !important;
  height: auto !important;
}

.us-state-map path {
  pointer-events: all;
}

.us-state-map path:hover {
  opacity: 0.5;
  cursor: pointer;
}

.app-check-container>.segment>div {
  background-color: blue;
}

.google-data-container .tv-tr>td:first-child {
  background-color: rgb(229, 255, 229);
  text-align: center;
}

.google-data-container .tv-tr-new>td:first-child {
  background-color: rgb(255, 222, 222);
  text-align: center;
}

.aie {
  display: grid;
  border: 1px solid rgb(219, 219, 219);
  padding: .25em;
  margin-bottom: 1em;
}

.aie>div:last-child {
  text-align: right;
}

.grid-sports-active {
  background-color: white;
}

.grid-sports-active .row.centered {
  background-color: grey;
}

.grid-sports-active .row>.column {
  padding: .5em !important;
}

.card-group-favs {
  margin: 0 !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.card-group-favs>div:first-child {
  padding: .5em;
}

.card-group-favs>div:last-child {
  padding: .5em;
}

.card-group-favs>.segment:last-child>.header {
  box-shadow: none !important;
}

.ct-no-data {
  height: 100% !important;
}

.rating-container {
  text-align: center;
}

.piom>.header,
.piom>.menu>.item {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr !important;
}

.piom>.menu>.item {
  padding: 1.5em !important;
}

.piom>.header>div:last-child,
.piom>.menu>.item>div:last-child {
  text-align: right;
}

.srr .push-container {
  grid-template-rows: auto 1fr 1fr;
}

.scoring-slider {
  display: grid;
  grid-template-rows: 1fr 14fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1em;
  grid-gap: .5em;
  gap: .5em;
}

.scoring-slider .label {
  width: 100%;
}

.vertical-slider {
  height: 100%;
  width: 100%;
  background-color: rgb(75, 75, 75);
  border-radius: 4px;
}

.horizontal-slider {
  width: 100%;
  height: var(--horz-height);
  background-color: rgb(75, 75, 75);
  color: white;
  vertical-align: middle;
  border-radius: 4px;
}

.vertical-slider.dis,
.horizontal-slider.dis {
  background-color: lightgrey;
}

.vertical-slider>.example-mark .horizontal-slider>.example-mark {
  margin: 0px calc(19px);
  bottom: calc(50% - 6px);
}

.example-thumb {
  font-size: 0.8rem;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.horizontal-slider .example-thumb {
  width: 28px;
  height: var(--horz-height);
  line-height: var(--horz-height);
}


.vertical-slider>.example-mark {
  background-color: grey;
  width: 100%;
  height: 22px;
  border: 1px solid grey;
  color: white;
  left: 0;
}

.example-mark::before {
  background-color: red;
}

.vertical-slider .example-thumb {
  width: 100%;
  height: 22px;
  line-height: 22px;
}

.iconed-map {
  display: flex !important;
  padding: .5em !important;
  align-items: center;
  justify-content: flex-start;
}

.settings-console-list .header>.icon {
  margin-right: .5em !important;
}

.settings-console-list>.item>.content>.content>.label {
  min-width: 48px !important;
  text-align: center !important;
}

.pending-dimmer {
  display: none;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgba(0, 0, 0, .85);
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  transition: background-color .5s linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}

.card-flag {
  display: flex;
  grid-gap: 1em;
  gap: 1em
}

.card.bu>.content>.meta {
  word-break: break-all;
}

.form>.field.df>.input>input {
  border-color: rgb(121, 0, 0);
}

.upl-event {
  border: 2px solid black;
  background-color: rgb(28, 28, 28);
  border-radius: 8px;
}

.places-container {
  padding: .5em !important;
}

.btn-save-container {
  padding: 0 !important;
  margin: 0 !important;
}

.signin .btn-save-container .button {
  float: inherit !important;
  text-align: center;
}

.places-container.grouped>div:first-child {
  font-weight: bold;
}

.places-list,
.places-list.addit {
  padding-bottom: .5em;
  padding-top: .5em;
  display: grid !important;
  grid-template-columns: 1fr 9fr;
  grid-gap: .5em;
  gap: .5em;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.places-list.addit {
  padding-bottom: .5em;
  padding-top: .5em;
  display: grid !important;
  grid-template-columns: 2fr 5fr;
  grid-gap: .5em;
  gap: .5em;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.places-list>div:nth-child(2)>div:first-child {
  font-weight: bold;
}

.bgb {
  background-color: rgb(229, 255, 209) !important;
}

.label-selects {
  display: flex !important;
  flex-direction: column;
  grid-row-gap: .25em;
  row-gap: .25em;
}

.label-selects>.label>.label:first-child {
  margin-right: .5em;
}

.label-selects>.label>.dropdown {
  margin-right: .5em;
}

.mu-pop>.item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.mu-pop>.item .icon {
  margin-right: .75em !important;
}

/* .create-season-container {
  width: 50%;
  margin: 1em auto;
} */

.create-season>.content>.vert-container>.content {
  width: 50%;
  margin: 1em auto;
}

.icon-text.nrr {
  text-decoration: underline;
}

.microphone-wrapper {
  text-align: right;
  padding: 0 .5em !important;
}

.client-status>.item>.content>.description:nth-child(3) {
  font-size: .8rem !important;
}

.client-status>.item>.content>.description .label {
  width: 100%;
}

.df-r1-c2 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .25em;
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c4 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: .25em;
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c5 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: .25em;
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c4:nth-child(4) {
  justify-self: right;
}

.df-r3-c2,
.df-r2-c2 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: .25em;
  gap: .25em;
}

.df-r3-c2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.df-r2-c2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.df-r3-c2 .label,
.df-r2-c2 .label {
  width: 100%;
  margin: 0;
}

.form>textarea {
  margin-bottom: 1em !important;
}

.app-user-meta>.meta {
  padding: .25em !important;
}

.app-user-meta>.meta>.icon {
  margin-right: .5em !important;
}

.image-mapping-list>.header {
  padding-top: 1rem;
}

.img-type-bordered {
  border: 2px solid rgb(73, 73, 73);
  border-radius: 8px;
}

.sfp {
  height: 100%;
}

.opt-icn {
  display: flex !important;
}

.opt-icn>.text {
  width: 100%;
}

.opt-icn>.text>.icon {
  float: right;
}

.seg-cal-full {
  height: 100%;
}

.full-web-page {
  width: 100vw !important;
  height: 100vh;
  background-color: grey;
}

.full-web-page .content .content {
  margin: auto;
}

.sign-up {
  text-align: center;
  margin-top: 2em;
}

.goo-location-list .icn {
  font-size: 4px;
}

.list-check>.item {
  align-content: center !important;
  align-items: center !important;
}

.list-check>.item>.checkbox {
  margin-right: 1em !important;
}

.list-cb-container>.list>.checkbox {
  margin-right: 1em;
}

.image-meta {
  padding: 1em;
  color: black;
  word-break: break-word;
}

.motion-div-container {
  height: 100%;
}

.card-icons>div {
  display: flex;
  justify-items: flex-end;
  width: 100%;
}

.popup-wrapper {
  height: 100% !important;
  display: grid;
  grid-template-rows: 1fr auto !important;
}

.popup-wrapper-icon {
  position: relative;
}

.margin-auto {
  margin: auto
}

.message-icon .list {
  float: right !important;
}

.seg-button {
  width: 100%;
  background-color: rgb(91, 91, 91) !important;
}

.seg-button.slt-clientProfiles {
  background-color: var(--sem-blue) !important;
}


.seg-button>input {
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 9999 !important;
}

.btn-space {
  display: flex;
  justify-content: space-between;
}

.sib>.button {
  margin-top: 1em;
}

.content.segment.placeholder>.form>.phone-container>.field,
.content.segment.placeholder>.form>.field {
  max-width: none;
  margin-right: none;
  margin-left: none;
}

.content.segment.placeholder>.button {
  margin-top: 1em;
}

.content.segment.placeholder>div {
  margin-top: 2em;
  text-align: center;
}

.content.segment.placeholder.fw {
  margin-top: 0;
}

.seg-header-label {
  display: flex;
  justify-content: space-between;
}

.seg-header-label>div:nth-child(1) {
  font-weight: bold;
}

.no-b-tlr input {
  border-left: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  border-width: 2px !important;
}

.aul-events {
  padding: 1em;
  display: flex;
  flex-direction: column;
  grid-gap: .5em;
  gap: .5em;
}

.aul-events .label {
  padding: 1em;
  font-weight: bold !important;
}

.cred-lbls {
  display: flex;
  padding: 1em;
  justify-content: center;
}

.ms-key {
  font-size: .7rem;
  margin-right: 1em;
}

.img-tn {
  border: 1px solid blue
}

.listUploader .image,
.listUploader .content:not(.extra)>.icon {
  width: 110px !important;
}

.list>.item>.label {
  margin-right: .5em;
}

.tab-note .segment {
  padding: 0 !important
}

.tab-note .segment .app-notification-group,
.tab-note .segment .app-notification-group>div {
  padding: 0 !important
}

.hdd.tv,
.hdd.tv .menu>div>span {
  font-size: .7rem;
}

.hdd.tv .menu>div {
  padding: .3em !important
}

.split-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow-y: hidden;
}

.split-list-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  overflow-y: hidden;
}

.split-list.three {
  grid-template-columns: 1fr 2fr;
}

.split-list.vert {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
}

.split-list.vert.equal {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.split-list-3 .segment,
.split-list .segment {
  margin: 0;
  height: 100%;
  overflow-y: auto;
}

/* .split-list .content:nth-child(2) {
  border-right: solid 1px rgb(201, 201, 201) !important;
} */

.split-list .vert-container>.header {
  text-align: center;
}

.split-list .vert-container {
  height: 100%;
  overflow-y: auto;
}

.split-list .list {
  padding: .5em
}

.split-list.q {
  grid-template-columns: 1fr 3fr;
}

.split-list.qr {
  grid-template-columns: 3fr 1fr;
}

.split-list.q .form {
  padding: 1em !important
}

.split-list.three .footer div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-self: baseline;
}

.dimmer-seg {
  height: 100% !important;
  width: 100% !important;
}

.dimmer-seg .button {
  margin-top: 2em !important;
}

.table-labels {
  display: flex;
  flex-direction: column;
  grid-gap: .2em;
  gap: .2em;
}

.table-labels>.label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.table-wrapper>.table>thead>tr>th,
.table-wrapper>.table>tbody>tr>td {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.tab-count {
  display: flex;
}

.prp-description {
  padding: 1em .5em;
  font-weight: normal;
  opacity: 1 !important;
}

.sidebar-fdv {
  padding: 0 !important;
  border: 0 !important;
}

.sidebar-fdv-settings {
  border: 0 !important;
  padding: 0 !important;
}

.sidebar-fdv-settings.normal {
  border: 0 !important;
}

.sidebar-fdv-settings.normal {
  padding: 0 !important
}

.sidebar-fdv>.segment,
.sidebar-fdv-settings>.segment {
  padding: 0 !important
}

.h100 {
  height: 100% !important;
}

.line-icon {
  display: flex;
  justify-content: flex-end;
}

.query-line {
  display: flex;
}

.query-line {
  font-size: .8rem
}

.query-line div {
  margin-right: .75em !important;
}

.header-flex {
  display: flex;
  justify-content: space-between;
}

.header-flex .selection {
  font-size: .8rem;
  margin-right: .5em;
}

.header-flex .selection .item>span {
  font-size: .8rem;
}

.header-flex>form,
.header-flex>.two>div {
  width: 50%;
}

.header-flex>div:nth-child(1) {
  justify-content: flex-start;
}

.header-flex>div:nth-child(2) {
  justify-content: flex-end;
}

.sat-preview {
  margin-top: 1em !important;
}

.btn-slim>.button.icon.labeled.mini {
  padding-left: .75em !important;
  padding-right: 3.25em !important;
}

.input.icon {
  margin-top: .5em
}

.list-button,
.list-button>.text,
.list-button .header,
.list-button>.menu,
.list-button>.menu>.item {
  font-weight: bold !important;
  padding: .35em auto !important;
  font-size: .78571429rem !important;
}

.PhoneInputInput {
  font-size: 400;
  padding: .67857143em 1em;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, .15)
}

.user-auths .label {
  float: right;
}

.schedule-grid {
  margin: 0 !important
}

.schedule-grid>.row {
  border-bottom: 1px solid rgb(204, 204, 204);
  padding: 0 !important
}

.schedule-grid>.row>.column {
  padding: .5em !important
}

.input.labeled>.label.label>.icon {
  margin: 0
}

.icons>.icon.corner {
  font-size: .5em !important;
}

.pending-wait .icon {
  font-size: 1em !important;
}

.form-golfers {
  /* padding: 0 !important; */
  height: 100%;
}

.dater {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  text-align: left;
}

.dater>div:first-child {
  font-weight: bold;
  font-size: 1.2em;
}

.dater>div:last-child {
  font-weight: bold;
}

.full-image>.content {
  margin: auto;
}

.sidebar-title {
  padding: 1em 1em 0 1em !important
}

.segment.is-html {
  border: 1px solid rgba(34, 36, 38, .15) !important;
  border-radius: .28571429rem !important;
  min-height: 50px;
  width: 100%;
  word-break: break-word;
}

.lbl-icon-right {
  margin: 0 0 0 .75em !important
}

.field-object-container {
  margin-bottom: 1em;
}

.confirmation-button {
  margin-bottom: 4px !important;
}

.reg-results {
  padding: .75em .25em !important
}

.bld {
  font-weight: bold;
}

.abc.so>.btn-confirm>.button {
  height: 3em;
  border-radius: 0 !important;
}

.abc.so {
  text-align: left !important;
  border-radius: 0 !important;
  height: 3em;
}

.action-sidebar>.item {
  padding: 1.5em !important;
  font-weight: bold !important;
  font-size: .9rem;
}

.dis-none {
  display: none !important;
}

.hodd {
  padding: 0 !important;
  float: right;
}

.ip-url {
  word-wrap: break-word;
  word-break: break-all;
}

.is-html {
  padding: .5em !important;
  /* color: white !important; */
}

.inverted .is-html {
  color: white !important;
}

.sec-content .is-html {
  color: black !important;
}

.is-html img {
  max-width: 100px !important;
  max-height: 100px !important;
}

.is-html ul {
  padding-left: 1.5em !important;
}

.icon.bordered {
  border-radius: 3px !important;
}

.info-container {
  font-size: small !important;
  margin: .5em 2em .5em .25em;
  padding: .15em
}

.info-container>div:nth-child(1) {
  font-weight: bold;
}

.json-container {
  height: 100% !important;
  font-size: .9rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  white-space: pre-wrap;
  scrollbar-width: thin;
}

.json-container.nfh {
  height: auto !important;
}

.menu.transition {
  min-width: 180px !important;
}

.menu.borderless {
  border-radius: 0 !important;
}

.react-json-view {
  height: 100% !important;
  padding: 1em;
}

.rc-input-number {
  margin-bottom: 1em !important;
}

.create-event-container {
  width: 50% !important;
  height: 100% !important;
  margin: 5em auto !important;
}


.paste-container {
  padding: .25em
}

.paste-content {
  height: 100%;
}

.paste-toggle {
  margin-bottom: 1em;
}

.ta-full {
  height: 100%;
  width: 100%;
  border: none;
  padding: .5em
}

.line-s {
  display: block;
}

.line-h {
  display: none !important;
}

.seg-no-padd {
  padding: 0 !important
}

.menu-slider {
  margin: .2em 0 .2em 0 !important
}

.menu-slider>.item {
  font-weight: bold !important;
  padding: .2em !important;
}

.menu-slider>.item>.icon {
  margin: 0 !important
}

.cal-select-container {
  margin: 0 0 .2em 0 !important
}

.cal-select-container>.item {
  font-weight: bold !important;
  padding: .2em !important
}

.cal-select-container>.item>.icon {
  margin: 0 !important
}

.create-content>textarea {
  height: 100%;
  border: .1px solid lightgrey
}

.create-content.json {
  padding: .5em;
}

*/ .create-content.json {
  padding: 1em;
}

.text-area-json {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  font-size: .8rem;
}

.qr-container {
  align-items: center !important;
}

.qr-container.solo {
  padding: 0 1em;
  text-align: right;
}

.list-overflow {
  height: 200px;
  overflow-y: auto;
}

.fav-header {
  font-weight: bold;
}

.fav-items {
  font-weight: bold;
}

.ph-full {
  height: 100%;
}

.menu-header-topper-simple,
.menu-header-topper {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  padding: .5em;
  align-items: center;
  font-weight: bold;
  line-height: normal;
}

.menu-header-topper>div:nth-child(1) {
  align-self: flex-start;
}

.menu-header-topper>div:nth-child(2)>div:nth-child(2) {
  font-size: .7rem;
  font-weight: normal;
}

.segment.placeholder {
  height: 100% !important;
}

.menu-header-alt>div:nth-child(1) {
  text-align: left;
}

.menu-header-topper>div:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

.menu-header-topper-simple {
  grid-template-columns: 100%;
  text-align: center;
  padding: 1em;
}

.menu-header-alt {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  padding: .5em;
  align-items: center;
}

.menu-header-alt>div:nth-child(1) {
  text-align: left;
}

.menu-header-alt>div:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

.menu-header-alt>div:nth-child(3) {
  text-align: right;
}

.seg-ph-full {
  height: 100%;
  text-align: center;
  font-weight: bold;
}

.h0 {
  height: auto !important;
}

.tar {
  text-align: right;
}

.mar0 {
  margin: 0 !important
}

.mar10 {
  margin: 1em !important;
}

.mar10 {
  margin: 1em !important;
}

.mar15 {
  margin: 1.5em !important;
}

.mar15 {
  margin: 1.5em !important;
}

.mar2 {
  margin: 2em !important;
}

.mar5 {
  margin: .5em !important;
}

.padd0 {
  padding: 0 !important
}

.padd10 {
  padding: 1em !important;
}

.padd10 {
  padding: 1em !important;
}

.padd15 {
  padding: 1.5em !important;
}

.padd15 {
  padding: 1.5em !important;
}

.padd2 {
  padding: 2em !important;
}

.padd5 {
  padding: .5em !important;
}

.no100 {
  height: auto !important;
}

.grid-text {
  padding: .1em
}

.grid-text-map {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  width: 100%;
}

.grid-text-map>.row {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.grid-text-map>.row>.column {
  text-align: center !important;
  padding-left: .1rem !important;
  padding-right: .1rem !important;
}

.grid-text-map>.row>.column>.field {
  margin: .25em !important;
  text-align: left;
}

.vert-container.sgn .grid>.column:nth-child(2) {
  font-weight: bold;
}

.vert-container.sgn .button>.icon.loading {
  background: none;
}

.seg-sign-in .button>.icon.loading {
  background: none !important;
}

.profile-select {
  border-left: 1px solid rgb(104, 104, 104) !important;
  border-right: 1px solid rgb(104, 104, 104) !important;
}

.profile-select.access {
  font-size: .8rem;
}

.profile-select.access .item {
  font-size: .8rem !important;
}

.profile-select.data-source.dropdown.scrolling.ui .menu {
  width: 280px !important;
}

.pusher-container {
  height: 100%;
}

body ::-webkit-scrollbar {
  width: 2px !important;
}

.full-data-viewer ::-webkit-scrollbar {
  width: 2px !important;
}

.item-content ::-webkit-scrollbar {
  width: 2px !important;
}

body ::-webkit-scrollbar {
  width: 2px !important;
}

.item-content {
  scrollbar-width: none !important;
}

.help-seg.doc mark {
  font-weight: bold;
}

.tar {
  text-align: right;
}

.inh {
  padding: 0 !important
}

/* Alt VS */
/* :not(.dark-mode) .vert-container > .header:not(.nob) {border-bottom: var(--thin-border-settings) !important;} */
:not(.dark-mode) .vert-container>.footer {
  border-top: var(--thin-border-settings) !important;
}

.nob {
  text-align: center;
  background-color: green !;
}

:not(.dark-mode) .vert-container>.footer.modify {
  text-align: right;
}

/* VERT CONTAINER */
.vert-container.basic>.header,
.vert-container.basic>.footer {
  padding: .5em;
  font-weight: normal;
}

.vert-container.paddedHeaderAndFooter>.header,
.vert-container.noContent>.header,
.vert-container.paddedHeader>.header,
.vert-container.paddedContent>.footer,
.vert-container.padded>.header {
  padding: 1em;
  font-weight: bold;
}

.vert-container.noContent>.content>.header {
  text-align: center;
  padding-bottom: 1em;
}

.vert-container.noContent>.content {
  padding: .5em;
  margin: auto;
}

.vert-container.padded>.content:not(.igw) {
  padding: .5em;
}

.vert-container.paddedHeaderAndFooter>.footer,
.vert-container.paddedHeader>.footer,
.vert-container.paddedContent>.footer,
.vert-container.padded>.footer {
  padding: 1em .25em;
}

.vert-container.paddedFooter>.footer {
  padding: 1em;
}

.vert-container.sgn>.header {
  padding: 0;
  font-weight: normal;
}

.vert-container.sgn>.footer {
  padding: .5em;
  font-weight: normal;
}

.vert-container.linking>.header {
  padding: .5em;
  font-weight: normal;
}

.vert-container.linking>.content {
  padding: 0;
}


.vert-container.splitLR {
  display: grid;
  grid-template-rows: 1fr !important;
  grid-template-columns: 1fr 4fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "header content" !important;
  background-color: black;
}

.vert-container.split.tbf {
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: 100% !important;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "top"
    "bottom"
    "footer"
}

.vert-container.splitHorz {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "left"
    "right"
}

.vert-container.splitVert {
  display: grid;
  grid-template-rows: 1fr 1fr !important;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "header"
    "content"
}

.vert-container.splitVert>.header {
  margin-bottom: .5em;
  overflow-y: auto;
  height: 100%;
}

.vert-container.split>.header {
  padding: .5em;
  font-weight: normal;
}

.vert-container.split>.top {
  padding: .5em;
}

.vert-container.split>.bottom {
  padding: .5em;
}

.vert-container.split>.footer {
  padding: .5em;
}

.vert-container.match-pending>.footer {
  text-align: right;
}

.vert-container.nf {
  height: auto !important;
}

.bulk-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 100% !important;
  width: 100%;
  height: 100%;
}

.bulk-container>div:first-child {
  overflow-y: auto;
  height: 300px;
}

.atb {
  display: flex;
  justify-content: space-between;
}

.map-address-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 3fr 1fr;
}

.map-address-container.full {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 3fr 1fr 1fr 1fr;
}

.svg-image {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
}

/* *********** */
.svg-wrapper {
  position: relative;
  display: inline-block;
  padding: .5em;
  /* <= shrinks container to image size */
  /* transition: transform 150ms ease-in-out; */
}

.svg-wrapper img {
  /* <= optional, for responsiveness */
  display: block;
  max-width: 100%;
  height: auto;
}

.svg-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-map,
.map-address-map,
.map-address-map>div,
.map-address-map>div>div {
  height: 100%;
}

.map-address-address {
  padding: 0 .5em;
}

.map-address-url {
  padding: 0 .5em;
  margin: auto;
}

.map-address-name {
  padding: 1em .5em .5em .5em;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.vert-container.pending {
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "content";
}

.vert-container.pending>.content {
  text-align: center;
  font-size: 1.5rem;
  padding: 2em;
  margin: auto 0 !important;
  height: auto !important;
}


.vert-container.pending>.header {
  padding: 1em;
  font-weight: bold;
  text-align: center;
}

.vert-container.segments,
.vert-container.segments>.segment {
  border: 0;
  border-radius: 0 !important;
}

/* .vert-container .grid > .column:last-child > .icon{float: right;} */
.vert-container.fip {
  padding-bottom: 2em;
}

.form-container .vert-container {
  height: auto;
}

.path div {
  word-wrap: break-word;
}

.ui.header>.grid .icon:only-child {
  margin: 0 !important;
  padding: 0
}

.killOf {
  overflow-y: hidden !important;
  -webkit-overflow-y: hidden !important;
}

.notification-forground {
  position: fixed !important;
  bottom: 5%;
  z-index: 1000;
  width: 100%;
}

.notification-forground.desktop {
  right: 1%;
  bottom: 1%;
  width: 20%;
}

.form.simput input {
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid red !important;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }

  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }

  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }

  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }

  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

@-webkit-keyframes glow {
  0% {
    color: #2ba805;
  }

  50% {
    color: #49e819;
  }

  100% {
    color: #2ba805;
  }
}

@keyframes glow {
  0% {
    color: #2ba805;
  }

  50% {
    color: #49e819;
  }

  100% {
    color: #2ba805;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-out {
  -webkit-animation-name: fade-out;
  animation-name: fade-out;
}

.fader {
  animation: fade-out ease 1s;
  -webkit-animation: fade-out ease 1s;
  -moz-animation: fade-out ease 1s;
  -o-animation: fade-out ease 1s;
  -ms-animation: fade-out ease 1s;
}

.start-icon {
  margin-left: .25em !important
}

.cbil-container {
  display: grid !important;
  grid-template-columns: 1fr 9fr;
}

.cbir-container {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

.cbir-confirm {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

.cbc-container {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr;
  ;
}

.file-split {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
}

.zone-split {
  display: grid !important;
  grid-template-columns: 3fr 1fr;
}

.zone-split .content {
  height: 100% !important;
  overflow-y: auto;
  ;
}

.zone-split .label {
  margin: .5em;
}

.cbc-container .button:nth-child(2),
.cbil-container .button:nth-child(2),
.cbir-container .button:nth-child(1) {
  text-align: left;
}

.cbc-container .button {
  margin-bottom: .5em !important;
  padding: 1em 0 !important
}

.topic-groups {
  padding: .5em
}

.header-button-select {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.header-button-select>div:nth-child(2) {
  text-align: center;
}

.split-container.vertical {
  display: grid;
  height: 100% !important;
  grid-template-columns: 90% 10%;
  grid-template-areas:
    "split-content split-menu";
}

.split-content {
  grid-area: split-content;
  height: 100% !important;
}

.split-content .item-container {
  height: 100% !important;
}

.split-menu {
  grid-area: split-menu;
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.split-menu .menu-alphabet {
  scrollbar-width: none;
  width: 100% !important;
}

.split-menu .menu-alphabet>.item {
  width: 100%;
  padding: .75em;
  text-align: center;
}

.split-container-half {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
}

.app-user-list .menu-alphabet {
  width: 100%;
  padding: .25em !important;
}

.table-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.table-split>div {
  padding: 1em;
  height: 100%;
  overflow-y: auto;
}

.table-split.three,
.table-split.upl {
  grid-template-columns: 2fr 1fr;
}

.table-split.upl>div:nth-child(2) {
  padding: 0em !important;
}

.table-split .card.sel {
  background-color: rgb(217, 217, 217);
}

.table-split.three .icon.user {
  font-size: 5em !important;
}

.row-split-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.row-split-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.row-split-2>div,
.row-split-3>div {
  padding: 0 1em;
}

.listUploader .table-split>.segment> {
  overflow-y: auto !important;
  height: 100%;
}

.listUploader .table-split>.segment> {
  overflow-y: auto !important;
  height: 100%;
}

/* .cbir-confirm .button:nth-child(1){text-align: left;} */
/* .btn-confirm {display: grid !important;  grid-template-columns: 1fr 8fr 1fr;  padding-bottom: .5em !important; margin-bottom: 8px !important}    */

.fresnel-greaterThan-mobile .swipe-tab::-webkit-scrollbar {
  background: rgb(92, 92, 92);
  height: 6px !important;
}

.fresnel-at-mobile .swipe-tab::-webkit-scrollbar {
  background: black;
  height: 0px !important;
}

.upc {
  text-transform: uppercase;
}

.item-info {
  position: absolute;
  top: .5em;
  right: .5em;
}

body {
  height: 100vh;
  -webkit-user-select: none;
  user-select: none;
}

.phone-auth>.mdl-card {
  box-shadow: none;
}

.phone-auth {
  font-family: inherit !important;
  font-size: 1rem !important;
}

.grecaptcha-badge {
  display: none;
}

#firebaseui-auth-container {
  padding: 0;
  height: 100%;
  width: 100%
}

.firebaseui-container {
  padding: 0;
  height: 100%;
  width: 100%
}

#firebaseui-auth-container div,
#firebaseui-auth-container input,
#firebaseui-auth-container h1 {
  font-size: 1rem !important;
}


.dddddddddd {
  position: relative;
}

.pppppppppp {
  position: relative;
}

.menu-app-settings {
  padding-top: .5em !important
}

.menu-app-settings>.item {
  padding: .75em !important;
  margin-left: .5em !important;
}

.menu-app-settings>.item>.header {
  margin: 0 !important;
}

.menu-app-settings>.item>.icon {
  float: left !important;
  margin-right: .75em !important;
}

.menu-app-settings.profile {
  height: 100%;
}

.menu-app-settings.profile>.item {
  margin: 0 !important;
}

.menu-app-settings.profile>.item>.header {
  padding: 0 !important;
}

.menu-app-settings.profile>.item>.icon {
  margin: 0 !important;
}

.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #473BF0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff !important;
  border-radius: 0;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  transition: all .3s ease-out 0s;
  border-radius: 5px;
}

.card-schedule>.content.extra:not(.other),
.card-schedule.plus>.content.extra:not(.other) {
  display: grid;
  grid-column-gap: .1em;
  -webkit-column-gap: .1em;
          column-gap: .1em;
  grid-row-gap: .5em;
  row-gap: .5em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "pi-startDate pi-endDate"
    "pi-startTime pi-endTime"
    "pi-locations pi-locations"
}

.card-schedule>.content.extra.grp.tz,
.card-schedule.plus>.content.extra.grp.tz {
  display: grid;
  grid-column-gap: .1em;
  -webkit-column-gap: .1em;
          column-gap: .1em;
  grid-row-gap: .5em;
  row-gap: .5em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "pi-startDate pi-location"
    "pi-times pi-times";
}

.card-schedule>.content.extra.other,
.card-schedule.plus>.content.extra.other {
  display: grid;
  grid-row-gap: .5em;
  row-gap: .5em;
  grid-template-columns: 1fr;
  grid-template-areas: none !important;
}

.card-schedule>.content.extra.other>.label {
  margin: 0 !important;
}


.card-schedule>.content.extra.card-link {
  grid-template-columns: 1fr;
  justify-items: end;
}

.card-schedule>.content.extra.card-link .check-toggle,
.card-schedule>.content.extra.card-link .check-toggle>label {
  font-size: .9rem !important;
}

.pi-time {
  display: flex !important;
}

/* .card-link .check-toggle>label::before {
  width: 2rem !important;
  height: 1rem !important;
}

.card-link .check-toggle>label::after {
  width: 1rem !important;
  height: 1rem !important;
  font-size: 10px;
  left: 0rem !important;
}

.card-link .check-toggle>label:checked::after {
  left: 1rem !important;
} */

.pi-startDate {
  grid-area: pi-startDate;
}

.pi-endDate {
  grid-area: pi-endDate;
}

.pi-times {
  grid-area: pi-times;
  margin: 0 !important;
}

.pi-startTime {
  grid-area: pi-startTime;
  display: grid !important;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr;
  grid-row-gap: 1em;
  row-gap: 1em;
  margin-left: 0 !important;
}

.tz>.pi-startTime {
  grid-template-rows: 1fr 1fr;
}

.pi-startTime>div {
  text-align: left;
}

.pi-startTime>.icon:nth-child(2) {
  margin-left: .5em;
}

.pi-endTime {
  grid-area: pi-endTime;
  display: grid !important;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr;
  grid-row-gap: 1em;
  row-gap: 1em;
  margin-right: 0 !important;
}

.tz>.pi-endTime {
  grid-template-rows: 1fr 1fr;
}

.pi-endTime-alt {
  grid-area: pi-endTime-alt;
}

.pi-location {
  grid-area: pi-location;
}

.pi-locations {
  grid-area: pi-locations;
}

.pi-times>.icon:first-child {
  margin-right: .5em !important;
}

.pi-times>.icon:last-child {
  margin-left: .5em !important;
}

.pi-times>.detail {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.flx-lr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.swiper-sub {
  overflow-y: hidden;
  height: 100%;
}

.swiper-sub>.react-swipeable-view-container {
  height: 100%;
}

.swiper-sub>.react-swipeable-view-container>div {
  height: 100%;
  overflow-y: auto;
}

/* .sec-container.hotelInfo>.sec-content {
  display: grid;
  background-color: red;
} */
:root {
  --allow-bgc: rgb(43, 43, 43);
  --almost-black-hov: rgb(32, 32, 32);
  --almost-black-item: rgb(27, 27, 27);
  --almost-black: rgb(14, 14, 14);
  --dark-adjust-brdc: rgb(83, 83, 83);
  --dark-adjust-dvdr-bgc: #1b1c1d;
  --dark-divider-div: #858585;
  --dark-inputs-bgc: rgb(41, 41, 41);
  --dark-inputs-c: rgb(233, 233, 233);
  --dark-label: rgb(182, 182, 182);
  --dark-thin-border-menu: rgb(112, 112, 112);
  --dark-thin-border-settings: solid 1px rgb(44, 44, 44);
  --menu-item-border: rgb(15, 15, 15);
  --menu-item-divider: #b1a500;
  --menu-item-divider-alt: #a5a5a5;
  --page-bgc: rgb(26, 26, 26);
  --pbc: rgba(47, 47, 47);
  --settings-menu-item-bc-restrict: rgb(122, 77, 77);
  --drop-content-darkish: rgb(37, 37, 37);
}


/* REMOVE */
.dt .app-settings-container .app-content {
  width: 100%;
  margin: 0 auto;
}

.app-settings-container>.grid>.column:nth-child(2) {
  padding: 0 !important
}

/* REMOVE - app_settings_menu */
/* app_settings_menu */
/* .app-settings-menu{
  position: fixed !important;
  z-index: 999;
  text-align: center;  
  width: 100%;
  top: .3em; 
  height: 0;
} 

.app-settings-menu > .buttons{
  border: 2px solid grey;
  border-radius: 6px;
} */

/* REMOVE - app-settings-grid */
.app-settings-grid {
  height: 100vh;
  margin: 0 !important;
}

.app-settings-grid>.column {
  background-color: var(--side-menu-bgc);
  padding: 0 !important
}

.app-settings-grid.dmo>.column {
  background-color: var(--side-menu-dmo-bgc);
  padding: 0 !important
}

.app-settings-grid>.column.app {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: white;
}

/* REMOVE - settings-app-container */
/* settings-app-container */
.settings-app-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "settings-app-content";
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  background-color: whitesmoke;
}

/* REMOVE - sbrc */
.sbrc {
  width: 100%;
  border-radius: 0 !important;
  overflow-x: hidden;
  scrollbar-width: none;
}

/* VERT-CONTAINER */
.vert-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.vert-container.cmq {
  height: auto;
}

.vert-container.segment {
  padding: 0
}

.vert-container.hcf {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.vert-container.ocf {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "notification"
    "content"
    "footer";
}

.vert-container.hnc {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header"
    "navigation"
    "content";
}

.vert-container.hcff {
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    "header"
    "content"
    "footer"
    "footer2";
}

.vert-container.htbf {
  grid-template-rows: auto 1fr 4fr auto;
  grid-template-areas:
    "header"
    "top"
    "bottom"
    "footer";
}

.vert-container.hc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
}

.vert-container.hoc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
}

.vert-container.oc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "notification"
    "content";
}

.vert-container.cf {
  grid-template-rows: 1fr auto;
  grid-template-areas: "content"
    "footer";
}

.vert-container.c {
  grid-template-rows: 1fr;
  grid-template-areas: "content";
}

.vert-container.hp {
  grid-template-rows: 2 auto;
  grid-template-areas:
    "header"
    "path";
}

.vert-container.hpc {
  grid-template-rows: 1fr 1fr auto;
  grid-template-areas:
    "header"
    "path"
    "content";
}

.vert-container.tc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "topper"
    "content";
}

.vert-container>.header {
  grid-area: header;
}

.vert-container>.notification {
  grid-area: notification;
  margin: 0 1em 0 1em;
  font-size: .9rem;
  position: fixed;
  top: 90px;
  z-index: 9999;
  width: 90%;
  line-height: 1.3rem;
}

.vert-container.sw-wrapper>.notification {
  margin-top: 2em;
}

.vert-container>.path {
  grid-area: path;
  padding: 1em;
}

.vert-container>.navigation {
  grid-area: navigation;
}

.vert-container>.top {
  grid-area: top;
  padding: 1em;
}

.vert-container>.content {
  grid-area: content;
}

.vert-container>.bottom {
  grid-area: bottom;
  padding: 1em;
}

.vert-container>.footer {
  grid-area: footer;
}

.vert-container>.footer2 {
  grid-area: footer2;
}

.vert-container.linking {
  padding-right: .5em;
  border-right: 1px solid rgb(239, 239, 239);
}

.vert-container.linking>.header>.divider {
  margin-top: 0 !important;
  font-size: .8rem;
  color: grey;
}

.vert-container.setz>.header,
.vert-container.setz>.content,
.vert-container.setz>.footer,
.vert-container.setz>.footer2 {
  background-color: var(--side-menu-bgc) !important;
}

/* HEADER */
.vert-container>.header>.menu:not(.menu-header):not(.menu-alphabet) {
  display: grid !important;
  grid-template-columns: 1fr 10fr 1fr !important;
}

/* .vert-container>.header>.menu>.item:last-child { */
.vert-container>.header>.menu:not(.menu-alphabet)>.item:first-child {
  padding-right: 0 !important
}

.vert-container>.header>.menu {
  border-radius: 0 !important;
}

.vert-container>.header>.menu .menu>.divider {
  background-color: black;
}

.vert-container>.header>.menu>.item>.search {
  padding: .5em !important;
}

.vert-container>.header>.menu>.item,
.vert-container>.header>.menu>.menu>.item {
  padding-top: .5em !important;
  padding-bottom: .5em !important;
  border-radius: 0;
  border: 0 !important
}

.vert-container>.header>.menu:not(.menu-header) {
  margin: 0 !important;
  padding: .25em 0;
  border-bottom: solid 1px rgb(44, 44, 44);
  border-bottom: var(--dark-thin-border-settings)
}

/* CONTENT */
.vert-container>.content {
  /* height: 100%;  */
  overflow-y: auto;
  scrollbar-width: thin;
}

.vert-container.full-data-viewer .content,
.vert-container.full-data-viewer .content>div {
  height: 100%;
  overflow-y: hidden;
  overflow-y: auto;
}

.zzzz {
  height: 100%;
  overflow-y: auto;
}

.vert-container.full-data-viewer .content>div>.menu {
  padding: 0 !important;
  margin: 0 !important;
}

.vert-container.full-data-viewer .content>div>.tab {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.vert-container.full-data-viewer .table-wrapper {
  position: relative;
  width: 100%;
  height: 93%;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.vert-container.full-data-viewer.sett .item-container .item-container {
  width: 30%;
  margin: .5em auto;
  height: 95%;
  border: 8px solid rgb(179, 177, 197);
  border-radius: 10px;
  overflow-y: auto;
}

.vert-container.full-data-viewer .table-wrapper>.table {
  position: absolute;
  max-height: 100% !important;
  width: 100% !important;
}

.vert-container.full-data-viewer>.dimmer>.content {
  width: 100% !important;
}

.vert-container.full-data-viewer>.dimmer>.content>.dimmer-seg {
  width: 100% !important;
}

.table-wrapper {
  height: 100%;
}

.table-wrapper .table {
  font-size: .8rem;
}

.vert-container>.header>.form {
  padding: .5em;
}

.vert-container>.content>.form {
  padding: 1em;
}

.push-content.np>.vert-container>.content>.form {
  padding: .5em;
}

/* CONTENT - MENU */
.vert-container>.content>.menu.vertical {
  background-color: inherit !important;
  padding: .5em 0;
}

.vert-container.upload>.content,
.vert-container.upload .uploader-container {
  padding: 0;
}

.vert-container.upload>.footer {
  padding: .5em;
}

.vert-container.upload .uploader-container {
  padding: 0;
}

.vert-container.ah {
  height: auto;
}

/* HEADERS */
.mis>.header,
.drop-group-content .header,
.vert-container .footer>.menu>.item>.header,
.vert-container .footer2>.menu>.item>.header,
.ct-vs .msmi>.item>.header {
  align-items: center;
  display: grid;
  font-size: 1.1rem !important;
  padding: .5em 1em !important;
}


.action-preview .menu>.item>.header {
  padding: .75em 1em !important;
}

.action-preview .header {
  color: rgb(206, 206, 206)
}

/* msmi = menu settings menu item */
.mis,
.msmi>.item {
  background-color: inherit !important;
  padding: 0 !important;
  width: 100%;
}

.mis>.header>.icon.mrk {
  opacity: 1;
}

.mis>.header>.icon.n-mrk {
  opacity: .5;
}

.msmi.two .item>.header {
  grid-template-columns: 1fr 9fr !important;
}

.msmi>.item>.header,
.msmi.three>.header,
.msmi.three .item>.header {
  grid-template-columns: 1fr 6fr 1fr !important;
}


.group-menu-header {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr !important;
  padding: .5em 1em !important;
  margin: 0 0 0.5em;
  align-items: center;
}

.group-menu-header>.icons {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.msmi.four .item>.header {
  grid-template-columns: 1fr 6fr 2fr 1fr !important;
}

.app .msmi.three .item>.header,
.app .msmi.four>.item>.header {
  padding: 1em !important;
}

.app .msmi.three .item>.header {
  grid-template-columns: 1fr 4fr 1fr !important;
}

.app .msmi.four .item>.header {
  grid-template-columns: 2fr 6fr 3fr 2fr !important;
}

.msmi.three>.item>.header {
  color: rgb(232, 232, 232);
}

.msmi.three>.item.alt>.header {
  color: rgb(188, 188, 188);
}

.mis>.header>.icon:last-child,
.msmi>.item>.header>.icon:last-child {
  justify-self: end;
}

.msmi>.item>.menu {
  margin: 0 !important;
  padding: .5em 0 1em 0 !important;
}

.msmi>.item>.menu .item,
.msmi>.item>.menu>.item,
.msmi>.item>.menu>.drop-list .item {
  font-size: 1rem !important;
  margin-top: .35em;
  padding: .5em 1.5em 1em 1.5em !important;
  color: rgb(255, 255, 255, .8) !important;
}

.app .msmi>.item>.menu>.item {
  padding: 1em 1.5em 1.25em 1.5em !important;
}

.msmi>.item>.menu>.divider {
  font-size: .8rem !important;
  margin: 0 !important;
  padding: .5em !important;
}

.inverted .mis,
.inverted .msmi>.item,
.inverted .msmi>.mi-group-item .item,
.inverted .msmi>.mi-group-item>.item,
.inverted .msmi>.drop-list .item {
  background-color: rgb(14, 14, 14) !important;
  background-color: var(--almost-black) !important;
}

.adm.menu .mi-group-item {
  background-color: rgb(40, 40, 40);
}

.mi-group-item .icon.external.alternate {
  color: var(--sem-orange) !important;
}

.msmi>.content>.divider.horizontal {
  color: #b1a500;
  color: var(--menu-item-divider);
  font-size: .8rem !important;
}

.msmi>.content>.divider.horizontal.alt {
  color: red !important;
}

.msmi.app-dark-mode>.item>.menu>.item {
  border-left: 4px solid rgb(112, 0, 0) !important;
}

.msmi.app-dark-mode>.item>.header {
  border-top: 1px solid rgb(112, 0, 0);
}

.vert-container>.header>.pbc {
  background-color: rgba(47, 47, 47);
  background-color: var(--pbc);
}

.dis-none {
  display: none !important;
}

.settings-breadcrumb {
  padding: .25em 1em;
  margin-top: 4px !important;
  margin-bottom: 9px !important;
  font-size: .8rem !important;
}

.settings-breadcrumb>.section {
  margin-top: .5em !important;
  color: var(--off-white) !important
}

.dark-mode .settings-breadcrumb>.divider {
  color: #858585 !important;
  color: var(--dark-divider-div) !important
}

.smii {
  text-align: right;
}

.smii.tt {
  display: flex;
  justify-content: space-between;
  background-color: rgb(43, 43, 43);
  background-color: var(--allow-bgc);
  padding: .5em;
}

/* COLORS */
.dark-mode .settings-menu-admin {
  border-top: solid 1px rgb(44, 44, 44) !important;
  border-top: var(--dark-thin-border-settings) !important;
}

.dark-mode .vert-container>.header {
  border-bottom: solid 1px rgb(44, 44, 44) !important;
  border-bottom: var(--dark-thin-border-settings) !important;
}

.dark-mode .vert-container>.footer {
  border-top: solid 1px rgb(44, 44, 44) !important;
  border-top: var(--dark-thin-border-settings) !important;
}

.dark-mode .vert-container>.header>.menu {
  background-color: black;
}

.msmi:not(.app-dark-mode) .mi-group-item {
  border-bottom: 8px solid rgb(15, 15, 15);
  border-bottom: 8px solid var(--menu-item-border);
}

.msmi.app-dark-mode .mi-group-item {
  border-top: 8px solid rgb(15, 15, 15);
  border-top: 8px solid var(--menu-item-border);
}

.ct-vs,
.ct-vs.settings {
  background-color: var(--side-menu-bgc) !important;
}

.ct-vs>.menu {
  background-color: inherit !important;
}

.ct-vs>.menu>.item,
.ct-vs>.menu>.mi-group-item .item,
.ct-vs>.menu>.mi-group-item>.item,
.ct-vs>.menu>.drop-list .item {
  background-color: rgb(14, 14, 14) !important;
  background-color: var(--almost-black) !important;
}

.dropdown.icon .divider:not(.text) {
  background-color: var(--off-white);
}

.ct-vs .divider.horizontal {
  color: #b1a500;
  color: var(--menu-item-divider);
  font-size: .8rem !important;
}

.ct-vs .divider.horizontal.alt {
  color: #a5a5a5;
  color: var(--menu-item-divider-alt);
}

.mi-group-item {
  background-color: var(--side-menu-bgc);
}

/* HOVERS */
.dark-mode .data-list-container>.data-list-list>.item:hover {
  background-color: rgb(32, 32, 32);
  background-color: var(--almost-black-hov);
}

.dark-mode .ct-vs>.menu>.item.smi:not(.restrict) .header:not(.hddh):hover {
  background-color: rgb(32, 32, 32);
  background-color: var(--almost-black-hov);
}

.dark-mode .msmi:not(.app-dark-mode) .item:not(.restrict) .header:not(.hddh):hover {
  background-color: rgb(32, 32, 32) !important;
  background-color: var(--almost-black-hov) !important;
}

.dark-mode .help-accordion>.title:hover {
  background-color: rgb(32, 32, 32) !important;
  background-color: var(--almost-black-hov) !important;
}

/* OTHER GROUPS */
.color-content {
  margin-bottom: 1em
}

.lbl-color {
  padding-left: 0 !important;
  border: 1px solid rgb(204, 204, 204) !important
}

.lbl-color>.detail {
  background-color: white !important;
  color: black !important;
  padding: .5em;
  margin-left: 0.5em !important;
}

/* .view-settings-container.segment{padding: 0 !important; }  */
.profile-select {
  min-width: 180px;
}

.hodd {
  padding: 0 !important;
  float: right;
  background-color: inherit !important;
}

input[readOnly] {
  background-color: rgb(209, 209, 209) !important;
}

/* DARK MODE */
.dark-mode .sbrc .menu-row-container .dropdown,
.dark-mode .sbrc .menu-row-container .dropdown .menu,
.dark-mode .sbrc .menu-row-container .dropdown .menu>div {
  border-color: rgb(32, 32, 32);
  border-color: var(--almost-black-hov);
}

.dark-mode .sbrc .menu-row-container .dropdown,
.dark-mode .sbrc .menu-row-container .dropdown .menu,
.dark-mode .sbrc .wrap-header-mini,
.dark-mode .sbrc .tab-menu,
.dark-mode .sbrc .tab-menu .item,
.dark-mode .sbrc .inverted textarea,
.dark-mode .sbrc .inverted .input.readOnly input,
.dark-mode .sbrc .inverted .input.icon input,
.dark-mode .sbrc .inverted .input input,
.dark-mode .sbrc .inverted .dropdown:not(.hodd),
.dark-mode .sbrc .vert-container .header .card,
.dark-mode .sbrc .vert-container .header.card .content,
.dark-mode .sbrc .vert-container .header .card .content div,
.dark-mode .sbrc .table thead th,
.dark-mode .sbrc .table tr td,
.dark-mode .sbrc .text-area-json,
.dark-mode .sbrc .images-content,
.dark-mode .sbrc .inverted .menu.transition .header,
.dark-mode .sbrc .inverted .menu.transition .item span,
.dark-mode .sbrc .inverted .menu-select-items>.menu,
.dark-mode .sbrc .inverted .menu-select-items .item,
.dark-mode .sbrc .dropdown.selection>.label,
.dark-mode .sbrc .delete-header>.label,
.dark-mode .sbrc .inverted .dropdown .menu,
.dark-mode input {
  background-color: rgb(41, 41, 41) !important;
  background-color: var(--dark-inputs-bgc) !important;
  color: rgb(233, 233, 233) !important;
  color: var(--dark-inputs-c) !important
}

.dark-mode .is-html .dark-mode .sbrc .form>.field>label {
  color: rgb(233, 233, 233) !important;
  color: var(--dark-inputs-c) !important
}

.dark-mode .sbrc .form-container {
  background-color: rgb(14, 14, 14);
  background-color: var(--almost-black);
}

.dark-mode .sbrc .inverted .ps-pager .item {
  background-color: rgb(41, 41, 41) !important;
  background-color: var(--dark-inputs-bgc) !important;
}

.dark-mode .sbrc .inverted .ps-pager .item {
  color: rgb(182, 182, 182) !important;
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .inverted .ps-pager .item.active {
  font-weight: bold;
  color: white !important;
  border: 0 !important;
  border-radius: 0;
}

.dark-mode .inverted label,
/* .dark-mode .inverted > .icon, */
.dark-mode .sbrc .menu-row-container .dropdown .menu .item,
.dark-mode .sbrc .inverted label:not(.file-label),
.dark-mode .sbrc .inverted .field label {
  color: rgb(182, 182, 182) !important;
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .ql-toolbar,
.dark-mode .sbrc .ql-formats>button,
.dark-mode .sbrc .ql-formats>span>span {
  background-color: rgb(182, 182, 182) !important;
  background-color: var(--dark-label) !important
}

.dark-mode .sbrc .lbl-color,
.dark-mode .sbrc .inverted .dropdown .menu>.item {
  color: rgb(182, 182, 182) !important;
  color: var(--dark-label) !important;
  border-color: rgb(83, 83, 83) !important;
  border-color: var(--dark-adjust-brdc) !important;
}

.dark-mode .sbrc .inverted .dropdown.selection.active .text {
  color: rgb(182, 182, 182) !important;
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .inverted .form-container .divider.horizontal {
  background-color: #1b1c1d !important;
  background-color: var(--dark-adjust-dvdr-bgc) !important;
  color: var(--dark-divider-c) !important;
  font-size: .8rem;
}

.dark-mode .sbrc .inverted .action-container .divider.horizontal {
  color: var(--dark-adjust-d-c) !important;
  font-size: .8rem;
  padding: 1em
}

.dark-mode .sbrc .images-content,
.dark-mode .sbrc .inverted .ui.active.selection.dropdown,
.dark-mode .sbrc .inverted .ui.selection.active.dropdown:hover,
.dark-mode .sbrc .inverted .ui.active.selection.dropdown:focus,
.dark-mode .sbrc .inverted .ui.selection.active.dropdown .menu {
  border-color: rgb(83, 83, 83) !important;
  border-color: var(--dark-adjust-brdc) !important;
}

.dark-mode .sbrc .dropdown>.label {
  color: var(--dark-active-selection-c) !important;
}

.dark-mode .sbrc .inverted .card.inline-container {
  box-shadow: 0 1px 3px 0 rgb(83, 83, 83), 0 0 0 1px rgb(83, 83, 83);
  box-shadow: 0 1px 3px 0 var(--dark-adjust-brdc), 0 0 0 1px var(--dark-adjust-brdc);
}

.dark-mode .sbrc .inverted .card.inline-container>.content,
.dark-mode .sbrc .inverted .card.inline-container {
  background-color: #1b1c1d !important;
  background-color: var(--dark-adjust-dvdr-bgc) !important;
  box-shadow: 0 1px 3px 0 rgb(83, 83, 83), 0 0 0 1px rgb(83, 83, 83);
  box-shadow: 0 1px 3px 0 var(--dark-adjust-brdc), 0 0 0 1px var(--dark-adjust-brdc);
}

.dark-mode .dd-container,
.dark-mode .drop-content,
.dark-mode .drop-group-container,
.dark-mode .drop-group-content,
.dark-mode .drop-group-header {
  background-color: var(--drop-content-dark);
  color: white;
}

.drop-group-container.settingsGroups .item>.header>div>div:nth-child(1) {
  font-size: .7rem;
  background-color: rgb(37, 37, 37) !important;
  background-color: var(--drop-content-darkish) !important;
}

.dark-mode .form-container .form>.icon:not(.paste):not(.copy) {
  color: rgb(182, 182, 182) !important;
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .inverted .card.inline-container .content .header {
  color: white;
}

.dark-mode .msmi .item.restrict>.header:first-child {
  color: rgb(122, 77, 77) !important;
  color: var(--settings-menu-item-bc-restrict) !important
}

.dark-mode .vert-container.preview .header {
  color: rgb(182, 182, 182) !important;
  color: var(--dark-label) !important
}

.sgn {
  border: 1px solid rgb(83, 83, 83);
  border: 1px solid var(--dark-adjust-brdc);
  padding: .5em
}

.help-seg .message {
  background-color: black !important;
}

.menu-help {
  font-size: .8rem;
  padding: 1em 0 0 1em;
  font-weight: normal;
  color: rgb(197, 197, 197)
}
:root {
  --main-bgc: #0081c6;
  --main-c: white;

  --secondary-bgc: #193e6b;
  --secondary-c: white;

  --me-background-bgc: #dbd9d9ca;
  --me-background-bgc-sp: #fcfcfcca;
  --background-c: black;

  --alternative-bgc: #2db099;
  --alternative-c: black;

  --active-bgc: #2db099;
  --active-c: black;

  --settings-bgc: #004062;
  --settings-c: white;

  --settings-seg-bgc: #006aa3;
  --settings-seg-c: white;

  --settings-dead-bgc: #c0cbd1;
  --settings-dead-c: black;

}

.bc-main {
  background-color: #0081c6 !important;
  background-color: var(--main-bgc) !important;
  color: white !important;
  color: var(--main-c) !important;
}

.bc-sec {
  background-color: #193e6b !important;
  background-color: var(--secondary-bgc) !important;
  color: white !important;
  color: var(--secondary-c) !important;
}

.bc-bk {
  background-color: #dbd9d9ca;
  background-color: var(--me-background-bgc);
}

.app-container .bc-bk {
  background-color: #dbd9d9ca;
  background-color: var(--me-background-bgc);
}

.bc-alt {
  background-color: #2db099 !important;
  background-color: var(--alternative-bgc) !important;
  color: black !important;
  color: var(--alternative-c) !important;
}

.bc-act {
  background-color: #2db099 !important;
  background-color: var(--active-bgc) !important;
  color: black !important;
  color: var(--active-c) !important;
}

.bc-settings {
  background-color: #004062 !important;
  background-color: var(--settings-bgc) !important;
  color: white !important;
  color: var(--settings-c) !important;
}

.bc-settings.dead {
  background-color: #c0cbd1 !important;
  background-color: var(--settings-dead-bgc) !important;
  color: black !important;
  color: var(--settings-dead-c) !important;
}

.menu-signin {
  padding: 1.5em !important;
  font-weight: bold !important;
  border-bottom: 1px solid lightgray;
}

.menu-signin-confirm {
  border-radius: 8px;
  margin: 8px 4px;
  text-align: center;
}

.menu-signin-confirm.cancel {
  border: 2px solid white !important;
  background-color: red !important;
}

.menu-signin-confirm.confirm {
  border: 2px solid white !important;
  background-color: green !important;
}

.hlgt,
.hlgt>div {
  -webkit-animation-name: flash-animation;
  -webkit-animation-duration: 0.3s;
  animation-name: flash-animation;
  animation-duration: 0.3s;
}

@-webkit-keyframes flash-animation {
  from {
    background: hotpink;
  }

  to {
    background: default;
  }
}

@keyframes flash-animation {
  from {
    background: hotpink;
  }

  to {
    background: default;
  }
}
