:root {
  --allow-bgc: rgb(43, 43, 43);
  --almost-black-hov: rgb(32, 32, 32);
  --almost-black-item: rgb(27, 27, 27);
  --almost-black: rgb(14, 14, 14);
  --dark-adjust-brdc: rgb(83, 83, 83);
  --dark-adjust-dvdr-bgc: #1b1c1d;
  --dark-divider-div: #858585;
  --dark-inputs-bgc: rgb(41, 41, 41);
  --dark-inputs-c: rgb(233, 233, 233);
  --dark-label: rgb(182, 182, 182);
  --dark-thin-border-menu: rgb(112, 112, 112);
  --dark-thin-border-settings: solid 1px rgb(44, 44, 44);
  --menu-item-border: rgb(15, 15, 15);
  --menu-item-divider: #b1a500;
  --menu-item-divider-alt: #a5a5a5;
  --page-bgc: rgb(26, 26, 26);
  --pbc: rgba(47, 47, 47);
  --settings-menu-item-bc-restrict: rgb(122, 77, 77);
  --drop-content-darkish: rgb(37, 37, 37);
}


/* REMOVE */
.dt .app-settings-container .app-content {
  width: 100%;
  margin: 0 auto;
}

.app-settings-container>.grid>.column:nth-child(2) {
  padding: 0 !important
}

/* REMOVE - app_settings_menu */
/* app_settings_menu */
/* .app-settings-menu{
  position: fixed !important;
  z-index: 999;
  text-align: center;  
  width: 100%;
  top: .3em; 
  height: 0;
} 

.app-settings-menu > .buttons{
  border: 2px solid grey;
  border-radius: 6px;
} */

/* REMOVE - app-settings-grid */
.app-settings-grid {
  height: 100vh;
  margin: 0 !important;
}

.app-settings-grid>.column {
  background-color: var(--side-menu-bgc);
  padding: 0 !important
}

.app-settings-grid.dmo>.column {
  background-color: var(--side-menu-dmo-bgc);
  padding: 0 !important
}

.app-settings-grid>.column.app {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: white;
}

/* REMOVE - settings-app-container */
/* settings-app-container */
.settings-app-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "settings-app-content";
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  background-color: whitesmoke;
}

/* REMOVE - sbrc */
.sbrc {
  width: 100%;
  border-radius: 0 !important;
  overflow-x: hidden;
  scrollbar-width: none;
}

/* VERT-CONTAINER */
.vert-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.vert-container.cmq {
  height: auto;
}

.vert-container.segment {
  padding: 0
}

.vert-container.hcf {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.vert-container.ocf {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "notification"
    "content"
    "footer";
}

.vert-container.hnc {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header"
    "navigation"
    "content";
}

.vert-container.hcff {
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    "header"
    "content"
    "footer"
    "footer2";
}

.vert-container.htbf {
  grid-template-rows: auto 1fr 4fr auto;
  grid-template-areas:
    "header"
    "top"
    "bottom"
    "footer";
}

.vert-container.hc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
}

.vert-container.hoc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
}

.vert-container.oc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "notification"
    "content";
}

.vert-container.cf {
  grid-template-rows: 1fr auto;
  grid-template-areas: "content"
    "footer";
}

.vert-container.c {
  grid-template-rows: 1fr;
  grid-template-areas: "content";
}

.vert-container.hp {
  grid-template-rows: 2 auto;
  grid-template-areas:
    "header"
    "path";
}

.vert-container.hpc {
  grid-template-rows: 1fr 1fr auto;
  grid-template-areas:
    "header"
    "path"
    "content";
}

.vert-container.tc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "topper"
    "content";
}

.vert-container>.header {
  grid-area: header;
}

.vert-container>.notification {
  grid-area: notification;
  margin: 0 1em 0 1em;
  font-size: .9rem;
  position: fixed;
  top: 90px;
  z-index: 9999;
  width: 90%;
  line-height: 1.3rem;
}

.vert-container.sw-wrapper>.notification {
  margin-top: 2em;
}

.vert-container>.path {
  grid-area: path;
  padding: 1em;
}

.vert-container>.navigation {
  grid-area: navigation;
}

.vert-container>.top {
  grid-area: top;
  padding: 1em;
}

.vert-container>.content {
  grid-area: content;
}

.vert-container>.bottom {
  grid-area: bottom;
  padding: 1em;
}

.vert-container>.footer {
  grid-area: footer;
}

.vert-container>.footer2 {
  grid-area: footer2;
}

.vert-container.linking {
  padding-right: .5em;
  border-right: 1px solid rgb(239, 239, 239);
}

.vert-container.linking>.header>.divider {
  margin-top: 0 !important;
  font-size: .8rem;
  color: grey;
}

.vert-container.setz>.header,
.vert-container.setz>.content,
.vert-container.setz>.footer,
.vert-container.setz>.footer2 {
  background-color: var(--side-menu-bgc) !important;
}

/* HEADER */
.vert-container>.header>.menu:not(.menu-header):not(.menu-alphabet) {
  display: grid !important;
  grid-template-columns: 1fr 10fr 1fr !important;
}

/* .vert-container>.header>.menu>.item:last-child { */
.vert-container>.header>.menu:not(.menu-alphabet)>.item:first-child {
  padding-right: 0 !important
}

.vert-container>.header>.menu {
  border-radius: 0 !important;
}

.vert-container>.header>.menu .menu>.divider {
  background-color: black;
}

.vert-container>.header>.menu>.item>.search {
  padding: .5em !important;
}

.vert-container>.header>.menu>.item,
.vert-container>.header>.menu>.menu>.item {
  padding-top: .5em !important;
  padding-bottom: .5em !important;
  border-radius: 0;
  border: 0 !important
}

.vert-container>.header>.menu:not(.menu-header) {
  margin: 0 !important;
  padding: .25em 0;
  border-bottom: var(--dark-thin-border-settings)
}

/* CONTENT */
.vert-container>.content {
  /* height: 100%;  */
  overflow-y: auto;
  scrollbar-width: thin;
}

.vert-container.full-data-viewer .content,
.vert-container.full-data-viewer .content>div {
  height: 100%;
  overflow-y: hidden;
  overflow-y: auto;
}

.zzzz {
  height: 100%;
  overflow-y: auto;
}

.vert-container.full-data-viewer .content>div>.menu {
  padding: 0 !important;
  margin: 0 !important;
}

.vert-container.full-data-viewer .content>div>.tab {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.vert-container.full-data-viewer .table-wrapper {
  position: relative;
  width: 100%;
  height: 93%;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.vert-container.full-data-viewer.sett .item-container .item-container {
  width: 30%;
  margin: .5em auto;
  height: 95%;
  border: 8px solid rgb(179, 177, 197);
  border-radius: 10px;
  overflow-y: auto;
}

.vert-container.full-data-viewer .table-wrapper>.table {
  position: absolute;
  max-height: 100% !important;
  width: 100% !important;
}

.vert-container.full-data-viewer>.dimmer>.content {
  width: 100% !important;
}

.vert-container.full-data-viewer>.dimmer>.content>.dimmer-seg {
  width: 100% !important;
}

.table-wrapper {
  height: 100%;
}

.table-wrapper .table {
  font-size: .8rem;
}

.vert-container>.header>.form {
  padding: .5em;
}

.vert-container>.content>.form {
  padding: 1em;
}

.push-content.np>.vert-container>.content>.form {
  padding: .5em;
}

/* CONTENT - MENU */
.vert-container>.content>.menu.vertical {
  background-color: inherit !important;
  padding: .5em 0;
}

.vert-container.upload>.content,
.vert-container.upload .uploader-container {
  padding: 0;
}

.vert-container.upload>.footer {
  padding: .5em;
}

.vert-container.upload .uploader-container {
  padding: 0;
}

.vert-container.ah {
  height: auto;
}

/* HEADERS */
.mis>.header,
.drop-group-content .header,
.vert-container .footer>.menu>.item>.header,
.vert-container .footer2>.menu>.item>.header,
.ct-vs .msmi>.item>.header {
  align-items: center;
  display: grid;
  font-size: 1.1rem !important;
  padding: .5em 1em !important;
}


.action-preview .menu>.item>.header {
  padding: .75em 1em !important;
}

.action-preview .header {
  color: rgb(206, 206, 206)
}

/* msmi = menu settings menu item */
.mis,
.msmi>.item {
  background-color: inherit !important;
  padding: 0 !important;
  width: 100%;
}

.mis>.header>.icon.mrk {
  opacity: 1;
}

.mis>.header>.icon.n-mrk {
  opacity: .5;
}

.msmi.two .item>.header {
  grid-template-columns: 1fr 9fr !important;
}

.msmi>.item>.header,
.msmi.three>.header,
.msmi.three .item>.header {
  grid-template-columns: 1fr 6fr 1fr !important;
}


.group-menu-header {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr !important;
  padding: .5em 1em !important;
  margin: 0 0 0.5em;
  align-items: center;
}

.group-menu-header>.icons {
  width: fit-content;
}

.msmi.four .item>.header {
  grid-template-columns: 1fr 6fr 2fr 1fr !important;
}

.app .msmi.three .item>.header,
.app .msmi.four>.item>.header {
  padding: 1em !important;
}

.app .msmi.three .item>.header {
  grid-template-columns: 1fr 4fr 1fr !important;
}

.app .msmi.four .item>.header {
  grid-template-columns: 2fr 6fr 3fr 2fr !important;
}

.msmi.three>.item>.header {
  color: rgb(232, 232, 232);
}

.msmi.three>.item.alt>.header {
  color: rgb(188, 188, 188);
}

.mis>.header>.icon:last-child,
.msmi>.item>.header>.icon:last-child {
  justify-self: end;
}

.msmi>.item>.menu {
  margin: 0 !important;
  padding: .5em 0 1em 0 !important;
}

.msmi>.item>.menu .item,
.msmi>.item>.menu>.item,
.msmi>.item>.menu>.drop-list .item {
  font-size: 1rem !important;
  margin-top: .35em;
  padding: .5em 1.5em 1em 1.5em !important;
  color: rgb(255, 255, 255, .8) !important;
}

.app .msmi>.item>.menu>.item {
  padding: 1em 1.5em 1.25em 1.5em !important;
}

.msmi>.item>.menu>.divider {
  font-size: .8rem !important;
  margin: 0 !important;
  padding: .5em !important;
}

.inverted .mis,
.inverted .msmi>.item,
.inverted .msmi>.mi-group-item .item,
.inverted .msmi>.mi-group-item>.item,
.inverted .msmi>.drop-list .item {
  background-color: var(--almost-black) !important;
}

.adm.menu .mi-group-item {
  background-color: rgb(40, 40, 40);
}

.mi-group-item .icon.external.alternate {
  color: var(--sem-orange) !important;
}

.msmi>.content>.divider.horizontal {
  color: var(--menu-item-divider);
  font-size: .8rem !important;
}

.msmi>.content>.divider.horizontal.alt {
  color: red !important;
}

.msmi.app-dark-mode>.item>.menu>.item {
  border-left: 4px solid rgb(112, 0, 0) !important;
}

.msmi.app-dark-mode>.item>.header {
  border-top: 1px solid rgb(112, 0, 0);
}

.vert-container>.header>.pbc {
  background-color: var(--pbc);
}

.dis-none {
  display: none !important;
}

.settings-breadcrumb {
  padding: .25em 1em;
  margin-top: 4px !important;
  margin-bottom: 9px !important;
  font-size: .8rem !important;
}

.settings-breadcrumb>.section {
  margin-top: .5em !important;
  color: var(--off-white) !important
}

.dark-mode .settings-breadcrumb>.divider {
  color: var(--dark-divider-div) !important
}

.smii {
  text-align: right;
}

.smii.tt {
  display: flex;
  justify-content: space-between;
  background-color: var(--allow-bgc);
  padding: .5em;
}

/* COLORS */
.dark-mode .settings-menu-admin {
  border-top: var(--dark-thin-border-settings) !important;
}

.dark-mode .vert-container>.header {
  border-bottom: var(--dark-thin-border-settings) !important;
}

.dark-mode .vert-container>.footer {
  border-top: var(--dark-thin-border-settings) !important;
}

.dark-mode .vert-container>.header>.menu {
  background-color: black;
}

.msmi:not(.app-dark-mode) .mi-group-item {
  border-bottom: 8px solid var(--menu-item-border);
}

.msmi.app-dark-mode .mi-group-item {
  border-top: 8px solid var(--menu-item-border);
}

.ct-vs,
.ct-vs.settings {
  background-color: var(--side-menu-bgc) !important;
}

.ct-vs>.menu {
  background-color: inherit !important;
}

.ct-vs>.menu>.item,
.ct-vs>.menu>.mi-group-item .item,
.ct-vs>.menu>.mi-group-item>.item,
.ct-vs>.menu>.drop-list .item {
  background-color: var(--almost-black) !important;
}

.dropdown.icon .divider:not(.text) {
  background-color: var(--off-white);
}

.ct-vs .divider.horizontal {
  color: var(--menu-item-divider);
  font-size: .8rem !important;
}

.ct-vs .divider.horizontal.alt {
  color: var(--menu-item-divider-alt);
}

.mi-group-item {
  background-color: var(--side-menu-bgc);
}

/* HOVERS */
.dark-mode .data-list-container>.data-list-list>.item:hover {
  background-color: var(--almost-black-hov);
}

.dark-mode .ct-vs>.menu>.item.smi:not(.restrict) .header:not(.hddh):hover {
  background-color: var(--almost-black-hov);
}

.dark-mode .msmi:not(.app-dark-mode) .item:not(.restrict) .header:not(.hddh):hover {
  background-color: var(--almost-black-hov) !important;
}

.dark-mode .help-accordion>.title:hover {
  background-color: var(--almost-black-hov) !important;
}

/* OTHER GROUPS */
.color-content {
  margin-bottom: 1em
}

.lbl-color {
  padding-left: 0 !important;
  border: 1px solid rgb(204, 204, 204) !important
}

.lbl-color>.detail {
  background-color: white !important;
  color: black !important;
  padding: .5em;
  margin-left: 0.5em !important;
}

/* .view-settings-container.segment{padding: 0 !important; }  */
.profile-select {
  min-width: 180px;
}

.hodd {
  padding: 0 !important;
  float: right;
  background-color: inherit !important;
}

input[readOnly] {
  background-color: rgb(209, 209, 209) !important;
}

/* DARK MODE */
.dark-mode .sbrc .menu-row-container .dropdown,
.dark-mode .sbrc .menu-row-container .dropdown .menu,
.dark-mode .sbrc .menu-row-container .dropdown .menu>div {
  border-color: var(--almost-black-hov);
}

.dark-mode .sbrc .menu-row-container .dropdown,
.dark-mode .sbrc .menu-row-container .dropdown .menu,
.dark-mode .sbrc .wrap-header-mini,
.dark-mode .sbrc .tab-menu,
.dark-mode .sbrc .tab-menu .item,
.dark-mode .sbrc .inverted textarea,
.dark-mode .sbrc .inverted .input.readOnly input,
.dark-mode .sbrc .inverted .input.icon input,
.dark-mode .sbrc .inverted .input input,
.dark-mode .sbrc .inverted .dropdown:not(.hodd),
.dark-mode .sbrc .vert-container .header .card,
.dark-mode .sbrc .vert-container .header.card .content,
.dark-mode .sbrc .vert-container .header .card .content div,
.dark-mode .sbrc .table thead th,
.dark-mode .sbrc .table tr td,
.dark-mode .sbrc .text-area-json,
.dark-mode .sbrc .images-content,
.dark-mode .sbrc .inverted .menu.transition .header,
.dark-mode .sbrc .inverted .menu.transition .item span,
.dark-mode .sbrc .inverted .menu-select-items>.menu,
.dark-mode .sbrc .inverted .menu-select-items .item,
.dark-mode .sbrc .dropdown.selection>.label,
.dark-mode .sbrc .delete-header>.label,
.dark-mode .sbrc .inverted .dropdown .menu,
.dark-mode input {
  background-color: var(--dark-inputs-bgc) !important;
  color: var(--dark-inputs-c) !important
}

.dark-mode .is-html .dark-mode .sbrc .form>.field>label {
  color: var(--dark-inputs-c) !important
}

.dark-mode .sbrc .form-container {
  background-color: var(--almost-black);
}

.dark-mode .sbrc .inverted .ps-pager .item {
  background-color: var(--dark-inputs-bgc) !important;
}

.dark-mode .sbrc .inverted .ps-pager .item {
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .inverted .ps-pager .item.active {
  font-weight: bold;
  color: white !important;
  border: 0 !important;
  border-radius: 0;
}

.dark-mode .inverted label,
/* .dark-mode .inverted > .icon, */
.dark-mode .sbrc .menu-row-container .dropdown .menu .item,
.dark-mode .sbrc .inverted label:not(.file-label),
.dark-mode .sbrc .inverted .field label {
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .ql-toolbar,
.dark-mode .sbrc .ql-formats>button,
.dark-mode .sbrc .ql-formats>span>span {
  background-color: var(--dark-label) !important
}

.dark-mode .sbrc .lbl-color,
.dark-mode .sbrc .inverted .dropdown .menu>.item {
  color: var(--dark-label) !important;
  border-color: var(--dark-adjust-brdc) !important;
}

.dark-mode .sbrc .inverted .dropdown.selection.active .text {
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .inverted .form-container .divider.horizontal {
  background-color: var(--dark-adjust-dvdr-bgc) !important;
  color: var(--dark-divider-c) !important;
  font-size: .8rem;
}

.dark-mode .sbrc .inverted .action-container .divider.horizontal {
  color: var(--dark-adjust-d-c) !important;
  font-size: .8rem;
  padding: 1em
}

.dark-mode .sbrc .images-content,
.dark-mode .sbrc .inverted .ui.active.selection.dropdown,
.dark-mode .sbrc .inverted .ui.selection.active.dropdown:hover,
.dark-mode .sbrc .inverted .ui.active.selection.dropdown:focus,
.dark-mode .sbrc .inverted .ui.selection.active.dropdown .menu {
  border-color: var(--dark-adjust-brdc) !important;
}

.dark-mode .sbrc .dropdown>.label {
  color: var(--dark-active-selection-c) !important;
}

.dark-mode .sbrc .inverted .card.inline-container {
  box-shadow: 0 1px 3px 0 var(--dark-adjust-brdc), 0 0 0 1px var(--dark-adjust-brdc);
}

.dark-mode .sbrc .inverted .card.inline-container>.content,
.dark-mode .sbrc .inverted .card.inline-container {
  background-color: var(--dark-adjust-dvdr-bgc) !important;
  box-shadow: 0 1px 3px 0 var(--dark-adjust-brdc), 0 0 0 1px var(--dark-adjust-brdc);
}

.dark-mode .dd-container,
.dark-mode .drop-content,
.dark-mode .drop-group-container,
.dark-mode .drop-group-content,
.dark-mode .drop-group-header {
  background-color: var(--drop-content-dark);
  color: white;
}

.drop-group-container.settingsGroups .item>.header>div>div:nth-child(1) {
  font-size: .7rem;
  background-color: var(--drop-content-darkish) !important;
}

.dark-mode .form-container .form>.icon:not(.paste):not(.copy) {
  color: var(--dark-label) !important;
}

.dark-mode .sbrc .inverted .card.inline-container .content .header {
  color: white;
}

.dark-mode .msmi .item.restrict>.header:first-child {
  color: var(--settings-menu-item-bc-restrict) !important
}

.dark-mode .vert-container.preview .header {
  color: var(--dark-label) !important
}

.sgn {
  border: 1px solid var(--dark-adjust-brdc);
  padding: .5em
}

.help-seg .message {
  background-color: black !important;
}

.menu-help {
  font-size: .8rem;
  padding: 1em 0 0 1em;
  font-weight: normal;
  color: rgb(197, 197, 197)
}