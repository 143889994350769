/* This css sets all the colors */

:root {
  --card-shadow: rgb(228, 228, 228);
  --card-shadow-fav: rgb(175, 185, 255);
  --ddp: rgb(70, 70, 70);
  --drag-number-b: rgb(149, 182, 157);
  --drag-number-d: rgb(149, 182, 157);
  --drag-number-dd: rgb(101, 126, 107);
  --drag-number: rgb(186, 226, 196);
  --drag-number: rgb(224, 224, 224);
  --drop-content: lightgray;
  --help-header: rgb(235, 235, 235);
  --json-viewer-light: rgb(241, 241, 241);
  --json-viewer: rgb(19, 19, 19);
  --light-bgc: #f0f0f0;
  --marked-items: rgb(210, 252, 255);
  --mid-bgc: #adadad;
  --mod-h-bgc: rgb(245, 240, 240);
  --modify-footer: rgb(233, 233, 233);
  --off-black: rgb(24, 24, 24);
  --off-blackish: rgb(36, 36, 36);
  --off-blackish-bc: rgb(48, 48, 48);
  --off-blackish-bcp: rgb(72, 72, 72);
  --off-blackish-sel: rgb(64, 64, 64);
  --off-white-text: rgb(236, 236, 236);
  --off-whiteish: rgb(183, 183, 183);
  --off-white: rgb(196, 196, 196);
  --page-bgc-mob: rgb(218, 218, 218);
  --page-bgc: rgb(31, 31, 31);
  --selected-borders: solid 2px lightgrey;
  --sem-blue: #2185d0;
  --sem-light-green: #bbffcb;
  --sem-green: #21ba45;
  --sem-greenish: #dfffe6;
  --sem-green-light: #21ba45;
  --sem-light-blue: #d2ebff;
  --sem-light-green: #b6f1c4;
  --sem-grey: rgb(116, 116, 116);
  --sem-light-red: #ebc3c3;
  --sem-purple: #a333c8;
  --sem-red: #db2828;
  --sem-redish: #ffe3e3;
  --sem-red-light: rgb(255, 118, 118);
  --sem-orange: #f2711c;
  --sem-black: #1b1c1d;
  --sem-black-b: rgb(55, 56, 59);
  --sem-yellow: #fbbd08;
  --settings-menu-item-dd: rgb(77, 33, 33);
  --side-menu-bgc: rgba(0, 0, 0);
  --side-menu-dmo-bgc: rgb(44, 40, 40);
  --sort-borders: solid 1px rgb(153, 163, 177);
  --splash-container: rgb(14, 14, 14);
  --test: rgb(40, 75, 172);
  --theme-c: #131313;
  --thin-border-dm: solid 1px rgb(42, 42, 42);
  --thin-border-settings: solid 1px rgb(73, 73, 73);
  --thin-border-thin: solid 1px rgb(255, 255, 255);
  --thin-border: solid 1px lightgrey;
  --thin-borders-sort: dotted 1px rgb(75, 75, 75);
  --thin-borders: solid 1px lightgrey;
  --topper-sub-2: rgb(123, 123, 123);
  --topper-sub: rgb(93, 93, 93);
  --topper: rgb(63, 63, 63);
  --url-color: #4183c4;
  --me-background-bgc-dm: black;
  --me-background-bgc-dmoff: rgb(43, 43, 43);
  --me-border-bgc-dm: rgb(66, 66, 66);
  --me-card-bgc-dm: rgb(36, 36, 36);
  --me-card-c-dm: rgb(230, 230, 230);
  --me-card-c-dm-2: rgb(177, 177, 177);
  --app-google: rgb(241, 254, 231);
  --app-google-temp: rgb(231, 245, 254);
  --app-google-wrap: rgb(185, 207, 170);
  --app-google-wrap-temp: rgb(180, 220, 245);
  --non-sel: rgb(184, 184, 184);
  --horz-height: 36px;
}

.App,
.start-container,
.splash-container {
  background-color: var(--splash-container) !important;
}

.card-indics {
  border-color: var(--card-shadow) !important;
}

.app-homepage {
  background-color: rgb(61, 61, 61);
  color: white;
}

.app-container {
  background-color: var(--me-background-bgc) !important;
}

.all-headers-menu .menu>.divider {
  background-color: black;
}

.all-headers-menu .input.icon>.icon {
  color: white !important
}

/* THIS iS THE SORT DIV */
.ddp>div,
.ddp>div>div {
  background-color: var(--off-blackish-sel) !important;
  color: white !important;
  padding: .25em !important;
}

.drop-group-header {
  background-color: #575757;
  color: white;
}

.drop-group-content {
  background-color: var(--drop-content);
}

.drag-number>.label {
  background-color: var(--drag-number);
  border: 1px solid var(--drag-number-b);
}

.drag-number.issue:not(.ls)>.label {
  background-color: rgb(255, 95, 95) !important;
}

.dd-menu-menu>.item,
.dd-menu>.item {
  background-color: rgb(170, 170, 170) !important;
}

.ddp .label {
  color: white;
}

.ddp,
.ddp.simple>div,
.ddp.viewSettingsMenu>div {
  background-color: var(--ddp);
}

.drop-header {
  background-color: var(--mid-bgc);
}

.drop-content {
  background-color: var(--drop-content);
}

.filter-selected {
  padding: .5em;
  border-bottom: var(--thin-border);
  background-color: lightgrey;
}

.form-container:not(.sf) .divider:not(.text) {
  background-color: rgb(232, 232, 232);
}



.grid-check-container {
  background-color: var(--settings-bgc);
}

.grid-check-container.adding .segment {
  background-color: var(--settings-bgc);
  color: var(--settings-c);
}

.grid-icons-content .segment.ao {
  border-color: gray;
  background-color: black !important;
  color: rgb(175, 175, 175) !important
}

.grid-icons-content .segment.hid {
  background-color: rgb(88, 88, 88) !important;
  color: white !important;
}

.hcf-footer.modify {
  background-color: var(--modify-footer);
}

.hcf-container.vid>.hcf-header,
.hcf-container.vid>.hcf-footer {
  background-color: white;
}

.hcs-container {
  background-color: black;
}

.hcaf-header {
  border-bottom: var(--thin-border);
  background-color: var(--mod-h-bgc);
}

.help-container {
  background-color: rgb(65, 65, 65) !important;
}

.help-container .list>.item .header {
  background-color: inherit;
}

.help-header.group {
  background-color: rgb(153, 153, 255);
}

.help-seg:not(.doc) .help-group-item-header {
  background-color: rgb(68, 68, 68) !important;
}

.help-seg:not(.doc) .help-group-item-description {
  color: white !important;
}

.dark-mode .help-edit-edit {
  background-color: rgb(29, 29, 29);
}

.help-seg:not(.doc),
.help-seg:not(.doc) .header,
.help-seg:not(.doc) .message,
.help-seg:not(.doc) .accordion,
.help-seg:not(.doc) .accordion .content {
  background-color: black !important;
  color: white !important;
}

.help-seg:not .header {
  background-color: rgb(41, 41, 41) !important;
}

.images-content {
  border: 1px solid lightgrey;
  background-color: rgb(220, 242, 248);
}

.images-content>.images-picker .item>.content>.description {
  color: var(--dark-adjust-text) !important;
}

.inline-container:not(.drk) .content:nth-child(1) {
  background-color: var(--light-bgc) !important;
  border-color: var(--off-black) !important;
}

.single-page-container>.item-container {
  border: 1px solid grey;
  background-color: var(--dtd-bgc);
}

.label-container>.ui.label.selected {
  background-color: lightblue;
}

.map-list-container {
  background-color: white;
}

.hcaf-container {
  background-color: white;
}

.map-list-list .map-item-selected {
  background-color: whitesmoke;
}

.message-line>.message-message {
  background-color: lightgray;
}

.message-line.au>.message-message {
  background-color: lightblue;
}

.menu-row-container {
  background-color: inherit !important;
}

.menu-select-items .item {
  background-color: whitesmoke !important;
}

.modify-container {
  background-color: white;
}

.modify-header {
  border-bottom: var(--thin-border);
  background-color: var(--mod-h-bgc);
}

.page-wrapper-container {
  background-color: grey;
}

.page-wrapper {
  background-color: black;
}

.page-area-container {
  background-color: var(--page-bgc);
}

.push-container>.push-header>.push-caption {
  color: white;
}

.start-content {
  color: white !important;
}

.tab-menu>.item.active {
  border-color: red !important;
}

.update-container {
  background-color: white;
  border-color: rgba(34, 36, 38, .15);
}

.uploader-content>.bulk-container {
  color: black
}

.start-dimmer {
  background-color: var(--splash-container);
}

.update-wrapper {
  background-color: rgb(0, 0, 0, 0.2);
}

.num-pad {
  background-color: rgb(42, 42, 42);
}

.num-pad-current {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr !important;
}

.num-pad-value {
  background-color: white;
}

.num-pad-pad>div {
  background-color: rgb(61, 61, 61);
  color: white;
}

.num-pad-pad>div:nth-child(10),
.num-pad-pad>div:nth-child(12) {
  background-color: rgb(170, 170, 170);
  color: black
}

.pending-wait {
  background-color: black;
}

.scroll-active {
  color: red !important;
}

.mob .page-area-container {
  background-color: var(--page-bgc-mob)
}

.ns-header {
  background-color: rgb(88, 88, 88);
  color: white;
}

.app-notification-container.active {
  background-color: rgb(236, 236, 236)
}

.app-notification-container.past {
  background-color: rgb(239, 255, 237)
}

.app-notification-container.past-ns {
  background-color: rgb(255, 229, 224)
}

.app-notification-container.past {
  border: 1px solid rgb(182, 250, 172) !important;
}

.app-notification-container.past-ns {
  border: 2px solid rgb(255, 197, 197) !important;
}

.app-notification-container .note-description,
.app-notification-container .note-note {
  background-color: rgb(255, 255, 255)
}

.top-menu>.item {
  color: inherit !important
}

.top-menu .icon {
  color: inherit !important
}

.top-menu>.menu-title {
  color: inherit !important
}

.sem-banner {
  background-color: black;
}

.sem-carosel {
  background-color: black;
}

.img-ts-col.approved {
  border: var(--selected-borders);
  border-color: rgb(214, 255, 214);
}

.img-ts-container {
  background-color: white !important;
}

.search-page {
  background-color: white;
}

.item-mask {
  background-color: inherit !important;
}

.item-filter .menu-filter.tags .label.active {
  background-color: var(--live-view-bgc)
}

.item-filter .menu-filter.menu .menu,
.item-filter .menu-filter.menu .dropdown {
  color: inherit !important;
}

/* ui tiny inverted vertical ui overlay bottom thin visible sidebar action-sidebar menu */
/* ui tiny inverted vertical ui overlay bottom thin visible sidebar action-sidebar menu" */

.action-sidebar {
  width: 100%;
  /* height: 0; */
}

.action-sidebar:not(.visable) {
  width: 100%;
  /* display: none; */
  /* height: 0; */
}

.action-sidebar>.item {
  width: 100% !important;
}

.action-sidebar>.item {
  padding: 1.5em !important;
  font-weight: bold !important;
  font-size: .9rem;
  color: inherit !important;
  background-color: inherit !important;
}

.box {
  background-color: rgb(221, 221, 221);
}

.box.mapp {
  background-color: rgb(241, 241, 241);
}

.cal-close>div {
  border: 1px solid grey;
  background-color: rgb(230, 230, 230);
}

.cal-month {
  background-color: rgb(185, 185, 185);
  border: 1px solid grey;
}

.calendar-container {
  background-color: rgb(230, 230, 230);
}

.calendar-container>div {
  border: 1px solid lightblue;
}

.calendar-container>div.dow {
  background-color: rgb(135, 206, 250);
}

.calendar-container>div.nd {
  background-color: rgb(211, 211, 211);
}

.calendar-container>div.sel {
  background-color: rgb(135, 250, 154);
}

.calendar-container>div.norm {
  border-color: rgb(135, 250, 154);
}

.calendar-container>div.norm.dk {
  background-color: rgb(135, 250, 154);
}

.non-sel {
  background-color: var(--non-sel);
}


.norm-count>span>div {
  background-color: red !important;
}

.month-select-container>div {
  background-color: rgb(187, 187, 216);
  border: 1px solid grey;
}

.current-date {
  background-color: lightblue !important;
}

.card-meta {
  color: rgba(0, 0, 0, 0.6) !important
}

.wizard-wrapper.full {
  background-color: whitesmoke;
}

.news-container {
  color: white;
}

.ps-pager .item.active {
  background-color: lightgreen !important;
}

.card-img.card-profile>.content.extra {
  background-color: whitesmoke;
}

.app-sidebar-left-wrapper {
  background-color: black;
  color: white;
}

.item-popup.single>.icon {
  background-color: green !important;
}

.item-popup.single>.icon {
  background-color: green !important;
}

.item-popup>.item-popup-content {
  background-color: #eeeeee !important;
  border: 1px solid #bababc;
  box-shadow: 1px 1px 0 0 #bababc;
}

.item-settings>.item-settings-content {
  background-color: #eeeeee !important;
  border: 1px solid #bababc;
  box-shadow: 1px 1px 0 0 #bababc;
}

.aul-events .label {
  background-color: rgb(202, 202, 202) !important;
}

.ms-key {
  color: grey
}

.hdd.tv.nf {
  color: red !important
}

.prp-description {
  color: black
}

.sidebar-fdv {
  background-color: black !important;
}

.sidebar-fdv-settings {
  background-color: black !important;
}

.sidebar-fdv-settings.normal {
  background-color: white !important;
}

.query-line div {
  color: greenyellow;
}

.query-line div.isa {
  color: rgb(250, 149, 149);
}

.query-line>div:nth-child(2) {
  color: rgb(189, 189, 189)
}

.list-button,
.list-button>.text,
.list-button .header,
.list-button>.menu,
.list-button>.menu>.item {
  background-color: var(--sem-blue) !important;
  color: white !important;
  border-color: grey !important;
}

.vert-container.sdc {
  background-color: rgb(224, 249, 255);
}

.abc.so {
  background-color: inherit !important;
}

.action-sidebar>.item.mi-norm {
  color: white !important;
}

.action-sidebar>.item.mi-data {
  color: inherit !important;
}

.field.hlpw label {
  color: green !important
}

.hgs {
  background-color: #db2828;
}

.hodd {
  background-color: inherit !important;
}

.ip-url {
  color: var(--url-color);
}

.json-container {
  background-color: var(--sem-black) !important;
  color: whitesmoke !important;
}

.json-container.in-app {
  background-color: var(--json-viewer-light) !important;
}

/* .react-json-view {
  background-color: inherit !important;
} */

.create-event-header {
  background-color: rgb(216, 216, 216);
}

.menu-slider .icon.caret {
  color: rgb(199, 199, 199) !important
}

.menu-header-topper-simple,
.menu-header-topper {
  background-color: var(--topper) !important;
  color: white;
}

.menu-header-alt {
  background-color: var(--sem-blue);
  color: white;
}

.vert-container.apv>.header {
  background-color: var(--topper-sub) !important;
}

.vert-container.full-data-viewer>.header {
  background-color: var(--topper-sub-2) !important;
  /* background-color: rgb(14, 47, 109) !important; */
}

.dark-mode .vert-container.app .item {
  color: rgb(255, 255, 255, .7) !important
}

.dark-mode .vert-container .item.sel {
  color: rgb(255, 255, 255) !important
}

.profile-select.access {
  color: black !important;
}

.profile-select.access .item {
  color: black !important;
}

.help-seg:not(.doc) mark,
.help-container mark {
  background-color: inherit;
  color: yellow;
}

.help-seg.doc mark {
  background-color: inherit;
  color: black;
}

.help-group-item-description,
.help-seg .description {
  border: 1px solid rgb(228, 228, 228) !important;
  margin-bottom: .5em;
}

.inh,
.inh>.item {
  background: 0 0 !important;
  background-color: inherit !important;
  color: inherit;
}

.vert-container.paddedFooter>.footer.sbf {
  background-color: rgb(71, 71, 71);
}

.vert-container.splitVert>.header {
  background-color: rgb(203, 203, 203) !important;
  color: black !important;
}

.vert-container.splitVert>.header>.item {
  color: black !important;
}

.wizard-wrapper .vert-container>.header:not(.imgs),
.dark-mode .modal .vert-container>.header:not(.imgs),
.wizard-wrapper .vert-container>.footer {
  background-color: rgb(36, 36, 36);
  color: white;
}

.wizard-wrapper .vert-container>.header.imgs {
  background-color: var(--light-bgc);
}

.dark-mode .modal .vert-container>.footer {
  background-color: rgb(36, 36, 36);
  color: white
}

.map-address-address {
  background-color: white;
}

.map-address-name {
  background-color: white;
}

.vert-container.pending {
  background-color: var(--main-bgc);
  color: white;
}

.form.simput input {
  border-bottom: 1px solid red !important;
  background-color: black !important;
}

/* .tester {
  background-color: purple !important;
} */

.top-settings-caption {
  color: grey;
}

.app-settings-grid>.column {
  background-color: var(--side-menu-bgc);
}

.app-settings-grid>.column.app {
  background-color: white;
}

.settings-app-container {
  background-color: rgb(215, 255, 196);
}

.settings-app-container.gds {
  background-color: var(--app-google) !important;
}

.settings-app-container.gds.temp {
  background-color: var(--app-google-temp) !important;
}

.settings-app-mobile-wrap {
  border-color: rgb(182, 182, 182) !important;
}

.gds .settings-app-mobile-wrap {
  border-color: var(--app-google-wrap) !important;
}

.gds.temp .settings-app-mobile-wrap {
  border-color: var(--app-google-wrap-temp) !important;
}


.live.view.settings-app-mobile-wrap {
  border-color: var(--live-view-bgc) !important;
}

.live.global.settings-app-mobile-wrap {
  border-color: var(--live-global-bgc) !important;
}

.settings-app-container.google.sheets {
  background-color: var(--google-sheets-bc) !important;
}

.settings-app-container.google.previewing {
  background-color: var(--google-preview-bc) !important;
}

.settings-app-container.google.data {
  background-color: var(--google-data-bc) !important;
}

.settings-app-container.google.sheets .settings-app-mobile-wrap {
  border-color: var(--google-sheets) !important;
}

.settings-app-container.google.previewing .settings-app-mobile-wrap {
  border-color: var(--google-preview) !important;
}

.settings-app-container.google.data .settings-app-mobile-wrap {
  border-color: var(--google-data) !important;
}

.sec-container.block>.sec-header {
  border: var(--thin-border);
  background-color: var(--light-bgc);
}

.sec-container.blockAttached>.sec-header {
  border-bottom: var(--thin-border);
  background-color: var(--light-bgc);
}

.sec-header>.divider {
  color: inherit !important;
  background-color: inherit !important;
}

.noContent .header {
  background-color: inherit !important;
  padding: 0 !important;
}

.cards-container>.card,
.card-container,
.card-content {
  border-radius: 0 !important;
}

/* inverted */
.form-container:not(.inverted) .divider:not(.text) {
  color: rgb(57, 43, 122) !important;
}

.form-container:not(.inverted) .divider.emph:not(.text) {
  background-color: rgb(199, 199, 199);
  color: rgb(57, 43, 122) !important;
}

.inverted .app-notification-container.active {
  background-color: rgb(68, 68, 68);
}

.inverted .app-notification-group>div:first-child,
.inverted .app-notification-date-group>div:first-child,
.inverted .note,
.inverted .note>.note-note,
.inverted .note>.note-description {
  border-color: rgb(75, 75, 75) !important;
}

.inverted .note>.note-description,
.inverted .note>.note-note {
  background-color: rgb(90, 90, 90) !important;
}

.app-light-mode .push-pushable.inverted .menu-slider>.item,
.app-light-mode .push-pushable.inverted .menu-slider-sidebar>.item,
.app-light-mode .segment.inverted .card:not(.preview),
.app-light-mode .segment.inverted .card:not(.preview)>.content>.header,
.app-light-mode .segment.inverted .card:not(.preview)>.content>.description,
.app-light-mode .segment.inverted .card:not(.preview)>.content>.meta {
  background-color: var(--sem-black);
  color: rgba(255, 255, 255, .9);
}

.app-light-mode .push-pushable.inverted .menu-slider-sidebar,
.app-light-mode .push-pushable.inverted .menu-slider {
  border-color: var(--sem-black) !important;
}

.app-container.inverted .sec-container.blockAttached>.sec-header {
  background-color: inherit !important;
}

.app-container.inverted .grid-icons-container .divider,
.app-container.inverted .form>.divider {
  color: rgb(153, 153, 153) !important
}

.app-container.inverted .vert-container .header {
  color: white
}

.ui.card,
.ui.cards>.card {
  box-shadow: 0 1px 3px 0 var(--card-shadow), 0 0 0 1px var(--card-shadow) !important;
}

.card-group-favs .ui.card,
.card-group-favs .ui.cards>.card {
  box-shadow: 0 1px 3px 0 var(--card-shadow-fav), 0 0 0 1px var(--card-shadow-fav) !important;
}

.mi-fav>.icon.star {
  float: left !important;
  margin-left: 0 !important;
  margin-right: .5em !important;
}

.app-container.inverted .ui.card,
.app-container.inverted .ui.cards>.card,
.app-container.inverted .sec-container.blockAttached>.sec-header {
  box-shadow: 0 1px 3px 0 rgb(82, 82, 82), 0 0 0 1px rgb(82, 82, 82);
}

.app-container.inverted .sec-container.blockAttached:not(.separate) {
  border: 1px solid rgb(82, 82, 82);
  box-shadow: 0 1px 3px 0 rgb(82, 82, 82), 0 0 0 1px rgb(82, 82, 82);
}

.app-container .sec-container.separate .segment {
  margin: 0;
  padding: 0 .5em;
}

.full-data-viewer .menu-alphabet>.item.active {
  background-color: var(--sem-blue) !important;
}

/* Dark Mode */
.app-container.app-dark-mode {
  background-color: var(--me-background-bgc-dm) !important;
}

.app-dark-mode .dimmer {
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container {
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div {
  background-color: var(--off-black) !important;
  border-color: var(--off-blackish) !important;
}

.app-dark-mode .calendar-container>div.dow {
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.nd {
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.norm {
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.norm.dk {
  background-color: var(--off-black) !important;
}

.app-dark-mode .calendar-container>div.non-sel {
  background-color: var(--off-blackish-bc) !important;
}

.app-dark-mode .calendar-container>div.sel {
  background-color: var(--off-blackish-sel) !important;
}

.app-dark-mode .cal-month {
  background-color: var(--off-blackish) !important;
  border-color: var(--off-blackish) !important;
}

.app-dark-mode .cal-month-cb,
.app-dark-mode .cal-month-ic1,
.app-dark-mode .cal-month-ic2,
.app-dark-mode .cal-month-icw {
  background-color: var(--off-black) !important;
  border-color: var(--off-blackish-bc) !important;
}


.app-dark-mode .vert-container:not(.noContent) .content {
  background-color: var(--off-black) !important;
  border-color: var(--off-black) !important;
}

.app-dark-mode .cal-months-container {
  background-color: var(--off-black) !important;
  border: 1px solid grey;
}

.app-dark-mode .item-content {
  background-color: black;
}

.app-dark-mode .card {
  background-color: inherit;
  box-shadow: 0 1px 3px 0 black, 0 0 0 1px black !important;
}

.app-dark-mode .meta {
  color: var(--me-card-c-dm-2) !important;
}

.app-dark-mode .item-container .card .sec-header {
  background-color: var(--me-card-bgc-dm);
  border: 0 !important;
  box-shadow: 0 1px 3px 0 black, 0 0 0 1px black !important;
  color: var(--me-card-c-dm);
}

.app-dark-mode .item-container .card .sec-container {
  background-color: var(--me-card-bgc-dm);
  border: 0 !important;
  box-shadow: 0 1px 3px 0 rgb(39, 39, 39), 0 0 0 1px rgb(39, 39, 39) !important;
  color: var(--me-card-c-dm);
}

.app-dark-mode .sec-container,
.app-dark-mode .item-container .cards-container>.card,
.app-dark-mode .item-container .card-container,
.app-dark-mode .item-container .card.card-schedule,
.app-dark-mode .item-container .card.card-profile {
  background-color: var(--me-card-bgc-dm) !important;
  box-shadow: 0 1px 3px 0 rgb(39, 39, 39), 0 0 0 1px rgb(39, 39, 39) !important;
  color: var(--me-card-c-dm);
}

.card.card-schedule.completed {
  background-color: rgb(255, 237, 237) !important;
}

.app-dark-mode .label {
  background-color: var(--me-card-bgc-dm);
  /* color: var(--off-white) !important; */
}

.app-dark-mode .divider,
.app-dark-mode .prp-description,
.app-dark-mode .segment {
  color: var(--off-white) !important;
}

.app-dark-mode .blockAttached {
  border: none !important;
  box-shadow: none !important;
}

.app-dark-mode .ms-button-group {
  background-color: black !important;
}

.app-dark-mode .sec-container.blockAttached>.sec-header {
  border-bottom: var(--thin-border-dm) !important;
  background-color: var(--off-black) !important;
}

.app-dark-mode .prc {
  color: var(--me-card-c-dm);
}

.app-dark-mode .header:not(.nob) {
  /* background-color: var(--me-card-bgc-dm) !important; */
  background-color: inherit !important;
  color: var(--me-card-c-dm) !important;
}

.app-dark-mode .push-container {
  background-color: var(--me-background-bgc-dm) !important;
  color: var(--off-white-text) !important;
}


.app-dark-mode .schedule-dates {
  background-color: black !important;
  color: inherit !important;
}

.app-dark-mode .placeholder.segment {
  background-color: var(--me-background-bgc-dm) !important;
}

.app-dark-mode .ct-no-data {
  background-color: var(--me-background-bgc-dm) !important;
}

.app-dark-mode .ct-no-data .icon {
  color: var(--sem-blue) !important;
}

.app-dark-mode .menu-slider {
  background-color: var(--off-black) !important;
  color: white;
}

.app-dark-mode .menu-slider>.item,
.app-dark-mode .menu-slider>.item:hover {
  color: white !important;
}

.app-dark-mode .pending-container,
.app-dark-mode .pending-container .dimmer,
.app-dark-mode .pending-container .loader {
  background-color: var(--off-black) !important;
  color: white !important;
}

.app-dark-mode [data-swipeable] {
  background-color: var(--off-black) !important;
}

.app-dark-mode .menu-slider-sidebar {
  background-color: var(--off-black) !important;
}

.app-dark-mode .menu-slider-sidebar>.item {
  color: white !important;
}

.app-dark-mode .dropdown,
.app-dark-mode .dropdown .menu {
  background-color: var(--off-black) !important;
  color: white !important;
}

.app-dark-mode .dropdown>.label,
.app-dark-mode .dropdown .menu .item {
  color: white !important;
  border-color: var(--off-blackish-bc) !important;
}

.setz .header {
  color: white;
}