.App {
  width: 100%;
  height: 100%;
  position: absolute;
}

#parent {
  width: 100%;
  height: 200px;
  /* Set the desired height for the parent div */
  overflow: auto;
}

.menu {
  white-space: nowrap;
}

.menu-item {
  display: inline-block;
  padding: 10px;
  border: 1px solid black;
}

.example-container div {
  background: white;
  border-radius: 10px;
  /* width: 150px;
  height: 150px; */
}

.app-wrapper-container {
  width: 100%;
  height: 100%;
}

.motion-container.pageItem,
.motion-container.splash {
  width: 100%;
  height: 100%;
}

/* .motion-container.pageItem>div  {
  width: 100%;
  height: 100%;
} */

.motion-container.element {
  width: 100%;
}

.stack-error {
  font-size: .8rem;
  height: 100%;
  overflow-y: auto;
  padding: .5em;
}

.hint {
  padding-top: .5em;
  padding-left: 1em;
  font-size: .8rem;
  color: grey;
}

.app-homepage {
  position: absolute;
  bottom: 0;
  display: grid;
  width: 100%;
  grid-template-rows: auto auto 1fr;
  padding: 1em;
}

.app-homepage>.buttons {
  display: grid;
  grid-template-columns: 10% 80% 10%;
}

.app-homepage.nh>.buttons {
  grid-template-columns: auto 1fr;
}

.app-homepage.nh>.buttons>.button {
  padding: 1em 1.5em !important;
}

.app-homepage>div {
  padding: .75em;
}

.app-homepage>div:nth-child(1)>.image {
  margin: auto;
  /* color: #d1d1ff;
  background-color: #ffadad; */
}

.dd-container {
  display: grid;
  grid-template-columns: auto 1fr;
}

/* .app-homepage:not(.zz)>div:nth-child(3) {
  text-align: right;
} */

.app-homepage.fp {
  position: relative;
}

.app-homepage div>p {
  line-height: 1 !important;
}

.app-full {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
}

/* CONTAINERS */

.buttons-container {
  text-align: center;
  padding: .5em;
}

.icn-grp,
.icn-ngrp {
  display: grid;
  grid-template-columns: 5% 95%;
  gap: .5em;
}

/* ************************************************ */

/* APP CONTAINER */
.app-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto auto auto;
  grid-template-areas:
    "app-menu"
    "app-banner"
    "app-content"
    "app-banners"
    "app-navigation"
    "app-homepage"
}

.app-container.segment {
  padding: 0;
}

.app-container.blt {
  grid-template-areas:
    "app-menu"
    "app-banner"
    "app-banners"
    "app-content"
    "app-navigation"
    "app-homepage"
}

.dt-mode .app-container {
  scrollbar-width: none;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr 8fr 1fr;
  grid-template-areas:
    "app-sticky app-sticky app-sticky"
    ". app-content ."
    "app-footer app-footer app-footer"
}

.dt-mode .app-container::-webkit-scrollbar {
  display: none;
}

.dt-mode .app-container {
  height: auto;
  overflow-y: auto;
  scrollbar-width: none;
  min-height: 100vh !important;
}

.dt-mode .app-container .app-header {
  width: 100%;
  z-index: 100;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "app-menu"
    "app-navigation"
}

.dt-mode .app-container>.app-header>.app-menu {
  width: 100% !important;
}

.dt-mode .app-container>.app-header>.app-navigation {
  width: 100% !important;
}

/* ************************************************ */

/* APP CONTENT */
.app-content {
  grid-area: app-content;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: none;
}

.app-content.ui-item {
  overflow-x: hidden;
}

.app-content.padd {
  padding: 8px;
  padding-top: 16px;
}

.app-content.padd .big-icon {
  text-align: center;
}

.app-content .image {
  margin: 0px 8px 8px 0
}

.app-content .normal .image {
  margin: 0
}

.app-content .header {
  vertical-align: top !important;
}

.app-content .group-swiper,
.app-content .group-swiper>div {
  overflow-y: hidden !important;
}

.app-content.cbn {
  overflow-y: auto;
}

.app-content>.list {
  margin: 1em !important
}

.app-banner {
  grid-area: app-banner;
}

.app-banners {
  grid-area: app-banners;
}

.app-header {
  grid-area: app-header;
}

.app-menu {
  grid-area: app-menu;
}

.app-navigation {
  grid-area: app-navigation;
}

.app-footer {
  grid-area: app-footer;
}

.app-sticky {
  grid-area: app-sticky;
}

.app-homepage {
  grid-area: app-homepage;
  /* display: none; */
}

.app-homepage.fp {
  display: block;
  height: 100%;
}

/* APP SWIPE CONTAINER */
.app-swipe-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "app-swipe-menu"
    "app-swipe-content"
}

.app-swipe-menu {
  grid-area: app-swipe-menu;
}

.app-swipe-content {
  grid-area: app-swipe-content;
}

.app-swipe-content {
  overflow-y: auto;
}

.ms-button-group {
  width: 100% !important;
  overflow-x: auto;
}

.app-swipe-menu>.menu {
  padding: 0 !important;
  overflow-x: auto;
  scrollbar-width: none;
  border-bottom: 0px !important;
  padding-bottom: 2px !important;
  width: 100% !important;
}

.menu-alphabet:not(.vertical) {
  width: 100%;
  overflow-y: auto;
}

.app-swipe-menu>.menu>.item {
  margin-bottom: 2px !important
}

.app-swipe-menu>.menu::-webkit-scrollbar {
  display: none;
}

/* .app-swipe-menu > .menu{scrollbar-width: thin} */
.dt .app-swipe-menu>.menu {
  scrollbar-width: thin
}

.mob .app-swipe-menu>.menu {
  scrollbar-width: none;
}

.settings-app-container .app-swipe-menu>.menu::-webkit-scrollbar {
  display: inline;
}

.swipe-tab {
  font-size: .9rem;
}

.swipe-tab.item,
.swipe-tab.item.active {
  padding: 1em 1.25em .75em 1.25em !important
}

/* END - APP SWIPE CONTAINER */

/* ************************************************ */

/* ALL HEADERS */
.all-headers-menu {
  display: grid !important;
  grid-template-columns: 1fr 10fr 1fr !important;
}

.all-headers-menu>.item:first-child,
.all-headers-menu>.item:last-child {
  padding-right: 0 !important;
}

.all-headers-menu {
  border-radius: 0 !important;
}

.all-headers-menu>.item>.search {
  padding: .5em !important;
}

.all-headers-menu>.item,
.all-headers-menu>.menu>.item {
  padding-top: .5em !important;
  padding-bottom: .75em !important;
  border-radius: 0;
  border: 0 !important
}

.all-headers-menu {
  margin: 0 !important;
  padding: .25em 0;
  border-bottom: var(--thin-border)
}


/* ************************************************ */

/* COLORS CONTAINER */
.colorz-container {
  width: 100%;
  display: grid;
  margin: 0 0 1em 0px;
  border-radius: 4px;
  grid-template-columns: 20% 40% 40%;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    "colorz-apply"
    "colorz-bc"
    "colorz-c";
}

.colorz-container.colorSelectSingle {
  grid-template-columns: 20% 80%;
}

.colorz-container div {
  margin: 0 .1em;
  justify-self: stretch
}

.colorz-container .colorz-apply:not(.applied) {
  opacity: .3;
}

.msmi>.item>.header>.icon.clr:not(.applied) {
  opacity: .3;
}

/* END - COLORS CONTAINER */

/* ************************************************ */

/* CONTENT CONTAINER */
.content-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "content-header"
    "content-content";
}

.content-container {
  height: 100%;
  overflow-y: auto !important;
}

.content-content {
  grid-area: content-content;
  scrollbar-width: none;
  height: 100%;
}

.content-content>.cards.solo {
  margin: 0 !important;
}

.content-content>.seg-item-list>.item {
  padding: .5em 0em !important
}

.content-content.cardNameSectionOnly,
.content-content.cardTaggedOnly {
  padding: .35em
}

.content-content.static .card {
  border-left: black solid 1px !important;
}

.content-content.weather {
  margin: 0 !important;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
}

.dt-off .content-content .card {
  margin-bottom: .5em !important;
}

.menu>.content-container>.content-content {
  height: auto !important;
  overflow-y: hidden !important;
  padding: .5em;
}

.content-content.cardNameSectionOnly .cards-container>.motion-container.element>.card:not(:static),
.content-content.cardNameSectionOnly .cards-container>.card:not(:static) {
  box-shadow: none !important;
  margin-bottom: .25em !important;
  border-radius: 0 !important;
  border: none !important;
}

/* ************************************************ */

/* CONVERSATIONS */

.conversation-line {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas:
    "conversation-icon conversation-from conversation-date"
    "conversation-icon conversation-message conversation-date";
  border-bottom: var(--thin-border);
  padding: .5em 0;
}

.conversation-icon {
  grid-area: conversation-icon;
}

.conversation-from {
  grid-area: conversation-from;
  font-weight: bold;
}

.conversation-message {
  grid-area: conversation-message;
  padding: .5em .5em
}

.conversation-date {
  grid-area: conversation-date;
  font-weight: lighter;
  font-size: 9pt;
  text-align: right;
}

.conversation-from>.label {
  margin-bottom: .25em;
}

/* ************************************************ */


/* DELETE CONTAINER */
.delete-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "delete-button"
    "delete-header"
    "delete-content";
}

.delete-button {
  grid-area: delete-button;
  border-bottom: var(--thin-border);
}

.delete-header {
  grid-area: delete-header;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.delete-content {
  grid-area: delete-content;
  overflow-y: auto;
  scrollbar-width: none;
}

.delete-header>.label {
  margin-top: .5em !important
}

/* END - DELETE CONTAINER */

/* ************************************************ */

/* DELETE SUB CONTAINER */
.delete-sub-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "delete-sub-header"
    "delete-sub-content";
}

.delete-sub-header {
  grid-area: delete-sub-header;
}

.delete-sub-content {
  grid-area: delete-sub-content;
  padding: 1em;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.delete-sub-header>.label {
  margin-top: .5em !important
}

.delete-sub-content {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.delete-sub-content>.checkbox {
  padding-bottom: .5em;
}

/* END DELETE SUB CONTAINER */

/* ************************************************ */

/* DROP GROUP CONTAINER */
.drop-group-container {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: .25em;
  /* padding: .1em; */
  /* border: var(--sort-borders);   */
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "drop-group-header"
    "drop-group-content";
}

.drop-group-container.settingsGroups .item>.header>div {
  display: flex;
  flex-direction: column;
}

.drop-group-container.settingsGroups .item>.header>div>div:nth-child(1) {
  font-size: .7rem;
  columns: blue !important;
}

.drop-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1.5em;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
}

.drop-group-content {
  min-height: 46px;
  margin: 0;
  display: block;
}

.drop-group-content.nd {
  border: none;
}

.drop-group-header>.dropdown {
  float: right;
}

.drop-group-header>.dropdown,
.drop-group-header>.dropdown>.menu>.item {
  font-size: .8rem !important;
}

.drop-group-container.fixed {
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
}

.drop-list .label {
  width: 100%;
  margin-bottom: .5em;
  padding: .75em;
}

.drop-list,
.drop-list.active {
  padding: 0;
  height: auto;
  min-height: 46px;
}

.ddp .label,
.drop-list .label {
  width: 100% !important;
  padding: 1em !important;
  margin-left: 0 !important;
  /* background-color: blue !important; */
}

.drop-list>div>label {
  display: flex;
}

.ddp.label>div>div {
  padding: .2em !important;
}

.lbl-additional {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.drag-number {
  margin-bottom: .25em !important;
}

.drag-number>.label {
  margin: 0 .25em 0 0 !important;
}

.drag-number>.label {
  width: 100% !important;
  padding: .5em !important;
}

.drag-number>.label>.label {
  width: 24px !important;
  padding: .5em !important;
  margin: 0 1em 0 0 !important;
  text-align: center;
}

.drag-number>.label>.label:nth-child(2) {
  float: right;
}

.drag-number>.label>.label:nth-child(3) {
  float: right;
  width: auto !important;
}

/* dragDropper */
.dd-menu {
  margin: .5em !important;
}

.dd-menu-menu>.item,
.dd-menu>.item {
  display: grid !important;
  padding: .5em !important;
  align-items: center !important;
  grid-template-columns: auto 1fr;
  gap: .5em;
  border-radius: 4px;
}

.dd-menu>.item.ls {
  grid-template-columns: auto 1fr auto !important;
}

.dd-menu>.item>.label {
  padding: .5em !important;
}

.dd-menu-menu>.item {
  margin: .5em !important
}

.ddp .label {
  width: 100% !important;
  border-radius: 4px !important;
  padding: .5em !important;
}

.ddp.label>div,
.ddp.simple>div,
.ddp.viewSettingsMenu>div {
  border-radius: 4px;
  font-weight: bold;
  margin: 0 !important;
  padding: 0 !important;
}

/* THIS IS THE div that is dragged */
.ddp {
  font-weight: bold;
}

.drop-header {
  padding: .5em;
  margin-bottom: 0em;
  font-weight: bold;
}

/* DROP CONTENT */
.drop-content {
  min-height: 46px;
  padding: 0 .25em;
  padding: .25em
}

.drop-content .label {
  width: 100% !important;
  margin-bottom: .5em !important;
  padding: .5em !important
}

.drpi {
  border-bottom: 3px solid red;
}

/* END - DROP GROUP CONTAINER */

/* FILTER */
.filter-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "filter-header"
    "filter-selected"
    "filter-content"
}

.menu-filter .dropdown>.menu {
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-selected {
  padding: .5em;
  border-bottom: var(--thin-border);
}

.filter-content {
  grid-area: filter-content;
  padding: .5em;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-selected {
  grid-area: filter-selected;
  padding: .5em;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-header {
  padding: .5em;
  border-top: var(--thin-border);
  display: flex;
  flex-direction: column;
  gap: .25em;
}

/* ************************************************ */

/* FILTER ITEMS */
.filter-group-container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "filter-group-header"
    "filter-group-footer"
}

.filter-group-header {
  padding: .5em;
  font-weight: bold;
  border-bottom: var(--thin-border)
}

.filter-group-content {
  padding: .5em
}

.filter-group-content>.label {
  margin: .2em !important
}

/* ************************************************ */

/* FORM CONTAINER */
.form-container {
  /* display: grid; */
  height: 100%;
  grid-template-rows: 1fr;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 1em;
}

.form-container.simp input {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid lightgray !important;
}

.form-container.simp .input,
.form-container.simp .dropdown {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.form-container.simp .dropdown {
  border-bottom: 1px solid lightgray !important;
}

.form-container.simp .dropdown.icon {
  border: none !important;
}

[formkey='dataSource'] {
  grid-template-rows: 5fr 1fr !important;
}

.form-buttons {
  padding: 1em 0 !important
}

.form-container.dm .form:not(.mlc) {
  padding-bottom: 4em !important;
}

.form-container.dm>.form {
  margin: .5em;
  padding-bottom: 4em !important;
}

.form-container.dm {
  padding: 0;
}

.form-container.segment {
  padding: 0 !important;
}

.form-container.sf .form {
  padding-bottom: 4em !important;
}

.form-container.sf>.form {
  margin: .5em;
  padding-bottom: 4em !important;
}

.form-container.sf {
  padding: .5em !important;
}

.form-container:not(.inverted) .divider:not(.text) {
  margin: 0 0 1em 0 !important;
  padding: .5em;
}

.form-container .divider>.icon {
  margin-right: .5em !important;
}

.form-container:not(.inverted) .divider.emph:not(.text) {
  margin: 0 0 1em 0 !important;
  padding: .5em;
}

.form-container .divider.alt {
  text-transform: lowercase;
}

/* END - FORM CONTAINER */

/* ************************************************ */

/* GOOGLE DATA CONTAINER */
.google-data-container {
  display: grid;
  width: 100%;
  height: 100%;
  /* grid-template-rows: auto 1fr;  */
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.google-data-container>.gst {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.google-data-container>.segment,
.google-data-container>.gst,
.google-data-container>.gst>.menu,
.google-data-container>.gst>.tab,
.google-data-container>.gst>.tab>.table-wrapper,
.google-data-container>.gst>.tab>.table-wrapper>.table {
  width: 100% !important;
}

.gst>.segment.tab {
  display: contents !important;
}

.google-data-container>.gst>.tab>.table-wrapper {
  height: 100%;
}

.google-data-container>.gst>.tab>.table-wrapper>.table {
  position: absolute;
}

.google-data-container>.gst>.menu {
  overflow-y: auto;
  background-color: black;
}

.google-data-container>.gst>.menu>.item {
  color: rgb(179, 179, 179) !important;
}

.google-data-container>.gst>.menu>.item.active {
  background-color: var(--sem-blue);
  color: white !important;
}

/* dddd */
.gst {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.gst.bot {
  grid-template-rows: 1fr auto;
}

.gst,
.gst>.grid,
.gst>.grid>.column,
.gst>.menu,
.gst>.tab,
.gst>.tab>.table-wrapper,
.gst>.tab>.table-wrapper>.table {
  width: 100% !important;
}

.gst>.tab,
.gst>.grid,
.gst>.grid>.column,
.gst>.tab .pushable,
.gst>.tab .pusher,
.gst>.tab>.table-wrapper {
  height: 100%;
}

.gst>.tab>.table-wrapper>.table {
  position: absolute;
}

.gst>.menu {
  overflow-y: auto;
  border-radius: 0;
}

.gst.vit>.menu {
  margin-bottom: 0 !important;
  padding: .25em;
}

.gst.vit>.menu>.item.active {
  background-color: var(--sem-blue) !important;
}

.gst.vit>.tab {
  padding: 0;
  margin-top: 0 !important;
  border: 0;
}

.gst>.menu>.item,
.gst>.menu>.item.active {
  font-weight: bold;
}

.google-data-container .table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
}

.table-wrapper>.table th:first-child,
.table-wrapper>.table td:first-child {
  width: 50px !important
}


/* ************************************************ */

/* GRID CHECK CONTAINER */

.grid-check-container {
  padding: 0 !important
}

.grid-check-container .label:nth-child(2) {
  border-left-width: 8px !important;
  border-right-width: 8px !important;
  font-weight: 700;
}

.grid-check-container .grid-check-header {
  font-weight: 700;
}

.grid-check-container .label:not(.applied) {
  opacity: .5;
}

.grid-check-container.adding .segment {
  margin-top: .5em !important;
  margin-bottom: .5em !important;
}

/* END - GRID CHECK CONTAINER */

/* ************************************************ */

/* GRID ICONS */
.grid-icons-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-x: hidden;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  grid-template-areas:
    "grid-icons-content"
}

.grid-icons-container {
  scrollbar-width: none;
  padding: .5em;
}

.grid-icons-content .column {
  align-self: stretch;
  padding: .5em !important;
}

.grid-icons-content .row {
  padding: 0 !important;
}

.grid-icons-content .segment .icon {
  text-align: center;
  margin-top: 10px
}

.grid-icons-content .segment div {
  text-align: center;
  font-size: 1rem;
  margin-top: 10px
}

.grid-icons-content {
  padding: .5em;
}

.grid-icons-content {
  padding: .5em;
  align-items: flex-start !important
}

.grid-icons-content .segment,
.grid-settings-icon {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 8px !important;
  border-style: solid !important;
}

.grid-icons-content>.divider.horizontal {
  padding: .5em
}

/* END - GRID ICONS */

/* ************************************************ */

/* GRID LIST CONTAINER */

.grid-list-container {
  height: 100%;
}

.grid-list-container>.list {
  padding: .25em .5em !important;
}

.grid-list-container>.list>.item {
  padding: .75em 0 !important;
}

.map-list-list>.menu>.item,
.grid-list-container>.menu>.item {
  padding: 1em .5em !important;
}

.grid-list-container>.menu>.item>.image:first-child {
  margin-right: .5em !important;
}

.grid-list-container>.menu>.item>.icon:first-child {
  float: left !important;
  margin-right: .5em !important;
}

.grid-list-container>.menu>.item>.icon,
.grid-list-container>.menu>.item>.image {
  display: inline-block;
}

.url-list-container {
  height: 100%;
}

.url-list-container>.list>.item {
  padding: .75em 1em !important;
}

/* ************************************************ */

/* HCF */
.hcf-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "hcf-header"
    "hcf-content"
    "hcf-footer";
}

.hcf-header {
  grid-area: hcf-header;
  border-bottom: var(--thin-border);
  font-weight: bold;
}

.hcf-content {
  grid-area: hcf-content;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
}

.hcf-header.padd,
.hcf-content.padd {
  padding: .5em;
}

.hcf-content.vpl div {
  display: contents;
}

.hcf-content.ctr>.segment {
  height: 100%;
}

.hcf-footer {
  grid-area: hcf-footer;
  border-top: var(--thin-border);
}

.hcf-footer:not(.settings-menu-footer) {
  padding: .5em;
}

.hcf-footer.modify>.button:nth-child(1) {
  float: left !important;
}

.hcf-footer.modify>.button:nth-child(2) {
  float: right !important;
}

.hcf-content form,
.hcf-content.cfm {
  margin: 1em;
  padding-bottom: 1em;
}

.hcf-container.vid>.hcf-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hcf-container.vid>.hcf-header,
.hcf-container.vid>.hcf-footer {
  z-index: 1000;
}

.hcf-header.mod {
  padding: 1em
}

.hcf-header.mod>.ui.dropdown {
  float: right;
  width: 80px;
  min-width: 5em;
  font-size: .8rem;
}

.hcf-footer.mod {
  padding: 1em
}

/* ************************************************ */

/* HCF */
.hcs-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "hcs-top"
    "hcs-bottom";
}

.hcs-top,
.hcs-bottom {
  padding: .5em !important
}

/* ************************************************ */

/* HCF */
.hcaf-container {
  height: 100%;
  width: 100%;
  display: grid;
  border-radius: 10px;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "hcaf-header"
    "hcaf-alert"
    "hcaf-content"
    "hcaf-footer";
}

.hcaf-header {
  padding: 1em 1em;
  border-radius: 10px;
}

.hcaf-header .icon:nth-child(1) {
  margin-right: 10px !important;
}

.hcaf-content {
  grid-area: hcaf-content;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
}

.hcaf-alert {
  grid-area: hcaf-alert;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.hcaf-footer {
  grid-area: hcaf-footer;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.hcaf-content form,
.hcaf-content.cc {
  margin: 1em;
  padding-bottom: 1em;
}

.hcaf-content.padd {
  padding: .5em;
}

/* ************************************************ */

/* HELP CONTAINER*/
.help-container {
  height: auto !important;
  padding: 0 !important;
  margin-top: .5em;
}

.help-container>.message {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.help-container .label.attached {
  margin: 0 !important;
  padding: .75em !important
}

.help-container .label.attached .icon {
  margin: 0 !important;
  padding: 0 !important
}

.help-container .list>.item .header {
  padding: .5em;
}

.help-container .list>.item .description {
  padding: 1em
}

.help-header {
  padding: .5em;
  font-weight: bold;
}

.help-content {
  padding: 1em;
}

.help-group-header {
  font-weight: bold;
  font-size: 1.1rem;
}

.help-seg .help-group-item-header {
  font-weight: bold
}

.help-seg:not(.doc) .help-group-item-description {
  font-weight: bold;
}

/* extra */
.help-seg {
  height: 100%;
  padding: .5em !important;
  border: 1px solid rgb(68, 68, 68) !important
}

.help-seg .header {
  margin-bottom: .25em !important;
}

.help-seg>.message {
  margin-bottom: 2em !important;
  padding: .75em !important;
  border-color: grey !important;
  box-shadow: none !important;
  ;
}

.help-seg>.message>.content {
  padding: .75em !important
}

.help-edit-seg {
  margin-top: .5em !important;
}

.help-accordion>.title {
  padding: .5em !important
}

.help-accordion>.title>.icon {
  float: right !important;
}

.help-edit textarea {
  width: 100%;
  padding: 1em
}

.help-list .label {
  margin: .5em !important
}

.help-edit label {
  padding-bottom: 1em !important
}

.help-list {
  padding: .5em
}

.help-edit-edit>div:first-child {
  margin-bottom: 1em;
}

F .help-edit-edit {
  padding: 1em !important;
  margin-top: .5em
}

.help-seg>.help-accordion>.content.active>.message {
  padding: 1em !important
}

/* END - HELP CONTAINER*/

/* ************************************************ */

/* .swipeable-group-container {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0 0 1em 0;
} */

.swipeable-group-container>div:nth-child(1) {
  padding: .5em;
  background-color: rgb(60, 60, 60);
  color: white
}

.swipeable-group-container .card {
  margin-top: .2em !important;
  margin-bottom: .5em !important;
}

/* HORIZONTAL */
.horizontal-container {
  overflow-y: auto;
  height: 100%;
  scrollbar-width: thin;
}

.app-horz-item {
  padding: .5em .2em;
}

.app-horz-item:not(:last-child) {
  border-bottom: solid 4px darkgray;
}

.item-horz {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  overflow-x: auto;
  scrollbar-width: thin;
  overflow-x: auto !important;
}

.item-horz>.card {
  min-width: 280px;
}


.horizontal-container .content-content {
  overflow-x: hidden;
}

.item-horz {
  margin: .2em;
  scrollbar-width: thin !important;
}

.horizontal-container .content-content .item-horz::-webkit-scrollbar {
  width: 1px !important;
}

.sb-content::-webkit-scrollbar,
.no-sb::-webkit-scrollbar {
  display: none;
}

/* ************************************************ */

/* IMAGES CONTAINER */
.images-container {
  /* height: 100%;  */
  width: 100%;
  display: grid;
  grid-template-areas:
    "images-header"
    "images-content";
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  margin-bottom: 1em;
}

.images-header {
  grid-area: images-header;
  padding: 1em;
  border-bottom: var(--thin-border);
  font-weight: bold;
}

.images-content {
  grid-area: images-content;
  padding: 10px;
  min-height: 80px;
}

.images-content>.sw {
  padding: 18px;
}

.img-80 {
  max-width: 80px !important
}

.img-100 {
  max-width: 100px !important
}

.img-140 {
  max-width: 140px !important
}

.img-200 {
  max-width: 200px !important
}

.images-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.images-content:not(.viewManifest)>.images-picker {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.images-content:not(.viewManifest)>.images-picker .span-image {
  max-width: 100px;
  margin: .25em
}

.images-content.viewManifest>.images-picker .span-image {
  margin: .25em
}

.images-content>.images-picker .image-manifest>.image {
  max-width: none
}

.file-selected>img,
.file-unselected>img {
  margin: 0 auto !important;
}

.file-selected {
  border: 2px solid rgba(53, 241, 7, 0.924);
}

.file-unselected {
  border: 2px solid #cfcfcf;
}

.images-manifest .span-image {
  max-width: 100px;
  margin: .25em
}

.images-manifest .image-manifest>.image {
  max-width: none
}

/* END - IMAGES CONTAINER */

/* ************************************************ */

/* INLINE CONTAINER */
.inline-container {
  width: 100%;
}

/* flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
 }
 .inline-container > .content > .checker,
 .inline-container > .content > .icon {
  width: 40%;  
} */

.inline-container .content>.checkbox>input {
  height: 1rem !important;
}

.inline-container .content>.checkbox {
  float: right;
  font-size: small;
  margin-left: 1em
}

.inline-container .content>.checker {
  float: right;
  font-size: small;
  margin-left: 1em;
  margin-left: 2em;
}

.inline-container .content:nth-child(1) {
  padding: 1em !important
}

.inline-container .content.extra {
  padding-right: 0 !important;
}

.inline-container .content.extra {
  padding: 1em !important
}

.inline-container .content.extra.checkers {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.inline-container .header {
  margin-bottom: .5em;
  font-size: small;
}

.inline-container {
  margin-bottom: .5em;
  font-size: small;
}

.header.in-line>div>div:first-child {
  float: left;
}

.header.in-line>div>div:last-child {
  float: right;
}

.inline-item-container {
  width: 100%;
  font-size: .9rem;
}

/* END - INLINE CONTAINER */

/* ************************************************ */

/* ITEM UI CONTAINER */
.item-container {
  height: 100%;
  width: 100%;
  /* overflow-x: hidden;  */
  scrollbar-width: none;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "item-header"
    "item-content"
}

.item-container.ac {
  grid-template-rows: auto 1fr;
}

.item-container .group-swiper>div {
  overflow-y: hidden !important;
}

.item-container .group-swiper,
.item-container .header {
  vertical-align: top !important;
}

.item-container .image {
  margin: 0px 8px 8px 0
}

.item-container.cbn {
  height: auto !important;
  overflow-y: auto;
}

.item-container.fh {
  height: 100%;
}

.item-container.fic .content-content .card {
  height: 100% !important;
}

.item-container.padd {
  padding: 8px;
  padding-top: 16px
}

.item-container.fic .content-content .card {
  height: 100% !important;
}

.item-content .gml-container,
.item-content .gml-container>div {
  height: 100% !important;
}

.item-container.solo>.item-content {
  display: grid;
  grid-template-rows: auto 1fr;
}

.item-container.solo>.item-content>.solo {
  height: 100%;
  overflow-y: auto;
  padding: 0 !important;
}

.item-container.giftCards>.item-content {
  padding: .25em
}

.single-page-container>.item-container {
  border-radius: 3px;
  padding: .5em;
  margin-bottom: 1em;
}


/* LABEL CONTAINER */
.label-container,
.label-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}

.landing-container.srt {
  grid-template-areas:
    "label-header"
    "label-save"
    "label-content";
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
}

.label-container>.ui.label {
  padding: .5em .5em !important;
}

.label-container>.label-header {
  padding: 1em !important;
  border-bottom: var(--thin-borders);
  font-size: .7em;
}

.label-container>.label-save {
  padding: .5em !important;
  border-bottom: var(--thin-borders);
}

.label-container>.label-header>.check-toggle {
  float: right;
  margin-right: 1em;
}

.hcf-header>.check-toggle,
.hc-header>.check-toggle {
  margin-bottom: 1em !important;
}

.label-content {
  padding: .5em;
  overflow-y: auto;
  max-height: 300px;
  scrollbar-width: thin;
}

/* ************************************************ */

/* MAP */

.map-container {
  height: 100%;
  width: 100% !important;
  display: grid;
  padding: 0 !important;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "map-map"
}

.map-map {
  grid-area: map-map;
  position: relative;
  height: 30vh;
  width: 100%;
}

.map-container.split {
  grid-template-rows: 6fr 3fr 1fr;
  grid-template-areas:
    "map-map"
    "map-list-list"
}

/* MAP-CONTAINER */
.map-list-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  grid-template-columns: 100%;
  grid-template-rows: 2fr 2fr;
  grid-template-areas:
    "map-list-map"
    "map-list-list";
}

.map-list-container.gm {
  position: absolute;
}

.map-list-map {
  grid-area: map-list-map;
  position: relative;
  display: block;
}

.map-list-map .gm-style {
  font-size: 8pt !important
}

.map-list-list {
  grid-area: map-list-list;
  scrollbar-width: thin;
  overflow-y: auto !important;
  padding: 0;
}

.map-list-list .item>.icon {
  width: 2em !important;
  display: inline-block;
  position: relative;
}

/* ************************************************ */

/* MAP LIST  CONTAINER */
.content-content.mapList {
  padding: 0;
  margin: 0 !important;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
}

.content-content.mapList>div {
  height: 100%;
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas:
    "map-list-container-a"
}

.map-list-container-a {
  grid-area: map-list-container-a;
}

/* ************************************************ */

/* MESSAGES */

.messages-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "messages-content"
    "messages-footer";
}

.messages-content {
  grid-area: messages-content;
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: 3em !important;
}

.message-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
  padding: 1em .5em;
}

.message-line {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: "message-icon message-message message-date";
  margin-top: 1em;
}

.message-line.au {
  grid-template-areas: "message-date message-message message-icon";
}

.message-icon {
  grid-area: message-icon;
}

.message-from {
  grid-area: message-from;
  font-weight: bold;
}

.message-message {
  grid-area: message-message;
  border: var(--thin-border);
  padding: 1em .5em;
  border-radius: 8px;
}

.message-date {
  grid-area: message-date;
  font-weight: lighter;
  font-size: 9pt;
}

.message-from>.label {
  margin-bottom: .25em;
}

.message-line.au>.message-date,
.message-line>.message-message,
.message-line>.message-icon {
  text-align: left;
}

.message-line>.message-date,
.message-line.au>.message-message,
.message-line.au>.message-icon {
  text-align: right;
}

.message-line.au>.message-message {
  margin-left: 2em;
  margin-right: 0
}

.message-line>.message-message {
  margin-left: 0;
  margin-right: 2em
}

/* ************************************************ */


/* MENU ROW CONTAINER */
.menu-row-container {
  display: flex;
  gap: 1em !important;
  margin: 1em 0;
  padding-right: 0 !important;
  width: 100%;
  align-items: center;
}

.menu-row-container .menu-row-container.button {
  max-width: 300px !important;
}

.menu-row-container .button {
  margin-top: 2em;
}

/* END - MENU ROW CONTAINER */

/* ************************************************ */

.menu-select>.item {
  padding: .25em !important;
}

.menu-select .segment {
  padding: .25em
}

.menu-select.quantity>.item {
  padding: .5em !important;
}

/* MENU SELECT CONTAINER */
.menu-select-container {
  width: 100%;
  display: grid;
  padding-top: .5em;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "menu-select-header"
    "menu-select-toggles"
    "menu-select-items"
  ;
}

.menu-select-header {
  padding: 0 0 .5em;
  font-weight: 700;
}

.menu-select-header>.label {
  margin-left: 1em !important;
}

.menu-select-header>.checker {
  float: right;
}

.menu-select-toggles {
  padding: .5em;
  margin-bottom: .5em;
  border-bottom: var(--thin-border)
}

.menu-select-toggles .checkbox:nth-child(2) {
  float: right;
  margin-right: 10px
}

.menu-select-toggles .checkbox>label {
  font-size: small !important;
}

.menu-select-items {
  max-height: 300px;
  overflow-y: auto !important;
  scrollbar-width: thin;
}

.menu-select-items .item {
  font-size: .7rem;
  padding: .75em 1em !important
}

.menu-select-container.adding {
  margin-bottom: 1em;
}

/* END - MENU SELECT CONTAINER */

/* ************************************************ */

/* MODIFY CONTAINER */
.modify-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "modify-header"
    "modify-sub-header"
    "modify-content"
    "modify-footer";
}

.modify-container.sw {
  grid-template-rows: 1fr;
  grid-template-areas:
    "modify-content"
}

.modify-content {
  grid-area: modify-content;
  overflow-y: auto;
  overflow-x: hidden;
  padding: .5em 1em;
  scrollbar-width: thin;
}

.modify-content>.form {
  margin-bottom: 1em;
}

.modify-content.dataTable {
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  padding: .5em .5em;
}

.modify-content.direct {
  padding: .25em;
}

.modify-footer {
  grid-area: modify-footer;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.modify-header .checkbox {
  margin-left: 1em;
}

.modify-header .icon:nth-child(1) {
  margin-right: 1em;
}

.modify-header>.grid>.column:nth-child(1) {
  font-weight: bold;
  display: inline-flex !important;
}

.modify-header {
  grid-area: modify-header;
  padding: 1em
}

.modify-sub-header {
  grid-area: modify-sub-header;
  border-bottom: var(--thin-border);
  padding: 1em 1em;
  text-align: right;
}

/* END - MODIFY CONTAINER */

/* ************************************************ */

/* PAGE WRAPPER */
.page-wrapper-container {
  height: 100vh;
  width: 100%;
}

.page-wrapper {
  height: 100vh;
  width: 40%;
  margin: auto;
}

.page-wrapper.mobile {
  width: 100%;
}

.page-wrapper>div:nth-child(1),
.page-wrapper>div:nth-child(3) {
  display: none;
}

.page-wrapper>div {
  height: 100vh;
}

.page-wrapper.print {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin: 0 auto;
  width: 100%;
}

.page-wrapper.print>div:nth-child(2) {
  border: 1em solid rgb(240, 240, 240);
  border-radius: 10px;
  height: 100%;
}

.page-wrapper.print {
  display: contents
}

/* END PAGE WRAPPER */

/* ************************************************ */

/* page-area-container */
.page-area-container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* end page-area-container */

/* ************************************************ */

/* push-container */
.push-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: .05em .15em;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
}

.push-container>.push-header {
  padding: 0 0 .1em 0;
}

.push-container>.push-header>.push-caption {
  padding: .5em;
}

.push-container>.push-content {
  padding: .25em .15em 0 .15em;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.push-container>.push-content.np {
  padding: 0;
  overflow-y: auto;
  height: 100%;
}

/* end push-container */

/* ************************************************ */

/* SLIDER CONTAINER */
.slider-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 1em 0;
}

.slider-container>div:nth-child(1) {
  width: 25%;
  text-align: left;
}

.slider-container>div:nth-child(2) {
  width: 25%;
  text-align: center;
}

.slider-container>div:nth-child(3) {
  width: 50%;
  text-align: right;
  margin: .5em;
  margin-left: 1.5em;
}

.slider-container>div:nth-child(2)>.label {
  min-width: 40px;
}

/* END SLIDER CONTAINER */

/* ************************************************ */

/* SLIDING */
.sliding-container {
  margin: 0;
  padding: 1em 0
}

.sliding-container>.header {
  padding-left: 1em !important;
  padding-right: .5em !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
}

/* ************************************************ */

/* SPLASH CONTAINER */
.splash-container {
  height: 100%;
  width: 100%;
}

.splash-content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    "splash-text"
    "splash-logo"
    "splash-footer";
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat !important;
}

/* .splash-container.logo-only{
  grid-template-rows:  auto 1fr;   
  grid-template-areas:  
  "splash-logo" 
  "splash-footer"; 
} */

.splash-text {
  text-align: center;
  font-size: 1.5rem;
  margin: auto 0
}

.splash-content>.splash-logo {
  padding: 2em;
  max-width: 280px;
}

.splash-content>.splash-text {
  padding: 1em;
}

.splash-content.desktop {
  height: 100%;
  width: 100%;
}

.splash-content.fh {
  height: 100vh !important;
}

.splash-content.mobile {
  height: 100vh;
}

.splash-content.preview {
  height: 600px;
}

.splash-content {
  max-width: 100%;
}

.splash-text {
  grid-area: splash-text;
}

.splash-footer {
  grid-area: splash-footer;
  bottom: 0px;
}

.splash-logo {
  grid-area: splash-logo;
}

.splash-text>.image {
  max-width: 200px;
}

.splash-logo,
.splash-footer {
  bottom: 0px;
  position: relative;
  margin: auto auto
}

/* END - SPLASH CONTAINER */

/* ************************************************ */

/* START CONTAINER */
.start-container {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 4fr 1fr;
  grid-template-areas:
    "start-logo"
    "start-content";
  /* "start-footer";  */
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat !important;
}

.start-container {
  max-width: 100%;
}

.start-container.fh {
  height: 100vh !important;
}

.start-container.desktop {
  height: 100%;
  width: 100%;
}

.start-container.mobile {
  height: 100vh;
}

.start-logo {
  grid-area: start-logo;
}

.start-content {
  grid-area: start-content;
  display: block;
}

.start-footer {
  grid-area: start-footer;
  bottom: 0px;
}

.start-logo,
.start-footer {
  bottom: 0px;
  position: relative;
  margin: auto auto
}

.start-content {
  text-align: center;
  font-size: 1rem;
  margin: auto 0;
  padding: 2em;
}

.start-container>.start-logo {
  padding: 2em;
  max-width: 180px;
}

/* END - START CONTAINER */

/* ************************************************ */

/* SWIPE-CONTAINER */
.swipe-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    "filter"
    "tab"
    "alpha"
    "app-content-swiper"
}

.search:not(.ua-search):not(.icon) {
  width: 100%;
}

.ua-search {
  min-width: auto !important;
  font-size: .7rem !important;
}

.app-tab-menu-swiper {
  grid-area: app-tab-menu-swiper;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: none;
  overflow-x: auto;
}

.app-content-swiper {
  grid-area: app-content-swiper;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

/* ************************************************ */

/* TAB CONTAINER */
.tab-container {
  height: 100%;
  width: 100%;
  display: grid;
  /* grid-template-columns: 100%; */
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "tab-menu"
    "tab-content"
}

.tab-menu {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: thin;
  grid-area: tab-menu;
  margin-bottom: 0 !important;

}

.tab-content {
  grid-area: tab-content;
  width: 100%;
}

.tab-content.tbl {
  overflow: hidden;
}

.tab-content>.form,
.tab-content>.map-list-container {
  padding: .75em !important;
  max-width: 100%;
}

.tab-button {
  padding: .25em 0;
  display: flow-root;
  margin: 1em 0;
  text-align: right;
}

/* END - TAB CONTAINER */

/* ************************************************ */

/* TABLE CONTAINER */
.table-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-search"
    "table-content";
}

.table-container.normal {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-diff"
    "table-content"
}

.table-container.normal.count {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-count"
    "table-content"
}

.table-container.normal.search {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-search"
    "table-content"
}

.table-container.normal.search.props {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "table-search"
    "table-props"
    "table-content"
}

.table-container.normal.toggle.props {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "table-props"
    "table-diff"
    "table-content"
}

.table-container.normal.foot {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "table-diff"
    "table-content"
    "table-footer";
}

.table-container.normal.toggle {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-diff"
    "table-content"
}

.table-count {
  grid-area: table-count;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-search {
  grid-area: table-search;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-props {
  grid-area: table-props;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-diff {
  grid-area: table-diff;
  padding: 1em;
  border-bottom: var(--thin-border)
}

.table-footer {
  grid-area: table-footer;
  padding: .5em 0;
  text-align: center;
  border-top: var(--thin-border)
}

.table-content {
  grid-area: table-content;
  font-size: .8rem !important;
  padding: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}

.table-content.np {
  padding: 0;
}

.dbl-lbl>.label {
  margin-left: .5em !important
}


.table-count {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: .25em;
}

/* END - TABLE CONTAINER */

/* ************************************************ */

/* TAG FILTER */
.tag-filter-container {
  padding: .5em;
}

.tag-filter-content {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.tag-filter-content>.label {
  text-align: left;
}

/* ************************************************ */

/* update-container */
.update-container {
  border: 3px solid rgba(34, 36, 38, .15);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%)
}

.update-container>.header {
  text-align: center;
  font-weight: bold;
}

.update-container label,
.update-container .input,
.update-container .menu,
.update-container .menu>.item,
.update-container .menu>.item>span,
.update-container .dropdown {
  font-size: .8rem;
}

/* end update-container */


/* UPLOADER CONTAINER */
.uploader-container {
  height: 100%;
  width: 100%;
  display: grid;
  overflow-x: hidden;
  border: 1px dashed lightgray !important;
  padding: .5em !important;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas:
    "uploader-header"
    "uploader-path"
    "uploader-progress"
    "uploader-file-name"
    "uploader-content"
    "uploader-manifest"
}

.uploader-container.url {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas:
    "uploader-header"
    "uploader-path"
    "uploader-url"
    "uploader-progress"
    "uploader-content"
}

.uploader-header {
  grid-area: uploader-header;
  padding: 0em;
  text-align: right;
}

.uploader-url {
  grid-area: uploader-url;
  padding: 8px;
}

.uploader-path {
  grid-area: uploader-path;
  padding: 1em;
  font-size: .7rem;
  text-align: center;
}

.uploader-progress {
  grid-area: uploader-progress;
  padding: 8px;
}

.uploader-file-name {
  grid-area: uploader-file-name;
  padding: 8px;
}

/* border-top: var(--thin-border); */
.uploader-content {
  grid-area: uploader-content;
  padding: 1em;
}

.uploader-manifest {
  grid-area: uploader-manifest;
}

.uploader-manifest>img {
  padding: .5em
}

.uploader-url>.input {
  width: 100%;
}

.dark-mode .uploader-header {
  border-bottom: var(--dark-thin-border-settings);
}

.dark-mode .uploader-path {
  border-bottom: var(--dark-thin-border-settings);
}

.dark-mode .uploader-progress {
  border-top: var(--dark-thin-border-settings);
}

.uploader-content .uploader-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

/* END - UPLOADER CONTAINER */

/* ************************************************ */

/* ************************************************ */

/* ************************************************ */

/* ************************************************ */

/* END CONTAINERS */

.ssc-normal {
  height: 100%;
}

.modal-wide {
  height: 84% !important;
  width: 98% !important;
}

.menu-slim:not(.chk)>.item {
  padding: .75em 1em !important;
  font-weight: bold !important;
}

.menu-slim {
  border: 0 !important;
  height: 100%;
}

.menu-slim .item.iconed .icon:first-child {
  float: left !important;
  padding: 0 !important;
  margin-right: 1em !important;
}

/* .modal-wide > .vert-container > .content{max-width: 1000px; margin: auto;} */

.trcl {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.trcl>.label {
  margin-left: 0 !important;
  width: fit-content;
}

.field.disabled {
  opacity: .9 !important;
}

.side-wrapper div:first-child {
  overflow-x: auto;
}

.display-editor {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.display-editor .editor {
  border: 1px solid red !important;
  margin-left: 1em;
  height: 100%;
  overflow-y: auto;
}

.ssc-normal,
.ssc-normal .segment,
.ssc-normal .menu,
.ssc-normal .grid,
.ssc-normal .label:not(.mini):not(.tiny):not(.small):not(.large),
.ssc-normal .table {
  font-size: .8rem !important;
}

.seg-nbm {
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  grid-template-rows: auto 1fr !important;
}

.ms-button-group {
  padding: .5em .5em !important;
  text-align: center;
  margin-bottom: .25em;
  background-color: white;
  border-radius: 4px;
  width: 100%;
  overflow-x: auto;
}

.ccs {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.start-dimmer {
  height: 100%;
  z-index: 9999;
}

.suspense-dimmer {
  background-color: red !important;
}

/* START ELEMENTS */
.start-dots-container {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-dot-container {
  width: 10rem;
  height: 5rem;
  display: flex;
  justify-content: space-around
}

.start-dot {
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: rgb(0, 54, 191);
  border-radius: 50%;
}

.send-buttons {
  display: flex;
  justify-content: space-between;
}

.start-circle-container {
  position: relative;
  width: 3rem;
  height: 3rem;
  box-sizing: border-box;
  margin: auto;
}

.start-circle {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.4rem solid darkgrey;
  border-top: 0.4rem solid blue;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.start-circle.suspense,
.start-circle.pageItem {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2rem solid white;
  border-top-color: black;
}

/* 
.start-circle.app {
  border-top-color: green;
}

.start-circle.authorizing {
  border-top-color: yellow;
}

.start-circle.event {
  border-top-color: blue;
} */

/* END START ELEMENTS */
.save-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1em 0 0 0;
}

.vid-container>div {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}

.vid-container iframe {
  margin: 0 !important;
}

.lazyload-wrapper {
  width: 100% !important;
}

.lazyload-wrapper>div {
  width: 100% !important;
}

.pending-container {
  height: 100%;
}

.update-wrapper {
  width: 100%;
  height: 100%;
  padding: .5em .25em;
  margin: 0;
}


.fcm-container>.item>.content {
  font-size: .7rem;
  word-wrap: break-word;
  word-break: break-all;
}

.num-pad {
  display: grid;
  grid-template-rows: 1fr 1fr 9fr;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
}

.num-pad-value {
  font-size: 2.5rem;
  padding: .25em;
  font-weight: bold;
}

.num-grid .num-pad-pad {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  font-size: 2rem;
  height: 100%;
  width: 100%;
  gap: 10px;
  align-items: center;
  background-color: rgb(42, 42, 42);
  padding: .5em;
}

.num-grid .num-pad-pad>div {
  display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  justify-items: center;
}

.pending-wait {
  height: 100% !important;
  width: 100% !important;
  display: block
}

.push-pushable {
  height: 100%;
  width: 100%;
  /* padding: .5em 0 !important; */
}

.push-push {
  height: 100%;
}

.scroll-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}

.scroll-contents {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.scroll-element {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: scroll;
}

.scroll-element.no-padding {
  padding-top: 0;
}

.menu-wrapper {
  width: 100%;
  padding: .25em .5em .25em .2em;
  margin: 0 auto;
  ;
}

.phone-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 9fr 1fr;
  align-items: center;
  margin-bottom: 1em;
}

.phone-container.full {
  grid-template-columns: 1fr;
}

.phone-container .icon {
  height: 100%;
  width: 100%;
}

.page-area-content.dt.ima-d {
  height: 100vh;
}

.page-area-content.dt-off.mob.ima-m {
  height: 100vh;
}

.page-area-content.ns .app-container {
  margin: 0 auto !important;
}

.app-container.dtm {
  padding: 0 0em
}

.questions-container>.header>.ui>i.icon:nth-child(1),
.questions-container>.content>.ui>i.icon:nth-child(1) {
  margin-right: .75em;
  margin-left: 0;
  font-size: 1.5em !important;
}

.questions-container>.header>.ui>i.icon:nth-child(3),
.questions-container>.content>.ui>i.icon:nth-child(3) {
  margin: 0;
  font-size: 1.5em !important;
}

.questions-container>.list>.item>.content>.description {
  padding-top: .5em;
}

.app-notifications-container {
  height: 100%;
}

.app-notification-group div {
  font-size: .9rem;
}

.app-notification-title-container>div:nth-child(4) {
  font-size: .7rem !important;
  padding: .25em;
  color: rgb(148, 161, 255) !important;
}

/* .app-notification-group>div, */
.app-notification-date-group>div {
  padding: 1em .25em;
}

.app-notification-date-group>div:nth-child(1) {
  text-align: center;
  margin-top: 1em;
}

.app-notification-date-group>div:nth-child(2) {
  padding: 0;
}

.app-notification-group>div:first-child,
.app-notification-date-group>div:first-child {
  font-weight: bold;
  padding: .25em !important;
  /* border-bottom: 1px solid rgb(202, 202, 202); */
  margin-bottom: .5em;
  font-size: 1.1rem;
}

.note:not(.icon) {
  border: 1px solid rgb(212, 212, 212);
  padding: .5em;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.app-note-user {
  margin: .2em !important;
}

.app-note-title {
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.app-note-title>div:nth-child(2) {
  text-align: right;
}

.app-note-title>div>div:nth-child(1) {
  font-weight: bold;
}

.note>.note-time {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding: .75em 0;
  text-align: left;
  font-size: .9rem;
}

.note>.note-note {
  display: grid;
  grid-template-rows: auto 1fr;
  border: 1px solid rgb(212, 212, 212);
  padding: 1em;
}

.note>.note-note>div:nth-child(2) {
  padding: 1em;
}

.note>.note-description {
  border: 1px solid rgb(212, 212, 212);
  padding: 1em;
}

.btn-rows {
  padding: 0;
  display: grid;
  gap: .25em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
}

.btn-rows.one {
  grid-template-rows: repeat(1, 1fr);
}

.btn-rows.two {
  grid-template-rows: repeat(2, 1fr);
}

.modal-wide .btn-rows {
  display: flex;
  justify-content: flex-end;
}

.note>.note-buttons {
  padding: .75em 0;
  display: grid;
  gap: .25em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.buttons-two-row {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.notification-status .nsi-header {
  padding: .5em;
  text-align: center;
  background-color: rgb(202, 202, 202);
  font-weight: bold;
}

.notification-status .nsi.sl,
.notification-status .nsi.dl {
  display: flex;
  padding: .75em 0;
  /* border: 1px solid rgb(228, 228, 228); */
}

.notification-status .dl {
  flex-direction: column;
}

.notification-status .sl {
  flex-direction: row;
  justify-content: space-between;
}

.notification-status .dl>div:nth-child(1) {
  text-align: center;
  padding: 1em 0 0 0;
}

.notification-status .dl>div:nth-child(2) {
  padding: 1em;
}

.notification-status .sl {
  grid-template-columns: 3fr 2fr;
}

.notification-status .sl>div:nth-child(1),
.notification-status .dl>div:nth-child(1) {
  font-weight: bold;
}

.notification-status .sl>div:nth-child(2),
.notification-status .dl>div:nth-child(2) {
  font-weight: normal;
}

.notification-status .nsstp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.notification-status .nsstp>div {
  padding: .25em;
}

.app-notifications .title>div:last-child {
  text-align: right;
}

.app-notifications .checker {
  float: right;
  font-size: 1em !important;
}

.app-notifications .checker label {
  margin-right: .5em;
  font-size: .8em;
}

.app-notifications>.title>div:nth-child(3) {
  margin-left: .5em !important
}

.seg-cal-select>.checker {
  padding: .5em;
  text-align: right;
}

.app-notification-container {
  display: grid;
  grid-template-rows: auto auto auto auto;
  padding: 1em !important;
  height: 100%;
  border-radius: 8px;
  gap: .5em;
  margin-bottom: .5em;
  border: 1px solid rgb(229, 229, 229);
}

.app-notification-container>div:nth-child(4) {
  font-size: .7rem;
  color: rgb(161, 161, 161) !important;
}

.app-notification-content {
  display: none;
}

.app-notification-content.active {
  display: block;
}

.table-filter-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 2fr;
}

/* APP HEADER */
.top-menu {
  border-radius: 0;
}

.top-menu>.item:nth-child(1) {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.top-menu .right>.item {
  padding: 0 .35em !important;
}

.top-menu>.item:nth-child(1) {
  padding-left: .75em !important;
  padding-right: .75em !important
}

.top-menu>.menu-title {
  display: grid !important;
  grid-template-rows: auto auto auto;
  padding-left: .25em !important;
}

.top-menu>.menu-title>div:not(:first-child) {
  font-size: .8rem;
  padding-top: .5em;
  opacity: .7;
}

.top-menu>.menu-title {
  font-size: .8rem;
  /* padding-top: 0.25 !important;
  padding-bottom: 0 !important; */
}


.app-topMenu {
  grid-area: app-topMenu;
}

.dt-mode .app-content,
.dt-mode .item-content,
.dt-mode .app-swipe-content,
.dt-mode .react-swipeable-view-container>div,
.dt-mode .react-swipeable-view-container>div>div {
  height: auto !important;
  overflow-y: hidden !important;
}

/* .react-swipeable-view-container>div,
.react-swipeable-view-container>div>div {
  height: 100% !important;
} */

.single-page-container {
  height: 100%;
  overflow-y: auto;
}

.single-page-container .item-container {
  height: auto;
  border: 0 !important;
  margin-bottom: 0;
}

.single-page-container .item-header {
  font-weight: bold;
  padding: .5em;
}


.single-page-container .item-content {
  overflow-y: hidden !important;
}

.dt-mode .app-container.mcnf>.app-content {
  padding-top: 4em !important
}

.dt-mode .react-swipeable-view-container>div {
  height: auto !important;
}

.dt-mode .item-container>.item-content {
  height: auto !important;
  overflow-y: hidden !important;
}

.settings-app-content {
  margin: auto !important;
}

[propname=selectedOnly] [propname=selectAll],
[propname=selectAll]>label {
  font-size: .8rem !important;
}

/* DATA-LIST */
.data-list-container {
  padding: 1em;
  height: 100%;
  font-size: .8rem;
}

.data-list-list>.item {
  justify-content: center !important;
}

.list-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dm-mode .app-header img {
  max-width: 200px !important;
  max-height: 200px !important;
}

.icons-left>.item.sel {
  opacity: .6;
}

/* This will line up the the icons to the left (sideMenu/iconsLeft */
.icons-left .item {
  display: flex !important;
  padding: .75em !important
}

.icons-left>.item {
  align-self: center !important;
}

.icons-left>.item>img,
.icons-left>.item>.icon {
  float: left !important;
  margin-right: 1em !important;
  margin-left: 0 !important;
  align-self: center;
}

.icons-left>.item>img {
  vertical-align: middle !important;
  display: inline-block !important;
}

.vert-container>.content>.menu>.item {
  align-items: center !important;
}

.app-users-container {
  padding: .25em !important;
}

.app-users-container .header {
  font-size: 1rem !important;
  margin-bottom: .25em;
}

.app-ticketing-container {
  padding: .5em !important;
}

.app-ticketing-container>.list>.item {
  display: grid !important;
  grid-template-columns: 6fr 1fr 1fr 1fr 1fr !important;
  align-items: center;
}

.app-ticketing-container .dropdown,
.app-ticketing-container .dropdown>.menu>.item {
  font-size: .9em !important;
}

.app-ticketing-container>.list>.item>div:not(:first-child) {
  text-align: center;
}

.app-ticketing-container .icon {
  margin-left: .5em
}

.app-container .menu:not(.menu-slider):not(.sports-active) {
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0;
}

/* app-note */

/* SV DATA */
.sv-data {
  width: 100%;
  height: 100%;
}

.sv-data.nc {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
}

.header.vds {
  padding: 0 !important
}

.sv-data-footer {
  padding: .5em
}

.sem-carosel {
  padding: .25em;
}

.sem-carosel .banners-image>.image {
  height: 60px;
  width: auto;
}

/* IMAGE THUMBNAIL */
.img-ts-col {
  /* padding: .25em !important; */
  border: var(--thin-borders);
}

.img-ts-col.pdf {
  border: none
}

.img-ts-container {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.img-ts-image {
  position: absolute;
  left: 50%;
  top: 50%;
  /* max-width: unset !important; */
  text-align: center;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.img-ts-image.pending {
  opacity: .35
}

;

.img-ico-sel {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.img-ico-view {
  position: absolute;
  top: 5px;
  right: 2px;
  z-index: 1;
}

.grid .img-ts-container>.img-ts-image {
  width: 100% !important;
}

.img-profile {
  padding: 3em !important;
  border: var(--thin-borders);
}

.item-header {
  grid-area: item-header;
}

.item-header {
  /* display: grid; */
  width: 100%;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 100%;
}

.search-page {
  padding: .5em;
  border: 0
}

.search-page .menu-search,
.item-header .menu-search {
  width: 100% !important;
}

.search-page>.item.search,
.item-header .menu-search>.menu {
  width: 100% !important;
}

.search-page>.item.search>.input.labeled {
  width: 100% !important;
}

.search-page>.item.search>.input.labeled .icon,
.item-header .menu-search>.menu .label>.icon {
  margin: 0 !important
}

/* .item-header div div{width: 100% !important;} */

.item-caption {
  grid-area: item-caption;
}

.dt-mode .item-caption {
  grid-area: item-caption;
  padding: 1em;
  font-size: 1.2rem;
  font-weight: bold;
}

.item-content {
  grid-area: item-content;
}

.item-content {
  overflow-y: auto;
  -webkit-overflow-y: auto;
  -webkit-box-flex: 0;
}

/* NEW */
.item-content>.cards.solo {
  margin: 0 !important;
}

.item-mask {
  grid-area: item-mask;
}

*/
/* END - ITEM UI CONTAINER */

/* .item-filter{text-align: right; }  */
.item-filter .menu-filter.fullPage,
.item-filter .menu-filter.tags {
  display: flex;
  padding: .5em;
}

.item-filter .menu-filter.fullPage {
  justify-content: flex-end;
}

.item-filter .menu-filter.tags {
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  scrollbar-width: thin;
  flex: 1 1 auto;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
}

.item-filter .menu-filter.menu {
  padding: .5em;
  border: 0;
}

.menu-alphabet {
  scrollbar-width: thin;
}

/* pad the cardNameSectionOnly smaller */
.cardNameSectionOnly .sec-container.header .sec-content {
  padding: .75em 0em .75em .5em;
}

/* END - CONTENT CONTAINER */

/* FULL HEIGHT */
.app-container,
.app-content,
.app-swipe-container,
.item-container {
  height: 100%;
  overflow-y: hidden !important;
}

/* CARDS CONTAINER */
.cards-container {
  padding: 0;
  overflow-x: hidden;
  margin-top: 4px !important;
  width: 100%;
}

.cards-container.profilePlus,
.cards-container.schedulePlus {
  margin-top: 0px !important;
}

.cards-container.solo {
  height: 100%;
}

.cards-container.solo>.card {
  border-radius: 0 !important;
}

.item-vert:not(.solo) {
  padding: .2em !important
}

.dt-off .cards-container.item-vert>.motion-container.element>.card,
.dt-off .cards-container.item-vert>.card {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: .5em !important;
}

.profilePlus>.card,
.card-profile.plus>.profilePlus>.card {
  margin: 0 !important;
}

.cards-container.multi {
  justify-content: space-between;
  align-content: stretch;
}

.cards-container.multi>.card {
  margin: .25em !important;
  width: calc(33.33333333% - 1em) !important;
  font-size: .8rem !important;
}

.cardMini {
  padding: 8px !important
}

.cardMini .sec-container {
  border: none !important
}

.cardMini .cards-container {
  display: grid !important;
  flex-direction: column !important;
  grid-gap: 6px;
  justify-content: space-evenly;
}

.cardMini .ipr-2 {
  grid-template-columns: repeat(2, 50%);
  font-size: .9rem;
}

.cardMini .ipr-3 {
  grid-template-columns: repeat(3, 33%);
  font-size: .8rem;
}

.cardMini .ipr-4 {
  grid-template-columns: repeat(4, 25%);
  font-size: .7rem;
}

.card.card-profile.plus,
.cards-container.profilePlus .card {
  border-radius: 0 !important;
  box-shadow: none;
}

.scroll-container>.card {
  width: 100%;
}

.cardMini .card {
  overflow-y: auto;
  scrollbar-width: none;
  height: 100%;
}

.cardMini .card {
  overflow-x: hidden;
}

.cardMini .card {
  margin: .25em !important;
  width: auto !important;
}

.cardMini .card .header {
  margin: 0 auto !important;
}

.cardMini .card .prc {
  font-size: .8rem !important
}

.prc.edt {
  display: grid;
}


/* .prc{width: 100%;} */

/* .solo .card {height: fit-content;} */
/* END -SEG CARD GROUP */

/* CARD */
.card>.header>.header {
  border-radius: 0 !important;
}

.dt-off .card:not(.normal):not(.card-normal):not(.inline-container):not(.card-profile):not(.card-schedule):not(.card-access):not(.card-note):not(.card-ticket):not(.card-bus-schedule)>.content {
  padding: 0 !important;
}

.busSchedule .cards {
  padding: .5em !important;
}

.busSchedule .cards .label {
  width: 100%;
  text-align: center;
}

.busSchedule .cards .content.extra {
  display: flex;
  flex-direction: row;
}

.card-normal.gift-card {
  padding: .5em 0;
  border: solid 2px rgb(185, 185, 247);
  height: 120px;
}

.card-normal.gift-card .buttons>.button:first-child {
  background-color: rgb(255, 216, 216) !important;
}

.card-normal.gift-card .buttons>.button:last-child {
  background-color: rgb(212, 255, 207) !important;
}

.card-normal.gift-card {
  border: solid 2px rgb(221, 221, 221);
}

.card-normal.gift-card>.content>.meta>.label {
  margin-top: 1em;
}

.dt-off .card.full {
  border-radius: 0;
  height: 100%
}

/* .card-access > .content > .header{margin-bottom: 1em !important;} */
.card.card-schedule>.content,
.card.card-profile>.content {
  padding: .75em .25em .5em .25em !important;
}

.cards .card-schedule {
  align-self: baseline !important;
}

/* INDIVIDUAL CONTIAINERS */


.action-sidebar>.item {
  padding: 1.5em !important;
  font-weight: bold !important;
  font-size: .9rem;
}

/* END - ACTION CONAINTER */

/* CONFIRM BUTTONS */
.btn-confirm {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr;
  padding-bottom: .5em !important;
  margin-bottom: 8px !important
}

.btn-confirm.alt-ib {
  grid-template-columns: 1fr 1fr 8fr 1fr;
}

.btn-confirm.alt-ib.alt-cb {
  grid-template-columns: 1fr 1fr 1fr 6fr 1fr;
}

.btn-confirm.help {
  grid-template-columns: 1fr 8fr 1fr 1fr;
}

.btn-confirm.no-commit {
  grid-template-columns: 1fr 8fr;
}

.btn-confirm.no-commit.help {
  grid-template-columns: 1fr 8fr 1fr;
}

.btn-confirm.confirmed {
  grid-template-columns: 8fr 1fr;
}

.btn-confirm .button {
  padding: 1em !important
}

.btn-confirm .button:nth-child(2) {
  text-align: left;
}

.btn-confirm.pending,
.btn-confirm.upload {
  grid-template-columns: 8fr 1fr !important;
}

.btn-confirm.alt-ib .button:nth-child(1),
.btn-confirm.alt-cb .button:nth-child(2) {
  border-right: 2px solid white;
}

.btn-confirm>.button.btn-input {
  padding: 0 .25em !important
}

.btn-confirm>.button.btn-input>.input {
  width: 100%;
}

.btn-confirm .rc-input-number {
  width: 100% !important;
}

.btn-confirm .rc-input-number-handler-wrap {
  width: 100% !important;
}

.btn-confirm .rc-input-number-input-wrap {
  width: 100% !important;
}


/* .78571429em 1.5em .78571429em */

.upload-btn-wrapper {
  height: 34px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 30px !important;
  position: relative !important;
  left: 0 !important;
  /* top: -30px !important; */
  opacity: 0 !important;
  z-index: 1 !important;
}

.upload-btn-wrapper .btn,
.upload-btn-wrapper input[type=file] {
  width: 200px !important;
  height: 34px !important;
  position: absolute;
}

/* END - CONFIRM BUTTONS */

/* ************************* */

.address-container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 30% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row];
}

.box {
  border-radius: 5px;
  padding: .25em .5em
}

.address {
  grid-column: span 2;
  grid-row: row 1;
}

.city {
  grid-column: span 2;
  grid-row: row 2;
}

.state {
  grid-column: span 1;
  grid-row: row 3;
}

.zip {
  grid-column: span 1;
  grid-row: row 3;
}

.address-container.mapp {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 30% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row] auto [row];
}

.address-container.mapp.url {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 50% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row] auto [row] auto [row];
}

.box.mapp {
  border-radius: 5px;
  padding: .5em;
}

.map-view.mapp {
  grid-column: span 2;
  grid-row: row 1;
  width: 100% !important;
  height: 200px !important;
  position: relative;
}

.address.mapp {
  grid-column: span 2;
  grid-row: row 2;
}

.city.mapp {
  grid-column: span 2;
  grid-row: row 3;
}

.state.mapp {
  grid-column: span 1;
  grid-row: row 4;
}

.zip.mapp {
  grid-column: span 1;
  grid-row: row 4;
}

/* ************************* */


/* ************************* */

.cal-months-container {
  width: 100%;
  height: 100%;
  padding: .1em;
  display: grid;
  grid-template-rows: auto 1fr;
}

.cal-close {
  display: grid;
  gap: .2em;
  grid-template-columns: 1fr;
  margin-bottom: .15em;
}

.cal-close.today {
  grid-template-columns: 1fr 1fr;
}

.cal-close.missing.today {
  grid-template-columns: 2fr 5fr 2fr;
}

.cal-close>div {
  padding: .5em;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.cal-months {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cal-month {
  font-weight: bold;
  text-align: center;
  padding: .5em;
  border-radius: 4px;
}

/* CALENDAR CONTAINER */
.calendar-container {
  display: grid;
  padding: .25em;
  gap: .2em;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto repeat(5, 1fr);
  /* max-width: 600px; */
  margin: auto;
}

.calendar-container>div {
  border-radius: 6px;
  padding: .5em;
  text-align: center;
}


.norm-count>span>div {
  margin: 0;
}

/* END - CALENDAR CONTAINER */

/* CALENDAR */
.cal-select-container {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
}

.cal-select-container>div {
  padding: 1em;
  text-align: center;
  font-weight: bold;
}

.cal-select-container>div:nth-child(0) {
  border-top: 1px solid grey
}

.cal-select-container>div {
  border-right: 1px solid grey
}

.cal-select-container>div {
  border-bottom: 1px solid grey
}

.cal-select-container>div:nth-child(0) {
  border-left: 1px solid grey
}

/* CALENDAR - MONTH */
.month-select-container {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background: #fff;
}

.month-select-container>div {
  padding: 1.78em;
  /* border: 1px solid grey; */
  text-align: center;
  font-weight: bold;
}

/* ************************* */

.header-checker {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.header-checker>.checker {
  text-align: right;
}


.push-pushable.inverted .menu-slider-sidebar,
.push-pushable.inverted .menu-slider {
  border-radius: 8px;
}

.modal-wide .sw-wrapper.dm {
  background-color: black;
}

.modal-wide .sw-wrapper.dm>.content {
  width: 60%;
  margin: 0 auto;
  border: 1px solid var(--topper);
}

/* .sw-wrapper.dm>.content>.form {
  padding: .5em;
} */

.sem-menu {
  padding: 1em;
  height: 100%;
}

.sw-wrapper.dm .divider {
  color: rgb(209, 209, 209);
}

.sw-wrapper .push-pushable {
  padding: 0
}

.sw-wrapper .push-container {
  padding: 0
}

.sw-wrapper .push-content {
  padding: 0
}

.sw-wrapper.sub {
  padding: .25em
}

/* ************************* */

/* JITSI */

.jitsi-wrapper {
  height: 100%;
}

.jitsi-wrapper>div {
  height: 100% !important;
  width: 100% !important;
}

#jitsi-container {
  height: 100% !important;
}

.chrome-extension-banner {
  width: 200px !important;
}

.fresnel-container {
  padding: 0 !important;
  margin: 0 !important
}

.hdr-containerXX {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center
}

.hdr-container {
  display: grid;
  padding: .5em;
  justify-content: center;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.hdr-container>div:nth-child(1) {
  text-align: left;
}

.hdr-container>div:nth-child(2) {
  text-align: center;
  margin: auto;
  font-weight: bold;
}

.hdr-container>div:nth-child(3) {
  text-align: right;
}

.sw-wrapper.full {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sw-wrapper>.header {
  border-bottom: none !important;
}

.sw-wrapper>.header>.menu {
  padding: 0 !important;
}

.sw-wrapper>.header>.menu>.item:nth-child(2) {
  font-weight: bold;
}

.sw-wrapper.topper {
  grid-template-rows: auto auto 1fr !important;
  height: 100% !important;
  grid-template-areas:
    "topper"
    "header"
    "content" !important
}

.sw-wrapper.topper.hoc {
  grid-template-rows: auto auto auto 1fr !important;
  height: 100% !important;
  grid-template-areas:
    "topper"
    "header"
    "notification"
    "content" !important
}

.modal-full>.vert-container.fpw {
  border: 4px solid var(--topper) !important;
}

.fpw .footer:not(.footer-right),
.sw-wrapper.topper .footer {
  background-color: var(--topper) !important
}

.hdr-container div[width='10'] {
  width: 10% !important;
}

.hdr-container div[width='20'] {
  width: 20% !important;
}

.hdr-container div[width='30'] {
  width: 30% !important;
}

.hdr-container div[width='40'] {
  width: 40% !important;
}

.hdr-container div[width='50'] {
  width: 50% !important;
}

.hdr-container div[width='60'] {
  width: 60% !important;
}

.hdr-container div[width='70'] {
  width: 70% !important;
}

.hdr-container div[width='80'] {
  width: 80% !important;
}

.hdr-container div[width='90'] {
  width: 90% !important;
}

.grid.drop-list .column {
  padding: .2em !important;
}

/* 
.ui[class*="three column"].grid >.row > [data-rbd-draggable-context-id],
.ui[class*="three column"].grid > [data-rbd-draggable-context-id] {
  width: 33.33333333% !important;
  padding: 0 !important; 
  display: inline-block; 
} */

/* ************************* */


.list-with-header .content {
  padding: .25em 0 .5em 0 !important;
  font-weight: bold;
}

/* ************************* */

.menu-slider-sidebar>.item {
  display: flex !important;
  padding: 1em 1em !important;
  justify-content: space-between !important;
}

.menu-alphabet-slider {
  display: grid !important;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr) !important;
}

.menu-alphabet:not(.vertical) {
  width: 100%;
  overflow-y: auto;
}

.copy-container {
  display: grid;
  grid-template-rows: auto 1fr;
}

.copy-container>div {
  padding: 1em;
}

.npicker-container {
  display: flex;
  flex-direction: row;
}

.time-container {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr
}

.time-container label {
  font-weight: bold;
  font-size: .92857143em;
}

.time-container>.grid {
  width: 100%;
  margin: 0 !important;
}

.time-container>.grid>.column {
  padding: 0 .25em 0 0 !important;
}

.time-container>.grid .dropdown {
  width: 100%;
  min-width: 1em !important;
}

.time-container>.menu {
  width: 50px !important;
}

/* ACTION CONAINTER */
.news-container {
  width: 100%;
  padding-top: .2em !important;
  margin: 0 !important;
}

.news-container .card {
  padding: 0 !important;
  margin: .2em !important;
}

/* ************************* */

/* PS CONTAINER */
.ps-container {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "ps-header"
    "ps-pager"
}

.ps-header {
  grid-area: ps-header;
  padding: 0 0 .5em;
  font-weight: 700;
}

.ps-pager {
  grid-area: ps-pager;
  text-align: center;
  padding: .5em 0 1em 0;
  width: 100%;
  overflow-x: auto !important;
  scrollbar-width: none;
}

.pgl-contaner {
  display: flex;
}

/* END - PS CONTAINER */

.question-container>textarea {
  width: 100% !important;
  height: 100% !important;
  border: .1px solid lightgrey;
  outline: none;
  padding: .5em;
}

.questions-container .list>.item>.icon {
  float: right;
}

/* ************************* */
.qr-content {
  padding: 1em;
  text-align: center;
  margin: auto 0;
}

.em-content {
  padding: 1.2em;
  text-align: center;
  margin: auto 0;
  font-size: 1.2rem !important;
}

/* ************************* */
.card-profile.fnd {
  border: 2px solid blue !important;
}

.card-profile.rct {
  border: 2px solid green !important;
}

.card-schedule>.content,
.card-profile>.content {
  padding: 1em !important;
}

.card-profile>.content>.header {
  display: flex !important;
}

.card-profile>.content>.header>div:not(.label) {
  padding-right: .2em !important;
}

.card-profile>.content>.header>div.label {
  margin-right: 1em !important;
}

.card-profile .card-image>.image {
  padding: 0 .25em 0 0 !important;
}

.card-profile .header>.label {
  margin: 0 0 .5em 0 !important;
}

.card-schedule>.content>.meta .icon,
.card-profile>.content>.meta .icon {
  margin-right: .5em !important;
}

.card-profile.plus,
.card-schedule.plus {
  width: 100% !important;
  border-radius: 0 !important;
  padding: .25em !important;
  margin-bottom: 0 !important;
}

.card-profile>.content {
  width: 100% !important;
}

.card-img.card-profile>.content.extra {
  padding: .5em !important;
}

/* card image */
.card-img.normal .card,
.card-img.nameSectionOnly .card,
.card-img:not(.img-inline).card-profile {
  display: grid !important;
}

.card-img.nameSectionOnly .card-content .header,
.card-img.img-inline.card-profile .card-content .header {
  grid-template-columns: 1fr 4fr !important;
  grid-template-areas: "card-image card-content";
}

.card-img.nameSectionOnly .card-content .icon,
.card-img.img-inline .card-image .icon {
  font-size: 1.2em;
}

.card-img.img-left.normal .card,
.card-img.img-left.nameSectionOnly .card,
.card-img.img-left.card-profile {
  grid-template-columns: 1fr 3fr !important;
  grid-template-areas: "card-image card-content";
}

.card-img.img-left.normal .card.plus,
.card-img.img-left.nameSectionOnly .card.plus,
.card-img.img-left.card-profile.plus {
  grid-template-columns: 2fr 5fr !important;
  grid-template-areas: "card-image card-content";
}

.card-img.img-right.normal .card,
.card-img.img-right.nameSectionOnly .card,
.card-img.img-right.card-profile {
  grid-template-columns: 1fr 3fr !important;
  grid-template-areas: "card-content card-image";
}

.card-img.img-top.normal .card,
.card-img.img-top.nameSectionOnly .card,
.card-img.img-topLeft.nameSectionOnly .card,
.card-img.img-topRight.nameSectionOnly .card,
.card-img.img-top.card-profile,
.card-img.img-topLeft.card-profile,
.card-img.img-topRight.card-profile {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image"
    "card-content";
}

.card-img.img-top.normal .card-image {
  text-align: center;
}

.card-img.img-bottom.normal .card,
.card-img.img-bottom.nameSectionOnly .card,
.card-img.img-bottomLeft.nameSectionOnly .card,
.card-img.img-bottomRight.nameSectionOnly .card,
.card-img.img-bottom.card-profile,
.card-img.img-bottomLeft.card-profile,
.card-img.img-bottomRight.card-profile {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-image";
}

.card-img.nameSectionOnly .card-image {
  padding: .5em 0 .5em .5em
}

.card-content {
  grid-area: card-content;
}

.card-image {
  grid-area: card-image
}

.card-indics {
  grid-area: card-indics;
  padding: .5em;
  border-top: 1px solid white;
}

.card-indic {
  padding: .25em;
}

.card-indic .icon {
  margin-right: .5em;
}

.card-image.il-topLeft,
.card-image.il-bottomLeft,
.img-topLeft .card-image,
.img-bottomLeft .card-image {
  text-align: left;
}

.card-image.il-topRight,
.card-image.il-bottomRight,
.img-topRight .card-image,
.img-bottomRight .card-image {
  text-align: right;
}

.card-image.il-top,
.card-img.img-top .card-image {
  text-align: center;
}

.card-image.il-topRight img,
.card-image.il-bottomRight img,
.img-topRight .card-image img,
.img-bottomRight .card-image img,
.card-image.il-topLeft img,
.card-image.il-bottomLeft img,
.img-topLeft .card-image img,
.img-bottomLeft .card-image img {
  padding: .5em;
}

.card-image .ui.image {
  /* width: 100%; */
  margin: auto;
}

.giftCards .card-image,
.giftCard .card-image {
  margin-bottom: .5em;
}

/* card qr */
.card-qr:not(.img-inline).card-profile {
  display: grid !important;
}

.card-qr.qr-inline .card-profile .card-content .header {
  grid-template-columns: 1fr 4fr !important;
  grid-template-areas: "card-qr card-content";
}

.card-qr.qr-left .card-profile {
  grid-template-columns: 1fr 4fr !important;
  grid-template-areas: "card-qr card-content";
}

.card-qr.qr-right .card-profile {
  grid-template-columns: 4fr 1fr !important;
  grid-template-areas: "card-content card-qr";
}

.card-qr.qr-top .card-profile,
.card-qr.qr-topLeft .card-profile,
.card-qr.qr-topRight .card-profile {
  grid-template-rows: 1fr auto !important;
  grid-template-areas:
    "card-qr"
    "card-content";
}

.card-qr.qr-bottom .card-profile,
.card-qr.qr-bottomLeft .card-profile,
.card-qr.qr-bottomRight .card-profile {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-qr";
}

.card-profile.indic {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image card-content"
    "card-indics card-indics" !important;
}

.card-profile.indic.top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-indics card-indics"
    "card-image card-content" !important;
}

.card-profile.indic.card-img.img-top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image"
    "card-content"
    "card-indics" !important;
}

.card-profile.card-img.img-top>.card-image {
  margin: auto;
  padding: 1em !important;
}

.card-profile.card-img.img-top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-image "
    "card-indics" !important;
}

.card>.card-content>.card-icon {
  float: right !important;
  margin: .5em;
  /* padding: .75em; */
}

.card-content {
  grid-area: card-content;
}

.card-image {
  grid-area: card-image
}

.card-qr {
  grid-area: card-qr
}

.card-auth-wrapper {
  width: 100%;
}

.card-auth-wrapper>.card {
  margin-bottom: .25em;
}

.card-auth {
  padding: .25em 1em;
  font-size: .8rem;
  background-color: rgb(220, 220, 255);
  width: 100%;
  border: 1px solid grey;
  margin-bottom: .5em;
  border-radius: 8px;
}

.qr-topLeft .card-qr,
.qr-bottomLeft .card-qr {
  text-align: left;
}

.qr-topRight .card-qr,
.qr-bottomRight .card-qr {
  text-align: right;
}

.card-qr .card-profile {
  display: grid !important;
  grid-template-columns: 4fr 1fr !important;
}


.card-schedule .header {
  font-size: 1.1rem !important;
}

.sec-container:not(.header) {
  display: grid;
  overflow-x: hidden;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "sec-header"
    "sec-content"
}

.sec-header {
  grid-area: sec-header
}

.sec-content {
  grid-area: sec-content;
}

.sec-content.noCaptions {
  padding: .5em !important;
}

/* ************************* */

/* SIDEBAR */
.app-sidebar-left-wrapper {
  z-index: 10000 !important;
  width: 100%;
  overflow-x: hidden;
  max-width: 90%;
}

.apl-3 .app-sidebar-left-wrapper {
  max-width: 90% !important;
}

.app-sidebar-left-wrapper.full-width {
  max-width: 100%;
}

.dt-on .app-sidebar-left-wrapper {
  max-width: 30%;
}

.app-navigation .menu>.item>.image {
  display: inline-block;
  max-height: 30px !important;
  max-width: 30px !important;
}

.app-sidebar-left-wrapper .menu>.item>.image {
  display: inline-block;
  margin-right: 1em;
  max-height: 40px !important;
  max-width: 40px !important;
}

/* END - SIDEBAR */

/* INDIVIDUAL ITEMS */

/* CHECKER */
.table .checker {
  justify-content: center;
}

.standings-table .table .checker {
  justify-content: left;
}

.checker .label {
  border-radius: 4px;
  border: 1px solid #9dbbd1 !important;
  padding: .5em .25em;
  min-width: 30px;
  text-align: center;
  margin-right: .25em !important;
}

.checker .icon {
  border-radius: 4px;
  border: 1px solid #9dbbd1 !important;
}

.checker label {
  margin-left: 1em !important;
  align-self: center;
}

.form-container .checker,
.form>.checker,
.checker.inline {
  padding: 0 0 1.5em 0;
}

.checker {
  display: flex;
  justify-items: center;
  align-items: flex-end;
}

.checker-text {
  display: flex;
  flex-direction: column;
  justify-items: left;
}

.checker-text>label:nth-child(1) {
  font-weight: bold;
}

.checker-text>label {
  align-self: normal;
}

/* .checker.alt {
  display: grid;
  justify-items: start !important;
  align-items: start !important;
} */

.inline-container .content>.checker label {
  margin-left: .25em !important
}

.sw-wrapper .checker {
  margin-bottom: .25em;
}

.menu-select-items .checker.counter {
  display: grid;
  grid-template-columns: 1fr 8fr 2fr;
  text-align: left !important;
  justify-items: left !important;
  align-items: center;
}

.checker.counter .rc-input-number {
  margin: 0 !important;
}

.wfw .checker {
  padding: 0 0 1.5em 0;
}

/* ITEM ADMIN */
/* https://the-echoplex.net/flexyboxes/?fixed-height=on&legacy=on&display=flex&flex-direction=column&flex-wrap=nowrap&justify-content=flex-end&align-items=flex-end&align-content=flex-end&order%5B%5D=0&flex-grow%5B%5D=0&flex-shrink%5B%5D=1&flex-basis%5B%5D=200px&align-self%5B%5D=auto&order%5B%5D=0&flex-grow%5B%5D=0&flex-shrink%5B%5D=1&flex-basis%5B%5D=100%25&align-self%5B%5D=auto&order%5B%5D=0&flex-grow%5B%5D=0&flex-shrink%5B%5D=1&flex-basis%5B%5D=200px&align-self%5B%5D=auto */
.item-popup {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  position: fixed;
  right: 1.5em;
  z-index: 1000 !important;
}

.react-swipeable-view-container>div>.golf-popup {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  position: fixed;
  right: 1.5em;
  z-index: 1000 !important;
}

.time-keeper {
  text-align: center;
  padding: 2em
}

.pusher-container .item-popup {
  bottom: 2em;
}

.item-popup.single-left {
  left: 2em;
  align-items: flex-start;
}

.item-popup.single-right {
  right: 2em !important;
  align-items: flex-start;
}

.item-popup.solo-right {
  position: relative;
  right: 2em;
  bottom: 2em !important;
  height: 0;
}

.item-popup.solo-right.stag {
  position: fixed;
}

.notes .item-popup {
  bottom: 2em !important;
}

.item-popup.solo-left {
  position: relative;
  align-items: flex-start;
  left: 2em;
}

.item-popup.indy {
  position: relative;
  align-items: flex-start;
  left: 2em;
}

.item-popup>.item-popup-content {
  padding: .25em .5em;
  border-radius: 2px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.page-wrapper .item-popup {
  bottom: 2em
}

.item-settings {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.item-settings>.item-settings-content {
  padding: .25em .5em;
  border-radius: 2px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.item-settings>.item-settings-content>.label {
  margin: .25em 0 .25em 0 !important;
  min-width: 160px;
  padding: .75em
}

.item-settings>.icon {
  margin-top: .5em
}

.item-settings>.segment {
  position: absolute !important;
  right: 1.5em;
  width: 40%;
  bottom: 4.5em;
  display: flex;
}

.item-allow {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: absolute;
  bottom: 5em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.item-mapping {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.item-meeting {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.icon-glow:before {
  -webkit-animation: glow 1300ms infinite;
  -moz-animation: glow 1300ms infinite;
  -o-animation: glow 1300ms infinite;
  animation: glow 1300ms infinite;
}

.item-meeting>.icon {
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}

/* https://bit.ly/3o7h9gj */

/* .twitter-container,
.twitter-container>div,
.twitter-container>div>.twitter-timeline,
.twitter-container>.twitterContainer,
.twitter-container>.twitterContainer .twitter-embed,
.twitter-container>.twitterContainer .twitter-embed>div,
.twitter-container>.twitterContainer .twitter-embed>div>.twitter-timeline {
  height: 100% !important;
}

.twitter-container {
  padding: .5em !important;
}

.twitter-embed {
  overflow-y: scroll !important;
} */

.modals.dimmer {
  padding: 0;
  justify-content: space-around !important;
}

.seg-sign-in {
  display: grid !important;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100%;
  width: 100%;
  padding: .5em;
  text-align: center;
}

.seg-sign-in {
  height: 100%;
}

.seg-sign-in .field {
  margin: 0 !important;
  max-width: none !important;
}

.seg-sign-in .message.icon>.content {
  margin-left: 1em
}

.seg-sign-in .reset-password {
  margin-top: 2em !important;
}

[propname='password'] {
  color: 'red' !important;
  background-color: #1d83cd;
}

/* OTHER */

.warning.message {
  word-wrap: break-word;
}

.dd-linking {
  font-size: .8rem;
  width: 200px !important;
  background: var(--sem-grey) !important;
  color: white !important;
  font-weight: bold;
}

.footer-right {
  padding: 1em !important;
  background-color: black !important;
}

.grid-storage-images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  padding: .5em;
}

.grid-storage-images.pageDirect,
.grid-storage-images.profileGallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
}

.file-management .grid-storage-images {
  grid-template-columns: repeat(10, 1fr) !important;
}

.file-management .image {
  max-width: 120px !important;
  height: auto !important;
}

.us-state-map path {
  pointer-events: all;
}

.us-state-map path:hover {
  opacity: 0.5;
  cursor: pointer;
}

.app-check-container>.segment>div {
  background-color: blue;
}

.google-data-container .tv-tr>td:first-child {
  background-color: rgb(229, 255, 229);
  text-align: center;
}

.google-data-container .tv-tr-new>td:first-child {
  background-color: rgb(255, 222, 222);
  text-align: center;
}

.aie {
  display: grid;
  border: 1px solid rgb(219, 219, 219);
  padding: .25em;
  margin-bottom: 1em;
}

.aie>div:last-child {
  text-align: right;
}

.grid-sports-active {
  background-color: white;
}

.grid-sports-active .row.centered {
  background-color: grey;
}

.grid-sports-active .row>.column {
  padding: .5em !important;
}

.card-group-favs {
  margin: 0 !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.card-group-favs>div:first-child {
  padding: .5em;
}

.card-group-favs>div:last-child {
  padding: .5em;
}

.card-group-favs>.segment:last-child>.header {
  box-shadow: none !important;
}

.ct-no-data {
  height: 100% !important;
}

.rating-container {
  text-align: center;
}

.piom>.header,
.piom>.menu>.item {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr !important;
}

.piom>.menu>.item {
  padding: 1.5em !important;
}

.piom>.header>div:last-child,
.piom>.menu>.item>div:last-child {
  text-align: right;
}

.srr .push-container {
  grid-template-rows: auto 1fr 1fr;
}

.scoring-slider {
  display: grid;
  grid-template-rows: 1fr 14fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1em;
  gap: .5em;
}

.scoring-slider .label {
  width: 100%;
}

.vertical-slider {
  height: 100%;
  width: 100%;
  background-color: rgb(75, 75, 75);
  border-radius: 4px;
}

.horizontal-slider {
  width: 100%;
  height: var(--horz-height);
  background-color: rgb(75, 75, 75);
  color: white;
  vertical-align: middle;
  border-radius: 4px;
}

.vertical-slider.dis,
.horizontal-slider.dis {
  background-color: lightgrey;
}

.vertical-slider>.example-mark .horizontal-slider>.example-mark {
  margin: 0px calc(19px);
  bottom: calc(50% - 6px);
}

.example-thumb {
  font-size: 0.8rem;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.horizontal-slider .example-thumb {
  width: 28px;
  height: var(--horz-height);
  line-height: var(--horz-height);
}


.vertical-slider>.example-mark {
  background-color: grey;
  width: 100%;
  height: 22px;
  border: 1px solid grey;
  color: white;
  left: 0;
}

.example-mark::before {
  background-color: red;
}

.vertical-slider .example-thumb {
  width: 100%;
  height: 22px;
  line-height: 22px;
}

.iconed-map {
  display: flex !important;
  padding: .5em !important;
  align-items: center;
  justify-content: flex-start;
}

.settings-console-list .header>.icon {
  margin-right: .5em !important;
}

.settings-console-list>.item>.content>.content>.label {
  min-width: 48px !important;
  text-align: center !important;
}

.pending-dimmer {
  display: none;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgba(0, 0, 0, .85);
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  transition: background-color .5s linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}

.card-flag {
  display: flex;
  gap: 1em
}

.card.bu>.content>.meta {
  word-break: break-all;
}

.form>.field.df>.input>input {
  border-color: rgb(121, 0, 0);
}

.upl-event {
  border: 2px solid black;
  background-color: rgb(28, 28, 28);
  border-radius: 8px;
}

.places-container {
  padding: .5em !important;
}

.btn-save-container {
  padding: 0 !important;
  margin: 0 !important;
}

.signin .btn-save-container .button {
  float: inherit !important;
  text-align: center;
}

.places-container.grouped>div:first-child {
  font-weight: bold;
}

.places-list,
.places-list.addit {
  padding-bottom: .5em;
  padding-top: .5em;
  display: grid !important;
  grid-template-columns: 1fr 9fr;
  gap: .5em;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.places-list.addit {
  padding-bottom: .5em;
  padding-top: .5em;
  display: grid !important;
  grid-template-columns: 2fr 5fr;
  gap: .5em;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.places-list>div:nth-child(2)>div:first-child {
  font-weight: bold;
}

.bgb {
  background-color: rgb(229, 255, 209) !important;
}

.label-selects {
  display: flex !important;
  flex-direction: column;
  row-gap: .25em;
}

.label-selects>.label>.label:first-child {
  margin-right: .5em;
}

.label-selects>.label>.dropdown {
  margin-right: .5em;
}

.mu-pop>.item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.mu-pop>.item .icon {
  margin-right: .75em !important;
}

/* .create-season-container {
  width: 50%;
  margin: 1em auto;
} */

.create-season>.content>.vert-container>.content {
  width: 50%;
  margin: 1em auto;
}

.icon-text.nrr {
  text-decoration: underline;
}

.microphone-wrapper {
  text-align: right;
  padding: 0 .5em !important;
}

.client-status>.item>.content>.description:nth-child(3) {
  font-size: .8rem !important;
}

.client-status>.item>.content>.description .label {
  width: 100%;
}

.df-r1-c2 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c4 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c5 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c4:nth-child(4) {
  justify-self: right;
}

.df-r3-c2,
.df-r2-c2 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: .25em;
}

.df-r3-c2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.df-r2-c2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.df-r3-c2 .label,
.df-r2-c2 .label {
  width: 100%;
  margin: 0;
}

.form>textarea {
  margin-bottom: 1em !important;
}

.app-user-meta>.meta {
  padding: .25em !important;
}

.app-user-meta>.meta>.icon {
  margin-right: .5em !important;
}

.image-mapping-list>.header {
  padding-top: 1rem;
}

.img-type-bordered {
  border: 2px solid rgb(73, 73, 73);
  border-radius: 8px;
}

.sfp {
  height: 100%;
}

.opt-icn {
  display: flex !important;
}

.opt-icn>.text {
  width: 100%;
}

.opt-icn>.text>.icon {
  float: right;
}

.seg-cal-full {
  height: 100%;
}

.full-web-page {
  width: 100vw !important;
  height: 100vh;
  background-color: grey;
}

.full-web-page .content .content {
  margin: auto;
}

.sign-up {
  text-align: center;
  margin-top: 2em;
}

.goo-location-list .icn {
  font-size: 4px;
}

.list-check>.item {
  align-content: center !important;
  align-items: center !important;
}

.list-check>.item>.checkbox {
  margin-right: 1em !important;
}

.list-cb-container>.list>.checkbox {
  margin-right: 1em;
}

.image-meta {
  padding: 1em;
  color: black;
  word-break: break-word;
}

.motion-div-container {
  height: 100%;
}

.card-icons>div {
  display: flex;
  justify-items: flex-end;
  width: 100%;
}

.popup-wrapper {
  height: 100% !important;
  display: grid;
  grid-template-rows: 1fr auto !important;
}

.popup-wrapper-icon {
  position: relative;
}

.margin-auto {
  margin: auto
}

.message-icon .list {
  float: right !important;
}

.seg-button {
  width: 100%;
  background-color: rgb(91, 91, 91) !important;
}

.seg-button.slt-clientProfiles {
  background-color: var(--sem-blue) !important;
}


.seg-button>input {
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 9999 !important;
}

.btn-space {
  display: flex;
  justify-content: space-between;
}

.sib>.button {
  margin-top: 1em;
}

.content.segment.placeholder>.form>.phone-container>.field,
.content.segment.placeholder>.form>.field {
  max-width: none;
  margin-right: none;
  margin-left: none;
}

.content.segment.placeholder>.button {
  margin-top: 1em;
}

.content.segment.placeholder>div {
  margin-top: 2em;
  text-align: center;
}

.content.segment.placeholder.fw {
  margin-top: 0;
}

.seg-header-label {
  display: flex;
  justify-content: space-between;
}

.seg-header-label>div:nth-child(1) {
  font-weight: bold;
}

.no-b-tlr input {
  border-left: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  border-width: 2px !important;
}

.aul-events {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.aul-events .label {
  padding: 1em;
  font-weight: bold !important;
}

.cred-lbls {
  display: flex;
  padding: 1em;
  justify-content: center;
}

.ms-key {
  font-size: .7rem;
  margin-right: 1em;
}

.img-tn {
  border: 1px solid blue
}

.listUploader .image,
.listUploader .content:not(.extra)>.icon {
  width: 110px !important;
}

.list>.item>.label {
  margin-right: .5em;
}

.tab-note .segment {
  padding: 0 !important
}

.tab-note .segment .app-notification-group,
.tab-note .segment .app-notification-group>div {
  padding: 0 !important
}

.hdd.tv,
.hdd.tv .menu>div>span {
  font-size: .7rem;
}

.hdd.tv .menu>div {
  padding: .3em !important
}

.split-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow-y: hidden;
}

.split-list-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  overflow-y: hidden;
}

.split-list.three {
  grid-template-columns: 1fr 2fr;
}

.split-list.vert {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
}

.split-list.vert.equal {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.split-list-3 .segment,
.split-list .segment {
  margin: 0;
  height: 100%;
  overflow-y: auto;
}

/* .split-list .content:nth-child(2) {
  border-right: solid 1px rgb(201, 201, 201) !important;
} */

.split-list .vert-container>.header {
  text-align: center;
}

.split-list .vert-container {
  height: 100%;
  overflow-y: auto;
}

.split-list .list {
  padding: .5em
}

.split-list.q {
  grid-template-columns: 1fr 3fr;
}

.split-list.qr {
  grid-template-columns: 3fr 1fr;
}

.split-list.q .form {
  padding: 1em !important
}

.split-list.three .footer div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-self: baseline;
}

.dimmer-seg {
  height: 100% !important;
  width: 100% !important;
}

.dimmer-seg .button {
  margin-top: 2em !important;
}

.table-labels {
  display: flex;
  flex-direction: column;
  gap: .2em;
}

.table-labels>.label {
  width: fit-content;
}

.table-wrapper>.table>thead>tr>th,
.table-wrapper>.table>tbody>tr>td {
  width: fit-content !important;
}

.tab-count {
  display: flex;
}

.prp-description {
  padding: 1em .5em;
  font-weight: normal;
  opacity: 1 !important;
}

.sidebar-fdv {
  padding: 0 !important;
  border: 0 !important;
}

.sidebar-fdv-settings {
  border: 0 !important;
  padding: 0 !important;
}

.sidebar-fdv-settings.normal {
  border: 0 !important;
}

.sidebar-fdv-settings.normal {
  padding: 0 !important
}

.sidebar-fdv>.segment,
.sidebar-fdv-settings>.segment {
  padding: 0 !important
}

.h100 {
  height: 100% !important;
}

.line-icon {
  display: flex;
  justify-content: flex-end;
}

.query-line {
  display: flex;
}

.query-line {
  font-size: .8rem
}

.query-line div {
  margin-right: .75em !important;
}

.header-flex {
  display: flex;
  justify-content: space-between;
}

.header-flex .selection {
  font-size: .8rem;
  margin-right: .5em;
}

.header-flex .selection .item>span {
  font-size: .8rem;
}

.header-flex>form,
.header-flex>.two>div {
  width: 50%;
}

.header-flex>div:nth-child(1) {
  justify-content: flex-start;
}

.header-flex>div:nth-child(2) {
  justify-content: flex-end;
}

.sat-preview {
  margin-top: 1em !important;
}

.btn-slim>.button.icon.labeled.mini {
  padding-left: .75em !important;
  padding-right: 3.25em !important;
}

.input.icon {
  margin-top: .5em
}

.list-button,
.list-button>.text,
.list-button .header,
.list-button>.menu,
.list-button>.menu>.item {
  font-weight: bold !important;
  padding: .35em auto !important;
  font-size: .78571429rem !important;
}

.PhoneInputInput {
  font-size: 400;
  padding: .67857143em 1em;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, .15)
}

.user-auths .label {
  float: right;
}

.schedule-grid {
  margin: 0 !important
}

.schedule-grid>.row {
  border-bottom: 1px solid rgb(204, 204, 204);
  padding: 0 !important
}

.schedule-grid>.row>.column {
  padding: .5em !important
}

.input.labeled>.label.label>.icon {
  margin: 0
}

.icons>.icon.corner {
  font-size: .5em !important;
}

.pending-wait .icon {
  font-size: 1em !important;
}

.form-golfers {
  /* padding: 0 !important; */
  height: 100%;
}

.dater {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  text-align: left;
}

.dater>div:first-child {
  font-weight: bold;
  font-size: 1.2em;
}

.dater>div:last-child {
  font-weight: bold;
}

.full-image>.content {
  margin: auto;
}

.sidebar-title {
  padding: 1em 1em 0 1em !important
}

.segment.is-html {
  border: 1px solid rgba(34, 36, 38, .15) !important;
  border-radius: .28571429rem !important;
  min-height: 50px;
  width: 100%;
  word-break: break-word;
}

.lbl-icon-right {
  margin: 0 0 0 .75em !important
}

.field-object-container {
  margin-bottom: 1em;
}

.confirmation-button {
  margin-bottom: 4px !important;
}

.reg-results {
  padding: .75em .25em !important
}

.bld {
  font-weight: bold;
}

.abc.so>.btn-confirm>.button {
  height: 3em;
  border-radius: 0 !important;
}

.abc.so {
  text-align: left !important;
  border-radius: 0 !important;
  height: 3em;
}

.action-sidebar>.item {
  padding: 1.5em !important;
  font-weight: bold !important;
  font-size: .9rem;
}

.dis-none {
  display: none !important;
}

.hodd {
  padding: 0 !important;
  float: right;
}

.ip-url {
  word-wrap: break-word;
  word-break: break-all;
}

.is-html {
  padding: .5em !important;
  /* color: white !important; */
}

.inverted .is-html {
  color: white !important;
}

.sec-content .is-html {
  color: black !important;
}

.is-html img {
  max-width: 100px !important;
  max-height: 100px !important;
}

.is-html ul {
  padding-left: 1.5em !important;
}

.icon.bordered {
  border-radius: 3px !important;
}

.info-container {
  font-size: small !important;
  margin: .5em 2em .5em .25em;
  padding: .15em
}

.info-container>div:nth-child(1) {
  font-weight: bold;
}

.json-container {
  height: 100% !important;
  font-size: .9rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  white-space: pre-wrap;
  scrollbar-width: thin;
}

.json-container.nfh {
  height: auto !important;
}

.menu.transition {
  min-width: 180px !important;
}

.menu.borderless {
  border-radius: 0 !important;
}

.react-json-view {
  height: 100% !important;
  padding: 1em;
}

.rc-input-number {
  margin-bottom: 1em !important;
}

.create-event-container {
  width: 50% !important;
  height: 100% !important;
  margin: 5em auto !important;
}


.paste-container {
  padding: .25em
}

.paste-content {
  height: 100%;
}

.paste-toggle {
  margin-bottom: 1em;
}

.ta-full {
  height: 100%;
  width: 100%;
  border: none;
  padding: .5em
}

.line-s {
  display: block;
}

.line-h {
  display: none !important;
}

.seg-no-padd {
  padding: 0 !important
}

.menu-slider {
  margin: .2em 0 .2em 0 !important
}

.menu-slider>.item {
  font-weight: bold !important;
  padding: .2em !important;
}

.menu-slider>.item>.icon {
  margin: 0 !important
}

.cal-select-container {
  margin: 0 0 .2em 0 !important
}

.cal-select-container>.item {
  font-weight: bold !important;
  padding: .2em !important
}

.cal-select-container>.item>.icon {
  margin: 0 !important
}

.create-content>textarea {
  height: 100%;
  border: .1px solid lightgrey
}

.create-content.json {
  padding: .5em;
}

*/ .create-content.json {
  padding: 1em;
}

.text-area-json {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  font-size: .8rem;
}

.qr-container {
  align-items: center !important;
}

.qr-container.solo {
  padding: 0 1em;
  text-align: right;
}

.list-overflow {
  height: 200px;
  overflow-y: auto;
}

.fav-header {
  font-weight: bold;
}

.fav-items {
  font-weight: bold;
}

.ph-full {
  height: 100%;
}

.menu-header-topper-simple,
.menu-header-topper {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  padding: .5em;
  align-items: center;
  font-weight: bold;
  line-height: normal;
}

.menu-header-topper>div:nth-child(1) {
  align-self: flex-start;
}

.menu-header-topper>div:nth-child(2)>div:nth-child(2) {
  font-size: .7rem;
  font-weight: normal;
}

.segment.placeholder {
  height: 100% !important;
}

.menu-header-alt>div:nth-child(1) {
  text-align: left;
}

.menu-header-topper>div:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

.menu-header-topper-simple {
  grid-template-columns: 100%;
  text-align: center;
  padding: 1em;
}

.menu-header-alt {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  padding: .5em;
  align-items: center;
}

.menu-header-alt>div:nth-child(1) {
  text-align: left;
}

.menu-header-alt>div:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

.menu-header-alt>div:nth-child(3) {
  text-align: right;
}

.seg-ph-full {
  height: 100%;
  text-align: center;
  font-weight: bold;
}

.h0 {
  height: auto !important;
}

.tar {
  text-align: right;
}

.mar0 {
  margin: 0 !important
}

.mar10 {
  margin: 1em !important;
}

.mar10 {
  margin: 1em !important;
}

.mar15 {
  margin: 1.5em !important;
}

.mar15 {
  margin: 1.5em !important;
}

.mar2 {
  margin: 2em !important;
}

.mar5 {
  margin: .5em !important;
}

.padd0 {
  padding: 0 !important
}

.padd10 {
  padding: 1em !important;
}

.padd10 {
  padding: 1em !important;
}

.padd15 {
  padding: 1.5em !important;
}

.padd15 {
  padding: 1.5em !important;
}

.padd2 {
  padding: 2em !important;
}

.padd5 {
  padding: .5em !important;
}

.no100 {
  height: auto !important;
}

.grid-text {
  padding: .1em
}

.grid-text-map {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  width: 100%;
}

.grid-text-map>.row {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.grid-text-map>.row>.column {
  text-align: center !important;
  padding-left: .1rem !important;
  padding-right: .1rem !important;
}

.grid-text-map>.row>.column>.field {
  margin: .25em !important;
  text-align: left;
}

.vert-container.sgn .grid>.column:nth-child(2) {
  font-weight: bold;
}

.vert-container.sgn .button>.icon.loading {
  background: none;
}

.seg-sign-in .button>.icon.loading {
  background: none !important;
}

.profile-select {
  border-left: 1px solid rgb(104, 104, 104) !important;
  border-right: 1px solid rgb(104, 104, 104) !important;
}

.profile-select.access {
  font-size: .8rem;
}

.profile-select.access .item {
  font-size: .8rem !important;
}

.profile-select.data-source.dropdown.scrolling.ui .menu {
  width: 280px !important;
}

.pusher-container {
  height: 100%;
}

body ::-webkit-scrollbar {
  width: 2px !important;
}

.full-data-viewer ::-webkit-scrollbar {
  width: 2px !important;
}

.item-content ::-webkit-scrollbar {
  width: 2px !important;
}

body ::-webkit-scrollbar {
  width: 2px !important;
}

.item-content {
  scrollbar-width: none !important;
}

.help-seg.doc mark {
  font-weight: bold;
}

.tar {
  text-align: right;
}

.inh {
  padding: 0 !important
}

/* Alt VS */
/* :not(.dark-mode) .vert-container > .header:not(.nob) {border-bottom: var(--thin-border-settings) !important;} */
:not(.dark-mode) .vert-container>.footer {
  border-top: var(--thin-border-settings) !important;
}

.nob {
  text-align: center;
  background-color: green !;
}

:not(.dark-mode) .vert-container>.footer.modify {
  text-align: right;
}

/* VERT CONTAINER */
.vert-container.basic>.header,
.vert-container.basic>.footer {
  padding: .5em;
  font-weight: normal;
}

.vert-container.paddedHeaderAndFooter>.header,
.vert-container.noContent>.header,
.vert-container.paddedHeader>.header,
.vert-container.paddedContent>.footer,
.vert-container.padded>.header {
  padding: 1em;
  font-weight: bold;
}

.vert-container.noContent>.content>.header {
  text-align: center;
  padding-bottom: 1em;
}

.vert-container.noContent>.content {
  padding: .5em;
  margin: auto;
}

.vert-container.padded>.content:not(.igw) {
  padding: .5em;
}

.vert-container.paddedHeaderAndFooter>.footer,
.vert-container.paddedHeader>.footer,
.vert-container.paddedContent>.footer,
.vert-container.padded>.footer {
  padding: 1em .25em;
}

.vert-container.paddedFooter>.footer {
  padding: 1em;
}

.vert-container.sgn>.header {
  padding: 0;
  font-weight: normal;
}

.vert-container.sgn>.footer {
  padding: .5em;
  font-weight: normal;
}

.vert-container.linking>.header {
  padding: .5em;
  font-weight: normal;
}

.vert-container.linking>.content {
  padding: 0;
}


.vert-container.splitLR {
  display: grid;
  grid-template-rows: 1fr !important;
  grid-template-columns: 1fr 4fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "header content" !important;
  background-color: black;
}

.vert-container.split.tbf {
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: 100% !important;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "top"
    "bottom"
    "footer"
}

.vert-container.splitHorz {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "left"
    "right"
}

.vert-container.splitVert {
  display: grid;
  grid-template-rows: 1fr 1fr !important;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "header"
    "content"
}

.vert-container.splitVert>.header {
  margin-bottom: .5em;
  overflow-y: auto;
  height: 100%;
}

.vert-container.split>.header {
  padding: .5em;
  font-weight: normal;
}

.vert-container.split>.top {
  padding: .5em;
}

.vert-container.split>.bottom {
  padding: .5em;
}

.vert-container.split>.footer {
  padding: .5em;
}

.vert-container.match-pending>.footer {
  text-align: right;
}

.vert-container.nf {
  height: auto !important;
}

.bulk-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 100% !important;
  width: 100%;
  height: 100%;
}

.bulk-container>div:first-child {
  overflow-y: auto;
  height: 300px;
}

.atb {
  display: flex;
  justify-content: space-between;
}

.map-address-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 3fr 1fr;
}

.map-address-container.full {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 3fr 1fr 1fr 1fr;
}

.svg-image {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
}

/* *********** */
.svg-wrapper {
  position: relative;
  display: inline-block;
  padding: .5em;
  /* <= shrinks container to image size */
  /* transition: transform 150ms ease-in-out; */
}

.svg-wrapper img {
  /* <= optional, for responsiveness */
  display: block;
  max-width: 100%;
  height: auto;
}

.svg-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-map,
.map-address-map,
.map-address-map>div,
.map-address-map>div>div {
  height: 100%;
}

.map-address-address {
  padding: 0 .5em;
}

.map-address-url {
  padding: 0 .5em;
  margin: auto;
}

.map-address-name {
  padding: 1em .5em .5em .5em;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.vert-container.pending {
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "content";
}

.vert-container.pending>.content {
  text-align: center;
  font-size: 1.5rem;
  padding: 2em;
  margin: auto 0 !important;
  height: auto !important;
}


.vert-container.pending>.header {
  padding: 1em;
  font-weight: bold;
  text-align: center;
}

.vert-container.segments,
.vert-container.segments>.segment {
  border: 0;
  border-radius: 0 !important;
}

/* .vert-container .grid > .column:last-child > .icon{float: right;} */
.vert-container.fip {
  padding-bottom: 2em;
}

.form-container .vert-container {
  height: auto;
}

.path div {
  word-wrap: break-word;
}

.ui.header>.grid .icon:only-child {
  margin: 0 !important;
  padding: 0
}

.killOf {
  overflow-y: hidden !important;
  -webkit-overflow-y: hidden !important;
}

.notification-forground {
  position: fixed !important;
  bottom: 5%;
  z-index: 1000;
  width: 100%;
}

.notification-forground.desktop {
  right: 1%;
  bottom: 1%;
  width: 20%;
}

.form.simput input {
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid red !important;
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }

  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }

  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

@keyframes glow {
  0% {
    color: #2ba805;
  }

  50% {
    color: #49e819;
  }

  100% {
    color: #2ba805;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-out {
  -webkit-animation-name: fade-out;
  animation-name: fade-out;
}

.fader {
  animation: fade-out ease 1s;
  -webkit-animation: fade-out ease 1s;
  -moz-animation: fade-out ease 1s;
  -o-animation: fade-out ease 1s;
  -ms-animation: fade-out ease 1s;
}

.start-icon {
  margin-left: .25em !important
}

.cbil-container {
  display: grid !important;
  grid-template-columns: 1fr 9fr;
}

.cbir-container {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

.cbir-confirm {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

.cbc-container {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr;
  ;
}

.file-split {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
}

.zone-split {
  display: grid !important;
  grid-template-columns: 3fr 1fr;
}

.zone-split .content {
  height: 100% !important;
  overflow-y: auto;
  ;
}

.zone-split .label {
  margin: .5em;
}

.cbc-container .button:nth-child(2),
.cbil-container .button:nth-child(2),
.cbir-container .button:nth-child(1) {
  text-align: left;
}

.cbc-container .button {
  margin-bottom: .5em !important;
  padding: 1em 0 !important
}

.topic-groups {
  padding: .5em
}

.header-button-select {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.header-button-select>div:nth-child(2) {
  text-align: center;
}

.split-container.vertical {
  display: grid;
  height: 100% !important;
  grid-template-columns: 90% 10%;
  grid-template-areas:
    "split-content split-menu";
}

.split-content {
  grid-area: split-content;
  height: 100% !important;
}

.split-content .item-container {
  height: 100% !important;
}

.split-menu {
  grid-area: split-menu;
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.split-menu .menu-alphabet {
  scrollbar-width: none;
  width: 100% !important;
}

.split-menu .menu-alphabet>.item {
  width: 100%;
  padding: .75em;
  text-align: center;
}

.split-container-half {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
}

.app-user-list .menu-alphabet {
  width: 100%;
  padding: .25em !important;
}

.table-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.table-split>div {
  padding: 1em;
  height: 100%;
  overflow-y: auto;
}

.table-split.three,
.table-split.upl {
  grid-template-columns: 2fr 1fr;
}

.table-split.upl>div:nth-child(2) {
  padding: 0em !important;
}

.table-split .card.sel {
  background-color: rgb(217, 217, 217);
}

.table-split.three .icon.user {
  font-size: 5em !important;
}

.row-split-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.row-split-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.row-split-2>div,
.row-split-3>div {
  padding: 0 1em;
}

.listUploader .table-split>.segment> {
  overflow-y: auto !important;
  height: 100%;
}

.listUploader .table-split>.segment> {
  overflow-y: auto !important;
  height: 100%;
}

/* .cbir-confirm .button:nth-child(1){text-align: left;} */
/* .btn-confirm {display: grid !important;  grid-template-columns: 1fr 8fr 1fr;  padding-bottom: .5em !important; margin-bottom: 8px !important}    */

.fresnel-greaterThan-mobile .swipe-tab::-webkit-scrollbar {
  background: rgb(92, 92, 92);
  height: 6px !important;
}

.fresnel-at-mobile .swipe-tab::-webkit-scrollbar {
  background: black;
  height: 0px !important;
}

.upc {
  text-transform: uppercase;
}

.item-info {
  position: absolute;
  top: .5em;
  right: .5em;
}

body {
  height: 100vh;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.phone-auth>.mdl-card {
  box-shadow: none;
}

.phone-auth {
  font-family: inherit !important;
  font-size: 1rem !important;
}

.grecaptcha-badge {
  display: none;
}

#firebaseui-auth-container {
  padding: 0;
  height: 100%;
  width: 100%
}

.firebaseui-container {
  padding: 0;
  height: 100%;
  width: 100%
}

#firebaseui-auth-container div,
#firebaseui-auth-container input,
#firebaseui-auth-container h1 {
  font-size: 1rem !important;
}


.dddddddddd {
  position: relative;
}

.pppppppppp {
  position: relative;
}

.menu-app-settings {
  padding-top: .5em !important
}

.menu-app-settings>.item {
  padding: .75em !important;
  margin-left: .5em !important;
}

.menu-app-settings>.item>.header {
  margin: 0 !important;
}

.menu-app-settings>.item>.icon {
  float: left !important;
  margin-right: .75em !important;
}

.menu-app-settings.profile {
  height: 100%;
}

.menu-app-settings.profile>.item {
  margin: 0 !important;
}

.menu-app-settings.profile>.item>.header {
  padding: 0 !important;
}

.menu-app-settings.profile>.item>.icon {
  margin: 0 !important;
}

.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #473BF0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #fff !important;
  border-radius: 0;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  border-radius: 5px;
}

.card-schedule>.content.extra:not(.other),
.card-schedule.plus>.content.extra:not(.other) {
  display: grid;
  column-gap: .1em;
  row-gap: .5em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "pi-startDate pi-endDate"
    "pi-startTime pi-endTime"
    "pi-locations pi-locations"
}

.card-schedule>.content.extra.grp.tz,
.card-schedule.plus>.content.extra.grp.tz {
  display: grid;
  column-gap: .1em;
  row-gap: .5em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "pi-startDate pi-location"
    "pi-times pi-times";
}

.card-schedule>.content.extra.other,
.card-schedule.plus>.content.extra.other {
  display: grid;
  row-gap: .5em;
  grid-template-columns: 1fr;
  grid-template-areas: none !important;
}

.card-schedule>.content.extra.other>.label {
  margin: 0 !important;
}


.card-schedule>.content.extra.card-link {
  grid-template-columns: 1fr;
  justify-items: end;
}

.card-schedule>.content.extra.card-link .check-toggle,
.card-schedule>.content.extra.card-link .check-toggle>label {
  font-size: .9rem !important;
}

.pi-time {
  display: flex !important;
}

/* .card-link .check-toggle>label::before {
  width: 2rem !important;
  height: 1rem !important;
}

.card-link .check-toggle>label::after {
  width: 1rem !important;
  height: 1rem !important;
  font-size: 10px;
  left: 0rem !important;
}

.card-link .check-toggle>label:checked::after {
  left: 1rem !important;
} */

.pi-startDate {
  grid-area: pi-startDate;
}

.pi-endDate {
  grid-area: pi-endDate;
}

.pi-times {
  grid-area: pi-times;
  margin: 0 !important;
}

.pi-startTime {
  grid-area: pi-startTime;
  display: grid !important;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr;
  row-gap: 1em;
  margin-left: 0 !important;
}

.tz>.pi-startTime {
  grid-template-rows: 1fr 1fr;
}

.pi-startTime>div {
  text-align: left;
}

.pi-startTime>.icon:nth-child(2) {
  margin-left: .5em;
}

.pi-endTime {
  grid-area: pi-endTime;
  display: grid !important;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr;
  row-gap: 1em;
  margin-right: 0 !important;
}

.tz>.pi-endTime {
  grid-template-rows: 1fr 1fr;
}

.pi-endTime-alt {
  grid-area: pi-endTime-alt;
}

.pi-location {
  grid-area: pi-location;
}

.pi-locations {
  grid-area: pi-locations;
}

.pi-times>.icon:first-child {
  margin-right: .5em !important;
}

.pi-times>.icon:last-child {
  margin-left: .5em !important;
}

.pi-times>.detail {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.flx-lr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.swiper-sub {
  overflow-y: hidden;
  height: 100%;
}

.swiper-sub>.react-swipeable-view-container {
  height: 100%;
}

.swiper-sub>.react-swipeable-view-container>div {
  height: 100%;
  overflow-y: auto;
}

/* .sec-container.hotelInfo>.sec-content {
  display: grid;
  background-color: red;
} */