:root {
  --ticket-bg-color: rgb(233, 205, 44);
  --ticket-color: black;
  --ticket-border: solid 2px #fbbd08;
  --ticket-shadow: rgb(82, 82, 82);
  --ticket-shadow-2: rgb(48, 48, 48);
  --sub-ticket-border: solid 3px rgb(187, 187, 187);
}

/* #ccab66; */
/* box-shadow: -2px -2px 2px 0px var( --ticket-shadow), 2px 2px 2px 0px var( --ticket-shadow-2) !important;   */
.card-ticket {
  background-color: var(--ticket-bg-color);
  color: var(--ticket-color);
  display: grid;
  /* margin-bottom: 1em !important; */
  position: relative;
}

.card-ticket.single {
  border-radius: .5em;
  border: var(--ticket-border);
  border-width: 6px 12px;
  margin-bottom: .5em;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "ticket-info"
}

.card-ticket.full {
  border: var(--ticket-border);
  border-width: 6px;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "ticket-info"
    "ticket-subs"
    "ticket-send"
}

.card-ticket.willCall>.ticket-info,
.card-ticket.full>.ticket-info,
.card-ticket.single>.ticket-info {
  display: grid;
  grid-template-areas:
    "ticket-logo"
    "ticket-name"
    "ticket-location"
    "ticket-date-container"
    "ticket-status"
    "ticket-click"
}

.card-ticket.willCall {
  border-radius: 1em;
  margin: .5em 0 .5em 0;
  border: var(--sub-ticket-border) !important;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "ticket-info"
    "ticket-subs"
}

.card-ticket.display {
  height: 100%;
  padding: 1em;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "ticket-name"
    "ticket-status"
    "ticket-subs"
}

.ticket-info {
  grid-area: ticket-info;
}

.ticket-name {
  grid-area: ticket-name;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}

.ticket-location {
  grid-area: ticket-location;
  text-align: center;
  font-size: .7rem;
  padding: 0 !important;
  color: white
}

.ticket-logo {
  grid-area: ticket-logo;
}

.ticket-click {
  grid-area: ticket-click;
  text-align: center;
  font-size: .9rem;
  text-transform: uppercase;
  font-weight: bold;
}

.ticket-logo {
  padding: .5em !important
}

.ticket-logo>.image {
  height: 40px !important;
  margin: auto;
  text-align: center;
}

.ticket-date {
  grid-area: ticket-date;
  text-align: center;
}

.ticket-date-2 {
  grid-area: ticket-date-2;
  text-align: center;
}

.ticket-start-time {
  grid-area: ticket-start-time;
}

.ticket-end-time {
  grid-area: ticket-end-time;
}

.ticket-time-to {
  grid-area: ticket-time-to;
  margin: auto
}

.ticket-start-time-2 {
  grid-area: ticket-start-time-2;
}

.ticket-end-time-2 {
  grid-area: ticket-end-time-2;
}

.ticket-time-to-2 {
  grid-area: ticket-time-to-2;
  margin: auto
}

.ticket-subs {
  grid-area: ticket-subs;
  height: 100% !important;
  overflow-y: auto;
  padding: .5em .5em;
  background-color: rgb(53, 53, 53);
  /* border-radius: 0 0 .75em .75em; */
}

.ticket-send {
  grid-area: ticket-send;
}

.ticket-send {
  grid-area: ticket-given;
}

.page-wrapper .ticket-subs {
  padding: 0 !important
}

.ticket-sms {
  /* display: grid; */
  /* grid-template-rows: auto 1fr;  */
  height: 100%;
  padding: .5em;
}


.ticket-scanner {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
}

.ticket-scanner>div:nth-child(2) {
  margin: auto
}

.ticket-warning {
  padding: .5em
}

.cred-scanner {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
}

.cred-scanner .segment {
  height: 100%;
  overflow-y: auto;
}

.scan-split {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
}

.scan-split .qr-reader>div {
  padding-top: 50% !important;
  height: 50% !important;
  background-color: black;
}

section.test {
  height: fit-content;
}

.scan-split>section>section {
  padding-top: 0 !important;
}

/* .scanned-status{height: 100%; margin: auto;} */

.will-call-container {
  height: 100%;
}

.will-call-container>div {
  height: 100%;
}

/* .will-call{
  padding: .5em;
  display: grid;
  gap: 1em;
  text-align: center;
  grid-template-columns: 1fr;  
  grid-template-rows: 1fr 2fr;
  grid-template-areas: 
  "will-call-info"  
  "will-call-qr"
} */

.will-call-info {
  grid-area: will-call-info;
  display: grid;
  grid-template-rows: repeat(4 1fr);
}

.will-call-info>div {
  font-size: 1.1rem;
}

.will-call-qr {
  grid-area: will-call-qr;
  margin: auto;
}

/* .will-call.exchange{
  height: 100%;
} */
.will-call.print {
  height: auto;
}

.will-call-header {
  padding: .5em;
  background-color: var(--topper) !important;
  color: white;
  text-align: center;
}


.card-ticket-sub {
  display: grid;
  padding: 0em !important;
  border-width: 3px !important;
  height: 100%;
  padding: 0em !important;
  border: 3px solid gray !important;
  margin: .1em !important;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sub-ticket-header"
    "sub-ticket-qr";
  ;
}

.card-ticket-sub.will-call {
  margin: .5em .1em !important;
  /* page-break-after: always; */
  page-break-inside: avoid;
}

.card-ticket-sub.will-call.exchange {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "sub-ticket-header"
    "sub-ticket-qr"
    "sub-ticket-forward"
  ;
}

.sub-ticket-forwarded {
  color: black
}

.card-ticket-sub.entered {
  border-color: var(--sem-red);
  background-color: var(--sem-light-red);
}

.card-ticket-sub>div {
  text-align: center;
}

.card-ticket-sub>.label {
  margin: 0;
  border-radius: 0;
  padding: 1em 0;
  text-align: center;
}

.sub-ticket-header {
  width: 100%;
  display: flex;
  justify-items: self-end;
}

.sub-ticket-header.fwd>.label {
  background-color: grey !important
}

.sub-ticket-header>.label {
  width: 100%;
  border-radius: 0;
}

.sub-ticket-header>.label>.label {
  margin-right: 2em;
}

.ticket-name>div:nth-child(2) {
  font-weight: bold;
  font-size: 1.2rem;
}

.ticket-name>div:nth-child(2),
.sub-ticket-name>div:first-child {
  padding: .5em 0;
}

.sub-ticket-number {
  grid-area: sub-ticket-number;
}

.sub-ticket-label {
  grid-area: sub-ticket-label;
}

.sub-ticket-label>.label {
  width: 90%;
  height: 100% !important;
  padding: 1em;
}

.sub-ticket-name>.sub-ticket-user {
  width: 100%;
  text-align: center;
}

.sub-ticket-name>.sub-ticket-user>.icon {
  width: 100%;
  text-align: center;
}

*/ .sub-ticket-name>div:nth-child(3) {
  font-size: .7rem;
}

.sub-ticket-name-name {
  grid-area: sub-ticket-name-name;
}

.sub-ticket-date {
  grid-area: sub-ticket-date;
}

.sub-ticket-subs {
  grid-area: sub-ticket-subs;
  overflow-y: auto;
  padding: em .5em !important;
  background-color: black;
}

.sub-ticket-send {
  grid-area: sub-ticket-send;
  padding: .5em !important;
  margin-top: .5em !important;
  text-align: center;
}

.sub-ticket-dt {
  grid-area: sub-ticket-dt;
  padding: .5em !important;
  margin-top: .5em !important;
  text-align: center;
}

.sub-ticket-exchange {
  grid-area: sub-ticket-exchange;
  padding: 0 !important;
  margin-top: .5em !important;
  text-align: center;
}

.sub-ticket-user {
  grid-area: sub-ticket-user;
}

.list.relaxed.ordered .icon {
  padding-left: .5em !important;
}

.ticket-status-grid {
  font-size: .8rem;
}

.ticket-status-grid>.row {
  padding: .75rem 0 !important;
}

.ticket-status-grid>.row:nth-child(1) {
  font-weight: bold;
  font-size: 1rem;
}

.ticket-status {
  grid-area: ticket-status;
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  /* border-bottom: 2px solid rgb(204, 204, 204); */
  padding: .5em;
}

/* .ticket-description{grid-area: ticket-description;} */
.ticket-qr {
  grid-area: ticket-qr;
  text-align: center;
  margin: auto
}

.card-ticket.full>.ticket-qr,
.card-ticket.display>.ticket-qr {
  margin: auto
}

.ticket-info>div {
  padding: .5em
}

.sub-ticket-qr {
  display: flex;
  align-items: center;
  grid-area: sub-ticket-qr;
  height: 100%;
  text-align: center;
  margin: auto !important;
}

.sub-ticket-qr .icon {
  margin: 0 !important;
  text-align: right;
}

.ticketing-status {
  display: flex;
  flex-direction: column;
  font-size: .8rem;
  font-weight: normal;
  width: 40%;
  justify-items: space-between !important;
}

/* .ticket-date-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  width: 100%;
  grid-area: ticket-date-container;
} */

.ticket-date-container {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 3fr 2fr 2fr;
  gap: .5em;
  justify-content: center;
  width: 100%;
  grid-area: ticket-date-container;
  grid-template-areas:
    "ticket-date ticket-start-time ticket-end-time";
  /* grid-template-areas:  
  "ticket-date ticket-date ticket-date"
  "ticket-start-time ticket-time-to ticket-end-time"; */
}

.ticket-date-container.multiple {
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "ticket-date ticket-start-time ticket-end-time"
    "ticket-date-2 ticket-start-time-2 ticket-end-time-2"
    /* grid-template-areas:  
  "ticket-date ticket-date ticket-date"
  "ticket-start-time ticket-time-to ticket-end-time"
  "ticket-date-2 ticket-date-2 ticket-date-2"
  "ticket-start-time-2 ticket-time-to-2 ticket-end-time-2"; */
}

.ticket-date-container>div {
  text-align: center;
}

.ticket-date-container>.label {
  margin: 0;
  text-align: center;
  padding: .5em .1em
}

.ticket-status>.label {
  display: flex;
  justify-content: space-between !important;
  margin: 0 0 0 0 !important;
  width: auto;
  padding: .75em;
  font-size: .7rem;
  font-weight: normal;
}

.ticketing-header {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(5, 1fr);
  font-size: .8rem;
  font-weight: normal;
  text-align: center;
}

.ticketing-header.solo {
  grid-template-columns: repeat(4, 1fr);
}

.card-ticket .card-canvas.horz {
  float: right;
  padding: 0em;
}

/* box-shadow: -2px -2px 2px 0px var( --ticket-shadow), 2px 2px 2px 0px var( --ticket-shadow-2) !important;   */
.sub-ticket-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 4fr 1fr;
  gap: 1em;
  align-items: center;
  grid-template-areas:
    "sub-ticket-number sub-ticket-key sub-ticket-icon"
    "sub-ticket-number sub-ticket-assigned sub-ticket-icon";
  background-color: white;
  color: black;
  border: var(--sub-ticket-border) !important;
  border-radius: 1em;
  margin: 1em 0;
  padding: 1em;
}

.sub-ticket-container.assigned {
  border-color: var(--sem-blue);
  background-color: var(--sem-light-blue);
}

.sub-ticket-container.entered {
  border-color: var(--sem-red);
  background-color: var(--sem-light-red);
}

.sub-ticket-container.selectedd {
  background-color: rgb(59, 59, 59);
  color: white;
  border-color: black
}

.sub-ticket-assigned {
  grid-area: sub-ticket-assigned;
  font-size: .8rem;
  padding: 0;
}

.will-call>.sub-ticket-assigned {
  font-size: 1rem;
}

.will-call .sub-ticket-assigned {
  justify-content: space-evenly;
}

.sub-ticket-icon {
  grid-area: sub-ticket-icon;
  text-align: center;
}

.sub-ticket-key {
  grid-area: sub-ticket-key;
  font-size: .8rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.sub-ticket-number {
  grid-area: sub-ticket-number;
  text-align: center;
  font-size: 1.2rem;
  position: relative;
  font-weight: bold;
}

.sub-ticket-number>.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sub-tickets-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5em;
  font-size: .7rem;
}

.sub-tickets-container:nth-child(1)>div {
  text-align: center;
  padding: .5em 0 1em 0;
  font-weight: bold;
  font-size: 1rem;
}

.sub-tickets-container>.list {
  margin: 0
}

.sub-tickets-container>.list:nth-child(1)>.item>.content>.header {
  color: blue
}

.sub-tickets-container>.list:nth-child(2)>.item>.content>.header {
  color: green !important
}

.sub-ticket-container.selectedd .sub-ticket-number>.label {
  background-color: var(--sem-green) !important;
}

.print .menu-header-topper-simple,
.print .menu-header-topper,
.print .wizard-wrapper .ticket-subs,
.print .wizard-wrapper .card-ticket,
.print .wizard-wrapper .footer {
  background-color: white !important;
  color: black;
  border-color: white !important;
}


.tickets-container {
  padding: .5em !important;
  height: 100%;
}

.page-wrapper .tickets-container {
  background-color: black;
}

.icon-phone {
  margin-bottom: .25em;
}

.qr-code {
  background-color: white;
  height: 100%;
}

.qr-code>div:nth-child(1)>.label {
  padding: 1em;
  width: 100%;
  text-align: center;
  border-radius: 0;
}

.sub-ticket-forward {
  grid-area: sub-ticket-forward;
  padding: 1em !important;
  color: black
}

.print .vert-container,
.print .content,
.print .card,
.print .ticket-info,
.print .card-ticket,
.print .card-ticket-sub {
  height: auto !important;
}

.print .sub-ticket-qr {
  padding: 2em !important
}

.ticks .card-ticket-sub {
  padding: .5em
}

.ticket-subs>div>div {
  width: 96% !important;
  margin: auto;
}

.page-wrapper.print .ticket-subs>div>div {
  width: 100% !important;
}

.page-wrapper.print .sub-ticket-qr {
  display: inline;
  margin: auto !important;
  width: 100%;
}

/* @media print {
  .card-ticket-sub {page-break-after: always;}
} */


.ticket-sms>.buttons {
  margin-bottom: 2em;
}